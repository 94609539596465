import React from "react";
import { Flex, Heading, Text, Stack, Box, Image } from "@chakra-ui/react";
import "./three-steps.css";
import invite from "../../assets/invite-icon_small.png";
import collect from "../../assets/collect-icon_small.png";
import movie from "../../assets/movie-icon_small.png";

const steps: { stepLabel: string; stepImage: any }[] = [
  {
    stepLabel: "Invite Loved Ones",
    stepImage: invite,
  },
  {
    stepLabel: "Collect Videos",
    stepImage: collect,
  },
  {
    stepLabel: "Make Your Final Movie",
    stepImage: movie,
  },
];

const HowItWorks = ({ hideHeading }: { hideHeading?: boolean }) => {
  return (
    <Stack py="md" w="full" spacing="lg" align="center">
      {!hideHeading && (
        <Heading as="h3" size="h4" variant="center">
          Create your gift in {steps.length} easy steps
        </Heading>
      )}
      <Flex
        direction={["column", "column", "row"]}
        justify="center"
        w="90%"
        className="three-steps"
      >
        {steps.map((step, idx) => {
          return (
            <React.Fragment key={idx}>
              <Box className="step-block">
                <Image src={step.stepImage} />
                <Text>{step.stepLabel}</Text>
              </Box>
              <hr />
              <Box className="mobile-step-seperator" />
            </React.Fragment>
          );
        })}
      </Flex>
    </Stack>
  );
};

export default HowItWorks;
