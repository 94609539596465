import React from "react";

import {
  Box,
  Stack,
  Heading,
  Text,
  List,
  ListIcon,
  ListItem,
  HStack,
} from "@chakra-ui/react";
import { MdWbSunny } from "react-icons/md";

const TipsForRecording = (props) => {
  const recordingTips = [
    {
      component: (
        <Text size="small">
          If filming from your phone,{" "}
          <Text as={"span"} fontWeight="bold" size="small">
            hold your phone horizontal
          </Text>{" "}
          when filming.
        </Text>
      ),
    },
    {
      component: (
        <Text size="small">
          <Text as="span" fontWeight="bold" size="small">
            Center your face
          </Text>{" "}
          on your screen and make sure you have lots of{" "}
          <Text as="span" fontWeight="bold" size="small">
            natural light
          </Text>
          .
        </Text>
      ),
    },
    {
      component: (
        <Text size="small">
          Find a{" "}
          <Text as="span" fontWeight="bold" size="small">
            quiet place
          </Text>{" "}
          with no background noise and speak up.
        </Text>
      ),
    },
    {
      component: (
        <Text size="small">
          Don&apos;t worry about messing up,{" "}
          <Text as="span" fontWeight="bold" size="small">
            you can always re-do
          </Text>{" "}
          your video.
        </Text>
      ),
    },
    {
      component: (
        <Text size="small">
          <Text as="span" fontWeight="bold" size="small">
            Have FUN
          </Text>{" "}
          with it and let your personality shine!
        </Text>
      ),
    },
  ];

  return (
    <Stack p="md" spacing="lg">
      {!props.hideHeading && (
        <Heading
          variant="center"
          size="h2"
          textAlign="start"
          alignSelf="center"
        >
          Recording Tips
        </Heading>
      )}

      <List spacing={3}>
        {recordingTips.map((tip, idx) => {
          return (
            <ListItem key={idx}>
              <HStack>
                <ListIcon as={MdWbSunny} color="tumbleweed.500" />
                <Box>{tip.component}</Box>
              </HStack>
            </ListItem>
          );
        })}
      </List>

      {props.children}
    </Stack>
  );
};

export default TipsForRecording;
