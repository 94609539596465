import React from 'react'
import { Box } from 'grommet'
import ResponsiveEmbed from 'react-responsive-embed'

const YoutubeVideo = ({ youtubeVideoId="TzR9b5QW9sY", title="How Quilted Works" }) => (
    <Box margin={{ bottom: "medium" }} flex={false}>
        <ResponsiveEmbed title={title} src={`https://www.youtube.com/embed/${youtubeVideoId}`} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    </Box>
)

export default YoutubeVideo