import React, { useEffect, useRef, useState } from "react";

import { useStoryManagerState, useStoryManagerDispatch } from "./StoryManager";
import WizardNavigation from "./WizardNavigation";
import CreateStoryNavbar from "./CreateStoryNavbar";
import { Box, Divider, Flex, Stack, useToast } from "@chakra-ui/react";
import {
  iStoryManagerStateContext,
  StoryManagerStep,
  StoryStepId,
} from "../../types/StoryManagerState";
// @ts-ignore
import { useAnalytics } from "use-analytics";
import { useNavigate, useParams } from "react-router-dom";
import isDeepEqual from "fast-deep-equal/react";
import Loading from "../common/Loading";
import { isEmpty } from "react-redux-firebase";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { buildIllustrationUrl } from "../../utils/buildIllustrationUrl";
import { useFeatureFlags } from "../featureFlags";
import PricingBox from "./Steps/components/PricingBox";

const check = (variable: boolean | { [x: string]: any }) => {
  const isBoolean = typeof variable === "boolean";
  const isObject = typeof variable === "object";
  const notEmpty = !isEmpty(variable);
  const notTrue = !variable;
  const allKeysValid =
    // @ts-ignore
    isObject && Object.keys(variable).every((k) => variable[k]);
  const absValue = !!variable;
  return isBoolean
    ? absValue
    : isObject && notEmpty && (notTrue || allKeysValid);
};

const getValidation = (
  id: StoryStepId,
  obj: boolean | { [x: string]: any }
) => {
  const isBoolean = typeof obj === "boolean";
  if (isBoolean) return obj;
  const isObject = typeof obj === "object";
  if (!id || isEmpty(obj)) return true;
  // @ts-ignore
  if (isObject && obj.hasOwnProperty(id)) return obj[id];
  return obj || true;
};

const CreateStoryWizard = () => {
  const dispatch = useStoryManagerDispatch();
  const state = useStoryManagerState();
  const flags = useFeatureFlags();
  const includePricingInSetupStep = flags.includePricingInSetupStep.getValue();

  // @ts-ignore
  const {
    state: { story, steps, ready, authChecks, signup, signin, valid },
  }: { state: iStoryManagerStateContext } = state;
  const storyRef = useRef(story);
  const [running, setRunning] = useState(false);
  const [hasRouted, setHasRouted] = useState(false);
  const [reRouting, setReRouting] = useState(false);
  const [hasAutoAdvancedTemplate, setHasAutoAdvancedTemplate] = useState(false);
  const [currentStepIdx, setCurrentStepIdx] = useState(-1);
  const currentStep = steps && !!steps.length ? steps[currentStepIdx] : null;

  const validation = currentStep && {
    // @ts-ignore
    valid: getValidation(currentStep.id, valid),
  };
  const stepValid = validation && check(validation.valid);
  const canContinue = stepValid || false;

  // @ts-ignore
  const { track } = useAnalytics();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const { id, step: urlStep } = params;

  const lastStep: boolean = !!steps && currentStepIdx === steps.length - 1;
  const canGoBack: boolean = currentStepIdx > 0;

  const toast = useToast();

  useEffect(() => {
    setRunning(false);
    console.log("Step changed", params.step);
  }, [params.step]);

  const push = (step: StoryStepId) => {
    const stepIds = steps.map((step) => step.id);
    const idx = stepIds.indexOf(step);
    setCurrentStepIdx(idx);
    console.log("push", { location, step });
    return navigate(`../${step}`);
  };

  const replace = (step: StoryStepId) => {
    const stepIds = steps.map((step) => step.id);
    const idx = stepIds.indexOf(step);
    setCurrentStepIdx(idx);
    console.log("push", { location, step });
    return navigate(`../${step}`, { replace: true });
  };

  if (!isDeepEqual(storyRef.current, story)) {
    storyRef.current = story;
  }

  // Handle initial routing
  useEffect(() => {
    if (!hasRouted && ready && storyRef.current) {
      const stepIds = steps.map((step) => step.id);

      let targetStep: StoryStepId | null = (urlStep as StoryStepId) || null;
      const hasTemplate = !!storyRef.current.templateId;

      // If a url route isn't specified and a step exists in the database for story, use the step in the database as the target step
      if (!urlStep && storyRef.current && storyRef.current.step)
        targetStep = storyRef.current.step;

      if (!(storyRef.current.id && storyRef.current.template)) {
        // If no story ID or template, send user to beginning of setup
        doInitialTracking();
        push(hasTemplate ? "setup" : "template");
      } else if (targetStep === "signup" && authChecks && authChecks.signedIn) {
        // Signup no longer exists in flow if user logged in. Advance user to prompts page.
        console.log("push to prompts");
        push("prompts");
      } else {
        // If route invalid or isn't specified, send user to first step. Otherwise, update step index to reflect route.
        if (!targetStep || !stepIds.indexOf(targetStep)) {
          doInitialTracking();
          push("setup");
        } else setCurrentStepIdx(stepIds.indexOf(targetStep));
      }
      setHasRouted(true);
    }
  }, [ready, urlStep, storyRef.current]);

  const doInitialTracking = () => track("Started new story");
  const doTracking = () => {
    if (currentStepIdx) {
      try {
        const trackingEvent = steps[currentStepIdx].trackingEvent || null;
        if (trackingEvent)
          track(trackingEvent, {
            storyId: story?.id,
            storyTitle: story?.title,
            storySubject: story?.subject,
            storySlug: story?.slug,
            templateId: story?.template?.id,
            templateName: story?.template?.title,
            templateIllustration: buildIllustrationUrl(
              story?.template?.illustration
            ),
            includePricingInSetupStep,
          });
      } catch {
        console.error("Unable to track step");
      }
    }
  };

  const getNextStep = () => {
    let nextIndex = currentStepIdx + 1;
    if (steps.length <= nextIndex) --nextIndex;
    const nextStep: StoryManagerStep = steps[nextIndex];
    return nextStep;
  };

  const getPreviousStep = () => {
    let prevIndex = currentStepIdx - 1;
    if (prevIndex < 0) ++prevIndex;
    const prevStep: StoryManagerStep = steps[prevIndex];
    return prevStep;
  };

  const handleSignupContinue = () => {
    console.log("handling signup continue");
    console.dir({ signup, signin, state });
    const promise = new Promise((resolve, reject) => {
      dispatch({
        type: (signup && "signup") || (signin && "signin"),
        // @ts-ignore
        payload: { ...state.state, valid: {} },
        callback: (result: any) => {
          if (result) resolve(result);
          else reject({ reason: "No reason", signup });
        },
      });
    });
    return promise;
  };

  const incrementStep = () => {
    const stepIds = steps.map((step) => step.id);
    let next: StoryManagerStep;

    if (
      currentStep &&
      authChecks &&
      authChecks.signedIn &&
      !authChecks.isAnon &&
      (currentStep.id === "setup" || currentStep.id === "signup")
    ) {
      const nextIndex = stepIds.indexOf("prompts");
      next = steps[nextIndex];
    } else next = getNextStep();

    if (next) push(next.id);
  };

  const onStepChanging = (
    step: StoryStepId,
    options: { valid?: any; changed?: boolean },
    callback: any
  ) => {
    const payload: { step: StoryStepId; valid?: any; changed?: boolean } = {
      step,
      ...(options || {}),
    };
    dispatch({
      type: "stepChanging",
      payload,
      callback,
    });
  };

  useEffect(() => {
    console.log("authChecks", { authChecks });
    if (urlStep === "signup" && authChecks?.signedIn && !reRouting) {
      setReRouting(true);
      console.log("Step is signup and user logged in, go to prompts.");
      onStepChanging("prompts", {}, () => {
        replace("prompts");
      });
    }
  }, [authChecks]);

  useEffect(() => {
    console.group("Advancing story");
    console.log("CreateStoryWizard.useEffect", { current: storyRef.current });
    if (
      story?.step === "template" &&
      story?.templateId &&
      !hasAutoAdvancedTemplate
    ) {
      console.log("advancing story");
      setHasAutoAdvancedTemplate(true);
      incrementStep();
    }
    console.groupEnd();
  }, [storyRef.current]);

  const deincrementStep = () => {
    if (canGoBack) {
      const stepIds = steps.map((step) => step.id);
      let prev: StoryManagerStep;

      if (
        currentStep &&
        authChecks &&
        authChecks.signedIn &&
        !authChecks.isAnon &&
        currentStep.id === "prompts"
      ) {
        const prevIndex = stepIds.indexOf("setup");
        prev = steps[prevIndex];
      } else prev = getPreviousStep();

      if (prev) push(prev.id);
    }
  };

  const onCanContinueException = () => {
    switch (currentStep?.id) {
      case "template":
      // Fallthrough case is okay here. If no template ID, but still can't continue, the switch statement should go to the default toast message.
      // @ts-ignore
      case "setup":
        if (!story?.templateId) {
          toast({
            title: "You must select a template to continue",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
          break;
        }

      default:
        toast({
          title: "Required fields are missing",
          description:
            "Please ensure all required fields are filled out before continuing",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        break;
    }
  };

  const onNext = () => {
    setRunning(true);
    console.log("CreateStoryWizard.onNext", {
      story,
      current: storyRef.current,
    });
    if (story && currentStep) {
      const _step = getNextStep().id;

      if (!canContinue) {
        console.log("Cannot continue");
        onCanContinueException();
      } else if (lastStep) {
        console.log("CreateStoryWizard.onNext", { lastStep });
        dispatch({
          type: "launch",
        });
      } else if (currentStep.id === "signup") {
        console.log("CreateStoryWizard.onNext", {
          currentStep,
          nextStep: _step,
        });
        handleSignupContinue();
      } else {
        console.log("CreateStoryWizard.onNext");
        onStepChanging(
          _step,
          !story.id ? { valid: {}, changed: false } : {},
          incrementStep
        );
      }
    }
  };
  console.log({ includePricingInSetupStep });
  return (
    <Box w="full">
      <Flex
        direction="column"
        alignSelf="stretch"
        flexShrink={0}
        align="stretch"
        justify="start"
      >
        {currentStep && ready ? (
          <>
            <CreateStoryNavbar
              // @ts-ignore
              step={currentStep}
              canGoBack={canGoBack}
              onClickPrevious={() => {
                deincrementStep();
              }}
              running={running}
            />
            <Helmet>
              <title>Quilted | Create Story - {currentStep.id}</title>
            </Helmet>
            <Box py="lg" id={currentStep.id}>
              {currentStep.component}
              {includePricingInSetupStep === currentStepIdx && (
                <Stack spacing="8" mt="8">
                  <Divider />
                  <PricingBox templateId={story?.templateId} />
                </Stack>
              )}
            </Box>
            {!(currentStep.id === "template" && !story?.templateId) && (
              <WizardNavigation
                onClickNext={() => {
                  doTracking();
                  onNext();
                }}
                onClickPrevious={() => {
                  deincrementStep();
                }}
                lastStep={lastStep}
                canGoBack={canGoBack}
                canContinue={canContinue}
                running={running}
              />
            )}
          </>
        ) : (
          <Loading />
        )}
      </Flex>
    </Box>
  );
};

export default CreateStoryWizard;

export interface RouteParams {
  id?: "new" | string;
  step?: "setup" | "signup" | "prompts" | "review" | "storytellers";
  setup: "start" | "edit";
}
