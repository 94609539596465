import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import {
  Flex,
  Image,
  Text,
  AspectRatio,
  Stack,
  Badge,
  Skeleton
} from "@chakra-ui/react";

import { BrowserView, MobileView } from "react-device-detect";
import { timestampToMoment } from '../../utils/convert';
import { isLoaded } from 'react-redux-firebase';
import { useNavigate } from 'react-router-dom';
import { buildIllustrationUrl } from '../../utils/buildIllustrationUrl';

const StoryView = ({children}) => (<><BrowserView>
  <AspectRatio maxW="400px" ratio={1}>
    {children}
  </AspectRatio>
</BrowserView>
<MobileView>
{children}
</MobileView></>)

const StoryCard = ({
  story,
  active,
}) => {
  const {accentColor, illustration} = story.template;
  const { recipientPhoto } = story;
  const [loaded, setLoaded] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    if(!loaded) {
      setLoaded(!!isLoaded(story))
    }
  }, [story, loaded])

  const handleSelect = () => {
    switch(story.state)
    {
      case "setup":
        {
          navigate(`/edit/${story.id}`);
        break;
        }
      default:
          navigate(`/my-stories/story/${story.id}`);
        break;
    }
  }

  const getDetail = () => {
    let date = timestampToMoment(story.createdAt).format('LL');
    let label = "Created";
    let status = 'setup';
    let colorScheme = `${active ? accentColor || "airBlue" : "gray"}`

    switch(story.state)
    {
      case "setup": {
        break;
      }
      case "fulfilled": {
          status ="needs approval"
          date = ""
          label = "Final Video Needs Approval"
          colorScheme = "tumbleweed"
          break;
      }
      case "complete": {
          status ="completed"
          date = timestampToMoment(story.startDate).format('LL')
          label = "Completed on"
          break;
      }
      default: {
        date = story.dueDate && timestampToMoment(story.dueDate).format('LL')
        label = "Due by"
        status = "running"
        break;
      }
    }

    return (
      <>
        {date && <Stack spacing={4}>
          <Text>
            <span>{label}</span> <span>{date}</span>
          </Text>
        </Stack>}
        <Text top="-0.5" right="-0.5" pos={"absolute"}>
          <Badge colorScheme={colorScheme}>
            <span>{status}</span>
          </Badge>
        </Text>
      </>
    );
  }

  return (<Skeleton isLoaded={loaded}>
    <StoryView>
    <Flex
      direction={["row", "column"]}
      justify="center"
      rounded="lg"
      cursor="pointer"
      p={["sm", "md"]}
      bgColor={
        `${active ? accentColor || "airBlue" : "gray"}.50`
      }
      bgImage={
        !illustration
          ? `/img/package-icons/dot-bg.svg`
          : undefined
      }
      border="2px"
      borderColor={`${active ? accentColor || "airBlue" : "gray"}.100`}
      onClick={handleSelect}
      pos={"relative"}
    >
      {illustration && (
      <Image
        p="6"
        pl={["sm", "6"]}
        src={recipientPhoto}
        fallbackSrc={buildIllustrationUrl(illustration)}
        maxW={["150px", "100%"]}
        maxH={["150px", "60%"]}
        borderRadius={ !!recipientPhoto ? "full" : undefined }
      />)}
      <Stack spacing="0" justify="center">
        <Text fontWeight="bold" alignSelf="flex-start">
          <span>{story.title}</span>
        </Text>
        {story.recipient && (
          <Text fontSize={["sm", "xs"]}><span>{story.recipient}</span></Text>
        )}
        {getDetail()}
      </Stack>
    </Flex>
    </StoryView></Skeleton>);
};

StoryCard.propTypes = {
  /* eslint-enable react/no-unused-prop-types */
  story: PropTypes.object,
  active: PropTypes.bool
};

export default StoryCard;