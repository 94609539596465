import { signin, signup } from '../../utils/authHandlers';

export const signIn = (credentials) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        signin(firebase)(
            credentials
        ).then((user) => {
            dispatch({
                type: 'SIGNIN_SUCCESS',
                user
            });
        }).catch((err) => {
            dispatch({
                type: 'SIGNIN_ERROR',
                err
            });
        })
    };
};

export const signOut = (callback) => {
    console.log('signOut called');
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        firebase.auth().signOut().then(() => {
          dispatch({ type: 'SIGNOUT_SUCCESS'});
          callback ? callback() : window.location.reload();
        });
    }
}

export const setSignUpInfo = (newUser) => {
    return (dispatch) => {
        dispatch({ type: 'SET_SIGNUP_INFO', newUser })
    }
}

export const signUp = (newUser, ownProps) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        const auth = firebase.auth();
        signup(firebase, ownProps.firestore)(newUser)
        .then(() => {
            dispatch({
                type: 'SIGNUP_SUCCESS'
            });
        })
        .catch((err) => {
            auth.signOut();
            dispatch({
                type: 'SIGNUP_ERROR',
                err
            });
        })
    };
}

export const signInAnon = () => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        firebase.auth().signInAnonymously().then((user) => {
          dispatch({ type: 'SIGNIN_SUCCESS'}, user)  ;
        });
    }
}

export const sendPasswordReset = (email) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        firebase.auth().sendPasswordResetEmail(email).then((user) => {
          dispatch({ type: 'PASSWORD_RESET_SENT'}, {email});
        }).catch(error => {
            dispatch({ type: 'PASSWORD_RESET_ERROR', error});
        });
    }
}

export const verifyPasswordResetCode = (code) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        firebase.auth().verifyPasswordResetCode(code).then((email) => {
          dispatch({ type: 'PASSWORD_RESET_CODE_VALID', email});
        }).catch(error => {
          dispatch({ type: 'PASSWORD_RESET_CODE_INVALID', error});
        });
    }
}

export const resetPassword = (code, password) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        firebase.auth().confirmPasswordReset(code, password)
        .then((resp) => {
            //success
            dispatch({ type: 'PASSWORD_RESET_SUCCESS'});
        }).catch(error => {
          dispatch({ type: 'PASSWORD_RESET_ERROR', error});
        });
    }
}