const hasNetworkType =
    global &&
    global.navigator &&
    global.navigator.connection &&
    global.navigator.connection.effectiveType;

const hasNetworkDownlink =
    global &&
    global.navigator &&
    global.navigator.connection &&
    global.navigator.connection.downlink;

const getVideoQuality = (preferred = "FHD") => {
    const videoQualitySettings = new Map([
        ["STANDARD", {
            width: {
                ideal: 480,
                max: 720
            }
        }],
        ["HD", {
            width: {
                ideal: 720,
                max: 1080
            }
        }],
        ["FHD", {
            width: {
                ideal: 1080
            }
        }],
        ["4K", {
                width: { ideal: 4096 },
                height: { ideal: 2160 }
        }]
    ]);

    const connectionType = hasNetworkType ?
        global.navigator.connection.effectiveType :
        null;
    const connectionDownlink = hasNetworkDownlink ?
        global.navigator.connection.downlink :
        null;

    let quality = preferred;

    if (
        (connectionDownlink && connectionDownlink < 4) ||
        (connectionType && connectionType !== "4g")
    ) {
        quality = "STANDARD";
    } else if (
        !videoQualitySettings.has(preferred)
    ) {
        quality = "FHD";
    }
    const videoQuality = videoQualitySettings.get(quality);
    return videoQuality;

}

export default getVideoQuality;