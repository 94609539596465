import React from "react";
import { Flex, Stack, Button } from "@chakra-ui/react";
import {
  iStoryManagerStateContext,
  StoryStepId,
} from "../../../../types/StoryManagerState";
import {
  useStoryManagerDispatch,
  useStoryManagerState,
} from "../../StoryManager";
import { iStoryPrompt } from "../../../../types/Templates";
import PromptCard from "./PromptCard";
import PromptChooser from "./PromptChooser";
import { useDisclosure } from "@chakra-ui/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalDialog from "../../../common/ModalDialog";

const PromptsList = ({ id }: { id: StoryStepId }) => {
  const dispatch = useStoryManagerDispatch();
  // @ts-ignore
  const {
    state: { story },
  }: { state: iStoryManagerStateContext } = useStoryManagerState();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleEdit = (idx: number, changes: Partial<iStoryPrompt>) => {
    if (story?.prompts) {
      story.prompts[idx] = { ...story.prompts[idx], ...changes };
      story.step = id;
      dispatch({
        type: "update",
        payload: { story, changed: true },
      });
    }
  };

  const handleDelete = (idx: number) => {
    if (story?.prompts) {
      story.prompts.splice(idx, 1);
      story.prompts = story.prompts.map((p, index) => ({ ...p, idx: index }));
      story.step = id;
      dispatch({
        type: "update",
        payload: { story, changed: true },
      });
    }
  };

  return (
    <Stack align="center" spacing="4" justify="start" mb="md" w="full">
      {story?.prompts?.map((prompt, idx) => (
        <PromptCard
          storyId={story.id}
          theme={{ width: "100%" }}
          prompt={{ idx, ...prompt }}
          key={prompt.text}
          onEdit={(changes: Partial<iStoryPrompt>) => handleEdit(idx, changes)}
          onDelete={() => handleDelete(idx)}
        />
      ))}
      <Button
        w="full"
        p="lg"
        rounded="lg"
        leftIcon={
          <Flex
            rounded="full"
            border="1px solid black"
            justify="center"
            align="center"
            color="gray"
            p="1"
            minW="1.75rem"
            minH="1.75rem"
          >
            <FontAwesomeIcon icon="plus" />
          </Flex>
        }
        onClick={onOpen}
        variant="outline"
        colorScheme="blackAlpha"
        color="black"
        fontWeight="normal"
      >
        {!!story?.prompts?.length ? "Add prompts" : "Add your first prompt"}
      </Button>
      <ModalDialog isOpen={isOpen} onClose={onClose} heading="Add prompts">
        <PromptChooser onCancel={onClose} />
      </ModalDialog>
    </Stack>
  );
};

export default PromptsList;
