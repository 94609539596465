import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Box,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Stack,
    Button,
    Heading,
    Text,
  } from '@chakra-ui/react';
import { sendPasswordReset } from '../../store/actions/authActions';
import PageContainer from '../../layout/components/PageContainer';
import { useAnalytics } from "use-analytics";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from 'react-router-dom';

const RequestPasswordReset = (props) => {

  const navigate = useNavigate();

    const schema = yup.object().shape({
        email: yup.string().email().required("Please enter your email address")
    });
    const {track} = useAnalytics();

  const { register, setFocus, watch, formState } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const { errors, isValid } = formState;

  const [email] = watch(["email"]);

  useEffect(() => {
    !props.sent && setFocus("email");
  }, [setFocus]);


    const handleSubmit = () => {
        props.sendPasswordReset(email);
        track("passwordResetRequested");
    }

    return (<PageContainer minW={undefined} maxW="90vw" w="3xl">
        <form>
                <Flex
                minH="lg"
                align={'center'}
                justify={'center'}
                >
                <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                  <Stack align={'center'}>
                    <Heading fontSize={'4xl'}>Reset your password</Heading>
                    <Text fontSize={'lg'} color={'gray.600'}>
                    { !props.sent && (<>Enter the email address that you used to register. We&apos;ll send you an email with a link to reset your password.</>)}
                    { props.sent && (<>We&apos;ve sent you an email with a link to reset your password.</>)}
                    </Text>
                    { props.sent && (<Button onClick={() => { navigate('/signin', { replace:true}) }}>Sign In</Button>)}
                  </Stack>
                { !props.sent && (<Box
                    rounded={'lg'}
                    boxShadow={'lg'}
                    p={8}>
                    <Stack spacing={4}>
                      <FormControl id="email" isInvalid={errors.email}>
                        <FormLabel variant="headingLabel">Email</FormLabel>
                        <Input {...register("email")} placeholder="youremail@domain.com" />
                        <FormErrorMessage color="puce.700">
                            {errors.email?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <Stack spacing={10} direction={'row'}
                          align={'start'}
                          justify={'space-between'}>
                        <Button
                        colorScheme="gray"
                          variant="outline"
                          onClick={() => { navigate('/signin', {replace:true}) }}>
                          Cancel
                        </Button>
                        <Button
                          colorScheme="majesty" onClick={handleSubmit} disabled={!isValid}>
                          Reset My Password
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>)}
                </Stack>
              </Flex>
              </form>
    </PageContainer>);
}



const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        error: state.auth.resetPasswordError,
        sent: state.auth.resetPasswordSent
    };
  }

  const mapDispatchToProps = (dispatch) => {
    return {
        sendPasswordReset: (email) => dispatch(sendPasswordReset(email))
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(RequestPasswordReset);