import React from 'react';

import { Box, Button, Text } from 'grommet';

const MenuButton = ({ label, icon, ...rest }) => (
  <Button plain {...rest}>
    {({ hover }) => (
      <Box
        direction="row"
        gap="xsmall"
        align="center"
        elevation={hover ? "small" : undefined}
        pad={{ horizontal: "large", bottom: "small", top: "small" }}
      >
        {icon}
        <Text size="small">
          <span>{label}</span>
        </Text>
      </Box>
    )}
  </Button>
);

export default MenuButton;