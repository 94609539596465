import React, {
    useLayoutEffect,
    useCallback,
    useEffect,
    useState,
    useMemo,
    useRef,
  } from "react";
  
  import {
    useMediaQuery,
    useTheme,
    Progress,
    VStack,
    Button,
    Flex,
    Box,
  } from "@chakra-ui/react";
  
  import { motion, PanInfo, useAnimation, useMotionValue } from "framer-motion";
  import { useBoundingRect } from "../../hooks";
  import { percentage } from "../../utils";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  
  const MotionFlex = motion(Flex);
  
  type WithChildren<T = unknown> = 
  T & { children?: React.ReactNode };

  const transitionProps = {
    stiffness: 400,
    type: "spring",
    damping: 60,
    mass: 3,
  };
  enum progressIndicator {
      dots,
      progress
  }
  type CarouselProps = WithChildren<{
    gap: number;
    navType?: progressIndicator;
  }>;

  
  const ChakraCarousel = ({ children, gap, navType=progressIndicator.dots }:CarouselProps) => {
    const [trackIsActive, setTrackIsActive] = useState(false);
    const [multiplier, setMultiplier] = useState(0.35);
    const [sliderWidth, setSliderWidth] = useState(0);
    const [activeItem, setActiveItem] = useState(0);
    const [constraint, setConstraint] = useState(0);
    const [itemWidth, setItemWidth] = useState(0);
  
    const initSliderWidth = useCallback((width:number) => setSliderWidth(width), []);
  
    const positions = useMemo(
      () =>
        Array.isArray(children) &&
        children.map((_, index) => -Math.abs((itemWidth + gap) * index)),
      [children, itemWidth, gap]
    );
  
    const { breakpoints } = useTheme();
  
    const [isBetweenBaseAndMd] = useMediaQuery(
      `(min-width: ${breakpoints.base}) and (max-width: ${breakpoints.md})`
    );
  
    const [isBetweenMdAndXl] = useMediaQuery(
      `(min-width: ${breakpoints.md}) and (max-width: ${breakpoints.xl})`
    );
  
    const [isGreaterThanXL] = useMediaQuery(`(min-width: ${breakpoints.xl})`);
  
    useEffect(() => {
      if (isBetweenBaseAndMd) {
        setItemWidth(sliderWidth - gap);
        setMultiplier(0.65);
        setConstraint(1);
      }
      if (isBetweenMdAndXl || isGreaterThanXL) {
        setItemWidth(sliderWidth / 2 - gap);
        setMultiplier(0.5);
        setConstraint(2);
      }/*
      if (isGreaterThanXL) {
        setItemWidth(sliderWidth / 3 - gap);
        setMultiplier(0.35);
        setConstraint(3);
      }*/
    }, [isBetweenBaseAndMd, isBetweenMdAndXl, isGreaterThanXL, sliderWidth, gap]);
  
    const sliderProps = {
      setTrackIsActive,
      initSliderWidth,
      setActiveItem,
      activeItem,
      constraint,
      itemWidth,
      positions:positions || [],
      gap,
      navType
    };
  
    const trackProps = {
      setTrackIsActive,
      setActiveItem,
      trackIsActive,
      sliderWidth,
      activeItem,
      constraint,
      multiplier,
      itemWidth,
      positions:positions || [],
      gap,
    };
  
    const itemProps = {
      setTrackIsActive,
      setActiveItem,
      trackIsActive,
      activeItem,
      constraint,
      itemWidth,
      positions:positions || [],
      gap,
    };
  
    return (
      <Slider {...sliderProps}>
        <Track {...trackProps}>
          {Array.isArray(children) &&
            children.map((child, index) => (
              <Item {...itemProps} index={index} key={index}>
                {child as any}
              </Item>
            ))}
        </Track>
      </Slider>
    );
  };
  
  type SliderProps = WithChildren<{
      setTrackIsActive:React.Dispatch<React.SetStateAction<boolean>>,
      setActiveItem:React.Dispatch<React.SetStateAction<number>>,
      initSliderWidth:(width:number)=>void,
      activeItem:number,
      constraint:number,
      itemWidth:number,
      positions:number[],
      gap:number,
      navType: progressIndicator
    }>;
  
  const Slider = ({
    activeItem,
    constraint,
    itemWidth,
    positions,
    children,
    gap,
    navType,
    setTrackIsActive,
    setActiveItem,
    initSliderWidth,
  }:SliderProps) => {
    const [ref, dimensions] = useBoundingRect() as [(node: any) => void, {width:number}, any];
    const { width } = dimensions as { width:number};
  
    useEffect(() => {
      console.log('Width changed', width);
    }, [width])
  
    useLayoutEffect(
      () => { !!width && initSliderWidth(Math.round(width)) },
      [width, initSliderWidth]
    );
  
    const handleFocus = () => setTrackIsActive(true);
  
    const handleDecrementClick = () => {
      setTrackIsActive(true);
      !(activeItem === positions.length - positions.length) &&
        setActiveItem((prev) => prev - 1);
    };
  
    const handleIncrementClick = () => {
      setTrackIsActive(true);
      !(activeItem === positions.length - constraint) &&
        setActiveItem((prev) => prev + 1);
    };
  
    const gotoX = (item:number) => {
      !(activeItem === item) && setActiveItem(item);
    };
  
    return (
      <>
        <Box
          ref={ref}
          w={{ base: "100%", md: `calc(100% + ${gap}px)` }}
          ml={{ base: 0, md: `-${gap / 2}px` }}
          px={`${gap / 2}px`}
          position="relative"
          overflow="hidden"
          _before={{
            bgGradient: "linear(to-r, base.d400, transparent)",
            position: "absolute",
            w: `${gap / 2}px`,
            content: "''",
            zIndex: 1,
            h: "100%",
            left: 0,
            top: 0,
          }}
          _after={{
            bgGradient: "linear(to-l, base.d400, transparent)",
            position: "absolute",
            w: `${gap / 2}px`,
            content: "''",
            zIndex: 1,
            h: "100%",
            right: 0,
            top: 0,
          }}
        >
          {children}
        </Box>
        {navType === progressIndicator.progress && (
        <Flex w={`${itemWidth}px`} mt={`${gap / 2}px`} mx="auto">
          <Button
            onClick={handleDecrementClick}
            onFocus={handleFocus}
            mr={`${gap / 3}px`}
            color="airBlue.200"
            variant="link"
            minW={0}
            data-testid="left"
          >
            <FontAwesomeIcon
            icon={["fas","angle-left"]}
            color="airBlue"
          />
          </Button>
  
          <Progress
            value={percentage(activeItem, positions.length - constraint)}
            alignSelf="center"
            borderRadius="2px"
            bg="base.d100"
            flex={1}
            h="3px"
            sx={{
              "> div": {
                backgroundColor: "airBlue",
              },
            }}
          />
  
          <Button
            onClick={handleIncrementClick}
            onFocus={handleFocus}
            ml={`${gap / 3}px`}
            color="airBlue.200"
            variant="link"
            zIndex={2}
            minW={0}
            data-testid="right"
          >
            <FontAwesomeIcon
            icon={["fas","angle-right"]}
            color="airBlue"
          />
          </Button>
        </Flex>
        )}
        {navType === progressIndicator.dots && (
        <Flex
          w={[`${itemWidth}px`,'full']}
          mt={`${gap / 2}px`}
          mx="auto"
          align="center"
          justify="center"
          wrap="wrap"
        >
          {positions.map((position, index) => (
            <Button
              onClick={() => gotoX(index)}
              onFocus={handleFocus}
              mr={`${gap / 5}px`}
              ml={`${gap / 5}px`}
              color={activeItem === index ? "airBlue.700" : "airBlue.200"}
              variant="unstyled"
              minW={["8px", "15px"]}
              // transform="scale(2)"
              key={`btnCarousel_${position}_${index}`}
              maxH="1rem"
            >
              &#9679;
            </Button>
          ))}
        </Flex>)}
      </>
    );
  };
  
  
  type TrackProps = WithChildren<{
      setTrackIsActive:React.Dispatch<React.SetStateAction<boolean>>,
      setActiveItem:React.Dispatch<React.SetStateAction<number>>,
      trackIsActive:boolean,
      activeItem:number,
      constraint:number,
      multiplier:any,
      itemWidth:number,
      positions:number[],
    }>;
  
  const Track = ({
    setTrackIsActive,
    setActiveItem,
    trackIsActive,
    activeItem,
    constraint,
    multiplier,
    itemWidth,
    positions,
    children,
  }:TrackProps) => {
    const [dragStartPosition, setDragStartPosition] = useState(0);
    const controls = useAnimation();
    const x = useMotionValue(0);
    const node = useRef(null);
  
    const handleDragStart = () => setDragStartPosition(positions[activeItem]);
  
    const handleDragEnd = (_:MouseEvent | TouchEvent | PointerEvent, info:PanInfo) => {
      const distance = info.offset.x;
      const velocity = info.velocity.x * multiplier;
      const direction = velocity < 0 || distance < 0 ? 1 : -1;
  
      const extrapolatedPosition =
        dragStartPosition +
        (direction === 1
          ? Math.min(velocity, distance)
          : Math.max(velocity, distance));
  
      const closestPosition = positions.reduce((prev, curr) => {
        return Math.abs(curr - extrapolatedPosition) <
          Math.abs(prev - extrapolatedPosition)
          ? curr
          : prev;
      }, 0);
  
      if (!(closestPosition < positions[positions.length - constraint])) {
        setActiveItem(positions.indexOf(closestPosition));
        controls.start({
          x: closestPosition,
          transition: {
            velocity: info.velocity.x,
            ...transitionProps,
          },
        });
      } else {
        setActiveItem(positions.length - constraint);
        controls.start({
          x: positions[positions.length - constraint],
          transition: {
            velocity: info.velocity.x,
            ...transitionProps,
          },
        });
      }
    };
  
    const handleResize = useCallback(
      () =>
        controls.start({
          x: positions[activeItem],
          transition: {
            ...transitionProps,
          },
        }),
      [activeItem, controls, positions]
    );
  
    const handleClick = useCallback(
      (event) =>
        // @ts-ignore
        node.current.contains(event.target)
          ? setTrackIsActive(true)
          : setTrackIsActive(false),
      [setTrackIsActive]
    );
  
    const handleKeyDown = useCallback(
      (event) => {
        if (trackIsActive) {
          if (activeItem < positions.length - constraint) {
            if (event.key === "ArrowRight" || event.key === "ArrowUp") {
              event.preventDefault();
              setActiveItem((prev) => prev + 1);
            }
          }
          if (activeItem > positions.length - positions.length) {
            if (event.key === "ArrowLeft" || event.key === "ArrowDown") {
              event.preventDefault();
              setActiveItem((prev) => prev - 1);
            }
          }
        }
      },
      [trackIsActive, setActiveItem, activeItem, constraint, positions.length]
    );
  
    useEffect(() => {
      handleResize();
  // @ts-ignore
      document.addEventListener("keydown", handleKeyDown);
      // @ts-ignore
      document.addEventListener("mousedown", handleClick);
      return () => {
          // @ts-ignore
        document.removeEventListener("keydown", handleKeyDown);
        // @ts-ignore
        document.removeEventListener("mousedown", handleClick);
      };
    }, [handleClick, handleResize, handleKeyDown]);
  
    return (
      <>
        {itemWidth && (
          <VStack ref={node} spacing={5} alignItems="stretch">
            <MotionFlex
              dragConstraints={node}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              animate={controls}
              style={{ x }}
              drag="x"
              _active={{ cursor: "grabbing" }}
              minWidth="min-content"
              flexWrap="nowrap"
              cursor="grab"
            >
              {children}
            </MotionFlex>
          </VStack>
        )}
      </>
    );
  };
  
  
  type ItemProps = WithChildren<{
      setTrackIsActive:React.Dispatch<React.SetStateAction<boolean>>,
      setActiveItem:React.Dispatch<React.SetStateAction<number>>,
      activeItem:number,
      constraint:number,
      itemWidth:number,
      positions:number[],
      index:number,
      gap:number,
    }>;
  
  const Item = ({
    setTrackIsActive,
    setActiveItem,
    activeItem,
    constraint,
    itemWidth,
    positions,
    children,
    index,
    gap,
  }:ItemProps) => {
    const [userDidTab, setUserDidTab] = useState(false);
  
    const handleFocus = () => setTrackIsActive(true);
  
    const handleBlur = () => {
      userDidTab && index + 1 === positions.length && setTrackIsActive(false);
      setUserDidTab(false);
    };
  
    const handleKeyUp = (event:React.KeyboardEvent) =>
      event.key === "Tab" &&
      !(activeItem === positions.length - constraint) &&
      setActiveItem(index);
  
    const handleKeyDown = (event:React.KeyboardEvent) => event.key === "Tab" && setUserDidTab(true);
  
    return (
      <Flex
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
        onKeyDown={handleKeyDown}
        w={`${itemWidth}px`}
        _notLast={{
          mr: `${gap}px`,
        }}
        py="4px"
      >
        {children}
      </Flex>
    );
  };
  
  export default ChakraCarousel;