import {enforceE164PhoneFormat} from './formatPhoneNumber';

const rxEmail = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

const validateEmail = (email) => 
{
    if (!email || (email && email.length>254) || !rxEmail.test(email)) {
        return false;
    }
    const [account, domain] = email.split('@');
    return !(account.length > 64 || domain.split('.').some((part) => part.length > 63));
}

const validatePhone = (phoneNumber) => {
    return !!(enforceE164PhoneFormat(phoneNumber));
}


const validateContact = (contact) => {
    const {name, email, phone, contactPreference } = contact;

    const nameIsValid = (name && name.length);
    const emailOrPhoneExist = (email || phone);
    const emailIsValid = emailValidation.validate(email);
    const phoneIsValid = phoneValidation.validate(phone);
    const preferedContactIsValid = contactPreference === "EMAIL" ? emailIsValid : phoneIsValid;

    const valid = (nameIsValid) && (emailOrPhoneExist) && (preferedContactIsValid);
    const errors = [];
    if(!nameIsValid) errors.push("Name Missing");
    if(contactPreference === "EMAIL" && !emailIsValid) errors.push("Phone Invalid");
    if(contactPreference === "SMS" && !phoneIsValid) errors.push("Email Invalid");

    contact.isValid = valid;
    return { valid, errors };
}

export const emailValidation = { validate: validateEmail };
export const phoneValidation = { validate: validatePhone, format: enforceE164PhoneFormat };
export const contactValidation = { validate: validateContact };

export default {
    emailValidation,
    phoneValidation,
    contactValidation
};