import React from "react";
import { isEmpty, isLoaded } from "react-redux-firebase";
import amplitudePlugin from "@analytics/amplitude";
import mixpanelPlugin from "@analytics/mixpanel";
import fullStoryPlugin from "@analytics/fullstory";
import googleAnalyticsPlugin from "@analytics/google-analytics";
import googleTagManagerPlugin from "@analytics/google-tag-manager";
import intercomPlugin from "@analytics/intercom";
import facebookPixelPlugin from "./plugins/facebookPixelPlugin";
import pageViewCounterPlugin from "./plugins/pageViewCounterPlugin";
import firebase from "firebase";

import { useSelector } from "react-redux";
import useLocalStorage from "../../hooks/useLocalStorage";

import config from "../../config/config";
import { Analytics } from "analytics";
import LoadingPage from "../../pages/LoadingPage";
import useCounter from "../../hooks/useCounter";
import { useFeatureFlags } from "../featureFlags";

const debug = false;

const generateUserId = (auth) => {
  const uid =
    (isLoaded(auth) && !isEmpty(auth) && auth.uid) || new Date().valueOf();
  return `${uid}`;
};

export const buildConfig = (analyticsUserId, initializedPlugins = []) => {
  const {
    amplitude,
    fullstory,
    "facebook-pixel": facebookPixel,
    "google-analytics": googleAnalytics,
    "google-tag-manager": googleTagManager,
    intercom,
    mixpanel,
    rules,
  } = config.analytics;

  const defaultConfig = { enabled: false };

  const plugins = [...initializedPlugins];
  if (amplitude) {
    plugins.push(
      amplitudePlugin({
        ...defaultConfig,
        ...amplitude,
        // See options at https://bit.ly/3dRdZnE
      })
    );
  }
  if (facebookPixel) {
    plugins.push(
      facebookPixelPlugin({
        ...defaultConfig,
        ...facebookPixel,
        advancedMatching: {
          userId: analyticsUserId,
        },
      })
    );
  }
  if (fullstory) {
    plugins.push(
      fullStoryPlugin({
        ...defaultConfig,
        ...fullstory,
      })
    );
  }
  if (googleTagManager) {
    plugins.push(
      googleAnalyticsPlugin({
        ...defaultConfig,
        ...googleAnalytics,
      })
    );
  }
  if (googleTagManager) {
    plugins.push(
      googleTagManagerPlugin({
        ...defaultConfig,
        ...googleTagManager,
      })
    );
  }
  if (intercom) {
    plugins.push(
      intercomPlugin({
        ...defaultConfig,
        ...intercom,
      })
    );
  }
  if (mixpanel) {
    plugins.push(
      mixpanelPlugin({
        ...defaultConfig,
        ...mixpanel,
      })
    );
  }

  const analyticsConfig = {
    app: config.firebase.appId,
    debug: process.env.NODE_ENV === "development",
    plugins,
  };

  return { analyticsConfig, rules };
};

const generateAnalytics = (analyticsUserId, initializedPlugins) => {
  const { analyticsConfig, rules } = buildConfig(
    analyticsUserId,
    initializedPlugins
  );
  return { instance: Analytics(analyticsConfig), rules: rules || [] };
};

const AnalyticsInitializer = ({ children }) => {
  const flags = useFeatureFlags();
  const { auth, profile } = useSelector((state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }));

  const { uid, email } = auth;
  const counter_name = "views";
  const counter_path = `stats/pages`;
  const counter = useCounter(counter_name, counter_path);
  const countForRoutes = ["/start/new/setup"];

  const [analyticsUserId, setAnalyticsUserId] = useLocalStorage(
    "_analyticsUserId",
    generateUserId(auth)
  );

  const [identified, setIdentified] = React.useState(false);
  const [prevUid, setPrevUid] = React.useState(null);

  const [analytics, setAnalytics] = React.useState({
    instance: undefined,
    rules: [],
  });

  const getListeners = (instance) => {
    const listeners = [];
    if (instance) {
      listeners.push(
        instance.on("ready", ({ payload, ...rest }) => {
          debug && console.group("Analytics");
          debug && console.log("Analytics.ready.payload", payload, rest);
          debug && console.groupEnd();
        })
      );
      listeners.push(
        instance.on("registerPlugins", ({ payload, ...rest }) => {
          debug && console.group("Analytics");
          debug && console.log("Analytics.registerPlugins.payload", payload, rest);
          debug && console.groupEnd();
        })
      );
      listeners.push(
        instance.on("pageStart", ({ payload, ...rest }) => {
          debug && console.group("Analytics");
          debug && console.log("Analytics.pageStart.payload", payload, rest);
          debug && console.groupEnd();
        })
      );
      listeners.push(
        instance.on("page", ({ payload, ...rest }) => {
          debug && console.group("Analytics");
          debug && console.log("Analytics.page.payload", payload, rest);
          debug && console.groupEnd();
        })
      );
      listeners.push(
        instance.on("pageEnd", ({ payload, ...rest }) => {
          debug && console.group("Analytics");
          debug && console.log("Analytics.pageEnd.payload", payload, rest);
          debug && console.groupEnd();
        })
      );
      listeners.push(
        instance.on("trackStart", ({ payload, ...rest }) => {
          payload.properties = {
            ...payload.properties,
            iosForceFileUploadFlag: flags.iosForceFileUpload.isEnabled().toString(),
            includePricingInSetupStep: flags.includePricingInSetupStep.getValue().toString()
          };
          debug && console.group("Analytics");
          debug && console.log("Analytics.trackStart.payload", payload, rest);
          debug && console.groupEnd();
          return payload;
        })
      );
    }
    return listeners;
  };

  const identifyUser = () => {
    if (analytics.instance && !identified) {
      const id = generateUserId(auth);
      setAnalyticsUserId(id);
      analytics.instance.identify(uid || analyticsUserId, {
        $email: email || undefined,
        email: email || undefined,
        // role and unsubscribed_from_emails are required for syncing to Intercom
        role: !!uid ? "user" : "lead",
        unsubscribed_from_emails:
          profile.sendMarketing !== undefined
            ? !profile.sendMarketing
            : undefined,
      });
      setIdentified(true);
    }
  };

  React.useEffect(() => {
    debug && console.group("Analytics");
    debug && console.log("AnalyticsInitializer.useEffect");
    const _analytics = generateAnalytics(analyticsUserId, [
      pageViewCounterPlugin({ counter, countForRoutes, enabled: true }),
    ]);
    setAnalytics(_analytics);
    const listeners = getListeners(_analytics.instance);
    debug && console.groupEnd();
    return () => {
      debug && console.group("Analytics");
      debug && console.log("Cleanup Analytics Event Listeners.");
      debug && console.groupEnd();
      listeners.forEach((listener) => listener());
    };
  }, []);

  React.useEffect(() => {
    if (!identified && isLoaded(profile)) {
      identifyUser();
    }
  }, [identified, isLoaded(profile)]);

  React.useMemo(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user?.uid !== prevUid) {
        setIdentified(false);
        setPrevUid(user?.uid);
      }
    });
  }, []);

  return analytics.instance ? children && children(analytics) : <LoadingPage />;
};

export default AnalyticsInitializer;
