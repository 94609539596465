import { createMachine, assign, spawn } from "xstate";
import { createModel } from "xstate/lib/model";
import { pure, send } from "xstate/lib/actions";
import { createPromptMachine } from "./promptMachine";
import {
  DIYVideoMachineContext,
  DIYVideoMachineEvent,
  PromptMachineEvent,
  VideoClipMachineEvent,
} from "../../types/DIYVideoBuilder";
const merge = require("deepmerge");

/** Sources/Examples
 * https://examples.bradwoods.io/xstate/react/invoke-service-send-parent
 * https://xstate-catalogue.com/machines/drag-and-drop - used to re-order
 * https://xstate.js.org/docs/guides/actions.html#respond-action
 * **/

const model = createModel({} as DIYVideoMachineContext);

export const diyVideoBuilderMachine = createMachine<
  DIYVideoMachineContext,
  DIYVideoMachineEvent | PromptMachineEvent | VideoClipMachineEvent
>(
  {
    id: "diy",
    context: model.initialContext,
    initial: "idle",
    states: {
      idle: {
        on: {
          FETCH: {
            target: "loading",
          },
          // TODO add guard to prevent moving to processing if movie not processing
          PROCESS: {
            target: "processing",
          },
        },
      },
      loading: {
        invoke: {
          id: "fetchData",
          src: "fetchData",
          onDone: {
            actions: `loadData`,
            target: "init",
          },
          onError: {
            actions: `logError`,
            target: "error",
          },
        },
      },
      error: {},
      init: {
        entry: [
          "announce",
          assign({
            prompts: (context) => {
              console.log("Rehydrating prompts");
              console.log(context);
              // 'Rehydrate' persisted prompts with machines
              return context.prompts.map((prompt, idx) => ({
                ...prompt,
                ref: spawn(
                  createPromptMachine({
                    ...prompt,
                    id: idx,
                    prevText: prompt.text,
                    clips: [],
                  })
                ),
              }));
            },
          }),
        ],
        always: "ready",
      },
      ready: {
        entry: ["announce"],
        on: {
          PROCESS: {
            target: "processing",
          },
          CHANGE: {
            actions: "change",
          },
        },
      },
      processing: {
        entry: ["announce"],
        // invoke: {
        //   src: "pushData",
        // },
        on: {
          DONE: {
            target: "complete",
          },
        },
      },
      complete: {
        entry: ["announce"],
      },
    },
    on: {
      "PROMPT.ACTIVE": {
        actions: "toggleActivePrompts",
      },
      "PROMPT.COMMIT": {
        actions: ["promptCommit"],
      },
      "CLIP.ACTIVE": {
        actions: ["setActiveClip", "clearPrevActiveClip"],
      },
      "CLIP.COMMIT": {
        actions: ["clipCommit"],
      },
      "CLIP.DONE": {
        actions: ["clipDone"],
      },
    },
  },
  {
    services: {
      fetchData: async () => {
        console.log("fetchData not implemented!");
        throw new Error("Not Implemented.");
      },
      pushData: async (context, event) => {
        console.log("pushData not implemented!");
        throw new Error("Not Implemented.");
      },
    },
    actions: {
      announce: (context, event) => {
        console.log("Story machine - ", { context, event });
      },
      loadData: assign((context, event) => {
        console.log("loadData", { event });
        if (event.type !== "done.invoke.fetchData") return {};
        return {
          ...event.data,
        };
      }),
      // @ts-ignore
      change: assign((context, event) => {
        console.log("changing prompt");
        if (event.type !== "CHANGE") return event;
        return {
          ...event.value,
        };
      }),
      logError: (context, event) => {
        console.log({ event });
      },
      toggleActivePrompts: pure((context, event) => {
        if (event.type !== "PROMPT.ACTIVE") return undefined;
        return context.prompts
          .filter((prompt) => event.id !== prompt.idx)
          .map((actor) => {
            return send({ type: "DONE" }, { to: actor.ref });
          });
      }),
      clearPrevActiveClip: pure((context, event) => {
        if (event.type !== "CLIP.ACTIVE") return undefined;
        console.log("clearPrevActiveClip", { event });
        const commands: Array<any> = [];
        if (context.prevActiveClipMachine) {
          commands.push(
            send({ type: "DONE" }, { to: context.prevActiveClipMachine })
          );
          commands.push(
            assign(() => {
              return {
                prevActiveClipMachine: undefined,
              };
            })
          );
        }
        return commands.length ? commands : undefined;
      }),
      setActiveClip: assign((context, event) => {
        if (event.type !== "CLIP.ACTIVE") return {};
        console.log("setActiveClip", { event });
        return {
          prevActiveClipMachine: context.activeClipMachine,
          activeClipMachine: event.clip,
        };
      }),
      promptCommit: assign((context, event) => {
        if (event.type !== "PROMPT.COMMIT") return {};
        const _prompts = [...context.prompts];
        const idx = context.prompts.findIndex((p) => {
          return event.prompt.id === p.id;
        });
        if (idx >= 0)
          _prompts[idx] = { ...context.prompts[idx], ...event.prompt };
        return { prompts: _prompts };
      }),
      clipCommit: () => ({}),
      clipDone: assign((context, event) => {
        if (event.type !== "CLIP.DONE") return {};
        console.log("clipDone", { event });
        let active = context.activeClipMachine;
        if (context.activeClipMachine) {
          if (
            event.clip.id === context.activeClipMachine.machine.context.id &&
            event.clip.type === context.activeClipMachine.machine.context.type
          )
            active = undefined;
        }
        return {
          prevActiveClipMachine: undefined,
          activeClipMachine: active,
        };
      }),
    },
  }
);

/*
 const MyComponent = () => {
  const [state] = useMachine(myMachine.withContext({ volume: 5 }));
  const { volume } = state.context;

  return <output>{volume}</output>;
};
*/
