import { createMachine, assign, sendParent } from "xstate";
import { Response } from "./responseMachine";

export interface PromptContext {
  id: string | number;
  idx: number;
  text: string;
  response?: Response | null;
}

export type PromptMachineEvent =
  | {
      type: "RECORD" | "CAPTURE";
      useInput?: boolean;
    }
  | {
      type: "IDLE";
    }
  | {
      type: "PROCESSING";
    }
  | {
      type: "DONE";
    }
  | {
      type: "PROMPT.ACTIVE";
      id: number;
    }
  | {
      type: "SET_RESPONSE";
      response: Response;
    };

export const promptMachine = createMachine<PromptContext, PromptMachineEvent>(
  {
    id: "promptMachine",
    tags: ["prompt"],
    context: {
      id: 0,
      idx: 0,
      text: "",
      response: null,
    },
    initial: "init",
    states: {
      init: {
        entry: ["announce"],
        always: "idle",
      },
      idle: {
        entry: ["announce"],
        on: {
          CAPTURE: {
            actions: ["setActive"],
          },
          RECORD: {
            actions: ["setActive"],
          },
          PROCESSING: "processing",
        },
        always: [{ target: "processing", cond: "responseProcessing" }],
      },
      processing: {
        entry: ["announce", assign({ response: (context, event) => null })],
        on: {
          IDLE: "idle",
        },
        always: [{ target: "idle", cond: "responseProcessed" }],
      },
      complete: {
        entry: ["commit"],
        type: "final",
      },
    },
    on: {
      SET_RESPONSE: {
        actions: ["announce", "setResponse"],
      },
    },
  },
  {
    actions: {
      announce: (context, event) => {
        console.log("Prompt machine - ", event.type, { context, event });
      },
      setActive: sendParent((context, event) => ({
        type: "PROMPT.ACTIVE",
        data: {
          id: context.id,
          ...event,
        },
      })),
      setResponse: assign((context, event) => {
        if (event.type !== "SET_RESPONSE") return {};
        return {
          response: event.response,
        };
      }),
    },
    guards: {
      responseProcessed: (context, event) => {
        const { response } = context;
        return !!(
          response &&
          response.content &&
          (response.content.muxPlaybackId || response.type === "image")
        );
      },
      responseProcessing: (context) => {
        const { response } = context;
        return (
          !!response &&
          response.type === "video" &&
          !(response.content && response.content.muxPlaybackId)
        );
      },
    },
  }
);

export const createPromptMachine = ({
  id,
  idx,
  text,
  response,
}: PromptContext) =>
  promptMachine.withConfig({ _key: String(id) }, { id, idx, text, response });
