export const getFileExtension = (mime) => {
    const extensions = {
      quicktime: "mov",
      webm: "webm",
      jpeg: "jpg",
    };

    try {
      const parts = mime.split(";")[0].split("/");
      return extensions[parts[1]] || parts[1] || "mp4";
    } catch (ex) {
      return "webm";
    }
  };

  export default getFileExtension;