import React from "react";
import {
  Flex,
  Stack,
  Container,
  Heading,
  Text,
  Divider,
  Spacer,
} from "@chakra-ui/react";
import ContactManager from "./components/ContactManager";
import TestInvites from "../../story/TestInvites";
import { useStoryManagerDispatch, useStoryManagerState } from "../StoryManager";
import {
  iStoryManagerStateContext,
  StoryStepId,
} from "../../../types/StoryManagerState";
import Loading from "../../common/Loading";
import InviteLinkBox from "../../invites/InviteLinkBox";
import IconSquiggle from "../../common/IconSquiggle";

const min_recommended = 15;

const StorytellersStep = ({ id }: { id: StoryStepId }) => {
  const dispatch = useStoryManagerDispatch();
  // @ts-ignore
  const {
    state: { story },
  }: { state: iStoryManagerStateContext } = useStoryManagerState();

  const [loading, setLoading] = React.useState(true);
  const [storytellers, setStorytellers] = React.useState(
    story?.storytellers || []
  );
  const [numberOfStorytellers, setNumberOfStorytellers] = React.useState(
    storytellers.length
  );

  const limit = (story?.template && story?.template.maxStorytellers) || 1;

  // @ts-ignore
  const actionHandler = (event, data) => {
    console.log(`Storytellers.${event}`, data);
  };

  const update = React.useCallback(
    (contacts, valid) => {
      actionHandler("useCallback[storytellers].update", {
        story,
        storytellers,
      });
      setStorytellers(contacts);
      setNumberOfStorytellers(contacts.length);

      dispatch({
        type: "update",
        payload: {
          story: {
            ...story,
            storytellers: contacts,
            numberOfStorytellers: contacts.length,
            step: id,
            inviteMethod: !!contacts.length ? "invites" : "link",
          },
          changed: true,
          valid: { [id]: valid },
        },
      });
    },
    [id, story, storytellers, dispatch]
  );

  const onLinkCopied = React.useCallback(() => {
    actionHandler("useCallback[storytellers].onLinkCopied", {
      story,
      storytellers,
    });

    dispatch({
      type: "update",
      payload: {
        story: {
          ...story,
          inviteMethod: !!storytellers.length ? "invites" : "link",
        },
      },
    });
  }, [id, story, dispatch]);

  React.useEffect(() => {
    actionHandler("useEffect[loading]", { loading });
    if (loading) setLoading(false);
  }, [loading]);

  if (loading) return <Loading />;

  return (
    <Stack spacing={8} textAlign="center">
      <Container textAlign="center">
        <Stack align="center" spacing="6">
          <Stack>
            <Heading as="h2" size="h2" variant="center">
              Invitations
            </Heading>
            <Text>
              Each person invited will receive an invite by email or text
              message containing a link to your Quilted project webpage to
              submit their videos. We will also send automatic reminders at key
              points during your Quilted project to remind people who have not
              yet submitted videos.
            </Text>
            <Text>
              We recommend inviting <strong>at least 15 people</strong> for the
              best results!
            </Text>
          </Stack>
        </Stack>
      </Container>
      <Spacer />
      <ContactManager
        existingContacts={[]}
        contacts={storytellers}
        limit={limit}
        // @ts-ignore
        onChange={({ contacts, valid }) => {
          update(contacts, valid);
        }}
      />

      <Divider />
      <Container alignSelf="center">
        <Stack spacing="1rem" align="center">
          <Heading as="h3" size="h3" variant="center">
            Don’t have the person’s email or <br />
            cell phone number?
          </Heading>
          <IconSquiggle color="seaGreen.500" />
        </Stack>
      </Container>
      {story.slug && <InviteLinkBox slug={story.slug} onCopy={onLinkCopied} />}
      <Flex w="full">
        <TestInvites story={story} />
      </Flex>
    </Stack>
  );
};

export default StorytellersStep;
