import React from "react";
import {
  Alert,
  Button,
  Input,
  FormControl,
  Flex,
  Stack,
  IconButton,
  HStack,
  AlertDescription,
} from "@chakra-ui/react";

import { phoneValidation, emailValidation } from "../../../../utils/validation";
import formatPhoneNumber from "../../../../utils/formatPhoneNumber";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFeatureFlags } from "../../../featureFlags";

export const ContactInputForm = (props) => {
  const {
    onSave,
    onCancel,
    nameIsNotDuplicate,
    emailIsNotDuplicate,
    phoneIsNotDuplicate,
  } = props;
  const _blank = {
    name: "",
    email: null,
    contactPreference: "EMAIL",
    isValid: false,
  };
  const [inputActive, setInputActive] = React.useState(false);
  const [contact, setContact] = React.useState({ ..._blank });
  const [changed, setChanged] = React.useState(false);
  const [valid, setValid] = React.useState();
  const [messages, setMessages] = React.useState({
    name: undefined,
    phone: undefined,
    email: undefined,
  });
  const {allowSMS} = useFeatureFlags();


  const validation = {
    hasValue: (value) => !!(!!value && value.length > 0),
    name: (name) => !!(name && nameIsNotDuplicate(name)),
    phone: (phone) =>
      !!(
        phone &&
        phoneValidation.validate(phone) &&
        phoneIsNotDuplicate(phone)
      ),
    email: (email) =>
      !!(
        email &&
        emailValidation.validate(email) &&
        emailIsNotDuplicate(email)
      ),
    contactPreference: (contactPreference, phoneValid, emailValid) =>
      !!(contactPreference === "SMS" ? phoneValid : emailValid),
  };

  const isChanged = ({ name, phone, email, contactPreference }) => {
    const _isChanged =
      validation.hasValue(name) ||
      (contactPreference === "SMS"
        ? validation.hasValue(phone)
        : validation.hasValue(email));
    setChanged(_isChanged);
    !_isChanged &&
      setMessages({ name: undefined, phone: undefined, email: undefined });
    return _isChanged;
  };

  const setNameValidationMessage = (name) => {
    if (!nameIsNotDuplicate(name)) {
      setMessages({
        ...messages,
        name: "This name is already assigned to another storyteller in your list. Please use a unique name.",
      });
    } else {
      setMessages({
        ...messages,
        name: undefined,
      });
    }
  };

  const setPhoneValidationMessage = (phone) => {
    if (!phoneIsNotDuplicate(phone)) {
      setMessages({
        ...messages,
        phone:
          "This number is already assigned to another storyteller in your list. Please use a different number.",
      });
    } else if (!phoneValidation.validate(phone)) {
      setMessages({
        ...messages,
        phone: "Phone number is not valid",
      });
    } else {
      setMessages({
        ...messages,
        phone: undefined,
      });
    }
  };

  const setEmailValidationMessage = (email) => {
    if (!emailIsNotDuplicate(email)) {
      setMessages({
        ...messages,
        email:
          "This email is already assigned to another storyteller in your list. Please use a different email.",
      });
    } else if (!emailValidation.validate(email)) {
      setMessages({
        ...messages,
        email: "Email is not valid",
      });
    } else {
      setMessages({
        ...messages,
        email: undefined,
      });
    }
  };

  const validate = (_contact) => {
    const contactValid = ({ name, phone, email, contactPreference }) => {
      console.dir({ name, phone, email });
      const nameValid = validation.name(name);
      const phoneValid = phone && validation.phone(phone);
      const emailValid = email && validation.email(email);
      const contactPreferenceValid = validation.contactPreference(
        contactPreference,
        phoneValid,
        emailValid
      );
      setMessages({
        name: nameValid ? undefined : "Name is required.",
        phone: phoneValid ? undefined : "Phone is required and must be valid.",
        email: emailValid ? undefined : "Email is required and must be valid.",
      });
      const valid = nameValid && contactPreferenceValid;

      setValid(valid);
      return valid;
    };
    const _changed_ = isChanged(_contact);
    console.log({ _changed_ });
    return { ..._contact, isValid: contactValid(_contact) };
  };

  const handleChange = (e) => {
    let _contact = { ...contact, [e.target.name]: e.target.value };
    if(!!_contact.email) _contact.email = _contact.email.trim();
    _contact.phone =
      (_contact.phone && formatPhoneNumber.forDisplay(_contact.phone)) ||
      _contact.phone ||
      null;
    setContact(_contact);
    isChanged(_contact);
  };

  const handleBlurValidation = (field) => {
    const _changed = isChanged(contact);
    console.log({ contact, _changed });
    if (_changed) {
      const validator = validation[field];
      const value = contact[field];
      const isValid = validator(value);
      const hasValue = validation.hasValue(value);
      if (field === "name") setNameValidationMessage(value);
      if (field === "phone") setPhoneValidationMessage(value);
      if (field === "email") setEmailValidationMessage(value);
      setValid(_changed && hasValue && isValid);
    }
  };

  const handleNameChange = (e) => {
    handleChange(e);
  };

  const handlePhoneChange = (e) => {
    handleChange(e);
  };

  const handleEmailChange = (e) => {
    handleChange(e);
  };

  const save = () => {
    const { isValid } = validate(contact);
    if (isValid) {
      onSave &&
        onSave({
          ...contact,
          phone: formatPhoneNumber.forStorage(contact.phone),
          isValid,
        });
      setContact({ ..._blank });
      setChanged(false);
      setValid();
    }
  };

  return (
    <Flex direction="column" align="flex-end" justify="center" w="full">
      <Stack w="full">
        <FormControl>
          <Input
            placeholder="Name"
            value={contact.name}
            onChange={(e) => {
              e.target.name = "name";
              handleNameChange(e);
            }}
            onBlur={() => handleBlurValidation("name")}
            data-testid="name"
          />
        </FormControl>
        <FormControl
          isInvalid={
            contact.contactPreference === "SMS"
              ? !!messages.phone
              : !!messages.email
          }
        >
          <HStack
            bgColor="white"
            rounded="md"
            border="1px"
            borderColor={
              inputActive
                ? "airBlue.500"
                : (contact.contactPreference === "SMS" && messages.phone) ||
                  (contact.contactPreference === "EMAIL" && messages.email)
                ? "puce.500"
                : "gray.500"
            }
            borderWidth={messages.phone || messages.email ? "2px" : "1px"}
            pl="1"
          >
            <Flex direction="row" bgColor="gray.100">
              <IconButton
                rounded="md"
                p="1"
                size="sm"
                icon={<FontAwesomeIcon icon="at" />}
                colorScheme={
                  contact.contactPreference === "EMAIL" ? "tumbleweed" : "gray"
                }
                onClick={(e) => {
                  e.target.name = "contactPreference";
                  e.target.value = "EMAIL";
                  handleChange(e);
                }}
                isActive={
                  !contact ||
                  !contact.contactPreference ||
                  contact.contactPreference === "EMAIL"
                }
              />
              {allowSMS.isEnabled() && (
              <IconButton
                rounded="md"
                p="1"
                size="sm"
                icon={<FontAwesomeIcon icon="mobile-alt" />}
                colorScheme={
                  contact.contactPreference === "SMS" ? "tumbleweed" : "gray"
                }
                onClick={(e) => {
                  e.target.name = "contactPreference";
                  e.target.value = "SMS";
                  handleChange(e);
                }}
                isActive={contact.contactPreference === "SMS"}
              />)}
            </Flex>
            {!contact.contactPreference ||
              (contact.contactPreference === "EMAIL" && (
                <Input
                  placeholder="email@domain.com"
                  onFocus={() => setInputActive(true)}
                  _focus={{ border: "none" }}
                  _invalid={{ border: "none" }}
                  value={contact.email || ""}
                  border="none"
                  onChange={(e) => {
                    e.target.name = "email";
                    handleEmailChange(e);
                  }}
                  onBlur={() => {
                    handleBlurValidation("email");
                    setInputActive(false);
                  }}
                  data-testid="email"
                />
              ))}
            {contact.contactPreference === "SMS" && allowSMS.isEnabled() && (
              <Input
                as={InputMask}
                mask={"(***) ***-****"}
                type="tel"
                placeholder="(330) 133-1333"
                value={contact.phone || ""}
                border="none"
                onFocus={() => setInputActive(true)}
                _focus={{ border: "none" }}
                _invalid={{ border: "none" }}
                onChange={(e) => {
                  e.target.name = "phone";
                  handlePhoneChange(e);
                }}
                onBlur={() => {
                  setInputActive(false);
                  handleBlurValidation("phone");
                }}
                data-testid="phone"
              />
            )}
          </HStack>
        </FormControl>
        <Stack>
          {contact.contactPreference === "SMS" && messages.phone && (
            <Alert status="error">
              <AlertDescription>{messages.phone}</AlertDescription>
            </Alert>
          )}
          {contact.contactPreference === "EMAIL" && messages.email && (
            <Alert status="error">
              <AlertDescription>{messages.email}</AlertDescription>
            </Alert>
          )}
          {messages.name && (
            <Alert status="error">
              <AlertDescription>{messages.name}</AlertDescription>
            </Alert>
          )}
        </Stack>
        <Flex direction="row" justify={["center", "flex-end"]}>
          <Button
            onClick={() => save()}
            size="sm"
            colorScheme="tumbleweed"
            bgColor="tumbleweed.600"
            isDisabled={!valid || !changed}
            data-testid="save"
          >
            Add contact
          </Button>
        </Flex>
      </Stack>
    </Flex>
  );
};

export default ContactInputForm;
