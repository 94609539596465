import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

//Error Handling Middleware
import './middleware/sentry';

import config from './config/config';

import './index.css';
import App from './App';
import ErrorBoundary from './components/common/ErrorBoundary';
import FeatureFlagsProvider from './components/featureFlags/FeatureFlagsProvider';
import ReduxProvider from './ReduxProvider';


ReactDOM.render(<ReduxProvider><ErrorBoundary><FeatureFlagsProvider {...config.rolloutSettings}><App /></FeatureFlagsProvider></ErrorBoundary></ReduxProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
