import React from 'react'
import { Box, Heading } from 'grommet'

const Loading = () => (
    <Box height="auto" overflow="hidden" margin={{"top":"none"}}>
      <Box flex={true} overflow={{ "horizontal":"auto" }} direction="row">
        <Heading>Loading</Heading>
      </Box>
  </Box>)

export default Loading
