import { useFirestore } from "react-redux-firebase";
import Counter from "../utils/sharded-counter";


const useCounter = (name, path) => {
    const firestore = useFirestore();
    const doc = firestore.doc(path);
    return new Counter(doc, name);
}

export default useCounter