import { object } from "prop-types";
import { State } from "xstate";
import {
  ClipsEntity,
  DIYVideoMachineContext,
  DIYVideoState,
  EditlySpec,
  Prompt,
  VideoLayer,
} from "../types/DIYVideoBuilder";


export const getMuxStaticFileUrl = (playbackId:string) => {
  return `https://stream.mux.com/${playbackId}/low.mp4`;
}

const buildEditlySpec = (state: State<DIYVideoMachineContext>) => {
  const prompts = state.context.prompts;

  const fontPath = "./assets/fonts/Open_Sans/OpenSans-Light.ttf";
  const baseSpec: EditlySpec = {
    width: 1920,
    height: 1080,
    fps: 60,
    outPath: "./custom.mp4",
    keepSourceAudio: true,
    allowRemoteRequests: true,
    defaults: {
      transition: null,
      layer: {
        background: {
          color: "#fbf8f3",
          colors: ["#fbf8f3", "#fbf8f3"],
          type: "linear-gradient",
        },
        textColor: "#264654",
        fontPath,
      },
    },
    audioNorm: {
      enable: true,
      gaussSize: 5,
      maxGain: 30,
    },
    clips: [],
    fast: false,
    verbose: false,
  };

  const clipList: ClipsEntity[] = [];

  clipList.push({
    duration: state.context.titleDuration,
    layers: [
      {
        type: "title-background",
        text: state.context.title,
        fontPath: "./assets/fonts/Open_Sans/OpenSans-Regular.ttf",
      },
    ],
  });

  prompts
    .filter((p) => p.include)
    .forEach((p) => {
      clipList.push({
        duration: 4,
        layers: [
          {
            type: "title-background",
            text: p.text,
          },
        ],
      });
      p.submissions
        .filter((s) => s.include)
        .forEach((s) => {
          console.log("submission", s);
          const layer: VideoLayer = {
            type: "video",
            path: getMuxStaticFileUrl(s.content.muxPlaybackId)
          };
          if (s.cutFrom) layer.cutFrom = s.cutFrom;
          if (s.cutTo) layer.cutTo = s.cutTo;
          clipList.push({
            submissionId: s.id,
            layers: [layer],
          });
        });
    });

  // console.log("clips", clipList);
  return {
    ...baseSpec,
    clips: clipList,
  };
};

export default buildEditlySpec;
