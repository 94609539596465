/* eslint-disable react/prop-types */
import React, { useRef, useState } from "react";
import { useActor } from "@xstate/react";
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  IconButton,
  Input,
  Portal,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { useReactMediaRecorder } from "./ReactMediaRecorder";
import VideoPreview from "./VideoPreview";
import AudioAnalyser from "../../audio/AudioAnalyser";
import { ReactMediaRecorderRenderProps } from "../../../types/ReactMediaRecorder";
import RecordButton from "./RecordButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AcceptRecordingButton from "./AcceptRecordingButton";
import CameraFacingButton from "./CameraFacingButton";
import getVideoQuality from "./get-video-quality";
import { BrowserView, MobileView } from "react-device-detect";
import styled from "@emotion/styled";
import getVideoInfo from "./get-video-info";

interface RecordingProps {
  url: string;
  blob: any;
}

interface ReviewVideoElProps {
  isFullscreen?: boolean;
}

const ReviewVideoEl = styled.video<ReviewVideoElProps>`
  width: 100%;
  height: 100%;
  object-fit: ${(props) => (props.isFullscreen ? "cover" : "contain")};
`;

interface VideoRecorderProps {
  machine: any;
}

const LayoutView: React.FC = ({
  children,
  useInput,
}: {
  children?: React.ReactNode;
  useInput?: boolean;
}) => {
  return (
    <>
      <BrowserView>
        <Box pos="relative" maxH="100%" maxW="100%" minH="350px">
          <Box
            sx={useInput ? undefined : { bgColor: "gray.600", shadow: "md" }}
          >
            {children}
          </Box>
        </Box>
      </BrowserView>
      <MobileView>
        <Box pos="relative" maxH="100vh" maxW="100vw">
          <Portal>
            <Box
              pos="fixed"
              top="0"
              left="0"
              w="100vw"
              h="100%"
              maxH="100vh"
              bgColor="gray.600"
            >
              {children}
            </Box>
          </Portal>
        </Box>
      </MobileView>
    </>
  );
};

const ReviewVideo: React.FC<{ url: string; isFullscreen: boolean }> = ({
  url,
  isFullscreen,
}) => {
  const [isPlaying, setPlaying] = useState(false);
  const videoObject = useRef();
  const toggleVideo = () => {
    // @ts-ignore
    // prettier-ignore
    videoObject && videoObject.current?.paused ? videoObject.current.play() : videoObject.current.pause();
    setPlaying(!isPlaying);
  };

  return (
    <>
      <ReviewVideoEl
        src={url}
        muted={false}
        playsInline
        autoPlay={false}
        controls={false}
        isFullscreen={isFullscreen}
        // @ts-ignore
        ref={videoObject}
        onEnded={() => setPlaying(false)}
      />
      <IconButton
        w="70px"
        h="70px"
        left="calc(50% - 35px)"
        variant="outline"
        pos="absolute"
        top="calc(50% - 35px)"
        zIndex="overlay"
        opacity={isPlaying ? 0 : 1}
        aria-label="play back video"
        color="white"
        onClick={toggleVideo}
        icon={<FontAwesomeIcon icon="play" size="2x" />}
        _hover={{ background: "none" }}
        _active={{ background: "none" }}
      />
    </>
  );
};

const RecordVideoView: React.FC<VideoRecorderProps> = ({ machine }) => {
  const [state, send]: any[] = useActor(machine);
  const [constraints, setConstraints] = useState<boolean | any>({
    ...getVideoQuality(),
  });

  const [recording, setRecording] = useState<RecordingProps & { info?: any }>();
  const bottom = useBreakpointValue({ base: "10%", md: "15%" });

  const {
    previewStream,
    previewAudioStream,
    error,
    startRecording,
    stopRecording,
    switchCamera,
    startMediaStream,
    cameras,
    facingMode,
    facingModeSupported,
    mediaBlobUrl,
  }: ReactMediaRecorderRenderProps = useReactMediaRecorder({
    video: constraints,
    askPermissionOnMount: true,
    onStop: (url: any, blob: any, info?: any) => {
      setRecording({ url, blob, info });
    },
    onReady: () => {
      send("READY");
    },
    blobPropertyBag: null,
  }) as ReactMediaRecorderRenderProps;

  const isFullscreen = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
  });

  React.useEffect(() => {
    return () => {
      console.log("Stop Recording (init)");
      stopRecording();
    };
  }, []);

  React.useEffect(() => {
    if (state.matches("shutdown")) {
      console.log("Stop Recording (shutdown)");
      send("QUIT");
    }
  }, [state.matches("shutdown")]);

  return (
    <>
      {state.matches("review")
        ? recording && (
            <ReviewVideo url={recording?.url} isFullscreen={!!isFullscreen} />
          )
        : previewStream && (
            <VideoPreview
              stream={previewStream}
              isFullscreen={isFullscreen}
              facingMode={facingMode}
            />
          )}
      <Box
        pos="absolute"
        background="linear-gradient(0deg,rgba(0, 0, 0, 0.8) 0%,rgba(0, 0, 0, 0) 100%)"
        bottom={0}
        w="full"
        h={`calc(${bottom} + 100px)`}
      />
      <Box
        position="absolute"
        bottom={state.matches("review") ? "8" : bottom}
        width="full"
        zIndex="overlay"
      >
        <Flex align="center" justify="center">
          {state.matches("ready") ? (
            <Grid templateColumns="repeat(3, 1fr)" gap={6} w="full">
              <Center>
                {!!facingModeSupported && (
                  <CameraFacingButton
                    alignSelf="center"
                    onClick={() => switchCamera()}
                    aria-label="change camera direction"
                    direction={facingMode}
                  />
                )}
              </Center>
              <Center w="full">
                <RecordButton
                  onClick={() => {
                    startRecording();
                    send("RECORD");
                  }}
                />
              </Center>
            </Grid>
          ) : state.matches("recording") ? (
            <RecordButton
              progress={0}
              variant="recording"
              onClick={() => {
                stopRecording();
                send("DONE");
              }}
            >
              <FontAwesomeIcon inverse size="2x" icon={"stop"} />
            </RecordButton>
          ) : state.matches("review") ? (
            <VStack spacing="md">
              <AcceptRecordingButton
                aria-label="Accept Recording"
                onClick={() => {
                  send({ type: "APPROVE", data: recording });
                }}
              >
                Save
              </AcceptRecordingButton>
              <Button
                size="sm"
                color="gray.300"
                variant="outline"
                onClick={() => {
                  startMediaStream();
                  send("RETAKE");
                }}
              >
                Restart recording
              </Button>
            </VStack>
          ) : state.matches("done") ? (
            <>done</>
          ) : null}
        </Flex>
      </Box>
      {state.matches("recording") && previewAudioStream && (
        <Box position="absolute" bottom={-1} width="full">
          <AudioAnalyser
            audio={previewAudioStream}
            decibels={{ min: -100, max: -30 }}
          />
        </Box>
      )}
    </>
  );
};

const InputVideoView: React.FC<VideoRecorderProps> = ({ machine }) => {
  const [state, send]: any[] = useActor(machine);
  let videoInputRef = React.createRef<typeof Input>();
  const [recording, setRecording] = useState<RecordingProps & { info?: any }>();
  const [error, setError] = useState<string | null>(null);

  const bottom = useBreakpointValue({ base: "10%", md: "15%" });
  const isFullscreen = useBreakpointValue({ base: true, md: false });

  const handleOpenVideoInput = React.useCallback(() => {
    if (videoInputRef) {
      // @ts-ignore
      videoInputRef.value = null;
      // @ts-ignore
      videoInputRef.click();
    }
  }, [videoInputRef]);

  React.useEffect(() => {
    if (state.matches("idle") && !!videoInputRef) send("READY");
    if (state.matches("ready") && !!videoInputRef) {
      handleOpenVideoInput();
      send("RECORD");
    }
    if (state.matches("shutdown")) {
      send("QUIT");
    }
  }, [state.value, videoInputRef]);

  const handleVideoSelected: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    // @ts-ignore
    const files = e.target.files || e.dataTransfer.files;
    if (files.length === 0) return;

    const video = files[0];

    // @ts-ignore
    e.target.value = null;

    if (!/^video/.test(video.type || video.mediaType)) {
      setError("The file selected must be a valid video file. MP4, MOV, WEBM"); //TODO: Do something? Reset to ready state?
      return;
    }

    // @ts-ignore
    const url = URL.createObjectURL(video);

    getVideoInfo(url)
      .then(({ duration }) => {
        return { duration };
      })
      .catch(({ name, message }) => {
        return { error: true, errorName: name, errorMessage: message };
      })
      .then((info) => {
        setRecording((prev) => {
          //@ts-ignore
          prev && URL.revokeObjectURL(prev.url);
          return {
            blob: video,
            url,
            info,
          };
        });
        send("DONE");
      });
  };

  return (
    <>
      <Input
        ref={(ref) => (videoInputRef = ref as any)}
        key="videoInput"
        type="file"
        accept="video/*"
        style={{ display: "none" }}
        variant="unstyled"
        onChange={handleVideoSelected}
      />
      {state.matches("review") && recording && (
        <ReviewVideo url={recording.url} isFullscreen={!!isFullscreen} />
      )}
      <Box
        position="absolute"
        bottom={state.matches("review") ? "8" : bottom}
        width="full"
        zIndex="overlay"
      >
        <Flex align="center" justify="center">
          {state.matches("ready") || state.matches("recording") ? (
            <Stack align="center" spacing=".25rem">
              <Button
                leftIcon={<FontAwesomeIcon icon={"upload"} />}
                onClick={() => {
                  handleOpenVideoInput();
                }}
              >
                Add Media
              </Button>
              <Text fontSize="sm">
                <i>or</i>
              </Text>
              <Button
                variant="link"
                colorScheme="puce"
                onClick={() => send("CANCEL")}
              >
                go back
              </Button>
            </Stack>
          ) : state.matches("review") ? (
            <VStack spacing="md">
              <AcceptRecordingButton
                aria-label="Accept Recording"
                onClick={() => {
                  send({ type: "APPROVE", data: recording });
                }}
              >
                Save
              </AcceptRecordingButton>
              <Button
                size="sm"
                color="gray.300"
                variant="outline"
                name="RetakeVideo"
                onClick={() => {
                  send("RETAKE");
                }}
              >
                Start over
              </Button>
            </VStack>
          ) : null}
        </Flex>
      </Box>
    </>
  );
};

const VideoRecorderView: React.FC<VideoRecorderProps> = ({
  machine,
  children,
}) => {
  const [state]: any[] = useActor(machine);
  const { useInput } = state.context;

  return (
    <Flex w="full" justify="center" align="stretch" pt="lg">
      <Box w="full" maxW="800px" maxH="80vh" bg={useInput ? "none" : "black"}>
        {/* @ts-ignore */}
        <LayoutView useInput={useInput}>
          {useInput ? (
            <InputVideoView machine={machine} />
          ) : (
            <RecordVideoView machine={machine} />
          )}
          {children}
        </LayoutView>
      </Box>
    </Flex>
  );
};

export default VideoRecorderView;
