import React, { FC } from "react";
import {
  Flex,
  Stack,
  Text,
  HStack,
  Heading,
  Image,
  Badge,
} from "@chakra-ui/react";
import svgDiy from "../../assets/diy-option.png";
import { Prices } from "../../utils/pricing";
export const DIYService: FC<{ prices: Prices }> = ({
  prices,
}: {
  prices: Prices;
}) => {
  return (
    <HStack gap={4} pt={6} px={6}>
      <Flex w="180px" h="180px" justify="center" align="center">
        <Image src={svgDiy} />
      </Flex>
      <Stack flex={1}>
        <HStack spacing="md">
          <Heading as="h3" size="h4">
            DIY
          </Heading>
          <Badge colorScheme="majesty" fontSize="1rem" px="sm">
            <HStack>
              {!!prices?.discount && (
                <Text as="s" fontWeight="thin">
                  {"$"}
                  {prices?.price?.toFixed(2)}
                </Text>
              )}
              <Text>
                {"$"}
                {(prices?.price - (prices?.discount || 0)).toFixed(2)}
              </Text>
            </HStack>
          </Badge>
        </HStack>
        <Text fontSize="sm">
          Our DIY editor allows you to create your final movie quickly and
          easily.
        </Text>
        <Text fontSize="sm">
          You&apos;ll have the ability to add or exclude any of your clips, or
          trim them to include only your favorite parts.
        </Text>
        <Text fontWeight="bold">Turnaround time: 1-2 days</Text>
      </Stack>
    </HStack>
  );
};

export default DIYService;
