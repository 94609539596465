import React from "react";
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import ContactManager from "../../start/Steps/components/ContactManager";

interface AddStorytellersViewProps {
  colorScheme: string;
  limit: number;
  storytellers: any[];
  invites?: any[];
  onClick: (contacts:any[]) => void;
  onBackClick: () => void;
}
export const AddStorytellersView: React.FC<AddStorytellersViewProps> = ({
  colorScheme,
  limit,
  storytellers,
  invites,
  onClick,
  onBackClick
}: AddStorytellersViewProps) => {
  const defaultContacts:any[] = [];
  const [contacts, setContacts] = React.useState<any[]>(invites || defaultContacts);
  const [valid, setValid] = React.useState(false);

  const handleChange = (changes:{contacts:any[],valid:boolean}) => {
    setContacts(changes.contacts);
    setValid(changes.valid);
  };

  const handleOnClick = () => {
    onClick && contacts && valid && onClick(contacts);
  }

  return (
    <Box>
      <Heading as="h4" size="md" mb={3}>
        Invite storytellers
      </Heading>
      <Text fontWeight="bold" mb={3}>
        Who would you like to invite?
      </Text>
      <Text mb={3}>
        Each person you invite will receive an invite by email or text message containing a link to submit their videos.
      </Text>
      <Text mb={3}>
        We recommend inviting <strong>at least 15 people</strong> for the best results!
      </Text>
      <ContactManager
          limit={limit}
          contacts={contacts}
          existingContacts={storytellers}
          onChange={handleChange}
        />
      <Button colorScheme={colorScheme} onClick={handleOnClick} disabled={!valid || !contacts || !contacts.length}>
        Continue
      </Button>
      <Button ml={3} px={10} colorScheme="black" variant="outline" onClick={onBackClick}>
        go back
      </Button>
    </Box>
  );
};

export default AddStorytellersView;
