import React from "react";
import { useAnalytics } from "use-analytics";
import { useLocation } from "react-router-dom";

const AnalyticsPageChangeHandler = (props: { children: any }) => {
  const { page, on } = useAnalytics();

  const location = useLocation();
  const [lastLocation, setLastLocation] = React.useState(location.pathname);

  React.useEffect(() => {
    const listener = on('ready', () => {
      console.group('Analytics')
      page && page();
      console.groupEnd();
    })
    return listener;
  },[])

  React.useEffect(() => {
    if (lastLocation !== location.pathname) {
      console.group('Analytics')
      console.log("AnalyticsPageChangeHandler - page changed");
      page && page();
      setLastLocation(location.pathname);
      console.groupEnd()
    }
  }, [location, lastLocation]);

  return <>{props.children && props.children}</>;
};

export default function withAnalyticsPageChange(WrappedComponent: any) {
  return class extends React.Component {
    displayName = "WithAnalyticsPageChange";
    constructor(props: any) {
      super(props);
    }

    render() {
      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      return (
        <AnalyticsPageChangeHandler>
          <WrappedComponent {...this.props} />
        </AnalyticsPageChangeHandler>
      );
    }
  };
}
