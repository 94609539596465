import { Box, Button, Card, CardBody, CheckBox, Heading, Paragraph, Text } from 'grommet'
import React from 'react'
import { useSelector } from 'react-redux'
import { isEmpty, isLoaded, useFirestore, useFirestoreConnect, useFirebase } from 'react-redux-firebase'
import { useNavigate } from 'react-router-dom'


import PageContainer from '../../layout/components/PageContainer'
import Running from '../common/RunningIndicator'

const Container = ({children, ...props}) => {
    return (<Box direction="column" fill="horizontal" align="stretch" justify="start" flex={{ shrink: 0 }} {...props}>
        {children}
    </Box>)
}

const DeleteError = () => {
    return (<Card background="status-error"><CardBody margin="medium" pad="medium">
    <Paragraph color="white"><Text weight="bold">We&apos;ve run into an error removing your account. Please contact support.</Text></Paragraph></CardBody>
</Card>)
}

const DeleteStatus = ({request}) => {
    if(!request) return null;
    const statusLabels = {
        requested: "Please wait",
        running: "Removing data",
        complete: "Account and all data has been removed. Logging you out",
    }
    const statusColors = {
        requested: "light-3",
        running: "status-warning",
        complete: "status-ok",
    }
    return request && (
        <Container>
            {request.status !== "error" && <Card background={statusColors[request.status]}><CardBody pad="medium"><Paragraph><Text weight="bold">{statusLabels[request.status]}
            <Running start={1} max={10} speed={1.5} />
            </Text></Paragraph></CardBody></Card>}
            {request.status === "error" && (<DeleteError />)}
        </Container>
    )
}

const DeleteAccountView = ({onDelete, onCancel}) => {
    const [agreed,setAgreed] = React.useState(false);
    return (<Container>
        <Paragraph margin={{top:"medium",bottom:"none"}}>Before you go...</Paragraph>
        <Box align="center" justify="between" border={{ side: "top", color: "border" }} pad="large" background="white">
            <Paragraph><Text weight="bold"> Deleting your account will delete <Text color="status-critical">all</Text> your information and videos.</Text></Paragraph>
            <Paragraph><Text weight="bold">Account deletion is <Text color="status-critical">final</Text>. There will be no way to restore your account or retrieve videos.</Text></Paragraph>

            <Box direction="column" gap="medium" pad={{vertical: "small",horizontal:"medium"}}>
                <CheckBox
                        checked={agreed}
                        label={<Paragraph><Text weight="bold">I understand. Please delete my account and all associated information.</Text></Paragraph>}
                        onChange={(event) => setAgreed(event.target.checked)}
                    />
                <Button primary={true} color="status-critical" label={<Text weight="bold" color="white">Delete my account</Text>} disabled={!agreed} onClick={onDelete} />
                <Button primary={false} color="status-ok" label={<Text weight="bold">Nevermind! Keep my account!</Text>} onClick={onCancel} />
            </Box>
        </Box>
    </Container>)
}

const DeleteAccount = () => {
    const navigate = useNavigate();
    const {auth} = useSelector(state => ({
        auth: state.firebase.auth
      }))
    const firebaseAuth = useFirebase().auth()
    useFirestoreConnect([
        {
            collection: 'deleteUserRequest',
            doc: auth.uid
        }
    ])
    const deleteUserRequest = useSelector(({ firestore: { data } }) => data.deleteUserRequest && data.deleteUserRequest[auth.uid])
    const {status} = deleteUserRequest || {status:null};

    React.useEffect(() => {
        if(status && status === "complete") {
            setTimeout(()=> firebaseAuth.signOut(),3000)
        }
    }, [status])

    const firestore = useFirestore()

    const createDeleteRequest = () => {
        return firestore.collection('deleteUserRequest').doc(auth.uid).set({ 'status':'requested', steps:[] })
    }

    const cancelRequest = () => {
        navigate(-1);
    }

    if (!isLoaded(auth)) return <PageContainer>splash screen...</PageContainer>;
    return (<PageContainer>
        <Container>
            <Heading level="1" margin={{bottom:"none"}} alignSelf="stretch">
                Delete Your Account
            </Heading>
            <Paragraph color="light-3" margin={{top:"none"}}><Text weight="bold">We&apos;re sorry to see you go.</Text></Paragraph>
        </Container>
        { isLoaded(deleteUserRequest) && isEmpty(deleteUserRequest) && <DeleteAccountView onDelete={createDeleteRequest} onCancel={cancelRequest} />}
        <DeleteStatus request={deleteUserRequest} />
    </PageContainer>)
}


export default DeleteAccount