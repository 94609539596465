import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { useAnalytics } from "use-analytics";
import LoadingPage from "../../../pages/LoadingPage";

type StorySlugParams = {
  slug: string;
};
function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
const QrCodeHandler = () => {
  const { track, on } = useAnalytics();
  const { slug } = useParams<StorySlugParams>();
  const [done, setDone] = React.useState(false);

  React.useEffect(() => {
    const listener = on("ready", () => {
      sleep(1000).then(() => {
        track("response:qrcode_scanned");
        setDone(true);
      });
    });
    return () => {
      //clean up
      listener();
    };
  }, []);

  return !done ? (
    <LoadingPage title="Unfolding Story..." />
  ) : (
    <Navigate to={`/${slug}`} />
  );
};

export default QrCodeHandler;
