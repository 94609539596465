import React from "react";
import { Icon, Link } from "@chakra-ui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import { faAt } from "@fortawesome/free-solid-svg-icons";

const FaFacebook = () => <FontAwesomeIcon icon={faFacebookSquare} />;
const FaTwitter = () => <FontAwesomeIcon icon={faTwitterSquare} />;
const FaEmail = () => <FontAwesomeIcon icon={faAt} />;

const ShareLink = ({ href, children, ...props }) => {
  return (
    <Link href={href} rel="noopener" isExternal {...props} fontWeight="bold">
      {children}
    </Link>
  );
};

export const FacebookShare = ({ story, url, toast, ...props }) => {
  const getFacebookLink = () => {
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}&hashtag=QuiltedStories`;
  };

  const FbShareLink = (props) => {
    return (
      <ShareLink href={getFacebookLink()} {...props}>
        <Icon as={FaFacebook} /> Facebook
      </ShareLink>
    );
  };

  const NativeShareLink = () => {
    return (
      <FbShareLink {...props}
        onClick={() => {
          const shareData = {
            title: "Quilted Stories",
            text: story.title,
            url: url,
          };
          try {
            navigator
              .share(shareData)
              .then(() => {
                toast &&
                  toast({
                    title: "Shared successfully!",
                    description: "Your Quilted Story has been shared.",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                    position: "top-right",
                  });
              })
              .catch(() => {
                toast &&
                  toast({
                    title: "Sharing was not successful!",
                    description: "Your Quilted Story could not be shared.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                    position: "top-right",
                  });
              });
          } catch (err) {
            toast &&
              toast({
                title: "Sharing was not successful!",
                description: "Your Quilted Story could not be shared.",
                status: "error",
                duration: 9000,
                isClosable: true,
                position: "top-right",
              });
          }
          return false;
        }}
      />
    );
  };

  return navigator && navigator.share ? <NativeShareLink /> : <ShareLink />;
};

export const TwitterShare = ({ story, url, ...props }) => {
  const getTwitterLink = () => {
    return `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      story.title
    )}&url=${encodeURIComponent(url)}&hashtags=QuiltedStories`;
  };

  return (
    <ShareLink href={getTwitterLink()} {...props}>
      <Icon as={FaTwitter} /> Twitter
    </ShareLink>
  );
};

export const EmailShare = ({ story, url, ...props }) => {
  const getEmailLink = () => {
    const title = `${story.title} - Quilted Story Video`;
    const body = `Quilted Story Video is available at ${url}`;
    return `mailto:?subject=${encodeURIComponent(
      title
    )}&body=${encodeURIComponent(body)}`;
  };

  return (
    <ShareLink href={getEmailLink()} {...props}>
      <Icon as={FaEmail} /> Email
    </ShareLink>
  );
};
