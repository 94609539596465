import React from "react";
import PropTypes from "prop-types";
import { isEmpty, isLoaded, useFirebase } from "react-redux-firebase";
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Text,
  Container,
  useToast,
  HStack,
} from "@chakra-ui/react";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";
import { connect } from "react-redux";
import { Send } from "grommet-icons";
import Loading from "../common/Loading";
import Running from "../common/RunningIndicator";
import ModalDialog, { ModalActions } from "../common/ModalDialog";

const TestInvites = ({ auth, story, ...props }) => {
  const firebase = useFirebase();
  const fn_sendTestInvites = firebase
    .functions()
    .httpsCallable("sendTestInvites");
  const [emails, setEmails] = React.useState();
  const [running, setRunning] = React.useState(false);
  const [successful, setSuccessful] = React.useState();
  const [error, setError] = React.useState();
  const [show, setShow] = React.useState();
  const toast = useToast();

  React.useEffect(() => {
    const initEmails = [];
    auth && auth.email && initEmails.push(auth.email) && setEmails(initEmails);
  }, [auth, show]);

  const handleExit = () => {
    setShow(false);
    setSuccessful();
    setError();
  };

  const handleShow = () => {
    setRunning();
    setSuccessful();
    setError();
    setShow(true);
  };

  const handleUnsuccessfulSend = (error) => {
    setSuccessful(false);
    setError(error);
    setShow();
  };

  const handleSuccessfulSend = () => {
    setSuccessful(true);
    setError();
    setShow();
    toast({
      title: "Invites successfully sent!",
      description: "Check your inbox for the invite.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const send = () => {
    const _story = { ...story, storytellers: [] };
    setRunning(true);
    setSuccessful(false);
    setError();
    fn_sendTestInvites({ story: _story, emails })
      .then((response) => {
        handleSuccessfulSend();
        return response;
      })
      .catch((error) => {
        handleUnsuccessfulSend(error);
      })
      .finally(() => setRunning(false));
  };

  const FormView = () => {
    return (
      <Box {...props}>
        <Text fontWeight="medium">Enter email</Text>
        <Box>
          <ReactMultiEmail
            placeholder="Type or paste email addresses and press `Enter`"
            style={{
              minHeight: "100px",
            }}
            emails={emails}
            onChange={(_emails) => {
              setEmails(_emails);
            }}
            validateEmail={(email) => {
              return isEmail(email); // return boolean
            }}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  {email}
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />
        </Box>
        {running && (
          <HStack align="center" justify="center" direction="row" mt="md">
            <Send color="text" size="large" />
            <Text fontWeight="bold">
              Sending test invite(s)
              <Running start={1} max={3} />
            </Text>
          </HStack>
        )}
        <ModalActions
          mt="lg"
          secondaryButton={{
            label: "cancel",
            onClick: () => setShow(false),
          }}
          primaryButton={{
            onClick: send,
            label: "send",
            isDisabled: running || !emails || emails.length === 0,
          }}
        />
      </Box>
    );
  };

  return (
    <>
      <ModalDialog
        isOpen={show}
        onClose={handleExit}
        heading="Send a test invite"
        modalContent={{
          w: "700px",
        }}
      >
        <FormView />
      </ModalDialog>
      <Container>
        {!isLoaded(auth) && <Loading />}
        {isLoaded(auth) && !isEmpty(auth) && (
          <Button
            colorScheme="blackAlpha"
            variant="outline"
            onClick={handleShow}
            disabled={running}
          >
            Send me a test invite first
          </Button>
        )}
        {error && (
          <Alert status="error">
            <AlertDescription>
              There was an error sending the test invites! Please check your
              project setup and try again.
            </AlertDescription>
          </Alert>
        )}
      </Container>
    </>
  );
};

TestInvites.propTypes = {
  story: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

export default connect(mapStateToProps)(TestInvites);
