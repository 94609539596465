import { createMachine, assign, sendParent, spawn } from "xstate";
import { pure, send } from "xstate/lib/actions";
import { createVideoClipMachine } from "./videoClipMachine";
import {
  isPrompt,
  isSubmission,
  PromptContext,
  PromptMachineEvent,
  VideoClipMachineEvent,
} from "../../types/DIYVideoBuilder";

export const createPromptMachine = ({
  id,
  idx,
  position,
  duration,
  text,
  submissions,
  include,
}: PromptContext) =>
  createMachine<PromptContext, PromptMachineEvent | VideoClipMachineEvent>(
    {
      id: "promptMachine",
      tags: ["prompt"],
      context: {
        id,
        idx,
        position,
        duration,
        text,
        prevText: text,
        submissions,
        include,
        clips: [],
      },
      initial: "init",
      states: {
        init: {
          entry: [
            "announce",
            assign({
              clips: (context) => {
                console.log("Rehydrating clips");
                // "Rehydrate" clips with machines
                const clips = context.submissions.map((submission, index) =>
                  spawn(
                    createVideoClipMachine({
                      id: submission.id,
                      idx: index,
                      type: "submission",
                      data: submission,
                    })
                  )
                );
                return [
                  spawn(
                    createVideoClipMachine({
                      id: context.id,
                      idx: context.idx,
                      type: "prompt",
                      data: { ...context },
                    })
                  ),
                  ...clips,
                ];
              },
            }),
          ],
          always: "idle",
        },
        idle: {
          entry: ["announce"],
          on: {
            EDIT: {
              target: "editing",
              actions: ["setActive"],
            },
          },
        },
        editing: {
          entry: ["announce", assign({ prevText: (context) => context.text })],
          on: {
            TEXT_CHANGE: {
              actions: [
                assign({
                  text: (_, event) => event.value,
                }),
                "commit",
              ],
            },
            DONE: {
              actions: ["commit"],
              target: "idle",
            },
            CANCEL: {
              actions: [
                assign({
                  text: (context, event) => context.prevText,
                }),
                "commit",
              ],
              target: "idle",
            },
          },
        },
      },
      on: {
        "CLIP.ACTIVE": {
          actions: ["setClipActive", "setActive", "toggleClipActive"],
        },
        "CLIP.COMMIT": {
          actions: ["clipCommit", "commit"],
        },
        "CLIP.DONE": {
          actions: ["clipDone", "toggleClipActive"],
        },
      },
    },
    {
      actions: {
        announce: (context, event) => {
          // console.log("Prompt machine - ", { context, event });
        },
        commit: sendParent((context) => ({
          type: "PROMPT.COMMIT",
          prompt: context,
        })),
        toggleClipActive: assign((context) => {
          return { isClipActive: !context.isClipActive };
        }),
        setActive: sendParent((context) => ({
          type: "PROMPT.ACTIVE",
          id: context.id,
        })),
        setClipActive: sendParent((context, event) => {
          if (event.type !== "CLIP.ACTIVE") return event;
          console.log("SET CLIP ACTIVE", { event });
          const actor = context.clips.find((clip) => {
            return (
              event.clip.id === clip.machine.context.id &&
              event.clip.type === clip.machine.context.type
            );
          });
          return {
            type: "CLIP.ACTIVE",
            clip: actor,
            prompt: context.id,
          };
        }),
        clipCommit: pure((context, event) => {
          if (event.type !== "CLIP.COMMIT") return event;
          // console.log("clipCommit", { event });
          const commands: Array<any> = [sendParent((context, event) => event)];
          const changes: any = {};
          if (event.clip.type === "prompt" && isPrompt(event.clip.data)) {
            changes.text = event.clip.data.text;
            changes.include = event.clip.data.include;
            commands.push(assign({ ...changes, prevText: changes.text }));
          } else if (
            event.clip.type === "submission" &&
            isSubmission(event.clip.data)
          ) {
            const _submissions = [...context.submissions];
            const idx = context.submissions.findIndex((submission) => {
              return event.clip.id === submission.id;
            });
            if (idx >= 0)
              _submissions[idx] = { ..._submissions[idx], ...event.clip.data };
            changes.submissions = _submissions;
          }
          commands.push(assign(changes));

          return commands;
        }),
        clipDone: sendParent((context, event) => event),
      },
    }
  );
