import React from "react";
import { Box, Button, Heading, Text } from "@chakra-ui/react";

interface ContinueCardViewProps {
  colorScheme: string;
  subject: string;
  onClick: () => void;
}
export const ContinueCardView: React.FC<ContinueCardViewProps> = ({
  colorScheme,
  subject,
  onClick,
}: ContinueCardViewProps) => {
  return (
    <Box>
      <Heading as="h4" size="md" mb={3}>
        Continue your final movie
      </Heading>
      <Text mb={3}>
        You need to complete your final movie before it can be shared with{" "}
        {subject}. Pick up where you left off.
      </Text>
      <Button colorScheme={colorScheme} onClick={onClick}>
        Continue editing
      </Button>
    </Box>
  );
};

export default ContinueCardView;
