import React from "react";
import { Box, Heading, Link, Text, Stack, Divider } from "@chakra-ui/react";
import QuiltedVideo from "./QuiltedVideo";

const HelpBlock = (props) => {
  return (
    <Stack p="md" spacing="lg">
      <Heading variant="center" size="h2" textAlign="start" alignSelf="center">
        We&apos;re here to help!
      </Heading>
      <Box
        round="small"
        background="light-1"
        align="center"
        justify="evenly"
        pad="large"
        margin={{ top: "medium", bottom: "xlarge" }}
        flex={{ grow: 1 }}
        fill="horizontal"
      >
        <Text>
          See if your question is answered in our{" "}
          <Link
            fontWeight="bold"
            color="airBlue.700"
            href="https://help.quilted.io"
            allyTitle="Check our FAQs"
            textDecoration="underline"
            isExternal
          >
            FAQs
          </Link>
        </Text>
        <Text>or</Text>
        <Text>
          Email us and we&apos;ll get back{" "}
          <Link
            fontWeight="bold"
            color="airBlue.700"
            href="https://quilted.io/pages/contact-us"
            a11yTitle="Email us for help"
            textDecoration="underline"
            isExternal
          >
            ASAP
          </Link>
        </Text>
      </Box>
      <Divider />
      <Text fontWeight="bold">
        In the meantime, check out our &quot;How It Works&quot; video
      </Text>

      <QuiltedVideo />
      {props.children}
    </Stack>
  );
};

export default HelpBlock;
