import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";

const AnonymousRoute = ({ auth, children, to }) => {
  let location = useLocation();

  if (!auth || !auth.isLoaded) return null;

  if (!auth.isEmpty) {
    return (
      <Navigate to={to || "/my-stories"} state={{ from: location }} replace />
    );
  }
  return children;
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

export default connect(mapStateToProps)(AnonymousRoute);
