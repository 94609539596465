import * as Phone from 'phone';

export const enforceE164PhoneFormat = (phoneNumber) => {
    const result = Phone(phoneNumber);
    if(result && result.length)
        return result[0];
    else
        return null;
}

const formatPhoneNumberForDisplay = (phoneString) => {
    let cleaned = ('' + phoneString).replace(/\D/g, '')
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        // var intlCode = (match[1] ? '+1 ' : '')
        return ['(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return phoneString;
}

export default {
    forDisplay: formatPhoneNumberForDisplay,
    forStorage: enforceE164PhoneFormat    
}