let fb;
let fbLoaded = false;
export default function facebookPixelPlugin(userConfig = {advancedMatching:{}, pixelId:''}) {
  return {
    name: "facebook-pixel",
    config: {
      ...userConfig,
    },
    methods: {
      trackCustom({title, payload}) {
        //analytics.plugins.facebookPixel.trackCustom()
        fb.trackCustom(title, payload);
      },
    },
    initialize: async ({ config }) => {
      const {advancedMatching, pixelId} = config;
      await import("react-facebook-pixel")
        .then((module) => (fb = module.default))
        .then(() => {
          if(!fbLoaded) {
            fb.init(pixelId, advancedMatching, {
              autoConfig: true,
              debug: true,
            });
            fbLoaded = true;
          }
        });
    },
    page: ({ payload }) => {
      fb.pageView();
    },
    /* Track event */
    track: ({ payload }) => {
      console.log("facebook track", payload)
      fb.track(payload.event, payload.properties)
    },
    /* Identify user */
    identify: ({ payload }) => {
      // I believe FB doesn't have an identify API any more
    },
    loaded: () => {
      return fbLoaded;
    },
  };
}
