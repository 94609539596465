import React from "react";
import { List } from "grommet";
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Spacer,
  Text,
  useTheme,
} from "@chakra-ui/react";

import ContactInputForm from "./ContactInputForm";
import ContactTable from "./ContactTable";
import CloudSpongeWidget from "../../../cloudsponge";

import * as phone from "phone";
import { contactValidation } from "../../../../utils/validation";
import formatPhoneNumber from "../../../../utils/formatPhoneNumber";
import { forceLowercaseProps } from "../../../../utils/convert";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DeDupper = (contacts) => {
  const emailIsNotDuplicate = (email, i) => {
    const others = [...contacts];
    if (i || i === 0) others.splice(i, 1);
    const hasEmailAlready = others.some((el) => {
      const dup =
        el.contactPreference === "EMAIL" &&
        String(el.email).toLowerCase() === String(email).toLowerCase();
      return dup;
    });
    return !hasEmailAlready;
  };

  const phoneIsNotDuplicate = (phone, i) => {
    const others = [...contacts];
    if (i || i === 0) others.splice(i, 1);
    const hasPhoneAlready = others.some((el) => {
      const dup =
        el.contactPreference === "SMS" &&
        formatPhoneNumber.forDisplay(el.phone) ===
          formatPhoneNumber.forDisplay(phone);
      return dup;
    });
    return !hasPhoneAlready;
  };

  const nameIsNotDuplicate = (name, i) => {
    const others = [...contacts];
    if (i || i === 0) others.splice(i, 1);
    const hasNameAlready = others.some((el) => {
      const dup =
        name &&
        name.length &&
        String(el.name).toLowerCase() === String(name).toLowerCase();
      return dup;
    });
    return !hasNameAlready;
  };

  const isDup = (contact, i, checkName = true) => {
    const email = emailIsNotDuplicate(contact.email, i);
    const phone = phoneIsNotDuplicate(contact.phone, i);
    const name = checkName ? nameIsNotDuplicate(contact.name, i) : true;
    console.log({ dups: { name, email, phone }, checkName });
    return !(email && phone && name);
  };

  const combineUnique = (collection, checkNames = true) => {
    const goodOnes = [...(collection || [])].filter((contact) => {
      console.log("Checking for duplicates", {
        collection,
        contact,
        checkNames,
        idDup: !isDup(contact, checkNames),
      });
      return !isDup(contact, checkNames);
    });
    const badOnes = [...(collection || [])].filter((contact) => {
      return isDup(contact, checkNames);
    });
    return [[...contacts, ...goodOnes], badOnes];
  };

  return {
    isDup,
    combineUnique,
    phoneIsNotDuplicate,
    emailIsNotDuplicate,
    nameIsNotDuplicate,
  };
};

const ContactManager = ({
  contacts,
  limit,
  onChange,
  existingContacts,
  ...rest
}) => {
  const [errors, setErrors] = React.useState(rest.errors || []);

  const theme = useTheme();

  const remaining = React.useMemo(() => {
    return limit - contacts.length;
  }, [limit, contacts]);

  const validator = React.useMemo(() => {
    return DeDupper([...contacts, ...(existingContacts || [])]);
  }, [contacts, existingContacts]);

  const handleAdd = (contact) => {
    const mapped = [contact, ...contacts];
    onChange && onChange({ contacts: mapped, valid: isValid(mapped) });
  };

  const handleRemove = (index) => {
    const mapped = [...contacts];
    mapped.splice(index, 1);

    onChange && onChange({ contacts: mapped, valid: isValid(mapped) });
  };

  const onUpdate = (contact, i) => {
    const mapped = [...contacts];
    mapped[i] = contact;
    onChange && onChange({ contacts: mapped, valid: isValid(mapped) });
  };

  const isValid = (_contacts) => {
    return _contacts.every((contact) =>
      contact.hasOwnProperty("isValid") ? contact.isValid : true
    );
  };

  const addError = (error) => {
    setErrors((errs) => errs.concat(error));
  };

  const cleanData = (data) => {
    return [...data]
      .map((row, i) => {
        row = forceLowercaseProps(row);
        console.log(i, { row });
        let _phone =
          formatPhoneNumber.forDisplay(String(row.phone).trim()) || row.phone;
        let _email = String(row.email).trim();
        row.phone = (_phone && phone(_phone)[0]) || null;
        row.email = _email && _email.length ? _email : null;
        row.contactPreference = row.email ? "EMAIL" : "SMS";
        const { valid, errors } = contactValidation.validate(row);
        row.isValid = valid;
        if (!valid)
          addError(`${i + 1}) Name: ${row.name} ${errors.join(", ")}`);
        return row;
      })
      .filter(({ isValid }, i) => {
        return isValid;
      })
      .slice(0, remaining);
  };

  const onImport = (imported) => {
    console.log({ imported });
    const [_contacts, dups] = DeDupper(contacts).combineUnique(imported);
    console.log({ _contacts, dups });
    onChange && onChange({ contacts: _contacts, valid: isValid(_contacts) });
  };

  const handleData = (data) => {
    const _data = cleanData(data);
    onImport && onImport(_data);
    console.log("Imported contacts passed to onImport", { onImport });
  };

  const items = (contacts && contacts.length && [...contacts]) || [];

  const UploadContactsBlock = () => {
    return (
        <CloudSpongeWidget
          onImport={handleData}
          limit={remaining}
          cloudspongeKey="3u8r6LpjVRMeN1E6pG1-XA"
        >
          {({ launchCloudspongeHandler }) => (
            <>
              <Button
                variant="ghost"
                rounded="none"
                colorScheme="tumbleweed"
                onClick={launchCloudspongeHandler}
                disabled={remaining === 0}
                size="lg"
                fontSize="md"
              >
                <HStack>
                  <Text color="tumbleweed.500" fontSize="md">
                    <FontAwesomeIcon icon="cloud-upload-alt" />
                  </Text>
                  <Text color="gray" fontSize="md">
                    Import your contacts
                  </Text>
                </HStack>
              </Button>
            </>
          )}
        </CloudSpongeWidget>
    );
  };

  const AddContactsBlock = () => {
    return (
      <ContactInputForm
        onSave={(contact) => handleAdd(contact)}
        nameIsNotDuplicate={(name) => {
          return validator.nameIsNotDuplicate(name);
        }}
        emailIsNotDuplicate={(email) => {
          return validator.emailIsNotDuplicate(email);
        }}
        phoneIsNotDuplicate={(phone) => {
          return validator.phoneIsNotDuplicate(phone);
        }}
      />
    );
  };

  return (
    <>
      <Flex direction="column" justify="start" align="start" w="full">
        <AddContactsBlock />
        <UploadContactsBlock />
      </Flex>
      <Spacer />
      {errors && errors.length > 0 && (
        <List data={errors} background="status-error" />
      )}
      {items && !!items.length && (
        <>
          {isMobile && (
            <>
              <Spacer />
              <Divider />
              <Spacer />
            </>
          )}
          <Text fontWeight="bold" mb={3}>
          Invites to be sent:
          </Text>
          <ContactTable
            contacts={items}
            onDelete={(itemIndex) => {
              handleRemove(itemIndex);
            }}
          />
        </>
      )}
    </>
  );
};

export default ContactManager;
