import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import { isIOS } from 'react-device-detect';
import { Box, Layer, Heading, Stack, Button, Text } from 'grommet';

import { setPlaylist, setPlayingIndex } from '../../store/actions/videoPlayerActions';
import TextShadow from '../common/TextShadow';
import { Image } from '@chakra-ui/react';

const PromptText = ({text}) => {
  const timer = React.useRef(null);
  const [showText,setShowText] = React.useState();

  useEffect(() => {
    setShowText(text);
    if(timer.current) clearTimeout(timer.current);

    timer.current = setTimeout(() => {
      setShowText(false)
    }, 6000);

    return () => {
      clearTimeout(timer.current)
    }
  },[text]);

  const render = (children) => {
    return (<Box fill={true} margin={isIOS && { top: "xlarge"}}
      animation={
        {
          "type": "fadeOut",
          "delay": 3000,
          "duration": 3000
        }
      }>
        {children}
  </Box>)
  }

  return showText ?
    render(<Box fill="horizontal" pad="medium"
        background={{"color": "dark-1","opacity": true}}
      ><Heading color="white" margin="large" level="3" alignSelf="stretch" textAlign="center">
      <TextShadow shadowColor="#000000">{text}</TextShadow>
    </Heading>
    </Box>) : <></>
}

export const Player = (props) => {
  const { index, playlist, modal, setMyPlaylist, setIndex, muted  } = props;

  const [idx, setIdx] = React.useState(index||0)
  const [media,setMedia] = React.useState(playlist[idx]);
  const [currentText,setCurrentText] = React.useState(media && media.text);
  useEffect(() => {
    if(currentText !== media.text) {
      setCurrentText(media.text);
    }
  }, [media,currentText])

  useEffect(() => {
    window.onpopstate = function historyChange(ev) {
      if (ev.type === "popstate") {
        setMyPlaylist && setMyPlaylist(null);
      }
    }
  }, [setMyPlaylist])

  const changeVideo = React.useCallback((i) => {
    setMedia(playlist[i]);
  }, [playlist])

  useEffect(() => {
    changeVideo(idx);
  },[idx,changeVideo])


  const asModal = () => {
    const isModal = ((typeof modal === "undefined") || modal === null) ? true : modal;
    return isModal;
  }

  const onClose = () => {
    setMyPlaylist && setMyPlaylist(null);
    props.onClose && props.onClose();
  }

  const onEnded = () => {
      if (idx + 1 === playlist.length) {
        onClose();
      } else {
        setIdx(idx+1);
	      setIndex && setIndex(idx + 1);
      }
      props.onEnded && props.onEnded();
  }

  const isPlaying = () => {
    return !!media;
  }

  const render = (children) => {
    if(asModal()) {
      return (<Layer
        onEsc={onClose}
        modal={true}
        full={true}  plain={true}  
        animation="fadeIn" 
      >{children}</Layer>);
    }
    else {
      return children;
    }

  }

  const playing = isPlaying();

  return (<Fragment>{ playlist && (
      render(<Box fill={true} background="background">
          <Stack fill={true} anchor="top">
            <Stack fill={true} anchor="center">
              <Box fill={true}>
              {media && media.type === "image" ? <Image objectFit="contain" src={media.url} height="full"/>:(
                    <ReactPlayer
                      muted={!!muted}
                      playsinline={true}
                      url={media && media.url}
                      playing={playing}
                      controls
                      width='100%'
                      height='100%'
                      onEnded={onEnded}
                      className={playing ? null : 'hidden'}
                    />
                  )
                }
                </Box>
                {playing && currentText && <PromptText text={currentText} />}
            </Stack>
            <Button color="accent-1" onClick={onEnded} plain={false} margin={{top:'xsmall'}}><Text color="accent-1">next</Text></Button>
          </Stack>
        </Box>
      ))}
</Fragment>);
};

const mapStateToProps = (state, ownProps) => {
  const data = {
    playlist: undefined,
    index: 0
  };

  data.index = state.videoPlayer && state.videoPlayer.index;
  data.playlist = state.videoPlayer && state.videoPlayer.playlist;

  return (data);
};

const mapDispatchToProps = (dispatch) => {
  return ({
    setMyPlaylist: (playlist) => dispatch(setPlaylist(playlist)),
    setIndex: (index) => dispatch(setPlayingIndex(index))
  })
}

Player.propTypes = {
  onEnded: PropTypes.func,
  onClose: PropTypes.func,
  playlist: PropTypes.array
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Player);