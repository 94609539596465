// TODO integrate with react-hook-form context

import React from "react";
import { Container, Flex, Stack, Text } from "@chakra-ui/react";
import ChatBox from "../../../layout/ChatBox";

import interpolate from "../../../../utils/interpolate";

export const welcomeMessageCharLimit = 600;
export const welcomeMessageDefaultCopy =
  "It will only take you a minute to record and submit your video. This will be an amazing gift, especially with you a part of it. Make sure you respond as soon as you can!";

export const EditWelcomeMessage = ({
  story,
  onChange,
  chatBoxProps,
  inputComponent,
  errorMessage,
}) => {

  // const [welcomeMessage, setWelcomeMessage] = React.useState(
  //   story.welcomeMessage
  // );

  // const [limitError, setLimitError] = React.useState(false);

  // React.useEffect(() => {
  //   if (story.welcomeMessage) {
  //     isValid(welcomeMessage);
  //   } else {
  //     handleChange(defaultCopy);
  //   }
  // }, []);

  // const hasValue = (value) => value && value.length;
  // const underLimit = (value) => value.length < welcomeMessageCharLimit;

  // const isValid = (value) => {
  //   if (hasValue(value)) {
  //     const hasLimitError = !underLimit(value);
  //     setLimitError(hasLimitError);
  //     return { isValid: !hasLimitError };
  //   } else {
  //     return { isValid: false };
  //   }
  // };

  // const handleChange = (value) => {
  //   const _valid = isValid(value);
  //   setWelcomeMessage(value);
  //   onChange && onChange({ value, valid: _valid, defaultCopy });
  // };

  return (
    <Flex align="center" justify="center" pad="medium">
      {/* {limitError && (
        <Box
          background="status-warning"
          pad="medium"
          round={true}
          margin={{ bottom: "small" }}
        >
          <Paragraph>{`Please keep your welcome message under ${messageCharLimit} characters.`}</Paragraph>
        </Box>
      )} */}
      <Container>
        <ChatBox {...(chatBoxProps || {})}>
          <Stack>
            <Text fontWeight="medium">Hi &#123;Storyteller&#125;!</Text>
            <Text fontWeight="medium">
              {interpolate(story.template.welcomeMessageTemplate, { story })}
            </Text>
            {inputComponent}
            {errorMessage}
            {/* <Text>
              {errors && errors.welcomeMessage
                ? errors.welcomeMessage.message
                : ""}
            </Text> */}
          </Stack>
          {/* <FormField
          name="welcomeMesage"
          component={TextArea}
          placeholder={defaultCopy}
          rows="6"
          onChange={(e) => handleChange(e.target.value)}
          value={welcomeMessage}
          validate={[
            (value) => {
              return hasValue(value) ? undefined : (
                <Text size="xsmall" color="status-error">
                  Please enter a welcome message.
                </Text>
              );
            },
            (value) => {
              return underLimit(value) ? undefined : (
                <Text size="xsmall" color="status-error">
                  Please keep your welcome message brief. (limit:{" "}
                  {messageCharLimit} characters)
                </Text>
              );
            },
          ]}
        /> */}
        </ChatBox>
      </Container>
    </Flex>
  );
};

export default EditWelcomeMessage;
