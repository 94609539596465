import React from "react";

import {
  Heading,
  Text,
  Divider,
  Flex,
  Box,
  Container,
  Collapse,
  useDisclosure,
  Button,
  Stack,
  Image,
} from "@chakra-ui/react";
import PromptsList from "./components/PromptsList";
import { timestampToMoment } from "../../utils/convert";

const PromptsView = ({
  story,
  responseState,
}: {
  responseState: "complete" | "active";
  story: {
    title: string;
    owner: string;
    subject: string;
    dueDate: any;
    template?: any;
    welcomeMessage: string;
    prompts: Array<any>;
  };
}) => {
  const getResponses = React.useCallback(() => {
    if (story) {
      const count = story.prompts.filter((p) => !!p.response).length;
      return count;
    } else {
      return 0;
    }
  }, [story]);

  const { isOpen: responsesOpen, onToggle: toggleResponses } = useDisclosure();

  return (
    <Flex
      justify="start"
      my="lg"
      h="full"
      direction="column"
      p="lg"
      align="center"
    >
      <Heading as="h2" size="h2" variant="center">
        {story.title}
      </Heading>
      {responseState === "complete" ? (
        <Container textAlign="center">
          <Stack my="lg" spacing="md">
            <Image
              src={`/img/package-icons/${
                story.template?.illustration || "mountain.svg"
              }`}
              maxW="300px"
              alignSelf="center"
            />
            <Text fontSize="xl" variant="serif">
              You&apos;ve responded to all {story.prompts.length} prompts!
            </Text>
            <Text>
              You can still view your videos and make changes until{" "}
              {timestampToMoment(story.dueDate).format("MM/DD/YYYY")}. After the
              due date, {story.owner || "the story owner"} will create the final
              video to be gifted to {story.subject}.
            </Text>
          </Stack>
        </Container>
      ) : (
        <>
          <Container>
            <Text align="center" my="4" lineHeight="short" fontSize="md">
              Your videos should be submitted by{" "}
              {timestampToMoment(story.dueDate).format("MM/DD/YYYY")} to be
              included in {story.subject}&apos;s final video.
            </Text>
          </Container>
          <Box
            rounded="lg"
            border="1px solid"
            borderColor="blackAlpha.300"
            p="sm"
            py="xs"
            mb="md"
          >
            <Text align="center" lineHeight="short" variant="serif">
              <strong>{getResponses()}</strong> of{" "}
              <strong>{story.prompts.length}</strong> responses submitted
            </Text>
          </Box>
        </>
      )}

      <Divider />

      {responseState === "complete" && (
        <Button onClick={toggleResponses} variant="outline" mt="lg">
          {responsesOpen ? (
            <span>Hide responses</span>
          ) : (
            <span>View your responses</span>
          )}
        </Button>
      )}
      <Box w="full">
        <Collapse in={responseState === "active" || responsesOpen}>
          {story.prompts && <PromptsList prompts={story.prompts} />}
        </Collapse>
      </Box>
    </Flex>
  );
};

export default PromptsView;
