import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  Skeleton,
} from "@chakra-ui/react";
import { sendPasswordReset } from "../../store/actions/authActions";
import { useAnalytics } from "use-analytics";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isLoaded, isEmpty } from "react-redux-firebase";
import {
  verifyPasswordResetCode,
  resetPassword,
} from "../../store/actions/authActions";
import PageContainer from "../../layout/components/PageContainer";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = (props) => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = React.useState(false);

  const {
    resetPasswordFor,
    resetPasswordCodeValid,
    resetPasswordError,
    resetPasswordComplete,
  } = props;
  let query = useQuery();
  const code = query.get("oobCode");

  const [verifyingCode, setVerifyingCode] = React.useState(false);
  const schema = yup.object().shape({
    password: yup.string().required("Password is required"),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const { register, setFocus, setValue, watch, formState } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const { errors, isDirty, isValid } = formState;
  const [password] = watch(["password"]);

  React.useEffect(() => {
    if (!isLoaded(resetPasswordCodeValid) && !isEmpty(code)) {
      setVerifyingCode(true);
      const timeout = setTimeout(() => {
        setLoaded(true);
        props.verifyPasswordResetCode(code);
      }, 3000);
      return () => {
        timeout && clearTimeout(timeout);
      };
    }
  }, []);

  React.useEffect(() => {
    loaded && setVerifyingCode(false) && setFocus("password");
  }, [loaded, resetPasswordCodeValid, resetPasswordError, setFocus]);

  const handleSubmit = () => {
    if (isValid) {
      props.resetPassword(code, password);
    }
  };

  return (
    <PageContainer minW={undefined} maxW="90vw" w="3xl">
      {isEmpty(code) && (
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Heading>
            Reset code is missing or invalid. Please check your email and use
            the link provided in the reset email.
          </Heading>
        </Stack>
      )}
      {!isEmpty(code) && (
        <>
          {verifyingCode && (
            <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
              <Heading>Verifying password reset code ...</Heading>
            </Stack>
          )}
          <form>
            <Flex minH="lg" align={"center"} justify={"center"}>
              <Skeleton isLoaded={loaded && !verifyingCode}>
                <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
                  <Stack align={"center"}>
                    <Heading fontSize={"4xl"}>
                      {resetPasswordComplete
                        ? "Password Reset"
                        : "Reset your password"}
                    </Heading>
                    {isLoaded(resetPasswordError) &&
                      !isEmpty(resetPasswordError) && (
                        <Text fontSize={"lg"} color={"puce.600"}>
                          {resetPasswordError}
                        </Text>
                      )}
                    {isLoaded(resetPasswordComplete) && resetPasswordComplete && (
                      <Text fontSize={"lg"} color={"seaGreen.600"}>
                        You have successfully changed your password.
                      </Text>
                    )}
                    {props.resetPasswordComplete && (
                      <Button
                        onClick={() => {
                          navigate("/signin", { replace:true});
                        }}
                      >
                        Sign In
                      </Button>
                    )}
                  </Stack>
                  {!resetPasswordComplete && (
                    <Box rounded={"lg"} boxShadow={"lg"} p={8}>
                      <Stack spacing={4}>
                        <Stack>
                          <FormControl
                            id="password"
                            isInvalid={errors.password}
                          >
                            <FormLabel variant="headingLabel">
                              New Password
                            </FormLabel>
                            <Input
                              {...register("password")}
                              placeholder="Enter a password"
                              type="password"
                            />
                            <FormErrorMessage color="puce.700">
                              {errors.password?.message}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            id="passwordConfirmation"
                            isInvalid={errors.passwordConfirmation}
                          >
                            <Input
                              {...register("passwordConfirmation")}
                              placeholder="Re-enter your new password"
                              type="password"
                            />
                            <FormErrorMessage color="puce.700">
                              {errors.passwordConfirmation?.message}
                            </FormErrorMessage>
                          </FormControl>
                        </Stack>
                        <Stack
                          spacing={10}
                          direction={"row"}
                          align={"start"}
                          justify={"space-between"}
                        >
                          <Button
                            colorScheme="gray"
                            variant="outline"
                            onClick={() => {
                              navigate("/signin", {replace:true});
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            colorScheme="majesty"
                            onClick={handleSubmit}
                            disabled={!resetPasswordCodeValid || !isValid}
                          >
                            Reset My Password
                          </Button>
                        </Stack>
                      </Stack>
                    </Box>
                  )}
                </Stack>
              </Skeleton>
            </Flex>
          </form>
        </>
      )}
    </PageContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    resetPasswordFor: state.auth.resetPasswordFor,
    resetPasswordError: state.auth.resetPasswordError,
    resetPasswordCodeValid: state.auth.resetPasswordCodeValid,
    resetPasswordComplete: state.auth.resetPasswordComplete,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyPasswordResetCode: (code) => dispatch(verifyPasswordResetCode(code)),
    resetPassword: (code, password) => dispatch(resetPassword(code, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
