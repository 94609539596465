import { isIOS, isMobile } from "react-device-detect";

export const isDownloadable = !(isIOS && isMobile);

export const downloadUrl = (downloadFileName, url, onProgress, onComplete, onError, onAbort) => {
  if(!isDownloadable) onError('iOS does not support downloading this file.');

  var xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = function(e) {
    if(this.readyState === 4 && this.status === 200) {
      const blob = xhr.response;
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = downloadFileName;
      a.target = '_blank';
      a.click();
      onComplete && onComplete()
    }    
    if(this.readyState === 4 && this.status === 404) onError('Video not found')
    if(this.readyState === 4 && this.status === 500) onError('Unknown error when trying to download video')
  }
  xhr.onprogress = function(e) {
    const {total, loaded} = e
    const percent_complete = Math.floor((loaded / total) * 100)
    onProgress && onProgress(percent_complete)
  }
  xhr.onreadystatechange = function(e) {
    console.log('xhr.onload', { e, readyState:this.readyState, status: this.status})
    if(this.readyState === 4 && this.status === 200) {
        var obj = window.URL.createObjectURL(this.response);        
        setTimeout(function() {
            window.URL.revokeObjectURL(obj);
            onComplete && onComplete()
        }, 60 * 1000);
    }
  }

  onError && xhr.addEventListener("error", onError);
  onAbort && xhr.addEventListener("abort", onAbort);

  xhr.open('GET', url);
  xhr.send();
};