import {
  Box,
  Button,
  Text,
  Select,
  Heading,
  Stack,
  List,
  Flex,
  ListItem,
  IconButton,
  Divider,
  Alert,
  AlertIcon,
  AlertDescription,
  HStack,
  Image,
  Textarea,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Add, Subtract } from "grommet-icons";
import React, { useEffect, useState } from "react";
import { getBoxTheme } from "../../../../grommetTheme";
import { useStoryManager } from "../../StoryManager";
import { useForm } from "react-hook-form";
import bg from "../../../../assets/story-manager-bg.svg";
import { getColorScheme } from "../../../../chakraTheme";
import customPromptBg from "../../../../assets/custom-prompt.svg";
import promptPackageBg from "../../../../assets/prompt-package.svg";

const PromptBox = ({ prompt, theme, onRemove }) => {
  return (
    <HStack
      justify="space-between"
      w="full"
      py="sm"
      px="lg"
      pr="sm"
      bgColor={`${getColorScheme(prompt.idx)}.600`}
      rounded="xl"
      spacing="6"
      backgroundPosition="bottom -80px right"
      backgroundRepeat="no-repeat"
      backgroundImage={`url(${bg})`}
    >
      <Text fontSize="lg" color="white">
        {prompt.text}
      </Text>
      <IconButton
        onClick={onRemove}
        colorScheme="whiteAlpha"
        backgroundColor="whiteAlpha.700"
        icon={<Subtract size="medium" color={theme.icon} />}
      />
    </HStack>
  );
};

const PromptChooser = ({ onCancel }) => {
  const [context, dispatch] = useStoryManager();
  const { story, templates } = context.state;
  const [current, setCurrent] = React.useState();
  const [selectedPackage, setSelectedPackage] = React.useState();
  const [error, setError] = React.useState();
  const [max, setMax] = React.useState();
  const [full, setFull] = React.useState(false);
  const [customPrompt, setCustomPrompt] = React.useState();
  const [promptMode, setPromptMode] = useState();

  const [added, setAdded] = React.useState([]);

  const findTemplate = (id) => {
    console.log(
      "finding templates",
      templates && [...templates].find((template) => template.id === id)
    );
    return templates && [...templates].find((template) => template.id === id);
  };

  const filterPrompts = (template, prompts) => {
    return [...template.prompts].filter(
      (prompt) => ![...prompts].map((p) => p.text).includes(prompt.text)
    );
  };

  const buildPackage = React.useCallback(
    (template) => {
      if (!story) return;
      const { prompts } = story;
      const templatePackage = {
        ...template,
        prompts: filterPrompts(template, [...prompts, ...added]),
      };
      setSelectedPackage(templatePackage);
      return templatePackage;
    },
    [added, story]
  );

  const load = React.useCallback(() => {
    if (!story || !templates) return;
    const { templateId } = story;
    const template = findTemplate(templateId);
    if (!template) {
      setError(
        `We could not determine the package (${templateId}) for this story! Please go back to setup and choose a package.`
      );
      return;
    }
    const templatePackage = buildPackage(template);
    setCurrent(templatePackage);
    setValue("templateId", templateId);
    const maxPrompts =
      template.maxPrompts || template.prompts.length + 1;
    setMax(maxPrompts);
    setFull(story.prompts.length >= maxPrompts);
    console.log([story.prompts.length, template.prompts.length]);
  }, [story, templates, added]);

  React.useEffect(load, [story, templates]);

  React.useEffect(() => {
    selectedPackage && buildPackage(findTemplate(selectedPackage.id));
    max &&
      setFull(
        added && added.length && added.length + story.prompts.length >= max
      );
  }, [added]);

  const { register, watch, setValue } = useForm();
  const [templateId] = watch(["templateId"]);

  useEffect(() => {
    if (templateId) {
      const t = findTemplate(templateId);
      buildPackage(t);
    }
  }, [templateId]);

  const onAdd = (templateId, prompt) => {
    setAdded([...added, { ...prompt, templateId }]);
  };

  const onRemove = (idx) => {
    const items = [...added];
    items.splice(idx, 1);
    setAdded(items);
  };

  const addNewPrompts = () => {
    dispatch({
      type: "update",
      payload: {
        story: {
          ...story,
          prompts: [...story.prompts, ...added],
          step: "prompts",
        },
        changed: true,
      },
      callback: onCancel,
    });
  };

  const comparer = (a, b) => {
    let comparison = 0;
    if (a.title > b.title) {
      comparison = 1;
    } else if (a.title < b.title) {
      comparison = -1;
    }
    return comparison;
  };

  const messageCharLimit = 150;

  const hasValue = (value) => value && value.length;
  const underLimit = (value) => value.length < messageCharLimit;
  //TODO: on error, show Toast and return empty node.

  const PromptSelector = () => {
    return (
      <Stack>
        <Text fontWeight="medium">Choose prompts from another package:</Text>
        <Select placeholder="Select a package" {...register("templateId")}>
          {templates.sort(comparer).map((t) => {
            return (
              <option value={t.id} key={t.id}>
                {t.title}
              </option>
            );
          })}
        </Select>
        {selectedPackage.prompts.length > 0 && (
          <Box align="center" justify="start">
            <List spacing={3} mt="sm" mb="lg">
              {selectedPackage.prompts.map((p) => {
                return (
                  <>
                    <ListItem key={p.id} py="xs">
                      <HStack direction="row" justify="space-between">
                        <Text textAlign="left" fontSize="md">
                          {p.text}
                        </Text>
                        <IconButton
                          onClick={() => onAdd(selectedPackage.id, p)}
                          icon={<Add size="medium" color="white" />}
                        />
                      </HStack>
                    </ListItem>
                    <Divider borderColor="gray.500" />
                  </>
                );
              })}
            </List>
          </Box>
        )}
        {selectedPackage.prompts.length === 0 && (
          <Box backgroundColor="tumbleweed.200" p="md" rounded="md">
            <Text color="status-error" textAlign="center">
              You are already using all the pre-written prompts from the{" "}
              <strong>{selectedPackage.title}</strong> package. Select another
              package above for additional prompts.
            </Text>
          </Box>
        )}
      </Stack>
    );
  };

  const FullAlert = () => {
    return (
      <Alert status="error" rounded="md" backgroundColor="puce.200">
        <AlertIcon />
        <AlertDescription>
          You have added the maximum number of prompts allowed for the{" "}
          {!!story.template?.title
            ? `"${story.template.title}" package`
            : "package you selected"}
          . To add more prompts, either delete an existing prompt or remove one
          of the prompts from the list below.
        </AlertDescription>
      </Alert>
    );
  };

  const AddedPrompts = () => {
    return (
      <Stack
        mt="xl"
        mb="md"
        align="center"
        justify="start"
        w="full"
        spacing="6"
      >
        <Heading as="h5" size="h5" variant="h5">
          Your selected prompts
        </Heading>
        <Stack w="full" mb="md">
          {added.map((prompt, idx) => (
            <PromptBox
              theme={getBoxTheme(idx)}
              prompt={{ idx, ...prompt }}
              key={prompt.text}
              onRemove={() => onRemove(idx)}
            />
          ))}
        </Stack>
      </Stack>
    );
  };

  const BoxButton = ({ children, ...props }) => {
    return (
      <Flex
        backgroundColor="white"
        justify="space-around"
        align="center"
        direction="column"
        cursor="pointer"
        rounded="xl"
        p="md"
        w="200px"
        h="200px"
        border="2px"
        borderColor="tumbleweed.300"
        {...props}
      >
        {children}
      </Flex>
    );
  };

  const CustomPrompt = () => {
    const { register, watch, formState } = useForm({
      mode: "onChange",
    });
    const { errors } = formState;
    const [customPrompt] = watch(["customPrompt"]);

    const addCustom = () => {
      if (customPrompt) {
        onAdd(null, { text: customPrompt });
        setCustomPrompt("");
      }
    };

    return (
      <Stack spacing="6">
        <form onSubmit={addCustom}>
          <FormControl id="customPrompt" isInvalid={errors.customPrompt}>
            <Textarea
              bgColor="white"
              placeholder="Write your prompt here"
              {...register("customPrompt", {
                maxLength: {
                  value: messageCharLimit,
                  message: `Please keep your prompt brief. (limit: ${messageCharLimit} characters)`,
                },
              })}
            />
            <FormErrorMessage color="puce.700">
              {errors.customPrompt && errors.customPrompt.message}
            </FormErrorMessage>
          </FormControl>
          <Flex
            direction="row"
            justify={added && !!added.length ? "center" : "space-between"}
            mt="xs"
          >
            {!added ||
              (!added.length && (
                <Button
                  variant="outline"
                  colorScheme="blackAlpha"
                  onClick={() => setPromptMode()}
                >
                  back
                </Button>
              ))}
            <Button
              colorScheme="airBlue"
              type="submit"
              disabled={!customPrompt || errors.customPrompt}
            >
              Add custom prompt
            </Button>
          </Flex>
        </form>
      </Stack>
    );
  };

  return (
    <Stack align="center" mb="md" w="full" minH="100%" flexShrink={0}>
      {error && (
        <Box w="full" background="status-error">
          <Text w="100%" textAlign="center">
            {error}
          </Text>
        </Box>
      )}
      <>
        <Flex direction="column" justify="start" p="sm" w="100%" flexShrink={0}>
          {!promptMode ? (
            <Box mx="auto">
              <Stack spacing="8" direction={["column", "row"]}>
                <BoxButton onClick={() => setPromptMode("custom")}>
                  <Image src={customPromptBg} />
                  <Text color="black">Write your own</Text>
                </BoxButton>
                <BoxButton onClick={() => setPromptMode("packages")}>
                  <Image src={promptPackageBg} />
                  <Text color="black">Add from packages</Text>
                </BoxButton>
              </Stack>
            </Box>
          ) : (
            <>
              {promptMode === "packages" && !full && (
                <>{selectedPackage && <PromptSelector />}</>
              )}
              {promptMode === "custom" && !full && <CustomPrompt />}
              {!!full && <FullAlert />}
            </>
          )}
          {added && !!added.length && <AddedPrompts />}
          {((promptMode === "custom" && added && !!added.length) ||
            promptMode === "packages") && (
            <Flex dir="row" justify="space-between" w="full" pt="sm">
              <Button
                variant="outline"
                colorScheme="blackAlpha"
                primary={true}
                onClick={() => {
                  !promptMode ? onCancel() : setPromptMode();
                }}
              >
                <Text weight="bold">
                  {!promptMode ? <span>Cancel</span> : <span>back</span>}
                </Text>
              </Button>
              <Button
                onClick={addNewPrompts}
                disabled={!added || !added.length}
              >
                <Text color="white" weight="bold">
                  Add{" "}
                  {!!added.length && added.length === 1 ? (
                    <span>prompt</span>
                  ) : (
                    <span>{added.length} prompts</span>
                  )}
                </Text>
              </Button>
            </Flex>
          )}
        </Flex>
      </>
    </Stack>
  );
};

export default PromptChooser;
