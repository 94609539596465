
const interpolate = (template, map, fallback) => {
    return String(template).replace(/\$\{[^}]+\}/g, (match) => 
        match
            .slice(2, -1)
            .trim()
            .split(".")
            .reduce(
                (searchObject, key) => searchObject[key] || fallback || match,
                map
            )
    ).replace(/(?:\r\n|\r|\n)/g, '<br />');
}

export default interpolate;