import { assign, createMachine, DoneInvokeEvent, send } from "xstate";
import { assertEventType } from "../helpers";
import { Asset } from "../manageStoryAssetsMachine";


export type ConciergeSettings = {
    videoMessage?: string;
    videoMessagePosition?: "beginning" | "end";
    positionMyVideos?: "first" | "last" | "no preference";
    assetNotes?: string;
    conciergeNotes?: string;
}

export type ConciergePurchaseModel = {
    id: string;
    purchase?: {
        state: "customize" | "addContent" | "checkout";
        cartId?: string;
        [key: string]: any;
    };
    assets?: Asset[];
    prompts: any[];
    recipientPhoto?: string;
} & ConciergeSettings;

export interface ConciergePurchaseContext {
    data: ConciergePurchaseModel;
    error?: null | string;
}

export type ConciergePurchaseEvents =
    | {
        type: "BACK";
    }
    | {
        type: "CONTINUE";
    }
    | {
        type: "CHECKOUT";
        data: ConciergePurchaseModel & {
            purchase: { state: "checkout"; cartId: string };
        };
    }
    | {
        type: "done.invoke.fetchData";
        data: ConciergePurchaseModel;
    }
    | {
        type: "error.platform.fetchData";
        error: any;
    }
    | DoneInvokeEvent<{ data: ConciergePurchaseModel }>;

export type ConciergePurchaseMachineState =
    | { value: "loading"; context: ConciergePurchaseContext }
    | { value: "init"; context: ConciergePurchaseContext }
    | {
        value: "customize";
        context: ConciergePurchaseContext & { data: ConciergePurchaseModel };
    }
    | { value: "addContent"; context: ConciergePurchaseContext }
    | { value: "addOns"; context: ConciergePurchaseContext }
    | {
        value: "completeCheckout";
        context: ConciergePurchaseContext & { data: ConciergePurchaseModel };
    };

const guards = {
    gotoAddContent: (context: ConciergePurchaseContext) => {
        return !!(
            context.data &&
            context.data.purchase &&
            context.data.purchase.state === "addContent"
        );
    },
};

const actions = {
    track: (
        context: ConciergePurchaseContext,
        event: ConciergePurchaseEvents
    ) => {
        console.log("track", event);
    },
    assignDataToContext: assign(
        (context: ConciergePurchaseContext, event: ConciergePurchaseEvents) => {
            assertEventType(event, "done.invoke.fetchData");
            return {
                data: event.data,
            };
        }
    ),
    assignErrorToContext: assign(
        (context: ConciergePurchaseContext, event: ConciergePurchaseEvents) => {
            assertEventType(event, "error.platform.fetchData");
            return {
                ...context,
                error: "event.error",
            };
        }
    ),
};

type ConciergePurchaseServices = {
    fetchData: {
        data: Partial<ConciergePurchaseModel>;
    };
};

const machineConfig = {
    schema: {
        context: {} as ConciergePurchaseContext,
        events: {} as ConciergePurchaseEvents,
        services: {} as ConciergePurchaseServices,
    },
    id: "ConciergePurchaseMachine",
    initial: "loading",
    states: {
        loading: {
            invoke: {
                id: "fetchData",
                src: "fetchData",
                onDone: {
                    actions: "assignDataToContext",
                    target: "init",
                },
                onError: {
                    actions: "assignErrorToContext",
                },
            },
        },
        init: {
            always: [
                {
                    cond: "gotoAddContent",
                    target: "addContent",
                },
                {
                    target: "customize",
                },
            ],
        },
        customize: {
            entry: "track",
            on: {
                CONTINUE: {
                    target: "addContent",
                },
            },
        },
        addContent: {
            entry: "track",
            on: {
                BACK: {
                    target: "customize",
                },
                CONTINUE: {
                    target: "addOns",
                },
            },
        },
        addOns: {
            on: {
                BACK: {
                    target: "addContent",
                },
                CONTINUE: {
                    target: "completeCheckout",
                },
            }
        },
        completeCheckout: {
            entry: "track",
        },
    },
};

const conciergePurchaseMachine = createMachine<
    ConciergePurchaseContext,
    ConciergePurchaseEvents,
    ConciergePurchaseMachineState,
    ConciergePurchaseServices
>(machineConfig, {
    actions,
    guards,
    services: {
        fetchData: async () => {
            console.log("Fetch Data");
            return {} as unknown as ConciergePurchaseModel;
        },
    },
});

export default conciergePurchaseMachine;
