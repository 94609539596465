import { useActor } from "@xstate/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import StoryIntroView from "../../../components/response/Intro";
import { useStoryViewContext } from "./StoryViewProvider";

const StoryIntro = () => {
  const { story, service } = useStoryViewContext();
  const [state] = useActor(service);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (state.matches("final")) {
      navigate("final");
    }
  }, [state.value]);

  return (
    <StoryIntroView story={story} onClick={() => service.send("RESPOND")} />
  );
};

export default StoryIntro;
