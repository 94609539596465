import React from "react";

// Removes focus for non-keyboard interactions
import "focus-visible/dist/focus-visible";

import { ChakraProvider, useColorMode } from "@chakra-ui/react";
import { theme as chakraTheme } from "../chakraTheme";
import { Flex, FlexProps, Grid } from "@chakra-ui/react";

import { Grommet, Box } from "grommet";
import { theme as grommetTheme } from "../grommetTheme";

import RoutedNavbar from "../routes/layout/RoutedNavbar";
import ScrollToTop from "../components/layout/ScrollToTop";
import { use100vh } from "react-div-100vh";

import bg from "../assets/story-manager-bg.svg";
import { Outlet } from "react-router-dom";

export const Layout = () => {
  const { colorMode } = useColorMode();
  const minHeight = use100vh();
  const background: FlexProps = (colorMode === 'light') ? {
        backgroundPosition: "bottom -80px right",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${bg})`,
      } : {};

  return (
    <>
      {/* @ts-ignore */}
      <Grommet theme={grommetTheme} themeMode={colorMode}>
        <ChakraProvider theme={chakraTheme}>
          <Flex
            direction="column"
            align="center"
            pb="sm"
            minH={`${minHeight}px`}
            flexShrink={0}
            w="100%"
            h="100%"
            {...background}
          >
            <Grid
              minH={`${minHeight}px`}
              flex={{ shrink: 0, grow: 1 }}
              minW={"full"}
              gridTemplateColumns="1fr"
              gridTemplateRows="auto 1fr"
              gridTemplateAreas="'header''main'"
            >
              <RoutedNavbar
                gridArea="header"
                direction="row"
                align="center"
                justify="between"
              />
              <Flex
                as="main"
                gridArea="main"
                justify="start"
                align="center"
                overflow="visible"
                p="0"
                flexGrow={1}
                flexShrink={0}
              >
                <Box fill>
                  <Outlet />
                </Box>
              </Flex>
            </Grid>
          </Flex>
          <ScrollToTop />
        </ChakraProvider>
      </Grommet>
    </>
  );
};
