import React from 'react'
import PageContainer from '../../layout/components/PageContainer'
import { useNavigate } from 'react-router-dom';
import { Box, Button, Center, Flex, Heading } from '@chakra-ui/react';

const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <PageContainer minW={undefined} maxW="90vw" w="3xl">
            <Box w="full" h="full">
                <Flex
                    justify="center"
                    my="lg"
                    h="full"
                    direction="column"
                    p="lg"
                    shadow="lg"
                    bgColor="springWood.100"
                    rounded="lg"
                    align="center"
                >
                    <Heading
                    as="h2"
                    size="h2"
                    variant="center"
                    >404 PAGE NOT FOUND</Heading>
                    <Button onClick={() => navigate("/")}>Back to the home page</Button>
                </Flex>
            </Box>
        </PageContainer>
    )
}

export default PageNotFound
