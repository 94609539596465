import React, { useEffect, useState } from "react";
import { Layer } from "grommet";
import {
  Heading,
  Stack,
  Text,
  Flex,
  Box,
  Button,
  Divider,
  FormLabel,
  Collapse,
  useDisclosure,
  Container,
  FormErrorMessage,
  Input,
  FormControl,
  FormHelperText,
  Link,
  Textarea,
  useBoolean,
  IconButton,
} from "@chakra-ui/react";
import Dropzone from "react-dropzone";
import { uploadToStorage } from "../../../utils/firebase-storage";

import PromptsList from "./components/PromptsList";
import EditWelcomeMessage, {
  welcomeMessageCharLimit,
} from "./components/EditWelcomeMessage";
import {
  StackedResponsiveImageCard,
  SubjectImage,
} from "../../layout/ImageResponsive";
import ImageCropper from "../../cropper/ImageCropper";
import HelpBlocks from "../../common/HelpBlocks";
import QuiltedVideo from "../../common/QuiltedVideo";

import { useStoryManager } from "../StoryManager";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import buildStoryUrl from "../../../utils/buildStoryUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as PhotoDropTargetImg } from "../../../assets/photo-drop-target.svg";
import TipsForRecording from "../../common/TipsForRecording";
import ModalDialog from "../../common/ModalDialog";
import DueDateBlock from "./components/DueDateBlock";

import { useAnalytics } from "use-analytics";

const schema = yup.object().shape({
  subject: yup
    .string()
    .required(
      "Please provide a name for the person you're making this movie for."
    ),
  title: yup.string().required("Please give your project a title."),
  welcomeMessage: yup
    .string()
    .required("Please provide a welcome message.")
    .max(
      welcomeMessageCharLimit,
      `Please keep your welcome message brief. (limit: ${welcomeMessageCharLimit} characters)`
    ),
});

const ReviewStep = ({ id }) => {
  const [context, dispatch] = useStoryManager();
  const {
    state: { story },
  } = context;

  const { track } = useAnalytics();

  const [dropActive, setDropActive] = React.useState(false);
  const [uploadRecipientPhoto, setUploadRecipientPhoto] = React.useState(
    !story.recipientPhoto
  );
  const [recipientPhoto, setRecipientPhoto] = React.useState(
    story.recipientPhoto
  );

  const [editSubject, setEditSubject] = useBoolean(false);
  const [editTitle, setEditTitle] = useBoolean(false);
  const [editWelcome, setEditWelcome] = useBoolean(false);
  const {
    isOpen: showingRecordingTips,
    onOpen: showRecordingTips,
    onClose: hideRecordingTips,
  } = useDisclosure();

  const [uploadedRecipientFile, setUploadedRecipientFile] =
    React.useState(false);
  const [recordWelcome, setRecordWelcome] = React.useState(false);
  const { isOpen: arePromptsOpen, onToggle: togglePrompts } = useDisclosure();
  const { isOpen: areHelpBlocksOpen, onToggle: toggleHelpBlocks } =
    useDisclosure();
  const [loading, setLoading] = useState(true);

  const { register, watch, formState, setValue, setFocus } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  const [subject, title, welcomeMessage] = watch([
    "subject",
    "title",
    "welcomeMessage",
  ]);

  useEffect(() => {
    setValue("subject", story?.subject);
    setValue("title", story?.title);
    setValue("welcomeMessage", story?.welcomeMessage);
    setLoading(false);
  }, []);

  const handleEnterKey = (e, fieldAction) => {
    if (e.keyCode === 13) {
      if (fieldAction === setEditTitle) {
        saveTitle();
      } else {
        fieldAction.toggle();
      }
    }
  };

  useEffect(() => {
    if (editSubject) setFocus("subject");
    if (editTitle) setFocus("title");
    if (editWelcome) setFocus("welcomeMessage");
  }, [editSubject, editTitle, EditWelcomeMessage]);

  const url = buildStoryUrl(story);

  const update = React.useCallback(() => {
    dispatch({
      type: "update",
      payload: {
        story: {
          ...story,
          welcomeMessage,
          subject,
          title,
          step: id,
        },
        valid: {
          [id]: {
            title: !!title && !errors.title,
            subject: !!subject && !errors.subject,
            welcomeMessage: !!welcomeMessage && !errors.welcomeMessage,
            dueDate: !!story.dueDate,
          },
        },
        changed: true,
      },
    });
  }, [title, subject, welcomeMessage]);

  React.useEffect(() => {
    update();
  }, [title, subject, welcomeMessage, loading]);

  const saveTitle = () => {
    if (title) {
      dispatch({
        type: "setSlug",
        payload: {
          slug: title,
        },
        callback: setEditTitle.toggle(),
      });
    }
  };

  const handleFileUpload = (files) => {
    if (files.length === 0) return;
    setUploadedRecipientFile(files[0]);
  };

  const uploadRecipientFile = (file) => {
    const { name, size, type } = file;
    uploadToStorage(
      `images/${story.id}/${name}`,
      file,
      { size, contentType: type },
      (progress) => {
        console.log("Image upload status", { progress });
      }
    )
      .then((info) => {
        dispatch({
          type: "save",
          payload: { story: { ...story, recipientPhoto: info.downloadURL } },
        });
        setRecipientPhoto(info.downloadURL);
        setUploadRecipientPhoto(false);
        setUploadedRecipientFile(false);
        setDropActive(false);
      })
      .catch((error) => {
        dispatch({
          type: "notify",
          payload: {
            title: `File: ${name} failed to save. Please upload another image.`,
            appearance: "error",
          },
        });
      });
  };

  const handleFileRejected = (files) => {
    if (files.length === 0) return;
    const {
      file: { name },
    } = files[0];
    console.log({ files });

    dispatch({
      type: "notify",
      payload: {
        title: `File: ${name} is not a valid image file. Please upload a .jpg or .png`,
        appearance: "error",
      },
    });
    setDropActive(false);
  };

  const RecipientPhoto = () => {
    return (
      <>
        {!uploadRecipientPhoto && recipientPhoto && (
          <StackedResponsiveImageCard
            anchor="top-right"
            margin={{ horizontal: "auto", bottom: "medium" }}
            flex={false}
          >
            <SubjectImage
              src={recipientPhoto}
              alt={`${story.subject}'s photo`}
              resize={false}
            />
            <IconButton
              bgColor="white"
              color="seaGreen"
              _hover={{ bgColor: "seaGreen.200" }}
              icon={<FontAwesomeIcon icon="pencil-alt" />}
              onClick={() => {
                dispatch({
                  type: "save",
                  payload: {
                    story: { ...story, recipientPhoto: null },
                  },
                  callback: setUploadRecipientPhoto(true),
                });
              }}
            />
          </StackedResponsiveImageCard>
        )}
        {uploadRecipientPhoto && (
          <Dropzone
            accept="image/jpeg, image/png"
            onDrop={(acceptedFiles, fileRejections) => {
              handleFileUpload(acceptedFiles);
              handleFileRejected(fileRejections);
            }}
            onDragEnter={() => {
              setDropActive(true);
            }}
            onDragLeave={() => {
              setDropActive(false);
            }}
            onDropRejected={() => {
              setDropActive(false);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <Stack
                bgColor="springWood.500"
                px="xl"
                py="lg"
                align="center"
                justify="center"
                border="1px"
                borderStyle="dashed"
                borderColor="tumbleweed.600"
                direction="column"
                minH="225px"
                spacing="5"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <PhotoDropTargetImg />
                <Text fontSize="lg" textAlign="center">
                  Drag &apos;n&apos; drop the photo here, OR
                </Text>
                <Button>Select photo</Button>
              </Stack>
            )}
          </Dropzone>
        )}
        {uploadedRecipientFile && (
          <Layer responsive={false}>
            <ImageCropper
              file={uploadedRecipientFile}
              onComplete={(file) => {
                uploadRecipientFile(file);
              }}
            />
          </Layer>
        )}
      </>
    );
  };

  if (!loading)
    return (
      <>
        <Stack spacing={8}>
          <Heading as="h2" size="h2" variant="center" maxW="600px" mx="auto">
            Get Excited! Your Quilted movie project is almost ready to go!
          </Heading>
          <Box>
            <Container>
              <Text fontSize="medium" textAlign="center">
                This is your opportunity to{" "}
                <strong>
                  add the finishing touches to the Quilted web page
                </strong>{" "}
                your participants will go to submit videos.
              </Text>
            </Container>
          </Box>
          <Divider />

          <Container textAlign="center" alignSelf="center">
            <Stack>
              <Heading as="h3" size="h3">
                Personalize your Quilted Movie
              </Heading>
              <Text>
                For an extra-special touch, add a picture of the person
                you&apos;re making this Quilted Movie for and upload a custom
                welcome video your storytellers will see when they open the link
                we send them.
              </Text>
            </Stack>
          </Container>
          <Stack>
            <FormLabel variant="headingLabel">
              Add a <strong>photo</strong> of the gift receiver.{" "}
              <Text as="span" fontSize="xs">
                (optional)
              </Text>
            </FormLabel>
            <RecipientPhoto />
          </Stack>
          <Divider />
          <DueDateBlock />
          <Divider />
          <Container alignSelf="center">
            <Stack textAlign="center">
              <Heading as="h3" size="h3">
                Review your Quilted Movie&apos;s details
              </Heading>
              <Text>
                Make sure the details of your movie look accurate. You can also
                customize the welcome message that will be shown to your
                participants.
              </Text>
            </Stack>
          </Container>

          {story.storytellers && story.storytellers.length > 0 && (
            <Flex align="center" justify="center" mb="sm">
              <Container alignSelf="center">
                <Text fontSize="small" fontWeight="bold" textAlign="center">
                  Invites will be sent after launching your story.
                </Text>
              </Container>
            </Flex>
          )}
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Stack spacing="8">
              <FormControl id="subject" isInvalid={errors.subject}>
                <Flex
                  direction="row"
                  justify="space-between"
                  py="sm"
                  align="flex-end"
                >
                  <FormLabel variant="headingLabel">
                    <strong>Who</strong> is this Quilted movie for?
                  </FormLabel>
                  <Button
                    variant={editSubject ? "solid" : "outline"}
                    colorScheme={editSubject ? "seaGreen" : "blackAlpha"}
                    disabled={editSubject && errors.subject}
                    size="sm"
                    leftIcon={
                      <FontAwesomeIcon
                        icon={editSubject ? "check-double" : ["far", "edit"]}
                      />
                    }
                    onClick={setEditSubject.toggle}
                  >
                    {editSubject ? <span>Save</span> : <span>Edit</span>}
                  </Button>
                </Flex>
                {editSubject ? (
                  <Input
                    {...register("subject")}
                    placeholder="Emily"
                    onFocus={() => track("review_editingSubject")}
                    onKeyUp={(e) => handleEnterKey(e, setEditSubject)}
                  />
                ) : (
                  <Stack
                    bgColor="white"
                    opacity="0.6"
                    border="1px"
                    rounded="md"
                    px="sm"
                    py="xs"
                    onClick={setEditSubject.toggle}
                  >
                    <Text fontSize="md">{subject}</Text>
                  </Stack>
                )}
                <FormErrorMessage color="puce.700">
                  {errors.subject && errors.subject.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl id="title" isInvalid={errors.title}>
                <Flex
                  direction="row"
                  justify="space-between"
                  py="sm"
                  align="flex-end"
                >
                  <FormLabel variant="headingLabel">
                    What&apos;s the <strong>title</strong> of your Quilted
                    movie?
                  </FormLabel>
                  <Button
                    variant={editTitle ? "solid" : "outline"}
                    colorScheme={editTitle ? "seaGreen" : "blackAlpha"}
                    disabled={editTitle && errors.title}
                    size="sm"
                    leftIcon={
                      <FontAwesomeIcon
                        icon={editTitle ? "check-double" : ["far", "edit"]}
                      />
                    }
                    onClick={editTitle ? saveTitle : setEditTitle.toggle}
                  >
                    {editTitle ? <span>Save</span> : <span>Edit</span>}
                  </Button>
                </Flex>
                {editTitle ? (
                  <Input
                    {...register("title")}
                    placeholder="Birthday wishes for Emily"
                    onFocus={() => track("review_editingTitle")}
                    onKeyUp={(e) => handleEnterKey(e, setEditTitle)}
                  />
                ) : (
                  <Stack
                    bgColor="white"
                    opacity="0.6"
                    border="1px"
                    rounded="md"
                    px="sm"
                    py="xs"
                    onClick={setEditTitle.toggle}
                  >
                    <Text minH="1em" fontSize="md">
                      {title}
                    </Text>
                  </Stack>
                )}
                <FormHelperText>
                  Your participants will be able to access your story at{" "}
                  <Link
                    target="_blank"
                    color="seaGreen"
                    textDecor="underline"
                    href={url}
                  >
                    {url}
                  </Link>
                </FormHelperText>
                <FormErrorMessage color="puce.700">
                  {errors.title && errors.title.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.welcomeMessage}>
                <Stack
                  direction={["column", "row"]}
                  justify="space-between"
                  py="sm"
                  align="flex-end"
                  spacing={[4, 12]}
                >
                  <Container ml="0" pl="0" textAlign={["center", "left"]}>
                    <FormLabel
                      variant="headingLabel"
                      textAlign={["center", "left"]}
                    >
                      <strong>What&apos;s</strong> this all about?
                    </FormLabel>
                    <Text>
                      Add a short, personal message for your participants.
                      Explain why you are creating this Quilted movie and
                      provide any personalized details your participants should
                      know.
                    </Text>
                  </Container>
                  <Button
                    variant={editWelcome ? "solid" : "outline"}
                    colorScheme={editWelcome ? "seaGreen" : "blackAlpha"}
                    size="sm"
                    disabled={editWelcome && errors.welcomeMessage}
                    leftIcon={
                      <FontAwesomeIcon
                        icon={editWelcome ? "check-double" : ["far", "edit"]}
                      />
                    }
                    onClick={setEditWelcome.toggle}
                  >
                    {editWelcome ? <span>Save</span> : <span>Edit</span>}
                  </Button>
                </Stack>
                {editWelcome ? (
                  <EditWelcomeMessage
                    story={{
                      ...story,
                      welcomeMessage: editWelcome.value || story.welcomeMessage,
                    }}
                    inputComponent={
                      <Textarea
                        {...register("welcomeMessage")}
                        bgColor="white"
                        rows="6"
                        onFocus={() => track("review_editingWelcomeMessage")}
                      />
                    }
                    formState={formState}
                    errorMessage={
                      <FormErrorMessage>
                        {errors &&
                          errors.welcomeMessage &&
                          errors.welcomeMessage.message}
                      </FormErrorMessage>
                    }
                    chatBoxProps={{ tailBackground: "light-1" }}
                  />
                ) : (
                  <Stack
                    bgColor="white"
                    opacity="0.6"
                    border="1px"
                    rounded="md"
                    px="sm"
                    py="xs"
                    onClick={setEditWelcome.toggle}
                  >
                    {/* <Text>
                    {interpolate(story.template.welcomeMessageTemplate, {
                      story,
                    })}
                  </Text>
                  <Divider /> */}
                    <Text fontSize="md">{welcomeMessage}</Text>
                  </Stack>
                )}
              </FormControl>
            </Stack>
          </form>

          <Divider />

          <Stack align="center" spacing="5">
            <Heading as="h3" size="h3" textAlign="center">
              Review your prompts
            </Heading>
            <Container>
              <Text textAlign="center">
                Take a moment to review the questions your storytellers will
                see. This is your last chance to make any edits before launching
                your project.
              </Text>
            </Container>
            <Button
              variant="outline"
              rounded="md"
              colorScheme="airBlue"
              onClick={() => {
                !arePromptsOpen ? track("review_showPrompts") : null;
                togglePrompts();
              }}
            >
              {arePromptsOpen ? (
                <span>Hide prompts</span>
              ) : (
                <span>Review your prompts</span>
              )}
            </Button>
            <Collapse in={arePromptsOpen} animateOpacity>
              <PromptsList />
            </Collapse>
          </Stack>
          <Divider />

          <Stack align="center" spacing="5">
            <Heading as="h3" size="h3" textAlign="center">
              Check out our recording tips
            </Heading>
            <Container>
              <Text textAlign="center">
                When a storyteller opens the link you share, we&apos;ll show
                them a few helpful recording tips. You can preview them here if
                you&apos;d like to see what your storytellers will see.
              </Text>
            </Container>
            <Button
              variant="outline"
              rounded="md"
              colorScheme="airBlue"
              onClick={() => {
                !areHelpBlocksOpen ? track("review_showHelpBlocks") : null;
                toggleHelpBlocks();
              }}
            >
              {areHelpBlocksOpen ? (
                <span>Hide recording tips</span>
              ) : (
                <span>Show recording tips</span>
              )}
            </Button>
            <Collapse in={areHelpBlocksOpen} animateOpacity w="full">
              <HelpBlocks />
              <QuiltedVideo />
            </Collapse>
          </Stack>
        </Stack>
        <ModalDialog
          heading="Recording tips"
          isOpen={showingRecordingTips}
          onClose={hideRecordingTips}
        >
          <TipsForRecording hideHeading />
        </ModalDialog>
      </>
    );

  return <></>;
};
export default ReviewStep;
