import React from "react";
import { Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";

import { DashboardNavbar } from "../../layout/components/DashboardNavbar";

import { HistoryBackButton } from "../../components/layout/BackButton";
import NavbarLayout from "../../layout/components/Navbar";

const RoutedNavbar = ({ auth, ...props }) => {
  const isAnon = isLoaded(auth) && !isEmpty(auth) && auth.isAnonymous;
  return (
    <Routes>
      {!isAnon && !isEmpty(auth) && (
        <Route
          path="/my-stories"
          exact
          element={<DashboardNavbar {...props} />}
        />
      )}
      {!isAnon && !isEmpty(auth) && (
        <Route
          path="/my-stories/story/:id/*"
          element={<NavbarLayout left={<HistoryBackButton />} {...props} />} />
      )}
      <Route path="/start/*" element={null} />
      <Route path="/edit/*" element={null} />
      <Route path="*" element={<NavbarLayout {...props} />} />
    </Routes>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

export default connect(mapStateToProps)(RoutedNavbar);
