import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

import { normalizeColor } from '../../utils/colors';
import { Box } from 'grommet';

const ensureColor = (theme, primary, fallback = 'currentColor') => {
  return normalizeColor(primary || fallback, theme);
}

const StyledColoredSvg = styled(Box)`
  fill: ${props => ensureColor(props.theme, props.fill, 'none')};
  color: ${props => ensureColor(props.theme, props.color)};
`;

const ColoredSvg = ({fill=undefined, children, ...rest}) => {
    return (<StyledColoredSvg {...rest} fill={fill}>
            {children}
    </StyledColoredSvg>)  
}


ColoredSvg.propTypes = {
    /* eslint-enable react/no-unused-prop-types */
    fill: PropTypes.string,
    color: PropTypes.string    
  };

  export default ColoredSvg;