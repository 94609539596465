
import { combineReducers } from "redux";
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import authReducer from "./authReducer";
import videoPlayerReducer from './videoPlayerReducer';
import storytellerReducer from './storytellerReducer'

const rootReducer = combineReducers({
    auth: authReducer,
    videoPlayer: videoPlayerReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    storyteller: storytellerReducer
});

export default rootReducer;
