import { createMachine, assign, spawn, Interpreter } from "xstate";

import { pure, send } from "xstate/lib/actions";
import recordingMachine from "../recordingMachine";
import { createPromptMachine } from "./promptMachine";
import { createUploadMachine } from "../uploadMachine";
import getFileExtension from "../../utils/getFileExtensionFromMime";
import { v5 as uuidv5 } from "uuid";
import { assertEventType } from "../helpers";
import { LocalStorageKeys } from "../../hooks/useLocalStorage";
import { StoryTemplateMetadata } from "../../types/Templates";

const getFileId = (
  id: string | undefined | null,
  storyId: string | undefined,
  promptIndex: number,
  user: string
) => {
  const generate = () => {
    return uuidv5(
      `/videos/response/${user}/${storyId}/${promptIndex}`,
      uuidv5.URL
    );
  };
  return id || generate();
};

export interface Identity {
  displayName?: string;
  email?: string;
  uid?: string;
  optInMarketing?: boolean;
}
export interface Response {
  id: string;
  promptIndex: number;
  storyId: string;
  submissionDate: Date;
  content: {
    thumbnailPath: string;
    downloadUrl: string;
    streamUrl?: string;
    muxPlaybackId?: string;
  };
  submittedBy: Identity;
  type?: "video" | "image";
}

export interface Prompt {
  id: string | number;
  idx: number;
  text: string;
  response?: Response | null;
  ref?: any;
  processing?: boolean;
}
type StoryState =
  | "setup"
  | "launch"
  | "running"
  | "paid"
  | "fulfilled"
  | "complete";

export interface Story {
  id: string;
  title: string;
  owner: string;
  subject: string;
  welcomeMessage: string;
  prompts: Array<Prompt>;
  state: StoryState;
  template: StoryTemplateMetadata;
}

export interface RespondMachineContext {
  story?: Story;
  identity?: Identity | null;
  activePrompt?: Prompt;
  uploads: Array<any>;
  uploadProgress: number;
  error?: string;
}

export type RespondMachineEvent =
  | {
      type: "RETRY" | "BACK";
    }
  | {
      type: "READY";
    }
  | {
      type: "START";
      data: Pick<RespondMachineContext, "story" | "identity">;
    }
  | {
      type: "LOAD_RESPONSES";
      data: Array<Response>;
    }
  | {
      type: "IDENTIFIED";
      value: Identity;
    }
  | {
      type: "RESPOND";
    }
  | {
      type: "ANALYTICS";
      action: "identify" | "track";
      track?: any;
      properties?: any;
    }
  | {
      type: "CHANGE";
    }
  | {
      type: "CONTINUE";
    }
  | {
      type: "DONE";
    }
  | {
      type: "CANCEL";
    }
  | {
      type: "PROMPT.ACTIVE";
      data: { id: number; useInput?: boolean; allowPhoto?: boolean };
    }
  | {
      type: "done.invoke.recordingMachine";
      data: { blob: any; info: any; type: "image" | "video" };
    }
  | {
      type: "UPLOAD.PROGRESS";
      data: any;
    }
  | {
      type: "UPLOAD.COMPLETE";
      data: any;
    };

const isReady = (context: RespondMachineContext) => {
  const { story } = context;
  console.log("Is Machine Ready?", { story });
  const isTrue = !!story;
  return isTrue;
};
const storyStateCondition =
  (state: StoryState) => (context: RespondMachineContext, event: any) => {
    const { story } = context;
    const isTrue = story?.state === state;
    return isTrue;
  };

const storySetupCondition = storyStateCondition("setup");

const storyRunningCondition = storyStateCondition("running");

const storyComplete = storyStateCondition("complete");

export type RespondMachineStates =
  | {
      value: "idle";
      context: RespondMachineContext;
    }
  | {
      value: "init";
      context: RespondMachineContext & { story: Story; error: undefined };
    }
  | {
      value: "ready";
      context: RespondMachineContext & {
        story: Story;
        identity: Identity;
        error: undefined;
      };
    }
  | {
      value:
        | "responding"
        | {
            responding: "active" | "complete" | "determine";
          };
      context: RespondMachineContext & {
        story: Story;
        identity: Identity;
        error: undefined;
      };
    }
  | {
      value: "recording";
      context: RespondMachineContext & {
        activePrompt: Prompt;
        story: Story;
        identity: Identity;
        error: undefined;
      };
    }
  | {
      value: "done";
      context: RespondMachineContext & {
        story: Story;
        identity: Identity;
        error: undefined;
      };
    }
  | {
      value: "notLaunched";
      context: RespondMachineContext;
    }
  | {
      value: "final";
      context: RespondMachineContext;
    };

const respondingStates = {
  initial: "determine",
  states: {
    determine: {
      on: {
        "": [{ target: "complete", cond: "isComplete" }, { target: "active" }],
      },
    },
    active: {
      always: [{ target: "complete", cond: "isComplete" }],
    },
    complete: {
      entry: [
        send({ type: "ANALYTICS", track: "response:allPromptsComplete" }),
      ],
      always: [{ target: "active", cond: "isIncomplete" }],
    },
  },
};

export const respondMachine = createMachine<
  RespondMachineContext,
  RespondMachineEvent,
  RespondMachineStates
>(
  {
    id: "respondMachine",
    initial: "idle",
    context: {
      story: undefined,
      identity: undefined,
      uploads: [],
      uploadProgress: 0,
    },
    states: {
      idle: {
        entry: ["announce"],
        on: {
          START: {
            actions: ["loadData"],
            target: "init",
          },
        },
        always: [{ target: "init", cond: isReady }],
      },
      init: {
        entry: [
          "announce",
          assign({
            story: (context) => {
              console.log("Rehydrating prompts");

              // 'Rehydrate' persisted prompts with machines
              const _prompts = context.story?.prompts.map((prompt, idx) => ({
                ...prompt,
                idx,
                ref: spawn(
                  createPromptMachine({
                    ...prompt,
                    idx,
                    id: idx,
                  })
                ),
              }));

              return {
                ...context.story,
                prompts: _prompts,
              } as Story;
            },
          }),
        ],
        always: [
          { target: "ready", cond: storyRunningCondition },
          { target: "final", cond: storyComplete },
          { target: "notLaunched", cond: storySetupCondition },
          {
            target: "error",
            actions: assign({
              error: (context, event) => "Could not determine story state.",
            }),
          },
        ],
        exit: send({ type: "ANALYTICS", track: "response:loaded" }),
      },
      error: {
        on: {
          RETRY: "idle",
        },
      },
      notLaunched: {
        entry: ["announce"],
      },
      ready: {
        entry: send({ type: "ANALYTICS", track: "response:readyToRespond" }),
        on: {
          RESPOND: [
            {
              target: "responding",
              cond: (context: RespondMachineContext) => !!context.identity,
            },
            { target: "identify" },
          ],
        },
      },
      identify: {
        entry: send({ type: "ANALYTICS", track: "response:contactForm_shown" }),
        on: {
          IDENTIFIED: {
            actions: ["storeIdentity", "identified"],
          },
        },
        always: [
          {
            target: "responding",
            cond: (context: RespondMachineContext) => {
              const hasIdentity = !!context.identity;
              console.log("Check condition", hasIdentity);
              return hasIdentity;
            },
          },
        ],
        exit: send({
          type: "ANALYTICS",
          action: "response:contactForm_submitted",
        }),
      },
      responding: {
        // No tracking events in this state since the user enters this state after every recording
        id: "responding",
        on: {
          CHANGE: {
            actions: assign({ identity: (_, event) => null }),
          },
        },
        always: [
          {
            target: "identify",
            cond: (context: RespondMachineContext) => !context.identity,
          },
        ],
        ...respondingStates,
      },
      selecting: {
        entry: ["announce"],
      },
      recording: {
        entry: ["announce"],
        invoke: {
          id: "recordingMachine",
          src: recordingMachine,
          onDone: {
            target: "done",
            actions: [
              "announce",
              "spawnUpload",
              send(
                { type: "PROCESSING" },
                { to: (context) => context.activePrompt?.ref }
              ),
            ],
          },
          data: {
            useInput: (
              context: RespondMachineContext,
              event: RespondMachineEvent
            ) => {
              return event.type === "PROMPT.ACTIVE" && !!event.data.useInput;
            },
            allowPhoto: (
              context: RespondMachineContext,
              event: RespondMachineEvent
            ) => {
              return event.type === "PROMPT.ACTIVE" && !!event.data.allowPhoto;
            },
          },
        },
        on: {
          CANCEL: {
            target: "responding",
            actions: [
              "cancel",
              send({
                type: "ANALYTICS",
                track: "response:recorder_recordingCancelled",
              }),
            ],
          },
          DONE: {
            target: "done",
          },
        },
      },
      upload: {
        entry: ["announce"],
      },
      done: {
        entry: [
          "announce",
          send((ctx) => ({
            type: "ANALYTICS",
            track: "response:user_finishedRecording",
            properties: {
              promptId: ctx.activePrompt?.id,
              promptText: ctx.activePrompt?.text,
            },
          })),
        ],
        on: {
          CONTINUE: "responding",
        },
      },
      final: {
        type: "final",
      },
    },
    on: {
      ANALYTICS: {
        actions: ["sendAnalytics"],
      },
      "PROMPT.ACTIVE": {
        actions: ["announce", "setPromptActive", "toggleActivePrompts"],
        target: "recording",
      },
      "UPLOAD.PROGRESS": {
        actions: ["announce", "uploadProgress"],
      },
      "UPLOAD.COMPLETE": {
        actions: ["announce", "uploadComplete"],
      },
      LOAD_RESPONSES: {
        actions: ["announce", "loadResponses"],
      },
    },
  },
  {
    services: {
      saveResponse: async (context: RespondMachineContext, event) => {
        console.log("saveResponse not implemented!");
        throw new Error("Not Implemented.");
      },
      saveIdentity: async (context: RespondMachineContext, event) => {
        assertEventType(event, "IDENTIFIED");
        //@ts-ignore
        window.localStorage.setItem(
          LocalStorageKeys.ContactInfo,
          JSON.stringify(event.value)
        );
      },
    },
    actions: {
      announce: (context, event) => {
        console.log("Respond machine - ", event.type, { context, event });
      },
      loadData: assign((context: RespondMachineContext, event) => {
        assertEventType(event, "START");

        return {
          story: event.data.story,
          identity: event.data.identity,
        };
      }),
      loadResponses: pure((context: RespondMachineContext, event) => {
        if (event.type !== "LOAD_RESPONSES") return undefined;
        const { story } = context;
        if (!story) return undefined;

        const { prompts } = story;
        const _prompts = [...prompts];
        const actions: Array<any> = [];
        if (event.data) {
          for (let index = 0; index < _prompts.length; index++) {
            const prompt = _prompts[index];
            const response = event.data.find(
              (response) => response.promptIndex === index
            );
            prompt.response = response || null;
            actions.push(
              send({ type: "SET_RESPONSE", response }, { to: prompt.ref })
            );
          }
        }
        actions.push(
          assign({
            story: { ...story, prompts: _prompts },
          })
        );
        return actions;
      }),
      setPromptActive: assign((context: RespondMachineContext, event) => {
        if (event.type !== "PROMPT.ACTIVE") return {};
        return {
          activePrompt: context.story.prompts[event.data.id],
        };
      }),
      toggleActivePrompts: pure((context: RespondMachineContext, event) => {
        if (event.type !== "PROMPT.ACTIVE") return undefined;
        const others = context.story.prompts.filter(
          (prompt) => event.data.id !== prompt.idx
        );
        return others.map((actor) => {
          return send({ type: "IDLE" }, { to: actor.ref });
        });
      }),
      uploadProgress: assign((context: RespondMachineContext, event) => {
        if (event.type !== "UPLOAD.PROGRESS") return {};
        const uploads = [...context.uploads];
        const upload = uploads[event.data.idx];
        if (upload) {
          upload.progress = event.data.progress;
          upload.isDone = event.data.progress === 100;
        }

        let progress = 0;
        const count = uploads.length;
        const total = uploads.reduce(function (runningTotal, cur) {
          return runningTotal + (cur.progress || 0);
        }, 0);

        progress = total / count;
        return { uploads, uploadProgress: progress };
      }),
      uploadComplete: assign((context, event) => {
        if (event.type !== "UPLOAD.COMPLETE") return {};
        const uploads = [...context.uploads];
        uploads.splice(event.data.idx, 1);

        let progress = 0;
        const count = uploads.length;
        const total = uploads.reduce(function (runningTotal, cur) {
          return runningTotal + (cur.progress || 0);
        }, 0);
        progress = total / count;
        return { uploads, uploadProgress: progress };
      }),
      identified: assign((context, event) => {
        assertEventType(event, "IDENTIFIED");
        return { identity: event.value };
      }),
      spawnUpload: assign({
        uploads: (context, event) => {
          if (
            event.type !== "done.invoke.recordingMachine" ||
            !context.activePrompt ||
            !context.identity ||
            !event.data
          )
            return context.uploads;
          if (!context.story) return context.uploads;
          const contentType = event.data.blob.type;
          const fileId = getFileId(
            context.activePrompt.response?.id,
            context.story.id,
            context.activePrompt.idx,
            String(context.identity.uid || context.identity.email)
          );
          const fileMetadata = {
            contentType,
            customMetadata: {
              id: fileId,
              prompt: context.activePrompt?.idx,
              story: context.story.id,
              ...context.identity,
              ...event.data.info,
            },
          };
          const uploadType = event.data.type;
          const filename = `${fileId}.${getFileExtension(contentType)}`;
          //@ts-ignore
          const file = new File([event.data.blob], filename, {
            type: contentType,
          });
          const machine = spawn(
            createUploadMachine({
              id: `upload${context.activePrompt.id}`,
              data: {
                path: `${uploadType}s/${context.story.id}/responses/${context.activePrompt?.idx}/${fileId}/${filename}`,
                file,
                metadata: fileMetadata,
                idx: context.uploads.length,
              },
            })
          );
          const _uploads = [
            ...context.uploads,
            {
              ...event.data,
              identity: context.identity,
              prompt: { ...context.activePrompt },
              ref: machine,
            },
          ];
          return _uploads;
        },
      }),
      storeIdentity: (context, event) => {
        assertEventType(event, "IDENTIFIED");
        //@ts-ignore
        window.localStorage.setItem(
          LocalStorageKeys.ContactInfo,
          JSON.stringify(event.value)
        );
      },
      sendAnalytics: () => {
        throw Error("sendAnalytics Not implemented.");
      },
    },
    guards: {
      isComplete: (context, event) => {
        const complete = context.story.prompts.every(
          (prompt) => !!prompt.response
        );
        return complete;
      },
      isIncomplete: (context, event) => {
        const complete = context.story.prompts.every(
          (prompt) => !!prompt.response
        );
        return !complete;
      },
    },
  }
);

export default respondMachine;

export type RespondMachineInterpreterType = Interpreter<
  RespondMachineContext,
  any,
  RespondMachineEvent,
  RespondMachineStates
>;
