import React from 'react';
import { connect } from 'react-redux';
import { Box, Button, Nav } from 'grommet';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Icon,
  IconButton,
  Text,
  useBreakpointValue 
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { signOut } from '../../store/actions/authActions';
import MenuButton from './MenuButton';
import NavbarLayout from './Navbar';


export const NavbarMenuContext = React.createContext({
    visible: false,
    setVisible: (isVisible) => {},
  });

const mapDispatchToProps = (dispatch) => {
  return {
      signOut: () => dispatch(signOut())
  }
};


const FaNewStory = () => (<FontAwesomeIcon icon="file-alt" />)
const FaChangeAccount = () => (<FontAwesomeIcon icon="address-card" />)
const FaPhoneNumber = () => (<FontAwesomeIcon icon="mobile-alt" />)
const FaEmail = () => (<FontAwesomeIcon icon="envelope" />)
const FaDelete = () => (<FontAwesomeIcon icon="user-times" />)
const FaHelp = () => (<FontAwesomeIcon icon="user-shield" />)
const FaPrivacy = () => (<FontAwesomeIcon icon="question" />)
const FaLogout = () => (<FontAwesomeIcon icon="sign-out-alt" />)

export const SignoutButton = connect(null, mapDispatchToProps)((props) => {
  return (<MenuButton
    label="Logout"
    icon={<Text color={"springWood.700"} px="2"><Icon as={FaLogout} h={4} w={4} /></Text> }
    onClick={()=>{ props.signOut(); }} />);
});

export const SettingsMenu = () => {
    const placement = useBreakpointValue({ base: "top", md: "right" })
    const size = useBreakpointValue({ base: "full", md: "md" })
    return (<NavbarMenuContext.Consumer>
    {({visible, setVisible}) => (visible && (
      <Drawer
      isOpen={visible}
      placement={placement}
      onClose={setVisible}
      size={size}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody>
         <Box
          fill
          direction="column"
          align="center"
          justify="stretch"
          pad={{ vertical: "small", horizontal: "medium" }} gap="small">
            <Box flex={false} pad="small" align="stretch" fill="horizontal">
                <Nav gap="xsmall">
                  <MenuButton
                      label="Start a new story"
                      icon={<Text color={"seaGreen.500"} px="2"><Icon as={FaNewStory} h={4} w={4} /></Text>}
                      href="/start/new"
                      onClick={()=> setVisible(false)}
                      />
                  <MenuButton
                      label="Change your account name"
                      icon={<Text color={"tumbleweed.500"} px="2"><Icon as={FaChangeAccount} h={4} w={4} /></Text>}
                      href="https://quilted.io/pages/contact-us"
                      onClick={()=> setVisible(false)}
                      />
                  <MenuButton
                      label="Change your account phone number"
                      icon={<Text color={"airBlue.500"} px="2"><Icon as={FaPhoneNumber} h={4} w={4} /></Text>}
                      href="https://quilted.io/pages/contact-us"
                      onClick={()=> setVisible(false)}
                      />
                    <MenuButton
                      label="Change your account email"
                      icon={<Text color={"majesty.500"} px="2"><Icon as={FaEmail} h={4} w={4} /></Text>}
                      href="https://quilted.io/pages/contact-us"
                      onClick={()=> setVisible(false)}
                      />
                    <MenuButton
                      label="Delete My Account"
                      icon={<Text color={"puce.500"} px="2"><Icon as={FaDelete} h={4} w={4} /></Text>}
                      href="/delete-account"
                      onClick={()=> setVisible(false)}
                      />
                    <MenuButton
                      label="Privacy"
                      icon={<Text color={"seaGreen.500"} px="2"><Icon as={FaPrivacy} h={4} w={4} /></Text>}
                      href="https://quilted.io/policies/privacy-policy"
                      onClick={()=> setVisible(false)}
                      />
                    <MenuButton
                      label="Help Center"
                      icon={<Text color={"springWood.700"} px="2"><Icon as={FaHelp} h={4} w={4} /></Text>}
                      href="https://quilted.io/pages/frequently-asked-questions"
                      onClick={()=> setVisible(false)}
                      />
                </Nav>
            </Box>
          </Box>
          </DrawerBody>
          <DrawerFooter>
            <SignoutButton />
          </DrawerFooter>
          </DrawerContent>
          </Drawer>
      ))}</NavbarMenuContext.Consumer>);
}

const FaMenu = () => (<FontAwesomeIcon icon="bars" />)

export const MainMenu = (props) => {
    return (<NavbarMenuContext.Consumer>
        {({visible, setVisible}) => (
        !visible && (<IconButton
        variant="ghost"
        colorScheme="airBlue"
        textColor="gray.500"
        onClick={() => {setVisible(!visible)}}
        {...props}
      />))}</NavbarMenuContext.Consumer>)
};

export const DashboardNavbar = ({icon, dispatch, ...rest}) => {
  const [visible, setVisible] = React.useState(false);

  return (<NavbarMenuContext.Provider value={{ visible, setVisible }}>
    <NavbarLayout {...rest} right={<MainMenu icon={icon || <Icon as={FaMenu} color="gray.900" />} />}>
    <SettingsMenu />
    </NavbarLayout>
  </NavbarMenuContext.Provider>);
}