import { useActor } from "@xstate/react";
import React from "react";
import { Flex, Button, } from "@chakra-ui/react";



const VideoEditorMenu = (props: VideoEditorMenuProps) => {
  const { activeClipMachine } = props;

  const [state, send]: any[] = useActor(activeClipMachine);
  if(!activeClipMachine) return null;
  return (
    <Flex direction="row" align="center" justify="end">
        <Button
                rounded="md"
                onClick={() => {send({ type: "DONE" })}}
              >
                Back
              </Button>
    </Flex>
  );
};

export default VideoEditorMenu;

interface VideoEditorMenuProps {
  activeClipMachine: any;
}
