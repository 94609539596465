import React from "react";
import { chakra, Box, Button, Image, Flex, useColorModeValue } from "@chakra-ui/react";

const ProductCard = ({product,onClick}:{product:{title:string,description:string,image:string, price:string,inCart:boolean},onClick:()=>void}) => {

   const buttonBgInCart = useColorModeValue("whiteAlpha","airBlue")
   const buttonBg = useColorModeValue("airBlue","whiteAlpha")

    return (
      <Flex
        maxW="xs"
        m={2}
        bg={useColorModeValue("white", "airBlue.900")}
        shadow="lg"
        rounded="lg"
        direction="column"
      >
        <Box px={4} py={2} flex="1">
          <chakra.h1
            fontWeight="bold"
            fontSize="2xl"
            textTransform="uppercase"
            my={2}
          >
            {product.title}
          </chakra.h1>
          <chakra.p fontSize="sm">{product.description}</chakra.p>
        </Box>
        <Image
          h={48}
          w="full"
          fit="cover"
          mt={2}
          src={product.image}
          alt={product.title}
        />

        <Flex
          alignItems="center"
          justifyContent="space-between"
          px={4}
          py={2}
          bg={useColorModeValue("airBlue.800", "airBlue.100")}
          roundedBottom="lg"
        >
          <chakra.h1
            color={useColorModeValue("white", "airBlue.900")}
            fontWeight="bold"
            fontSize="lg"
          >
            ${product.price}
          </chakra.h1>
          <Button
            px={1}
            py={1}
            fontSize="xs"
            colorScheme={product.inCart ? buttonBgInCart : buttonBg}
            fontWeight="bold"
            rounded="lg"
            textTransform="uppercase"
            onClick={onClick}
          >
            {product.inCart ? <span>Remove</span> : <span>Add to cart</span>}
          </Button>
        </Flex>
      </Flex>
    );
  };

  export default ProductCard;