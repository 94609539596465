import React, { useEffect, useRef, useState } from "react";
import {
  Flex,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  Checkbox,
  Link,
} from "@chakra-ui/react";
import {
  iStoryManagerStateContext,
  StoryStepId,
} from "../../../types/StoryManagerState";
import { useStoryManagerDispatch, useStoryManagerState } from "../StoryManager";
import { RouteParams } from "../CreateStoryWizard";
import { useNavigate, useParams } from "react-router-dom";
// @ts-ignore
import { useAnalytics } from "use-analytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputMask from "react-input-mask";
import isDeepEqual from "fast-deep-equal/react";
import formatPhoneNumber from "../../../utils/formatPhoneNumber";
import AuthErrorWidget from "../../auth/AuthErrorWidget";

yup.addMethod(
  yup.string,
  "phone",
  function (messageError = "Phone number is not valid") {
    const phoneRegExp =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return this.test("phone", messageError, (value) => {
      if (value && value.length > 0) {
        return phoneRegExp.test(value);
      }
      return true;
    });
  }
);

const SigninForm = ({ id }: { id: string }) => {
  const [loading, setLoading] = useState(true);

  const dispatch = useStoryManagerDispatch();
  // @ts-ignore
  const {
    state: { story, authChecks, signup, emailExists, error },
  }: { state: iStoryManagerStateContext } = useStoryManagerState();

  useEffect(() => {
    setLoading(false);
  }, [authChecks]);

  const storyRef = useRef(story);
  if (!isDeepEqual(storyRef.current, story)) {
    storyRef.current = story;
  }

  const schema = yup.object().shape({
    email: yup.string().email().required("Please enter your email address"),
    password: yup.string().required("Password is required"),
  });

  const { register, setFocus, setValue, watch, formState } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  const [email, password] = watch(["email", "password"]);

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  useEffect(() => {
    if (!email && storyRef.current?.email) {
      setValue("email", storyRef.current.email, {
        shouldValidate: true,
        shouldDirty: false,
        shouldTouch: false,
      });
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      const callback = () => {
        null;
      };

      if (story?.signup === true && authChecks?.signedIn) {
        console.log("SignupStep.useEffect signed up/in", { story, authChecks });
        return;
      }

      dispatch({
        type: "update",
        payload: {
          story: {
            ...story,
            step: [id],
          },
          [emailExists ? "signup" : "signin"]: {
            ...signup,
            email,
            password,
          },
          [!emailExists ? "signup" : "signin"]: null,
          valid: {
            [id]: {
              email: !!email && !errors.email,
              password: !!password && !errors.password,
            },
          },
        },
        callback,
      });
    }
  }, [loading, email, password, emailExists]);

  return (
    <form>
      <Stack spacing="6">
        <FormControl id="email" isInvalid={errors.email}>
          <FormLabel variant="headingLabel">Email</FormLabel>
          <Input {...register("email")} placeholder="youremail@domain.com" />
          <FormErrorMessage color="puce.700">
            {errors.email?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl id="password" isInvalid={errors.password}>
          <FormLabel variant="headingLabel">Create a Password</FormLabel>
          <Input
            {...register("password")}
            placeholder="Enter a password"
            type="password"
          />
          <FormErrorMessage color="puce.700">
            {errors.password?.message}
          </FormErrorMessage>
        </FormControl>
      </Stack>
    </form>
  );
};

const SignupForm = ({ id }: { id: string }) => {
  const [loading, setLoading] = useState(true);

  const dispatch = useStoryManagerDispatch();
  // @ts-ignore
  const {
    state: { story, authChecks, signup, emailExists },
  }: { state: iStoryManagerStateContext } = useStoryManagerState();

  useEffect(() => {
    setLoading(false);
  }, [authChecks]);

  const storyRef = useRef(story);
  if (!isDeepEqual(storyRef.current, story)) {
    storyRef.current = story;
  }

  const schema = yup.object().shape({
    firstName: yup.string().required("Please enter your first name"),
    lastName: yup.string().required("Please enter your last name"),
    email: yup.string().email().required("Please enter your email address"),
    // @ts-ignore
    phone: yup.string().phone(),
    password: yup.string().required("Password is required"),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    acceptedTerms: yup.boolean().required(),
    sendMarketing: yup.boolean(),
  });

  const { register, setFocus, setValue, watch, formState } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  useEffect(() => {
    if (!email && storyRef.current?.email) {
      setValue("email", storyRef.current.email, {
        shouldValidate: true,
        shouldDirty: false,
        shouldTouch: false,
      });
    }
  }, []);

  const [
    firstName,
    lastName,
    email,
    phone,
    password,
    passwordConfirmation,
    acceptedTerms,
    sendMarketing,
  ] = watch([
    "firstName",
    "lastName",
    "email",
    "phone",
    "password",
    "passwordConfirmation",
    "acceptedTerms",
    "sendMarketing",
  ]);

  // @ts-ignore
  const { track } = useAnalytics();

  useEffect(() => {
    setFocus("firstName");
  }, [setFocus]);

  useEffect(() => {
    track("signup_termsAccepted", { acceptedTerms });
  }, [acceptedTerms]);

  useEffect(() => {
    track("signup_marketingAccepted", {
      sendMarketing,
    });
  }, [sendMarketing]);

  useEffect(() => {
    if (!loading) {
      const callback = () => {
        null;
      };

      if (story?.signup === true && authChecks?.signedIn) {
        return;
      }

      const phoneFormatted = formatPhoneNumber.forStorage(phone);

      dispatch({
        type: "update",
        payload: {
          story: {
            ...story,
            step: [id],
          },
          [!emailExists ? "signup" : "signin"]: {
            ...signup,
            email,
            phoneNumber: phone,
            phone: phoneFormatted,
            firstName,
            lastName,
            password,
            acceptedTerms,
            sendMarketing,
          },
          [emailExists ? "signup" : "signin"]: null,
          valid: {
            [id]: {
              email: !!email && !errors.email,
              phone: emailExists || !errors.phone,
              firstName: emailExists || (!!firstName && !errors.firstName),
              lastName: emailExists || (!!lastName && !errors.lastName),
              password:
                !!password &&
                !errors.password &&
                ((!!passwordConfirmation && !errors.passwordConfirmation) ||
                  emailExists),
              acceptedTerms: !!acceptedTerms && !errors.acceptedTerms,
            },
          },
        },
        callback,
      });
    }
  }, [
    loading,
    email,
    phone,
    errors.phone,
    firstName,
    lastName,
    password,
    passwordConfirmation,
    emailExists,
    acceptedTerms,
    sendMarketing,
  ]);

  return (
    <form>
      <Stack spacing="6">
        <HStack align="flex-start">
          <FormControl id="firstName" isInvalid={errors.firstName}>
            <FormLabel variant="headingLabel">First Name</FormLabel>
            <Input
              {...register("firstName")}
              placeholder="Andre"
              onFocus={() => track("signup_editingFirstName")}
            />
            <FormErrorMessage color="puce.700">
              {errors.firstName?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl id="lastName" isInvalid={errors.lastName}>
            <FormLabel variant="headingLabel">Last Name</FormLabel>
            <Input
              {...register("lastName")}
              placeholder="Davis"
              onFocus={() => track("signup_editingLastName")}
            />
            <FormErrorMessage color="puce.700">
              {errors.lastName?.message}
            </FormErrorMessage>
          </FormControl>
        </HStack>
        <FormControl id="email" isInvalid={errors.email}>
          <FormLabel variant="headingLabel">Email</FormLabel>
          <Input {...register("email")} placeholder="youremail@domain.com" />
          <FormErrorMessage color="puce.700">
            {errors.email?.message}
          </FormErrorMessage>
        </FormControl>
        <Stack>
          <FormControl id="password" isInvalid={errors.password}>
            <FormLabel variant="headingLabel">Create a Password</FormLabel>
            <Input
              {...register("password")}
              placeholder="Enter a password"
              type="password"
              onFocus={() => track("signup_editingPassword")}
            />
            <FormErrorMessage color="puce.700">
              {errors.password?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="passwordConfirmation"
            isInvalid={errors.passwordConfirmation}
          >
            <Input
              {...register("passwordConfirmation")}
              placeholder="Enter your password again"
              type="password"
              onFocus={() => track("signup_editingPasswordConfirmation")}
            />
            <FormErrorMessage color="puce.700">
              {errors.passwordConfirmation?.message}
            </FormErrorMessage>
          </FormControl>
        </Stack>
        <FormControl id="phone" isInvalid={errors.phone}>
          <FormLabel w="full" variant="headingLabel">
            <Flex
              direction={["column", "column", "row"]}
              justify="space-between"
            >
              <Box>
                Your Cell Phone{" "}
                <Text as="span" fontSize="xs">
                  (optional)
                </Text>
              </Box>
              <Text fontSize="xs">
                Get your video progress updates by text message
              </Text>
            </Flex>
          </FormLabel>
          <Input
            as={InputMask}
            mask={"(***) ***-****"}
            {...register("phone")}
            onBlur={() => {
              phone === "(___) ___-____"
                ? setValue("phone", "", {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                : null;
            }}
            placeholder="(330) 333-8888"
            onFocus={() => track("signup_editingPhone")}
          />
          <FormErrorMessage color="puce.700">
            {errors.phone?.message}
          </FormErrorMessage>
        </FormControl>
        <Stack>
          <FormControl id="acceptedTerms" isInvalid={errors.acceptedTerms}>
            <Checkbox
              {...register("acceptedTerms")}
              colorScheme="airBlue"
              isInvalid={errors.acceptedTerms}
            >
              I agree to the Quilted{" "}
              <Link
                href="https://quilted.io/policies/terms-of-service"
                target="_blank"
                textDecor="underline"
                title="Quilted Terms of Service"
              >
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link
                href="https://quilted.io/policies/privacy-policy"
                target="_blank"
                textDecor="underline"
              >
                Privacy Policy
              </Link>
            </Checkbox>
          </FormControl>
          <FormControl id="acceptedTerms" isInvalid={errors.sendMarketing}>
            <Checkbox
              defaultChecked
              {...register("sendMarketing")}
              colorScheme="airBlue"
            >
              Share Quilted updates with me
            </Checkbox>
          </FormControl>
        </Stack>
      </Stack>
    </form>
  );
};

const SignupStep = ({ id }: { id: string }) => {
  const [authMode, setAuthMode] = useState<"login" | "signup">("signup");
  const [loading, setLoading] = useState(true);
  const dispatch = useStoryManagerDispatch();
  // @ts-ignore
  const {
    state: { authChecks, emailExists, error },
  }: { state: iStoryManagerStateContext } = useStoryManagerState();

  const params = useParams<RouteParams>();
  const navigate = useNavigate();
  // @ts-ignore
  const { track } = useAnalytics();

  const { setup: setupMode, id: urlId } = params;

  useEffect(() => {
    if (loading && !authChecks?.signedIn) {
      setAuthMode(emailExists ? "login" : "signup");
      setLoading(false);
    }
  }, [authChecks, emailExists]);

  const signinWithFacebook = () => {
    dispatch({
      type: "signin_facebook",
    });
  };

  return (
    <>
      <Stack spacing="8">
        <Heading as="h2" size="h2" variant="center">
          {authMode === "login" ? <span>Log in</span> : <span>Signup</span>}
        </Heading>
        <Button
          colorScheme="gray"
          borderColor="gray.300"
          fontWeight="500"
          variant="outline"
          h="64px"
          onClick={() => {
            track("signup_startFacebookSignup");
            signinWithFacebook();
          }}
        >
          <HStack>
            <FontAwesomeIcon
              size="2x"
              icon={["fab", "facebook"]}
              color="#1977F3"
            />
            <Text fontSize="lg">Continue with Facebook</Text>
          </HStack>
        </Button>
        <HStack spacing="lg">
          <Divider borderColor="gray.500" />
          <Text variant="serif" textTransform="uppercase">
            or
          </Text>
          <Divider borderColor="gray.500" />
        </HStack>
        {error && <AuthErrorWidget error={error} />}
        {authChecks?.signedIn && (
          <Text>Signed in: {JSON.stringify(authChecks, undefined, 4)}</Text>
        )}
        {authMode === "login" && (
          <>
            <HStack
              direction="row"
              bgColor="majesty.50"
              p="sm"
              textAlign="center"
              rounded="lg"
              justify="center"
            >
              <Text variant="serif" my="auto">
                Need to sign up for an account?
              </Text>
              <Button
                variant="plain"
                fontFamily="serif"
                color="majesty.700"
                p="0"
                onClick={() => setAuthMode("signup")}
              >
                Sign up
              </Button>
            </HStack>
            <SigninForm id={id} />
          </>
        )}
        {authMode === "signup" && (
          <>
            <HStack
              direction="row"
              bgColor="airBlue.50"
              p="sm"
              textAlign="center"
              rounded="lg"
              justify="center"
            >
              <Text variant="serif" my="auto">
                Already have an account?
              </Text>
              <Button
                variant="plain"
                fontFamily="serif"
                color="airBlue.700"
                p="0"
                onClick={() => setAuthMode("login")}
              >
                Sign in
              </Button>
            </HStack>
            <SignupForm id={id} />
          </>
        )}
        <Divider />
      </Stack>
    </>
  );
};

export default SignupStep;
