/* eslint-disable react/prop-types */
import React, { FC, useEffect } from "react";
import {
  Button,
  IconButton,
  ButtonProps,
  ButtonGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import detectCanRecord from "../../video/recorder/can-record";
import { useFeatureFlags } from "../../featureFlags";

export type RespondButtonProps = { onClick: (useInput?: boolean) => void };

const RespondButton: FC<ButtonProps & RespondButtonProps> = ({
  onClick,
  children,
  ...props
}) => {
  const { canRecord } = detectCanRecord;
  const flags = useFeatureFlags();
  const [forceFileUpload, setForceFileUpload] = React.useState(
    flags.iosForceFileUpload.isEnabled()
  );

  useEffect(() => {
    const iosForceFileUpload = flags.iosForceFileUpload.isEnabled();
    console.log("ROXY", { forceFileUpload, iosForceFileUpload });
    forceFileUpload !== iosForceFileUpload &&
      setForceFileUpload(iosForceFileUpload);
  }, [flags]);

  return !!canRecord && !forceFileUpload ? (
    <ButtonGroup isAttached {...props}>
      <Button
        name="respondButton"
        pl={5}
        pr={5}
        onClick={() => {
          console.log("Responding Click");
          onClick();
        }}
        disabled={props.disabled}
      >
        {children}
      </Button>
      <Menu>
        <MenuButton
          name="respondMenuButton"
          as={IconButton}
          aria-label="Media source"
          icon={<ChevronDownIcon />}
          disabled={props.disabled}
        />
        <MenuList>
          <MenuItem onClick={() => onClick()}>Record Video</MenuItem>
          <MenuItem onClick={() => onClick(true)}>Add media</MenuItem>
        </MenuList>
      </Menu>
    </ButtonGroup>
  ) : (
    <Button
      name="respondButtonInputOnly"
      pl={5}
      pr={5}
      onClick={() => onClick(true)}
      {...props}
    >
      {children}
    </Button>
  );
};

export default RespondButton;
