import React from 'react';
import { Box, Heading, Paragraph } from 'grommet';

import PageContainer from '../layout/components/PageContainer';


const GoodByePage = () => {

    return (<PageContainer>
            <Box align="center" justify="center" flex={{shrink:0}} pad={{vertical:"medium"}}>
                <Heading>Good Bye!</Heading>
                <Heading level={2}>Your account has been removed.</Heading>
            </Box>
    </PageContainer>);
}


export default GoodByePage;