import React, { Component } from "react";
import visualize from "./visualization/visualize.js";
import Helper from "./visualization/helper.js";
import styled from "@emotion/styled";

const Canvas = styled.canvas`
  width: 100%;
`;

class AudioVisualiser extends Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
    this.bufferLength = this.props.bufferLength;
  }

  componentDidUpdate() {
    this.draw();
  }

  draw() {
    if(!this.canvas.current || !this.props.audioData) return;
    const { audioData, frameCount } = this.props;
    const canvas = this.canvas.current;

    visualize(
      audioData,
      canvas,
      Helper,
      { type: "wave", colors: ["#ffffff"] },
      frameCount
    )

  }

  render() {
    return <Canvas height="50" ref={this.canvas} />;
  }
}

export default AudioVisualiser;