import React from "react";
import { Flex, Heading, Skeleton } from "@chakra-ui/react";
import PageContainer from "../layout/components/PageContainer";

const LoadingPage = ({ title }: { title?: string }) => {
  return (
    <PageContainer
      w="90vw"
      maxH="100vh"
      wrapperProps={{
        flex: 1,
        maxH: "100vh",
        mb: "none",
        pb: "none",
      }}
    >
      <Flex>
        <Heading>{title || "Loading"}</Heading>
        <Skeleton
          startColor="airBlue.500"
          endColor="seaGreen.500"
          height="200px"
        />
      </Flex>
    </PageContainer>
  );
};

export default LoadingPage;
