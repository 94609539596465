import React from 'react'
import styled from 'styled-components'

import { Card, CardBody, CardHeader, Stack } from 'grommet'
import { Picture } from 'react-responsive-picture'


export const ResponsivePicture = styled(Picture)`
   width: 100%;
   height: auto;
   display:block;
`
export const ResponsiveImage = ResponsivePicture



const getResizedImagePath = (path, dimensions = '327x327') => {    
	const replace = `_${dimensions}$1`;
  
  const parts = path.split('?')
  let result =  parts[0].replace(/(\.[\w\d_-]+)$/i, String(replace))
  
  return parts.length > 1 ? `${result}?${parts[1]}` : result;
}

export const SubjectImage = ({src, alt, resize = true}) => {
    const dimensions = [
        {
            size: "150x150",
            media: "(max-width: 275px)",
        },
        {
            size: "327x327",
            media: "(max-width: 425px)",
        },
        {
            size: "960x960",
            media: "(max-width: 768px)",
        }
    ]
    
    const sources = () => {
        const _sources = resize ? [...dimensions.map(d => ({ srcSet: getResizedImagePath(src, d.size), media: d.media }))] : []
        return [..._sources, { srcSet: src}];
    }
    
    return (<ResponsivePicture sources={sources()} alt={alt || 'image'} />)
}

/**
 * https://www.npmjs.com/package/react-responsive-picture
 * Persons image is 327x278
 * Video image is 250x165
 * yarn add react-responsive-picture
 * import { Picture } from 'react-responsive-picture'
 */
export const ResponsiveImageCard = ({image, ...props}) => {
    return (
        <Card {...props}>
            <CardBody>
                {image}
            </CardBody>
        </Card>
    )
}

export const StackedResponsiveImageCard = ({anchor, children, ...props}) => {
    return (
        <Card {...props}>
            <Stack anchor={anchor||"center"}>
                <CardBody>
                    {children && children[0]}
                </CardBody>
                <CardHeader
                    pad={{ horizontal: "small", vertical: "small" }}
                    justify="center"
                >
                    {children && children[1]}
                </CardHeader>
          </Stack>
        </Card>
    )
}
