import React from "react";
import { Flex, Stack, Text, Heading } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
// @ts-ignore
import { useAnalytics } from "use-analytics";

const ContinueEditingLatest = (props: ContinueEditingLatestProps) => {
  const { existingProjects, setProject } = props;
  const { track } = useAnalytics();
  return (
    <Flex direction="column" alignItems="center" justify="center">
      <Heading as="h2" size="h2" fontSize="2xl" variant="center" mb="lg">
        Pick up where you left off
      </Heading>
      <Flex
        p="md"
        bgColor="seaGreen.100"
        rounded="md"
        shadow="lg"
        direction={["column","row"]}
        align="center"
        justify="space-between"
        mb="sm"
      >
        <Stack align="flex-start" textAlign="left" spacing=".25rem">
          <Text fontSize="xl" fontWeight="bold">
            Continue editing your most recent movie
          </Text>
          <Text>
            {existingProjects[0].updatedAt ? "Last edited" : "Started"} on{" "}
            {moment
              .unix(
                existingProjects[0].updatedAt?.seconds ||
                  existingProjects[0].createdAt?.seconds
              )
              .format("MMMM Do [at] h:mm a")
              .toString()}
          </Text>
        </Stack>
        {/* Button */}
        <Flex
          bgColor="seaGreen.400"
          rounded="full"
          color="seaGreen.100"
          ml={["initial","4rem"]}
          w="50px"
          h="50px"
          justify="center"
          align="center"
          cursor="pointer"
          transition="200ms linear"
          onClick={() => {
            track("finalVideo | Opened latest DIY video project");
            setProject(existingProjects[0]);
          }}
          _hover={{
            transform: "scale(1.2)",
            shadow: "md",
          }}
        >
          <FontAwesomeIcon icon="chevron-right" size="2x" />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ContinueEditingLatest;

interface ContinueEditingLatestProps {
  existingProjects: any;
  setProject: (x: any) => any;
}
