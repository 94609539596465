import React, { FC } from "react";
import {
  Flex,
  Stack,
  Text,
  HStack,
  Heading,
  Image,
  Badge,
} from "@chakra-ui/react";
import svgConcierge from "../../assets/concierge-option.png";

import { Prices } from "../../utils/pricing";

export const ConciergeService: FC<{ prices: Prices }> = ({
  prices,
}: {
  prices: Prices;
}) => {
  return (
    <HStack gap={4} pt={6} px={6} rounded="lg" boxShadow="md" bg="gray.50">
      <Flex w="180px" h="180px" justify="center" align="center">
        <Image src={svgConcierge} />
      </Flex>
      <Stack flex="1">
        <HStack spacing="md">
          <Heading as="h3" size="h4">
            Concierge
          </Heading>
          <Badge colorScheme="tumbleweed" fontSize="1rem" px="sm">
            <HStack>
              {!!prices?.discount && (
                <Text as="s" fontWeight="thin">
                  {"$"}
                  {prices?.price?.toFixed(2)}
                </Text>
              )}
              <Text>
                {"$"}
                {(prices?.price - (prices?.discount || 0)).toFixed(2)}
              </Text>
            </HStack>
          </Badge>
        </HStack>
        <Text fontSize="sm">
          If you prefer a hands-off approach, let us do the editing for you.
          We&apos;ll make a few basic edits to make it look good, including
          trimming silence and adding background music.
        </Text>
        <Text fontSize="sm">
          You can also upload additional photos and videos or add a custom
          message to be included in your final movie.
        </Text>
        <Text fontWeight="bold">Turnaround time: 3 business days</Text>
      </Stack>
    </HStack>
  );
};

export default ConciergeService;
