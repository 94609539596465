import React, { useState } from "react";

import {
  Flex,
  Button,
  Box,
  Heading,
  Text,
  HStack,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from "react-device-detect";
import ModalDialog from "../../../common/ModalDialog";
import { getColorScheme } from "../../../../chakraTheme";
import bg from "../../../../assets/story-manager-bg.svg";
import { useAnalytics } from "use-analytics";

const createUseContext = (stateContext, dispatch) => {
  const useContext = () => {
    const innerDispatch = (action) => {
      console.log("Running innerDispatch", action);
      dispatch(action);
    };
    const contextState = React.useContext(stateContext);
    if (contextState === undefined) {
      throw new Error("useState must be used within a StateContext");
    }

    return [contextState, innerDispatch];
  };
  return useContext;
};

const useCreateContext = (init, dispatch) => {
  const stateContext = React.createContext(init);

  const useContextFn = createUseContext(stateContext, dispatch);
  return [stateContext, dispatch, useContextFn];
};

const promptReducer = (state, action) => {
  let reduced = state;
  switch (action.type) {
    case "setView":
      reduced = {
        ...state,
        view: action.payload || "detail",
        isEditing: false,
        showDelete: false,
      };
      break;
    case "setEditing":
      if (state.isEditing === action.payload) return state;
      reduced = { ...state, isEditing: action.payload, showDelete: false };
      break;
    case "setShowDelete":
      reduced = { ...state, showDelete: action.payload };
      break;
    case "handleEdit":
      state.data.prompt = { ...state.data.prompt, ...action.payload };
      reduced = {
        ...state,
        view: "detail",
        isEditing: false,
        showDelete: false,
      };
      break;
    case "handleDelete":
      reduced = {
        ...state,
        view: "deleted",
        isEditing: false,
        showDelete: false,
      };
      break;
    default:
      break;
  }
  action.callback && action.callback();
  return reduced;
};

const initState = {
  data: { storyId: undefined, prompt: undefined, theme: undefined },
  view: "detail",
  isEditing: false,
  showDelete: false,
  key: undefined,
};

const PromptCard = ({ storyId, prompt, theme, onEdit, onDelete }) => {
  const [state, dispatch] = React.useReducer(promptReducer, {
    data: { storyId, prompt, theme },
    view: "detail",
    isEditing: false,
    showDelete: false,
    key: prompt.idx,
  });

  const { track } = useAnalytics();

  const promptDispatch = (action) => {
    switch (action.type) {
      case "handleEdit":
        onEdit(action.payload);
        break;
      case "handleDelete":
        dispatch({
          type: "setIsEditing",
          payload: false,
        });
        onDelete();
        return;
      default:
        break;
    }

    dispatch(action);
    action = null;
  };

  const [StateContext, , useContext] = useCreateContext(
    initState,
    promptDispatch
  );

  return (
    <StateContext.Provider value={state}>
      <Detail useContext={useContext} />
    </StateContext.Provider>
  );
};

const PromptCardMenu = ({ useContext, onSave, onCancel }) => {
  const [context, dispatch] = useContext();
  const {
    data: { prompt, theme },
    view,
    isEditing,
    showDelete,
  } = context;

  const { track } = useAnalytics();

  const toggle = (shown) => {
    if (isEditing !== shown)
      dispatch({
        type: "setEditing",
        payload: shown,
      });
  };

  const toggleDelete = (shown) => {
    if (showDelete !== shown) {
      dispatch({
        type: "setShowDelete",
        payload: shown,
      });
    }
  };

  const handleDelete = () => {
    track("promptCard_deletePrompt");
    dispatch({
      type: "handleDelete",
      payload: prompt,
    });
  };

  return (
    <Flex direction="column" justify="space-around">
      <Stack>
        {!isEditing ? (
          <>
            <Button
              variant="outline"
              colorScheme="whiteAlpha"
              color="white"
              leftIcon={<FontAwesomeIcon icon={["far", "edit"]} />}
              onClick={() => toggle(true)}
            >
              Edit
            </Button>
            <Button
              variant="outline"
              colorScheme="whiteAlpha"
              color="white"
              leftIcon={<FontAwesomeIcon icon={["far", "trash-alt"]} />}
              onClick={() => toggleDelete(true)}
            >
              Delete
            </Button>
          </>
        ) : (
          <>
            <Button
              pl="6"
              pr="6"
              colorScheme="whiteAlpha"
              bgColor="whiteAlpha.400"
              leftIcon={<FontAwesomeIcon icon="check-double" />}
              onClick={() => {
                onSave();
                toggle(true);
              }}
            >
              Save
            </Button>
            <Button
              variant="outline"
              colorScheme="whiteAlpha"
              color="white"
              leftIcon={<FontAwesomeIcon icon={["far", "window-close"]} />}
              onClick={() => {
                onCancel();
                toggle(false);
              }}
            >
              Cancel
            </Button>
          </>
        )}
      </Stack>
      <ModalDialog isOpen={showDelete} onClose={() => toggleDelete(false)}>
        <Stack
          align="flex-start"
          justify="center"
          direction="column"
          p="lg"
          spacing="lg"
        >
          <Heading as="h4" fontSize="xl" textAlign="left" fontWeight="bold">
            Are you sure you want to delete this prompt?
          </Heading>
          <Box borderLeft="2px" borderLeftColor="puce.500" pl="3">
            <Text>
              <em>{prompt.text}</em>
            </Text>
          </Box>
          <HStack alignSelf="center">
            <Button
              variant="solid"
              colorScheme="blackAlpha"
              onClick={() => {
                toggleDelete(false);
              }}
            >
              No
            </Button>
            <Button
              variant="solid"
              colorScheme="puce"
              onClick={() => {
                toggleDelete(false);
                handleDelete();
              }}
            >
              Yes
            </Button>
          </HStack>
        </Stack>
      </ModalDialog>
    </Flex>
  );
};

const Detail = ({ useContext }) => {
  const [context, dispatch] = useContext();
  const {
    data: { prompt, theme },
    isEditing,
  } = context;

  const [text, setText] = useState(prompt.text);
  const { track } = useAnalytics();

  const onSave = (text) => {
    console.log(text);
    track("promptCard_savePrompt");
    if (prompt.text !== text) {
      const payload = {
        originalText: prompt.originalText || prompt.text,
        text,
      };
      dispatch({
        type: "handleEdit",
        payload,
      });
    } else {
      dispatch({
        type: "setView",
      });
    }
  };

  return (
    prompt && (
      <HStack
        justify="space-between"
        w="full"
        py="sm"
        px="lg"
        pr="sm"
        bgColor={`${getColorScheme(prompt.idx)}.500`}
        rounded="xl"
        spacing="6"
        backgroundPosition="bottom -80px right"
        backgroundRepeat="no-repeat"
        backgroundImage={`url(${bg})`}
      >
        {isEditing ? (
          <Textarea
            placeholder={prompt.text}
            value={text}
            onChange={(e) => setText(e.target.value)}
            onFocus={() => track("promptCard_editingPrompt")}
            rows={isMobile ? "5" : "3"}
            bgImage="none"
            bgColor="white"
            color="black"
            p="3"
            fontSize="md"
            rounded="md"
            w="full"
          />
        ) : (
          <Text fontSize="xl" color="white">
            {prompt.text}
          </Text>
        )}
        <PromptCardMenu
          useContext={useContext}
          onCancel={() => {
            track("promptCard_cancelEditing");
            setText(prompt.text);
          }}
          onSave={() => onSave(text)}
        />
      </HStack>
    )
  );
};

export default PromptCard;
