import React from "react";
import { Box, Button, Heading, Text } from "@chakra-ui/react";

interface InitialCardViewProps {
  colorScheme: string;
  disabled: boolean;
  forceConcierge?: true;
  onClick: () => void;
}
export const InitialCardView: React.FC<InitialCardViewProps> = ({
  colorScheme,
  disabled,
  forceConcierge,
  onClick,
}: InitialCardViewProps) => {
  return (
    <Box>
      <Heading as="h4" size="md" mb={3}>
        Create your final movie
      </Heading>
      <Text mb={3}>
        Once all your responses are in,{" "}
        {forceConcierge
          ? "have"
          : "you can choose between creating your own final video or having"}{" "}
        our concierge put together the perfect gift for you.
      </Text>
      <Button colorScheme={colorScheme} onClick={onClick} disabled={disabled}>
        Get Started
      </Button>
    </Box>
  );
};

export default InitialCardView;
