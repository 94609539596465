import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, useFirestore } from "react-redux-firebase";
import { Box, Heading, Paragraph } from "grommet";
import { useToast } from "@chakra-ui/react";

import authHandlers from "../../utils/authHandlers";
import { adjustDateBetweenLateHours } from "../../utils/dates";
import checkAuth from "../../utils/checkAuth";
import BackButton from "../layout/BackButton";
import Running from "../common/RunningIndicator";

import storyManagerSteps from "./steps";
import { useAnalytics } from "use-analytics";
import getTemplateMetadata from "../../utils/getTemplateMetadata";

const getStartDate = () => {
  const start = moment().startOf("minute");
  const remainder = 5 - (start.minute() % 5);
  return moment(start).add(remainder, "minutes").toDate();
};

const StoryManagerStateContext = React.createContext({
  story: undefined,
  templates: undefined,
  ready: false,
  steps: [],
  authChecks: undefined,
});
const StoryManagerDispatchContext = React.createContext();

const StoryCreator = () => {
  const [context, dispatch] = useStoryManager();
  const {
    state: { story, templates, authChecks },
  } = context;

  const buildStory = React.useCallback(() => {
    const templateId = window.location.hash && window.location.hash.substr(1);

    const _story = {
      version: 2,
      title: null,
      uid: (authChecks.signedIn && authChecks.auth.uid) || null,
      email: (authChecks.auth && authChecks.auth.email) || null,
      owner: (authChecks.auth && authChecks.auth.displayName) || null,
      state: "setup",
      step: templateId ? "setup" : "template",
    };

    const _template =
      templateId && templates && templates.find((t) => t.id === templateId);

    if (_template) {
      _story.templateId = _template.id;
      _story.welcomeMessage = _template.welcomeMessage;
      _story.prompts = _template.prompts;
      _story.template = getTemplateMetadata(_template);
    }

    dispatch({
      type: "ready",
      payload: { story: { ..._story } },
      callback: () => console.log("dispatch.ready.callback"),
    });
  }, [templates]);

  const init = React.useCallback(() => {
    if (story) return;
    buildStory();
  }, [dispatch, story]);

  React.useEffect(() => {
    if (templates && !!templates.length) {
      init();
    }
    return () => {
      console.log("--StoryCreator.useEffect.unload");
    };
  }, [templates]);

  return <></>;
};

const StoryLoader = () => {
  const [context, dispatch] = useStoryManager();
  const {
    state: { story },
  } = context;
  const [loading, setLoading] = React.useState(!story);
  const [error, setError] = React.useState();

  const params = useParams();
  const firestore = useFirestore();

  const load = React.useCallback(() => {
    if (params.id) {
      const id = params.id;
      firestore
        .collection("stories")
        .doc(id)
        .get()
        .then((snapshot) => {
          console.log("Story exists?", { exists: snapshot.exists });
          if (snapshot.exists) {
            const data = snapshot.data();
            data.prompts = data.prompts.map((prompt, idx) => {
              return { ...prompt, idx };
            });
            dispatch({
              type: "ready",
              payload: { story: { id, storytellers: [], ...data } },
              callback: () => {
                setLoading(false);
                console.log("dispatch.ready.callback");
              },
            });
          } else {
            setLoading(false);
            setError("Story project was not found!");
          }
        })
        .catch(() => {
          setLoading(false);
          setError("Story project could not be loaded.");
        });
    }
  }, [params.id, firestore, dispatch]);

  const init = React.useCallback(() => {
    if (story) return;
    console.log("Story not yet set. Load story.");
    load();
  }, [story, load]);

  React.useEffect(() => {
    console.log("--StoryCreator.useEffect");
    init();
    return () => {
      console.log("--StoryCreator.useEffect.unload");
    };
  }, []);

  const View = ({ title, text, showBack = false }) => (
    <Box
      direction="column"
      fill={true}
      flex={{ shrink: 0 }}
      align="stretch"
      justify="start"
      gap="large"
    >
      <Box
        direction="row"
        fill="horizontal"
        flex={{ shrink: 0 }}
        align="start"
        justify="start"
      >
        {showBack && <BackButton />}
      </Box>
      <Box
        direction="column"
        fill={true}
        flex={{ shrink: 0 }}
        align="center"
        justify="start"
      >
        <Heading>{title}</Heading>
        <Paragraph>{text}</Paragraph>
      </Box>
    </Box>
  );

  return (
    <>
      {loading && (
        <View
          title="One moment!"
          text={
            <>
              Loading your story project <Running speed={0.5} />
            </>
          }
        />
      )}
      {error && <View title="Oh Snap!" text={error} showBack={true} />}
    </>
  );
};

const StoryManagerReducer = (state, action) => {
  const onAuthChecksSet = (newState) => {
    const { authChecks, steps } = newState;
    if (authChecks && authChecks.signedIn) {
      const signup = steps.map((step) => step.id).indexOf("signup");
      console.log({ newState, authChecks, steps, signup });
      if (signup >= 0) newState.steps.splice(signup, 1);
    }
    return newState;
  };

  switch (action.type) {
    case "setAuthChecks": {
      return onAuthChecksSet({
        ...state,
        authChecks: action.payload,
        ready: !!(state.templates && state.story),
      });
    }
    case "setTemplates": {
      return {
        ...state,
        templates: action.payload,
        ready: !!(state.authChecks && state.story),
      };
    }
    case "update": {
      return { ...state, ...action.payload, changed: true };
    }
    case "ready": {
      return {
        ...state,
        ...action.payload,
        ready:
          !!state.authChecks && !!state.templates && !!state.templates.length,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const useStoryManagerState = () => {
  const context = React.useContext(StoryManagerStateContext);
  if (context === undefined) {
    throw new Error(
      "useStoryManagerState must be used within a StoryManagerProvider"
    );
  }
  return context;
};

export const useStoryManagerDispatch = () => {
  const context = React.useContext(StoryManagerDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useStoryManagerDispatch must be used within a StoryManagerProvider"
    );
  }
  return context;
};

export const useStoryManager = () => {
  return [useStoryManagerState(), useStoryManagerDispatch()];
};

const StoryManagerProvider = ({
  isNew,
  children,
  firebase,
  firestore,
  templates,
  auth,
  profile,
  ...props
}) => {

  const [state, dispatch] = React.useReducer(StoryManagerReducer, {
    templates,
    steps: storyManagerSteps,
  });

  const toast = useToast();
  const checkStorySlug = firebase.functions().httpsCallable("checkStorySlug");
  const [slugChecked, setSlugChecked] = React.useState(true);

  const { track } = useAnalytics();
  const navigate = useNavigate();

  console.log("-StoryManagerProvider.init");

  React.useEffect(() => {
    console.log("-StoryManagerProvider.useEffect", {
      auth,
      profile,
    });
    const authChecks = checkAuth({ auth, profile });
    dispatch({ type: "setAuthChecks", payload: authChecks });
  }, [auth, profile]);

  React.useEffect(() => {
    console.log("-StoryManagerProvider.useEffect", { templates });
    dispatch({ type: "setTemplates", payload: templates });
  }, [templates]);

  React.useEffect(() => {
    console.log("-StoryManagerProvider.useEffect", { ...state });

    return () => {
      console.log("-StoryManagerProvider.useEffect.unload", { ...state });
    };
  }, [state]);

  const cleanObj = (obj) => {
    !!obj && Object.keys(obj).forEach(
      (key) => (obj[key] === undefined && delete obj[key]) || (typeof obj[key] === "object" && cleanObj(obj[key]))
    );
  };

  const onSignup = () => {
    track("signup", {
      category: "Aquisition",
      action: "Sign-up",
      label: "Quilted Sign-up",
    });
  };

  const create = async (story) => {
    console.log("StoryManger.create", { story });
    if (!(story.email || story.uid))
      throw new Error("Cannot save story without email or user account");
    console.log("create", story);
    story = await updateSlugOnStoryTitleChanged(story);
    const doc = {
      ...story,
      uid: auth.uid || null,
      owner: (auth && auth.displayName) || null,
      createdBy: (auth && auth.uid) || story.email,
      createdAt: new Date(),
    };
    cleanObj(doc);
    return await firestore
      .collection("stories")
      .add(doc)
      .then((storyDoc) => {
        doc.id = storyDoc.id;
        return doc;
      });
  };

  const update = async (story) => {
    console.log("StoryManager.update", { story });
    story = await updateSlugOnStoryTitleChanged(story);
    const changes = {
      ...story,
      uid: (auth && auth.uid) || story.uid || null,
      owner: (auth && auth.displayName) || null,
      updatedBy: (auth && auth.uid) || story.email,
      updatedAt: new Date(),
    };

    if (!(changes.email || changes.uid))
      throw new Error("Cannot save story without email or user account");
    cleanObj(changes);
    return await firestore
      .collection("stories")
      .doc(story.id)
      .set(changes, { merge: true })
      .then(() => {
        console.log(`Document written`);
        return changes;
      });
  };

  const checkSlug = async (title) => {
    console.log("checkSlug", { title });
    const output = await checkStorySlug({ storyId: state.story.id, title });
    setSlugChecked(output); // keeps validation in sync.
    return output;
  };

  const updateSlugOnStoryTitleChanged = async (story) => {
    if (
      story.title &&
      (state.story.title !== story.title || (story.title && !story.slug))
    ) {
      const slug = await checkSlug(story.title);
      if (slug) {
        return {
          ...story,
          slug: slug.data,
        };
      }
    }
    return story;
  };

  const save = ({ story }) => {
    console.log("StoryManager.save");
    let action = story.id ? update : create;
    
    const promise = action(story);
    promise.catch((error) => {
      console.error("Error saving story changes", { error });
      toast({
        title: "Error saving story changes",
        description:
          "There was an error saving your story. If this error continues, please contact support.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    });
    return promise;
  };

  const dispatchMiddleware = async (action) => {
    let output = null;
    switch (action.type) {
      case "stepChanging": {
        const { step } = action.payload;
        const { story } = state;
        action.payload.story = { ...story, step };
        const newAction = { ...action, type: "save" };
        delete action.callback;
        dispatchMiddleware(newAction);
        break;
      }
      case "update": {
        if (
          action.payload.story &&
          action.payload.story.id &&
          action.payload.changed
        ) {
          output = await update(action.payload.story);
          action.payload.story = output;
        }
        dispatch(action);
        break;
      }
      case "save": {
        const story = await save(action.payload || state).catch(() => {
          delete action.callback;
          return action.payload.story;
        });
        output = story;

        dispatch({
          ...action,
          payload: { ...action.payload, story, saved: false },
          type: "update",
        });
        break;
      }
      case "setSlug": {
        if (!action.payload?.slug || !state.story.title) break;
        checkSlug(
          action.payload?.slug ? action.payload.slug : state.story.title
        ).then((slug) => {
          console.log("checkSlug.dispatch[update]", {
            story: state.story,
            slug,
          });
          dispatch({
            type: "update",
            payload: {
              story: {
                ...state.story,
                slug: slug.data,
              },
            },
          });
        });
        break;
      }
      case "launch": {
        await save({
          story: {
            ...state.story,
            startDate: adjustDateBetweenLateHours(getStartDate()),
            tzOffset: moment().utcOffset(),
            state: "running",
          },
        });
        navigate('/my-stories', {replace: true});
        navigate(`/my-stories/story/${state.story.id}#launched`);
        break;
      }
      case "notify": {
        toast({
          title: action.payload.title,
          status: action.payload.appearance || "info",
          duration: 9000,
          isClosable: true,
        });
        break;
      }
      case "signin": {
        const pendingCredential = state.error && state.error.credential;
        output = await authHandlers
          .signin(firebase)(action.payload.signin, pendingCredential)
          .then((user) => user)
          .catch((error) => {
            dispatch({
              type: "update",
              payload: {
                error: {
                  ...error,
                  credential: state.error && state.error.credential,
                },
              },
            });
            action.callback = undefined;
          });
        break;
      }
      case "signup": {
        console.log("StoryMangerProvider.signup");
        output = await authHandlers
          .signup(
            firebase,
            firestore
          )(action.payload.signup)
          .then((user) => {
            onSignup();
            return user;
          })
          .catch((error) => {
            const emailExists = error.code === "auth/email-already-in-use";
            dispatch({
              type: "update",
              payload: { error, emailExists },
            });
            action.callback = undefined;
          });
        break;
      }
      case "signin_facebook": {
        await authHandlers
          .signinWithFacebook(firebase)()
          .then((cred) => {
            console.log("Facebook login successful", cred);
            toast({
              title: "Signed In",
              description: "Facebook signin successful!",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            onSignup();
            const payload = {
              ...action,
              type: "save",
              payload: {
                story: { ...state.story, uid: cred.user.uid, step: "prompts" },
                error: null,
                emailExists: false,
              },
            };
            delete action.callback;
            dispatchMiddleware(payload);
          })
          .catch((error) => {
            if (
              error.code === "auth/account-exists-with-different-credential"
            ) {
              const auth = firebase.auth();
              // The pending Facebook credential. (error.credential)
              // The provider account's email address.
              var email = error.email;
              auth.fetchSignInMethodsForEmail(email).then(function (methods) {
                if (methods[0] === "password") {
                  dispatch({
                    type: "update",
                    payload: { error, emailExists: true },
                  });
                  action.callback = undefined;
                }
              });
            } else {
              dispatch({
                type: "update",
                payload: { error },
              });
              action.callback = undefined;
            }
          });
        break;
      }
      default:
        // Not a special case, dispatch the action
        dispatch(action);
    }

    if (action.callback && typeof action.callback == "function") {
      action.callback(output);
    }
  };

  return (
    <StoryManagerStateContext.Provider value={{ state }}>
      <StoryManagerDispatchContext.Provider value={dispatchMiddleware}>
        {isNew && state.authChecks && <StoryCreator />}
        {!isNew && <StoryLoader/>}
        {state.ready && children}
      </StoryManagerDispatchContext.Provider>
    </StoryManagerStateContext.Provider>
  );
};

const mapStateToProps = (state, ownProps) => {
  const templateId = window.location.hash && window.location.hash.substr(1);

  const data = {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    story: undefined,
    templates: undefined,
    templateId: ownProps.templateId || templateId,
  };

  data.templates = state.firestore.ordered.templates;

  return data;
};

export default 
  compose(
    firestoreConnect(() => {
      return ["templates"];
    }),
    connect(mapStateToProps)
  )(StoryManagerProvider);
