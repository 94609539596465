import React from "react";
import { Box, Button, Heading, Text } from "@chakra-ui/react";

interface ReviewCardViewProps {
  colorScheme: string;
  subject: string;
  onClick: () => void;
}
export const ReviewCardView: React.FC<ReviewCardViewProps> = ({
  colorScheme,
  subject,
  onClick,
}: ReviewCardViewProps) => {
  return (
    <Box>
      <Heading as="h4" size="md" mb={3}>
        Review your final movie
      </Heading>
      <Text mb={3}>
        Your final movie is done processing! Once you approve your movie, you
        can send it to {subject} or order a physical keepsake.
      </Text>
      <Button colorScheme={colorScheme} onClick={onClick}>
        Review
      </Button>
    </Box>
  );
};

export default ReviewCardView;
