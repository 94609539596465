import React from "react"
import { useFirebase } from "react-redux-firebase";

const useAuth = () => {
  const firebase = useFirebase()
  const auth = firebase.auth();

  return auth;
};

export default useAuth;
