import React from "react";
import {
  Button,
  Box,
  Container,
  Flex,
  Heading,
  Stack,
  Image,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import CoupleHero from "../../assets/couple-hero.svg";
import { isMobile } from "react-device-detect";
import { useAnalytics } from "use-analytics";

const EmptyStories = () => {
  const navigate = useNavigate();
  const { track } = useAnalytics();

  return (
    <Flex
      w="90%"
      maxW="1600px"
      minH="80vh"
      alignSelf="center"
      align="center"
      justify="center"
      textAlign={["center", "center", "left"]}
      mt={isMobile ? "4rem" : undefined}
    >
      <Flex
        direction={["column", "column", "row"]}
        justify="space-between"
        align="center"
      >
        <Container flex="1" flexBasis="1/2" maxW="400px">
          <Stack align={["center", "center", "flex-start"]} spacing="lg">
            <Heading
              as="h2"
              size="h2"
              variant={isMobile ? "center" : undefined}
            >
              What&apos;s your story?
            </Heading>
            <Text fontSize="lg">
              Celebrate your loved one&apos;s special occasion with a Quilted
              story. With templates for all sorts of special events, plus full
              customizability, you and your friends can create a meaningful gift
              that will last a lifetime.
            </Text>
            <Stack direction={["column", "column", "row"]} align="center">
              <Button
                rounded="md"
                onClick={() => {
                  track(`Clicked "Get started" on EmptyStories page`);
                  navigate("/start/new");
                }}
              >
                Get started
              </Button>
              {/* <Text>or</Text> */}
              {/* TODO Add "How it works" section */}
              {/* <Button rounded="md" variant="link" textDecor="underline">
                Learn How Quilted Works
              </Button> */}
            </Stack>
          </Stack>
        </Container>
        <Box flex="1" flexGrow={2}>
          <Image src={CoupleHero} />
        </Box>
      </Flex>
    </Flex>
  );
};

export default EmptyStories;
