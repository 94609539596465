import React from "react";
import firebase from "firebase";
import {
  Badge,
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  Text,
  useBreakpointValue,
  useDisclosure,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { useStoryContext } from "../StoryProvider";
import { useFirebase, useFirestore } from "react-redux-firebase";

import { ClipCircleBase } from "../../diyVideo/ClipCircle";


const SubmissionCustomizer = ({
  storyId,
  prompts,
}: {
  storyId: string;
  prompts: any[];
}) => {
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalSize = useBreakpointValue({ base: 'full', md: 'md', lg: 'lg' })
  const { submissions } = useStoryContext();

  const toggleSubmissionIncluded = (submission:{id:string, include:boolean}) => {
    const setSubmissionIncluded = firebase.functions().httpsCallable("setSubmissionIncluded");
    return setSubmissionIncluded(submission)
  }

  const getSubmissions = (promptIndex:number) => {
    return submissions
          .filter(
            // @ts-ignore
            (submission) => submission.promptIndex === promptIndex
          )
          .map((submission) => {
            return {
              id: submission.id,
              storyId: submission.storyId,
              submittedBy: submission.submittedBy,
              content: submission.content,
              include: (submission as any).include || (submission as any).include === undefined
            };
          });
  };

  return (
    <Box>
      <Button
        size="sm"
        variant="outline"
        color="black"
        disabled={isOpen}
        onClick={onOpen}
      >
        Select videos to exclude
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} size={modalSize} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              direction="column"
              bg="springWood.200"
              shadow="lg"
              minW="250px"
              flex={1}
              alignSelf="stretch"
              css={{ transition: "all 2s ease" }}
              key="promptsColumn"
              maxH="calc(100vh - 180px)"
              overflow="scroll"
            >
              <VStack
                m="1"
                divider={<StackDivider borderColor="gray.200" />}
                spacing={4}
                align="stretch"
              >
                {prompts &&
                  prompts.map((prompt, idx) => (
                    <Stack
                      key={idx}
                      p="sm"
                      m="xs"
                      rounded="lg"
                      borderColor="seaGreen.500"
                    >
                      <Box>
                        <Badge
                          textTransform="none"
                          fontWeight="normal"
                          fontSize="xs"
                        >
                          Prompt {prompt.idx + 1} of {prompts.length}
                        </Badge>
                      </Box>
                      <Text fontWeight="bold" fontSize="md">
                        {prompt.text}
                      </Text>
                      <Flex
                        dir="row"
                        flexWrap="wrap"
                        display="inline-flex"
                        gap="10px"
                        css={{ gap: "8px" }}
                      >
                        {getSubmissions(prompt.idx).map((submission,i) => (
                        <ClipCircleBase
                        key={submission.id+i}
                        type="submission"
                        state="idle"
                        data={submission}
                        onToggleEvent={
                          () => {
                            submission.include = !submission.include
                            toggleSubmissionIncluded({id:submission.id, include:submission.include});
                          }
                        }
                        onDoneEvent={
                          ()=>{console.log('Done',submission)}
                        }
                         />
                        ))}
                      </Flex>
                    </Stack>
                  ))}
              </VStack>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SubmissionCustomizer;
