import React, {FC} from 'react';
import { Box, Button, CircularProgress, useMultiStyleConfig } from '@chakra-ui/react';

type RecordButtonProps = {
    onClick: () => void,
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
    variant?: string,
    progress?: number
}
/* eslint-disable react/prop-types */
const RecordButton:FC<RecordButtonProps> = ({ variant, size, progress, children, ...rest }) => {
  const styles = useMultiStyleConfig('RecordButton', { size, variant });
  return (<Box sx={styles.box} {...rest} data-progress={progress}>
    <Box as="span" sx={styles.meter}><CircularProgress value={progress || 0} size="100%" thickness="4px" /></Box>
    <Button name="RecordButton" sx={styles.button}>{
      children
    }</Button>
  </Box>)
}


export default RecordButton;