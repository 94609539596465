import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Text, FormField, ResponsiveContext } from "grommet";
import * as gromicons from "grommet-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isMobile } from "react-device-detect";
import moment from "moment";
import {
  Box as ChakraBox,
  Flex,
  Input,
  Text as ChakraText,
  Alert,
  AlertDescription,
  Stack,
  forwardRef,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function datetimeToLocal(date, includeTime) {
  const m = moment(date);
  const format = includeTime
    ? m.format("YYYY-MM-DDTHH:mm")
    : m.format("YYYY-MM-DD");
  return format;
}

const ErrorMessage = ({ error }) => {
  return error ? (
    <Box margin="none" pad="none">
      <Text size="xsmall" color="status-error">
        {error}
      </Text>
    </Box>
  ) : (
    <>{error}</>
  );
};

const ChakraErrorMessage = ({ error }) => {
  return error ? (
    <Alert status="error" rounded="md">
      <AlertDescription>{error}</AlertDescription>
    </Alert>
  ) : (
    <>{error}</>
  );
};

const CalendarInput = React.forwardRef(
  ({ value, onClick, label, error }, ref) => {
    if (label)
      return (
        <FormField
          label={<Text size="small">{label}</Text>}
          error={error || ""}
        >
          <Box
            onClick={onClick}
            align="center"
            justify="between"
            direction="row"
            pad="small"
          >
            <Text size="small">{value}</Text>
            <gromicons.Down size="small" />
          </Box>
        </FormField>
      );
    return (
      <>
        <Box
          onClick={onClick}
          round="xsmall"
          align="center"
          justify="between"
          elevation="small"
          direction="row"
          pad="small"
          background="neutral-6"
        >
          <Text size="small">{value}</Text>
          <gromicons.Down size="xsmall" />
        </Box>
        <ErrorMessage error={error} />
      </>
    );
  }
);

// Use Chakra's exported forwardRef
const ChakraCalendarInput = forwardRef(
  ({ value, onClick, label, error }, ref) => {
    if (label)
      return (
        <Flex
          onClick={onClick}
          align="center"
          justify="between"
          direction="row"
          p="sm"
        >
          <ChakraText fontSize="sm">{value}</ChakraText>
          <FontAwesomeIcon icon="angle-down" />
        </Flex>
      );

    return (
      <Stack>
        <InputGroup>
          <Input onClick={onClick} value={value} isReadOnly />
          <InputRightElement>
            <FontAwesomeIcon icon="calendar-alt" />
          </InputRightElement>
        </InputGroup>
      </Stack>
    );
  }
);

const DropCalendarMobile = ({
  onChange,
  selected = new Date(),
  limitTo,
  showTimeSelect,
  ...rest
}) => {
  console.log("use DropCalendarMobile");
  var value = datetimeToLocal(selected, showTimeSelect);
  let props = { ...rest, value };

  if (limitTo && limitTo.minDate)
    props = { ...props, min: datetimeToLocal(limitTo.minDate, showTimeSelect) };
  if (limitTo && limitTo.maxDate)
    props = { ...props, max: datetimeToLocal(limitTo.maxDate, showTimeSelect) };

  const [newDate, setNewDate] = React.useState("not-set");

  const handleChange = (e) => {
    console.log("Mobile DropCalendar changed", moment(e.target.value).format());
    onChange(moment(e.target.value).toDate());
    setNewDate(datetimeToLocal(new Date(e.target.value), showTimeSelect));
  };

  return (
    <Input
      onChange={handleChange}
      type={showTimeSelect ? "datetime-local" : "date"}
      {...props}
      dataVal={String(newDate)}
    />
  );
};

const DropCalendar = (props) => {
  const size = React.useContext(ResponsiveContext);

  const validate = (date) => {
    let validated = undefined;
    if (props.limitTo) {
      const min = props.limitTo.minDate && props.limitTo.minDate;
      const max = props.limitTo.maxDate && props.limitTo.maxDate;
      if (!max && min && moment(date).isBefore(min))
        validated = `Date must be after ${moment(min).format("lll")}`;
      else if (!min && max && moment(date).isAfter(max))
        validated = `Date must be before ${moment(max).format("lll")}`;
      else if (
        min &&
        max &&
        !moment(date).isBetween(
          moment(min).subtract(1, "minute"),
          moment(max).add(1, "minute"),
          "minutes"
        )
      )
        validated = `Date must be between ${moment(min).format(
          "lll"
        )} and  ${moment(max).format("lll")}`;
    }
    return validated;
  };

  const [selected, setSelected] = React.useState(
    props.startDate || props.value
  );
  const [error, setError] = React.useState(validate(selected));

  React.useEffect(() => {
    const er = validate(selected);
    setError(er);
  }, [selected]);

  const handleChange = (date) => {
    setSelected(date);
    props.onChange(date);
  };

  useEffect(() => {}, [selected]);

  const dateFormat = `MMMM d, yyyy${props.showTimeSelect ? "  h:mm aa" : ""}`;

  return isMobile || size === "small" ? (
    <DropCalendarMobile
      onChange={handleChange}
      selected={selected}
      limitTo={props.limitTo}
      showTimeSelect={props.showTimeSelect}
      error={error}
    />
  ) : (
    <>
      <DatePicker
        selected={selected}
        onChange={handleChange}
        customInput={
          props.useChakraInput ? (
            <ChakraCalendarInput label={props.label} error={error} />
          ) : (
            <CalendarInput label={props.label} error={error} />
          )
        }
        dateFormat={dateFormat}
        showTimeSelect={props.showTimeSelect || false}
        selectsEnd={props.limitTo ? true : false}
        minDate={props.limitTo && props.limitTo.minDate}
        maxDate={props.limitTo && props.limitTo.maxDate}
      />
      {props.useChakraInput && (
        <ChakraBox>
          <ChakraErrorMessage error={error} />
        </ChakraBox>
      )}
    </>
  );
};

DropCalendar.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  /* eslint-enable react/no-unused-prop-types */
  showTimeSelect: PropTypes.bool,
  limitTo: PropTypes.shape({
    minDate: PropTypes.instanceOf(Date).isRequired,
    maxDate: PropTypes.instanceOf(Date).isRequired,
  }),
  useChakraInput: PropTypes.bool
};

export default DropCalendar;
