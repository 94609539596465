const initState = { authError:null, newUser: { firstName: undefined, lastName: undefined, email: undefined, phone: undefined, password: undefined }};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_SIGNUP_INFO':
      return {
        ...state,
        newUser: action.newUser,
        authError: null
      };
    case 'SIGNUP_SUCCESS':
      console.log('SIGNUP_SUCCESS')
      return {
        ...state,
        signedUp: state.newUser.email,
        newUser: { ...initState.newUser },
        authError: null
      };
    case 'SIGNUP_ERROR':
      return {
        ...state,
        authError: 'Signup failed: ' + action.err.message
      };
    case 'SIGNIN_ERROR':
      return {
        ...state,
        authError: 'Login failed: ' + action.err.message
      };
    case 'SIGNIN_SUCCESS':
      return {
        ...state,
        user: action.user,
        authError: null
      };
    case 'SIGNOUT_SUCCESS':
      return {
        ...state
      };
    case 'PASSWORD_RESET_SENT':
      return {
          ...state,
          resetPasswordSent: action.user || true
      }
    case 'PASSWORD_RESET_ERROR':
        return {
            ...state,
            resetPasswordError: 'Password reset failed: ' + action.error.message
        }
    case 'PASSWORD_RESET_CODE_VALID':
        return {
            ...state,
            resetPasswordComplete: undefined,
            resetPasswordFor: action.email,
            resetPasswordCodeValid: true
        }
    case 'PASSWORD_RESET_CODE_INVALID':

        return {
            ...state,
            resetPasswordComplete: undefined,
            resetPasswordCodeValid: false,
            resetPasswordError: 'Password reset failed: ' + action.error.message
        }
    case 'PASSWORD_RESET_SUCCESS':
      return {
          ...state,
          resetPasswordComplete: true,
          resetPasswordError: undefined
      }
    default:
      return state;
  }
}

export default authReducer;