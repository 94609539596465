const RequestAnimationFrame =
		window.requestAnimationFrame ||
		window.msRequestAnimationFrame ||
		window.mozRequestAnimationFrame ||
		window.webkitRequestAnimationFrame;
const CancelAnimationFrame =
		window.cancelAnimationFrame ||
		window.msCancelAnimationFrame ||
		window.mozCancelAnimationFrame ||
		window.webkitCancelAnimationFrame;

export default { request: RequestAnimationFrame.bind(window), cancel: CancelAnimationFrame.bind(window)};