import React from "react";
import {
  Button,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Heading,
  Flex,
  ModalCloseButton,
  Box,
  ModalFooter,
  ModalProps,
  ButtonProps,
  ModalContentProps,
  ModalOverlayProps,
  ModalFooterProps,
  HeadingProps,
  useBreakpointValue,
  ModalHeader
} from "@chakra-ui/react";
import { use100vh } from "react-div-100vh";

export const ModalDialog = (props: ModalDialogProps) => {
  const {
    children,
    isOpen,
    onClose,
    heading,
    modalOverlay,
    modalContent,
    modalHeading,
    hideCloseButton,
    ...modalProps
  } = props;
  const minHeight = use100vh();

  const defaultHPadding = ["2rem", "2.5rem", "4rem"];
  const defaultBPadding = ["1rem", "1.5rem", "2rem"];
  const defaultTPadding = 0;

  const size = useBreakpointValue({ base: 'full', md: 'xl', lg: '4xl' })

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} size={size} {...modalProps}>
      <ModalOverlay {...modalOverlay} />
      <ModalContent
        borderRadius={["0px", "16px"]}
        bgColor="powder"
        mt={["0", "auto"]}
        mb={["0", "auto"]}
        pr={defaultHPadding}
        pl={defaultHPadding}
        pt={defaultTPadding}
        pb={defaultBPadding}
        {...modalContent}
      >
        {!hideCloseButton && (<><ModalCloseButton mb={2} /><ModalHeader>&nbsp;</ModalHeader></>)}
        <ModalBody
        mt="0"
        mb="0"
        p={0}>
        {heading && (
              <Heading
                variant="center"
                as="h2"
                size="h2"
                mb="3rem"
                {...modalHeading}
              >
                {heading}
              </Heading>
            )}
          {children}
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};

export const ModalActions = (props: ModalActionsProps) => {
  const { primaryButton, secondaryButton, ...buttonContainer } = props;
  return (
    <ModalFooter p="0" mt="2rem" {...buttonContainer}>
      <Flex direction="row" justify="space-between" w="full">
        {secondaryButton && (
          <Button variant="plain" color="black" {...secondaryButton}>
            <span>{secondaryButton.label}</span>
          </Button>
        )}
        {primaryButton && (
          <Button ml="auto" {...primaryButton}>
            <span>{primaryButton.label}</span>
          </Button>
        )}
      </Flex>
    </ModalFooter>
  );
};

export default ModalDialog;

interface ModalDialogProps extends ModalProps {
  heading?: string;
  children: React.ReactNode;
  modalContent?: ModalContentProps;
  modalHeading?: HeadingProps;
  modalOverlay?: ModalOverlayProps;
  hideCloseButton?: boolean;
}

interface ModalActionsProps extends ModalFooterProps {
  primaryButton?: ModalButton;
  secondaryButton?: ModalButton;
}

interface ModalButton extends ButtonProps {
  label: string;
}
