import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Heading, Paragraph, Text } from "grommet";
import PageContainer from "../layout/components/PageContainer";
import { useStoryContext } from "../components/story/StoryProvider";
import StoryCountdown from "../components/common/StoryCountdown";
import { timestampToDate, timestampToMoment } from "../utils/convert";
import { Helmet } from "react-helmet";

const NoSubmissionsPage = (props) => {
  const { story } = useStoryContext();
  const navigate = useNavigate();

  const onBack = () => {
    navigate(-1);
  };

  return (
    <PageContainer>
      <Helmet>
        <title>Quilted | No Submissions</title>
      </Helmet>
      <Heading textAlign="center">
        Uh Oh! There are no video submissions yet!
      </Heading>
      <Box
        direction="column"
        fill="horizontal"
        align="stretch"
        justify="start"
        flex={{ shrink: 0 }}
        alignSelf="center"
      >
        <Box
          round="small"
          background="dark-1"
          align="stretch"
          justify="center"
          pad={{ vertical: "xsmall", horizontal: "small" }}
          margin={{ bottom: "medium" }}
          flex={{ grow: 1 }}
        >
          <Box
            align="stretch"
            justify="center"
            pad={{ bottom: "xsmall" }}
            direction="row"
            fill="horizontal"
          >
            <Paragraph size="small" margin={{ bottom: "xsmall" }}>
              <Text size="small" weight={600}>
                Quilted Movie Due Date:{" "}
                {timestampToMoment(story.dueDate).format("MM/DD/YYYY")}
              </Text>
            </Paragraph>
          </Box>
          <div>
            <Box
              align="stretch"
              justify="center"
              pad={{ bottom: "xsmall" }}
              direction="row"
              fill="horizontal"
            >
              <Box pad="none" align="center" justify="center">
                <StoryCountdown dueDate={timestampToDate(story.dueDate)} />
              </Box>
            </Box>
            <Box
              round="small"
              background="light-1"
              align="center"
              justify="center"
              pad={{ vertical: "medium", horizontal: "medium" }}
              margin={{ bottom: "medium" }}
              flex={{ grow: 1 }}
            >
              <Box
                align="stretch"
                justify="center"
                pad="none"
                direction="row"
                fill="horizontal"
                flex={{ shrink: 0 }}
              >
                <Box
                  pad={{ top: "small", bottom: "small" }}
                  align="center"
                  justify="center"
                >
                  <Paragraph size="small" margin="small" textAlign="center">
                    Your Quilted project does not yet have any video
                    submissions.{" "}
                    <Text weight="bold" size="small">
                      Once you have video submissions you can get started on
                      your final Quilted movie.
                    </Text>
                  </Paragraph>
                  <Paragraph size="small" margin="small" textAlign="center">
                    However, we recommend waiting until after the due date to
                    start your final video in order to give people enough time
                    to submit videos.
                  </Paragraph>
                </Box>
              </Box>
              <Box
                align="stretch"
                justify="center"
                pad="none"
                direction="row"
                fill="horizontal"
                flex={{ shrink: 0 }}
              >
                <Box
                  pad={{ top: "small", bottom: "small" }}
                  align="center"
                  justify="center"
                  gap="medium"
                >
                  <Button
                    primary
                    label={<Text color="white">Back to My Project Page</Text>}
                    onClick={onBack}
                  />
                </Box>
              </Box>
            </Box>
          </div>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default NoSubmissionsPage;
