export default function pageViewCounterPlugin(userConfig = {}) {
  return {
    name: userConfig.name || "pageViewCounter",
    config: Object.assign({ enabled: true }, userConfig),
    initializeStart: ({ abort, config }) => {
      console.log("pageViewCounterPlugin.initializeStart", config);
    },
    initialize: async ({ config }) => {
      const { counter, countForRoutes } = config;
    },
    page: ({ payload, config }) => {
      console.group('Analytics')
      const when = new Date();
      const { counter, countForRoutes } = config;
      const { properties } = payload;
      const path = properties.path || document.location.pathname;
      console.log(path, countForRoutes)
      if (counter && countForRoutes.includes(path)) {
        counter.incrementBy(
          1,
          `${path}.${when.getFullYear().toString()}.${when
            .getMonth()
            .toString()}.${when.getDate().toString()}`
        );
        console.log("counted", path);
      }
      console.groupEnd();
    }
  };
}
