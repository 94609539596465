
const initState = {
    playlist: null,
    index: 0
}

const videoPlayerReducer = (state = initState, action) => {
    //console.log(action, state);
    switch (action.type) {
      case 'SET_PLAYLIST': {
        state.index = 0;
        state.playlist = action.playlist; //translate paths from storage.
        return { ...state };
      }
      case 'SET_PLAYING_INDEX': {
        state.index = action.index;
        return { ...state };
      }
      default:
          return state;
    }
}

export default videoPlayerReducer;