import React from "react";
import ModalDialog from "../common/ModalDialog";
import { Heading, useBreakpointValue, ModalProps } from "@chakra-ui/react";
import EmptyEditorState from "./EmptyEditorState";

const HelpModal = (props: HelpModalProps) => {
  const scrollBehavior = useBreakpointValue({ base: 'inside', lg: 'outside' })
  const containerHeight = useBreakpointValue({base:'1138px', md: 'auto'})
  const modalProps = { scrollBehavior, ...props } as ModalProps;
  return (
    <ModalDialog {...modalProps } heading="Help with DIY video" modalHeading={{fontSize:"lg"}}>
      <div style={{height:containerHeight}}>
      <EmptyEditorState hideTitle={true} />
      </div>
    </ModalDialog>
  );
};

export default HelpModal;

interface HelpModalProps {
  isOpen: boolean;
  onClose: () => void;
}
