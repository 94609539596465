import React, { useState, useCallback } from 'react'
import styled, {css} from 'styled-components'
import Cropper from 'react-easy-crop'
import { Box, Button, RangeInput, Text } from 'grommet'
import {FiSave} from "react-icons/fi";
import { isIOS } from 'react-device-detect'
import { getCroppedImg, getImageDataUrlFromUpload } from './canvasUtils'
import Running from '../common/RunningIndicator';

const getSize = (props, size) => props.theme.global.size[size] || size;

const heightObjectStyle = css`
  ${props =>
    props.height.max &&
    css`
      max-height: ${getSize(props, props.height.max)};
    `};
  ${props =>
    props.height.min &&
    css`
      min-height: ${getSize(props, props.height.min)};
    `};
`;

const heightStyle = css`
  height: ${props => getSize(props, props.height)};
`;

const CropContainer = styled.div`
    position: relative;
    width: 100%;
    height: 200px;
    background: #333;
    ${props =>
        props.height &&
        (typeof props.height === 'object' ? heightObjectStyle : heightStyle)};
`


const ImageCropper = ({file, onComplete}) => {

    const [imageSrc, setImageSrc] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [_file, setFile] = useState(file)
    const [error, setError] = useState(null)
    
    React.useEffect(() => {
        /* 
            Feature NOT supported in iOS. 
            Return original file.
            Future plans to implement https://github.com/nodeca/image-blob-reduce
        */
        if(isIOS) { onComplete(file); return () => {
            console.log('iOS not supported for image cropping.')
        } }

        getImageDataUrlFromUpload(file)
        .then(imageDataUrl => setImageSrc(imageDataUrl))
        .catch(err => setError(err))
    },[file])

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
      }, [])

    const saveFile = useCallback(
        (blob) => {
            try
            {
                const { name, lastModified } = _file
                blob.name = name;
                blob.lastModified = lastModified;
                setFile(blob)
                onComplete && onComplete(blob);
            }
            catch(err) {
                setError(err)
            }
        },
        [_file]
    )

    const onSave = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
                rotation
            )
            saveFile(croppedImage)
            onClose();
        } catch (e) {
            console.error(e)
        }
    }, [imageSrc, croppedAreaPixels, rotation])

    const onClose = useCallback(() => {
        setCroppedImage(null)
    }, [])

    return (
        <Box>
            {!imageSrc && (<Running />)}
            {!isIOS && imageSrc && (<>
            <CropContainer height={{max:"medium"}}><Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            /></CropContainer>
            <Box direction="row" align="center" justify="evenly" background="white" pad="medium" gap="medium">
                <Box direction="column" gap="small">
                    <Text>Zoom</Text>
                    <RangeInput
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        onChange={event => setZoom(event.target.value)}
                        />
                </Box>
                <Box direction="column" gap="small">
                    <Text>Rotation</Text>
                    <RangeInput
                        value={rotation}
                        min={0}
                        max={360}
                        step={10}
                        onChange={event => setRotation(event.target.value)}
                        />
                </Box>
                <Button plain={false} primary={true} color="white" icon={<FiSave size="1.25em" color="dark-1" />} margin="xsmall" onClick={onSave} />
            </Box>
            </>)}
        </Box>
    )
}

export default ImageCropper
