import React from "react";
import InitialCardView from "./InitialCardView";
import StepCard from "../StepCard";

import CardImage from "../../../assets/create-final-movie.svg";
import ContinueCardView from "./ContinueCardView";
import ProcessingCardView from "./ProcessingCardView";
import ReviewCardView from "./ReviewCardView";

export type finalMovieState =
  | "notStarted"
  | "started"
  | "processing"
  | "review"
  | "complete";

type cardProps = {
  colorScheme: string;
  subject: string;
  state: finalMovieState;
  isConcierge: boolean;
  isFinal: boolean;
  disabled: boolean;
  forceConcierge?: true;
  onClick: () => void;
};

const FinalMovieCard = ({
  colorScheme,
  subject,
  state,
  isConcierge,
  isFinal,
  disabled,
  forceConcierge,
  onClick,
}: cardProps) => {
  return (
    <StepCard image={CardImage} colorScheme={colorScheme}>
      {
        state === "notStarted" ? (
          <InitialCardView
            colorScheme={colorScheme}
            disabled={disabled}
            onClick={onClick}
            forceConcierge={forceConcierge}
          />
        ) : state === "started" ? (
          <ContinueCardView
            colorScheme={colorScheme}
            subject={subject}
            onClick={onClick}
          />
        ) : state === "processing" ? (
          <ProcessingCardView
            colorScheme={colorScheme}
            isConcierge={isConcierge}
            isFinal={isConcierge || isFinal}
          />
        ) : state === "review" ? (
          <ReviewCardView
            colorScheme={colorScheme}
            subject={subject}
            onClick={onClick}
          />
        ) : (
          "complete..."
        ) //this state should show something else on dashboard.
      }
    </StepCard>
  );
};

export default FinalMovieCard;
