import React from 'react';
import Product from './Product';
import { useShopify } from './ShopifyProvider';
import {Flex,Spacer} from '@chakra-ui/react'

const Products = (props) => {

  const [shopify, dispatch] = useShopify();

  const addVariantToCart = (variantId, quantity) => {
    dispatch({
      type: 'addToCart',
      payload: { variantId, quantity }
    });
  }

  const removeLineItemInCart = (lineItemId) => {
    dispatch({
      type: "removeLineItem",
      payload: { lineItemId },
    });
  }

  const render = () => {
    let products = shopify.products.map((product) => {
      return (
        <Product
          addVariantToCart={addVariantToCart}
          removeLineItemInCart={removeLineItemInCart}
          client={shopify.client}
          checkout={shopify.checkout}
          key={product.id.toString()}
          product={product}
        />
      );
    });

    return (<Flex direction={["column","row"]}>
        {shopify.products && products}
    </Flex>
    );
  }

  return render();
}

export default Products;
