import React from 'react';
import PageContainer from '../../layout/components/PageContainer';
import {FinalVideoCardAdmin} from './finalVideo/FinalVideoCard'
import { useStoryContext } from './StoryProvider';

const FinalVideoView = () => {
    const {story} = useStoryContext();
    return <PageContainer w="90vw"><FinalVideoCardAdmin story={story} /></PageContainer>
}

export default FinalVideoView
