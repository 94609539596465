import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { Box, Heading } from "@chakra-ui/react";

import LoadStories from "../components/story/LoadStories";
import { Helmet } from "react-helmet";
import PageContainer from "../layout/components/PageContainer";

const MyStoriesPage = (props) => {
  const { auth, profile } = props;

  if (!isLoaded(auth) || !isLoaded(profile)) {
    return <Heading>Loading...</Heading>;
  }

  return (
    <PageContainer minW={undefined} maxW="90vw" w="3xl">
      <Helmet>
        <title>Quilted | My Stories</title>
      </Helmet>
      <Box
        my="lg"
        h="full"
        w="full"
      >
      <LoadStories auth={auth} profile={profile}></LoadStories>
      </Box>
    </PageContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default compose(connect(mapStateToProps))(MyStoriesPage);
