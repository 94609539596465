import React, { useEffect } from "react";
import {
  Flex,
  Container,
  Stack,
  Heading,
  Box,
  Button,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
// @ts-ignore
import { useAnalytics } from "use-analytics";

const ReviewMoviePreview = (props: ReviewMoviePreviewProps) => {
  const { existingProjects, completedProjects, setProject } = props;
  const { id: storyId } = useParams() as any;
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const videoSize = useBreakpointValue({
    base: { width: "100%", height: "auto" },
    lg: {},
  });

  useEffect(() => {
    track("finalVideo | Shown Preview Review Screen", {
      storyId,
    });
  }, []);

  return (
    <Flex
      direction="column"
      align="center"
      textAlign="center"
      p="md"
      h="100%"
      justify="center"
    >
      <Flex
        direction={["column", "column", "column", "row"]}
        justify="space-around"
        minH="200px"
        maxH="100%"
        mt="lg"
      >
        <Flex
          direction="column"
          flexBasis="2/3"
          flex="1"
          p="lg"
          pl="0"
          alignItems="center"
          justify="center"
          // maxH="100%"
          // overflow="scroll"
        >
          <Heading as="h2" size="h2" fontSize="2xl" variant="center" mb="lg">
            Your movie is ready to review!
          </Heading>
          <Container mb="md">
            <Text>
              This is a low-quality preview so you can make sure your movie is
              perfect. Once you have it just how you want it, checkout to
              generate a full-quality movie you can gift and share with loved
              ones.
            </Text>
          </Container>
          <Flex
            p="md"
            bgColor="springWood.600"
            rounded="md"
            shadow="lg"
            direction="row"
            align="center"
            justify="space-between"
          >
            <ReactPlayer
              url={completedProjects[0].streamUrl}
              controls
              {...videoSize}
            />
          </Flex>
        </Flex>
        <Box w="1" borderLeft=".5px dotted" h="100%" />
        <Stack
          px="lg"
          alignItems="flex-start"
          justifyContent="center"
          flexBasis="1/3"
          flex="1"
          maxW="400px"
          spacing="md"
        >
          <Stack
            rounded="md"
            bgColor="seaGreen.50"
            border="1px solid"
            borderColor="seaGreen.600"
            p="md"
            direction="column"
            align="flex-start"
          >
            <Stack align="flex-start">
              <Heading as="h4" size="h4">
                Like what you see?
              </Heading>
              <Text textAlign="left">
                If you're happy with your video, you can checkout and we'll send
                you a full-quality copy of your movie to download and share!
              </Text>
            </Stack>
            <Button
              variant="outline"
              colorScheme="seaGreen"
              rounded="md"
              onClick={() => {
                track("finalVideo | Started DIY checkout", {
                  storyId,
                });
                navigate(
                  `../final-video/diy/${completedProjects[0].id}`
                );
              }}
            >
              Checkout
            </Button>
          </Stack>
          <Stack p="sm" align="flex-start">
            <Stack align="flex-start">
              <Heading as="h5" size="h5" fontSize="md">
                Need to make some changes?
              </Heading>
              <Text textAlign="left">
                If you want to make a few changes, you can either continue
                editing this movie or start over. This version will still be
                available if you change your mind.
              </Text>
            </Stack>
            <Flex align="center" justify="flex-start">
              <Button
                variant="outline"
                colorScheme="majesty"
                rounded="md"
                onClick={() => {
                  track("finalVideo | Start DIY revisions", {
                    storyId,
                  });
                  setProject({
                    ...completedProjects[0],
                    id: null,
                  });
                }}
              >
                Continue editing
              </Button>
              <Button
                variant="link"
                colorScheme="majesty"
                ml="md"
                onClick={() => {
                  track("finalVideo | DIY Video - Start over", { storyId });
                  setProject(null);
                }}
                _hover={{ color: "puce.700" }}
              >
                or start over
              </Button>
            </Flex>
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  );
};

export default ReviewMoviePreview;

interface ReviewMoviePreviewProps {
  existingProjects: any;
  completedProjects?: any;
  setProject: (x: any) => any;
}
