import React from "react";
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import InviteLinkBox from "../../invites/InviteLinkBox";

interface ShowLinkViewProps {
  colorScheme: string;
  slug: string;
  onClick: () => void;
}
export const ShowLinkView: React.FC<ShowLinkViewProps> = ({
  colorScheme,
  slug,
  onClick,
}: ShowLinkViewProps) => {
  return (
    <Box>
      <Heading as="h4" size="md" mb={3}>
        Invite storytellers
      </Heading>
      <Text fontWeight="bold" mb={3}>
        Your invite link
      </Text>
      <Text mb={3}>
        You can invite someone at anytime during the process by copying and
        sending them the link above by email, text message, or social media
        platform.
      </Text>
      <InviteLinkBox slug={slug} hideDisclaimer hideLabel mb={3} />
      <Button colorScheme={colorScheme} onClick={onClick}>
        Continue
      </Button>
    </Box>
  );
};

export default ShowLinkView;
