import React from "react";
import {
  Box,
  Stack,
  Container,
  Flex,
  Heading,
  Text,
  Divider,
  Tooltip,
  Checkbox,
  useToast,
} from "@chakra-ui/react";
import DropCalendar from "../../../layout/DropCalendar";
import { useStoryManager } from "../../StoryManager";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { timestampToDate } from "../../../../utils/convert";
import config from "../../../../config/config";
import { useEffect } from "react";
import { useAnalytics } from "use-analytics";

const minStartDate = () => {
  const min = 30;
  const start = moment().add(1, "hour").startOf("hour").toDate();
  const now = moment();
  const diff = moment(start).diff(now, "minutes");
  if (diff < min) return moment(start).add(min, "minutes").toDate();
  return start;
};

const defaultDueDate = (() => {
  return moment(minStartDate()).add(48, "hours").toDate();
})();
const newDueDiff = (dueDate) => {
  const now = moment();
  const diff = moment(dueDate).diff(now, "days");
  return diff;
};

const DueDateBlock = () => {

  const id = "review";
  const [context, dispatch] = useStoryManager();
  const {
    state: { story },
  } = context;
  const toast = useToast();
  
  const isLegacy = story.template.type === 0;

  const { track } = useAnalytics();

  const [reminders, setReminders] = React.useState(
    (story && story.reminders) || {
      weekly: isLegacy,
      midway: true,
      dayBefore: !isLegacy,
      weekBefore: isLegacy,
      dueDate: !isLegacy,
      dayAfter: true,
    }
  );

  const [dueDate, setDueDate] = React.useState({
    value:
      (story && story.dueDate && timestampToDate(story.dueDate)) ||
      defaultDueDate,
  });

  const saveDueDate = (change, isSilent) => {
    dispatch({
      type: "update",
      payload: {
        story: { ...story, dueDate: change.value, step: id },
        changed: true,
        valid: { [id]: change.valid },
      },
      callback: () => {
        !isSilent
          ? toast({
              title: "Due date updated",
              status: "success",
              duration: 4000,
              isClosable: true,
            })
          : null;
      },
    });
  };

  const saveReminders = (change) => {
    dispatch({
      type: "update",
      payload: {
        story: {
          ...story,
          reminders: { ...change },
        },
        changed: true,
        valid: { [id]: true },
      },
    });
  }

  const handleDueDateChange = (value, isSilent = false) => {
    const isDateValid = (date) => {
      const _valid = !!(date && moment(date).isAfter());
      return _valid;
    };
    const editedDate = { value, valid: { isValid: isDateValid(value) } };
    track("setup_dueDateChanged", { newDueDateDiff: newDueDiff(value) });
    setDueDate(editedDate);
    saveDueDate(editedDate, isSilent);
  };

  useEffect(() => {
    if (story && !story.dueDate) handleDueDateChange(defaultDueDate, true);
  }, [story?.dueDate]);

  useEffect(() => {
    console.log('Should Save Reminders?', story?.reminders, reminders);
    if (story && !story.reminders) saveReminders(reminders);
  }, [])

  const onChangeReminder = ({ target: { name, checked } }) => {
    const change = { ...reminders, [name]: checked };
    setReminders(change);
    saveReminders(change);
  };

  const ReminderCheckbox = ({ tooltip, label, name }) => {
    return (
      <Checkbox
        id={name}
        name={name}
        onChange={onChangeReminder}
        isChecked={reminders[name]}
      >
        <Tooltip label={tooltip} fontSize="md">
          <Text fontWeight="medium" fontSize="sm">
            {label}
          </Text>
        </Tooltip>
      </Checkbox>
    );
  };

  return (
    <Stack justify="center" py="sm" spacing={8}>
      <Container textAlign="center">
        <Stack>
          <Heading as="h3" size="h3">
            Select a due date
          </Heading>
          <Text>
            When would you like people to complete their submissions by? This
            will be shown to your storytellers and be used to set reminders.
          </Text>
        </Stack>
      </Container>
      <Flex
        pad="none"
        align="flex-start"
        justify="center"
        direction={["column", "column", "row"]}
        w="full"
      >
        <Stack flex={1} flexBasis="1/2">
          <Text fontWeight="bold">All videos should be completed by:</Text>
          <DropCalendar
            useChakraInput
            startDate={timestampToDate(dueDate.value)}
            onChange={handleDueDateChange}
            showTimeSelect={true}
            limitTo={{
              minDate: moment().add(48, "hours").toDate(),
              maxDate: moment().add(48, "hours").add(1, "year").toDate(),
            }}
          />
          <Text>
            <em>
              To allow enough time to put together your finished movie, your due
              date should be at least {config.app.finalVideoMinDays} business
              days before you want the finished movie.
            </em>
          </Text>
        </Stack>
        {isMobile ? (
          <Divider my="lg" borderColor="gray.300" />
        ) : (
          <Box
            w="1px"
            borderLeft="1px"
            borderStyle="dotted"
            alignSelf="stretch"
            mx="lg"
            borderColor="gray.400"
          />
        )}
        <Stack flex={1} flexBasis="1/2">
          <Text fontWeight="bold">Reminders should be sent:</Text>
          <Text size="md">
            We will send automated reminders to people who have not responded.
            Select which you’d like us to send.
          </Text>
          <Stack spacing={0.5}>
            {isLegacy && (
              <ReminderCheckbox
                name="weekly"
                label="Weekly"
                tooltip="Send a reminder once a week during your Quilted Story"
              />
            )}
            <ReminderCheckbox
              name="midway"
              label="Midway"
              tooltip="Send a reminder midway through your Quilted Story"
            />
            {isLegacy && (
              <ReminderCheckbox
                name="weekBefore"
                label="1 week before"
                tooltip="Send a reminder the week before your Quilted Story deadline"
              />
            )}
            {!isLegacy && (
              <ReminderCheckbox
                name="dayBefore"
                label="Day before"
                tooltip="Send a reminder the day before your Quilted Story deadline"
              />
            )}
            {!isLegacy && (
              <ReminderCheckbox
                name="dueDate"
                label="Due date"
                tooltip="Send a reminder the day of your Quilted Story deadline"
              />
            )}
            <ReminderCheckbox
              name="dayAfter"
              label="Day after"
              tooltip="Send a reminder the day after your Quilted Story’s
                deadline"
            />
          </Stack>
        </Stack>
      </Flex>
    </Stack>
  );
};

export default DueDateBlock;
