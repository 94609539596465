import React from "react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  FormHelperText,
  Textarea,
  Button,
  Flex,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import { Story } from "../../../types/FinalVideoProps";
import { useFirestore } from "react-redux-firebase";
import useAuth from "../../../hooks/useAuth";

const FinalVideoRevisionRequestForm = ({story, onSubmitted, onCancel}:{story:Story, onSubmitted:()=>void, onCancel:()=>void}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm();
  const toast = useToast();

  const firestore = useFirestore();

  const onSubmit = handleSubmit((data) => {
    firestore.collection(`finalVideoRequests`).doc(story.id).update({ ...data, state: "rejected", revisionRequests: firestore.FieldValue.arrayUnion({ ...data, requestedOn:  new Date()}) }).then(() => {
      onSubmitted && onSubmitted();
    }).catch((e) => {
      toast({
        title: "Request failed!",
        description:
          "There was an error saving your video revisions! If the problem persists, please contact support.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    })
  });

  return (
    <form onSubmit={onSubmit}>
    <FormControl isInvalid={errors.revisions}>
        <FormLabel htmlFor="revisions">Please enter your revisions here</FormLabel>
        <Textarea 
          id="revisions"
          placeholder="Example: Remove video from 5:24 - 8:24"
          resize="vertical"
          {...register("revisions", { required: true })}
        />
        <FormErrorMessage>
          {errors.revisions && "Please enter your revisions, we will require these to update your Quilted Movie"}
        </FormErrorMessage>
        <FormHelperText>
          Please outline your revision requests in detail by referencing the time in the video for the indicated change.
        </FormHelperText>
        <FormHelperText>
          For example, "Remove video from 5:24 - 8:24"
        </FormHelperText>
        <FormHelperText>
          Revisions take 2-3 business days
        </FormHelperText>
      </FormControl>
      <Flex mt={4}>
      <Button type="reset" colorScheme="gray" variant="link" onClick={onCancel}>Back</Button>
      <Spacer/>
      <Button colorScheme="majesty" isLoading={isSubmitting} type="submit">
        Submit
      </Button>
      </Flex>
    </form>
  );
};

export default FinalVideoRevisionRequestForm;
