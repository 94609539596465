import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useMachine } from "@xstate/react";
import { useLocalStorage, useAuth } from "../hooks";
import { useAnalytics } from 'use-analytics';

import {
  Box,
  Heading,
  Text,
  Button,
  Center,
  Progress,
  Stack,
} from "@chakra-ui/react";
import { Lock } from "grommet-icons";

import { responseMachine } from "../machines/Response/responseMachine";
import StoryIntroView from "../components/response/Intro";
import PageContainer from "../layout/components/PageContainer";

import UserInfoForm from "../components/response/components/UserInfoForm";

import PromptsView from "../components/response/PromptsView";
import RespondedView from "../components/response/RespondedView";
import VideoRecorderView from "../components/video/recorder/VideoRecorderView";
import FinalVideoCard from "../components/story/finalVideo/FinalVideoCard";

import StoryCountdown from "../components/common/StoryCountdown";
import VideoQuestionOverlay from "../components/response/components/VideoQuestionOverlay";
import StoryNotFound from "../components/response/StoryNotFound";
import { timestampToDate } from "../utils/convert";

export const messages = {
  inSetup: "This Quilted Project is still being setup. Once the organizer has finished setup and launched the project you will be able to return to this web page to submit video responses to the prompts!",
  launching: "This Quilted Project is scheduled for launch! Please try again in a few moments!"
}

export const StoryNotLaunchedView = ({
  story: { id, uid, state, startDate },
  auth
}) => {
  const navigate = useNavigate();
  
  const isInSetup = state === "setup";

  const handleSignInClick = () => {
    navigate("/signin");
  };

  const handleCompleteClick = () => {
    navigate(`/edit/${id}`);
  };

  const message = isInSetup
    ? messages.inSetup
    : messages.launching;

  const AnonView = () => (
    <>
      <Text textAlign="center" mb={5} data-testid="message">
        {message}
      </Text>
      <Box
        align="center"
        justify="start"
        width={{ max: "medium" }}
        margin={{ top: "10" }}
      >
        <Text fontSize="xsmall">
          Are you the organizer?
        </Text>
        <Button variant="link"
          onClick={handleSignInClick}
        >Sign in to complete setup</Button>
      </Box>
    </>
  );

  const OwnerView = () => (
    <>
      <Text textAlign="center" data-testid="message">{message}</Text>
      {!isInSetup && startDate && (
        <Box
          round="small"
          background="dark-1"
          align="stretch"
          justify="center"
          pad={{ vertical: "xsmall", horizontal: "small" }}
          margin={{ bottom: "medium" }}
          flex={{ grow: 1 }}
        >
          <Box
            align="stretch"
            justify="center"
            pad={{ bottom: "xsmall" }}
            direction="row"
            fill="horizontal"
          >
            <Box margin={{ bottom: "xsmall" }}>
              <Text fontSize="small" weight={600}>
                Quilted Movie Launch In:
              </Text>
            </Box>
          </Box>
          <div>
            <Box
              align="stretch"
              justify="center"
              pad={{ bottom: "xsmall" }}
              direction="row"
              fill="horizontal"
            >
              <Box pad="none" align="center" justify="center">
                <StoryCountdown
                  dueDate={timestampToDate(startDate)}
                  completedMessage="Story should have started already. Please contact support."
                />
              </Box>
            </Box>
          </div>
        </Box>
      )}
      {isInSetup && (
        <Box
          direction="column"
          align="center"
          justify="start"
          width={{ max: "medium" }}
          margin={{ top: "xlarge" }}
        >
          <Button
            onClick={handleCompleteClick}
          >Complete Setup</Button>
        </Box>
      )}
    </>
  );

  const ManageView = () => {
    console.log({user: auth.currentUser, uid})
    if (auth.currentUser && auth.currentUser.uid === uid) return <OwnerView />;
    else return <AnonView />;
  };

  return (
    <Box
      p="10"
      align="stretch"
      justify="start"
      flex={{ shrink: 0 }}
    >
      <Box
        round="small"
        background="light-1"
        align="center"
        justify="center"
        pad={{ vertical: "medium", horizontal: "medium" }}
        margin={{ bottom: "medium" }}
        flex={{ grow: 1 }}
      >
        <Box
          align="center"
          justify="start"
        >
          <Heading as="h2" variant="squiggle" size="2xl">
            This Quilted Story <br />has not launched yet!
          </Heading>
          <Text m={10}>
            <Lock size="xlarge" color="text" />
          </Text>
          <ManageView />
        </Box>
      </Box>
    </Box>
  );
};

const StoryPaidView = (props) => {
  const history = useHistory();
  const onClick = () => {
    history.push("/start/new");
  };
  return (
    <React.Fragment>
      <Box
        round="small"
        background="dark-1"
        align="stretch"
        justify="center"
        pad="medium"
        margin={{ bottom: "medium" }}
        flex={{ grow: 1 }}
      >
        <div>
          <Box
            align="stretch"
            justify="center"
            pad={{ bottom: "xsmall" }}
            direction="row"
            fill="horizontal"
            background="accent-5"
            round="small"
          >
            <Box pad="none" align="center" justify="center">
              <Center>
                <Text weight="bold">
                  Submissions for this Quilted movie are now closed
                </Text>
              </Center>
            </Box>
          </Box>
        </div>
      </Box>

      <Box
        round="small"
        background="light-1"
        align="center"
        justify="center"
        pad={{ vertical: "medium", horizontal: "medium" }}
        margin={{ bottom: "medium" }}
        flex={{ grow: 1 }}
      >
        <Center>
          <Text weight="bold">We&apos;re compiling the final movie!</Text>
        </Center>
        <Box
          align="stretch"
          justify="center"
          pad="none"
          direction="row"
          fill="horizontal"
          flex={{ shrink: 0 }}
        >
          <Box
            pad={{ top: "small", bottom: "small" }}
            align="center"
            justify="center"
          >
            <Text margin="xsmall" textAlign="center" alignSelf="stretch">
              This Quilted movie is currently being compiled and will be ready
              in just a few days.
            </Text>
            <Text margin="xsmall" textAlign="center" alignSelf="stretch">
              We will send the organizer an email as soon as the final Quilted
              movie has been completed.
            </Text>
          </Box>
        </Box>
      </Box>
      <Box
        round="small"
        background="accent-3"
        align="center"
        justify="center"
        pad={{ vertical: "medium", horizontal: "medium" }}
        margin={{ bottom: "medium" }}
        flex={{ grow: 1 }}
      >
        <Box
          align="stretch"
          justify="center"
          pad="none"
          direction="row"
          fill="horizontal"
          flex={{ shrink: 0 }}
        >
          <Box
            pad={{ top: "small", bottom: "small" }}
            align="center"
            justify="center"
          >
            <Text
              margin="xsmall"
              textAlign="center"
              alignSelf="stretch"
              color="white"
            >
              Know anyone who has had to cancel an event this year? Make them
              still feel special by doing a Quilted movie for them.
            </Text>
            <Text
              margin="xsmall"
              textAlign="center"
              alignSelf="stretch"
              color="white"
            >
              We’ll even give you{" "}
              <Text weight="bold" fontSize="small">
                20% off
              </Text>
              .
            </Text>
            <Text
              margin="xsmall"
              textAlign="center"
              alignSelf="stretch"
              color="white"
            >
              <Text weight="bold" fontSize="small">
                QuiltedFamily20
              </Text>
            </Text>
          </Box>
        </Box>
      </Box>

      <Box
        fill="horizontal"
        direction="column"
        align="center"
        justify="center"
        pad="small"
      >
        <Button
          fill="horizontal"
          label={
            <Text color="white" weight="bold" textAlign="center">
              Start Your Own Quilted Movie
            </Text>
          }
          color="accent-1"
          type="submit"
          primary={true}
          alignSelf="stretch"
          onClick={onClick}
        />
      </Box>
    </React.Fragment>
  );
};

const Uploads = ({ length, progress }) => {
  return (
    (length && (
      <Box bg="puce.50" p="3" rounded="sm">
        <Stack spacing={1}>
          <Progress value={progress} size="xs" colorScheme="puce" />
          <Text fontSize="sm">
            {length} video(s) still uploading. Do not close this page.
          </Text>
        </Stack>
      </Box>
    )) ||
    null
  );
};

const ResponsesSync = ({ story, identity, onChange }) => {
  const query = () => {
    let where = [["storyId", "==", String(story.id)]];
    if (identity && (identity.uid || identity.email)) {
      if (identity.uid) where.push(["submittedBy.uid", "==", identity.uid]);
      if (identity.email)
        where.push(["submittedBy.email", "==", identity.email]);
    } else where.push(["public", "==", true]);
    const _query = {
      collection: "submissions",
      where,
    };
    return _query;
  };
  useFirestoreConnect(query());

  const submissions = useSelector(
    (state) => state.firestore.ordered.submissions
  );

  React.useEffect(() => {
    console.log("submissions changed", { submissions });
    onChange(submissions);
  }, [submissions]);

  return null;
};

const ViewStoryPage = () => {
  const firestore = useFirestore();
  const params = useParams();
  const auth = useAuth();
  const [contactInfo, setContactInfo] = useLocalStorage("contactInfo", null);
  const { track, identify } = useAnalytics();

  const fetchAuth = async () => {
    if (auth.currentUser && !auth.currentUser.isAnonymous)
      return {
        displayName: auth.currentUser.displayName,
        email: auth.currentUser.email,
        uid: auth.currentUser.uid,
      };
    else return contactInfo;
  };

  const fetchStory = (context, event) =>
    new Promise((resolve, reject) => {
      firestore
        .collection("stories")
        .where("slug", "==", String(params.slug))
        .limit(1)
        .get()
        .then((doc) => {
          if(doc.empty) throw new Error('Story not found.')
          return { story: doc.docs[0].data() };
        })
        .then(resolve)
        .catch(reject);
    });

  const fetchResponses = (context, event) =>
    new Promise((resolve, reject) => {
      const { story, identity } = context;
      if (!story) reject("Unknown Story");
      if (!identity) reject("Unknown User");
      const query = !!identity.uid ? "submittedBy.uid" : "submittedBy.email";
      firestore
        .collection("submissions")
        .where("storyId", "==", story.id)
        .where(query, "==", identity.uid || identity.email)
        .get()
        .then((doc) => {
          return doc.docs.map((doc) => doc.data());
        })
        .then((data) => {
          console.log("Found", { data });
          resolve(data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });

  const myMachine = responseMachine.withConfig({
    services: {
      fetchAuth,
      fetchStory,
      fetchResponses,
    },
    actions: {
      storeIdentity: (context, event) => {
        setContactInfo(event.value);
      },
      sendAnalytics: (context, event) => {
        switch (event.action) {
          case "identify":
            if (!context.identity?.uid) {
              console.error("Cannot identify. No user identifier provided.")
              break;
            }
            identify(context.identity.uid, {
                $email: context.identity.email,
                email: context.identity.email,
                name: context.identity.name,
                // role and unsubscribed_from_emails are required for syncing to Intercom
                role: "user",
                unsubscribed_from_emails:
                  context.identity.optInMarketing !== undefined
                    ? !context.identity.optInMarketing
                    : undefined,
                ...event.properties,
              });
            break;
          case "track":
          default:
            if (!event.track) {
              console.error("Cannot track. No tracking event provided.");
              break;
            }
            track(event.track, {
                storyId: context.story.id,
                storyTitle: context.story.title,
                storyOwner: context.story.owner,
                storySubject: context.story.subject,
                activePromptId: context.activePrompt?.id || context.activePrompt?.idx,
                activePromptText: context.activePrompt?.text,
                ...event.properties
              });
            break;
        }
      }
    },
  });

  const [state, send] = useMachine(myMachine, {
    devTools: true,
    context: { identity: contactInfo },
  });
  const { story, identity, activePrompt, uploads, uploadProgress, useInput } =
    state.context;
  const { recordingMachine } = state.children;

  useEffect(() => {
    state.value === "idle" && send({ type: "FETCH", variables: { id: 1 } });
  }, [state.value, send]);

  return (<PageContainer minW={undefined} maxW="90vw" w="3xl">
    <Helmet>
      <title>Quilted Stories - {story.title}</title>
      <meta name="description" content={`${story.title} - a Quilted Story`} />
    </Helmet>
    <Box w="full" h="full">
      {uploads && uploads.filter(u => !u.isDone).length > 0 && <Uploads length={uploads.length} progress={uploadProgress} />}
      {story && identity && <ResponsesSync story={story} identity={identity} onChange={(data)=>send({type:'LOAD_RESPONSES', data })} />}
      {state.matches('ready') ? (
        <StoryIntroView story={story} onClick={()=>send('RESPOND')} />
      ) : state.matches('identify') ? (
        <UserInfoForm title={story.title} owner={story.owner} onContinue={(value) => { send({type:'IDENTIFIED', value})}} />
      ) : state.matches('responding') ? (
        <PromptsView story={story} responseState={state.value.responding} />
      ) : state.matches('recording') ? (
        <VideoRecorderView machine={recordingMachine}>
          <VideoQuestionOverlay
            text={activePrompt.text}
            machine={recordingMachine}
          />
        </VideoRecorderView>
      ) : state.matches('done') ? (
        <RespondedView onClick={() => send('CONTINUE')} progress={uploadProgress} />
      ) : (state.matches('idle')||state.matches('loading')||state.matches('init')) ? (
        <div>loading</div>
      ) : state.matches('notLaunched') ? (
        <StoryNotLaunchedView story={story} auth={auth} />
      ) : state.matches('final') ? (
        <FinalVideoCard story={story} />
      ) : state.matches('error') ? (
        <StoryNotFound />
      )
      : null}
    </Box>
    </PageContainer>
  );
};

export default ViewStoryPage;
