import React from "react";
import { Heading, Stack, Text } from "@chakra-ui/layout";
import PromptsList from "./components/PromptsList";
import { StoryStepId } from "../../../types/StoryManagerState";

const PromptsStep = ({ id }: { id: StoryStepId }) => {
  return (
    <>
      <Stack spacing="8" align="center">
        <Heading as="h2" size="h2" variant="center">
          Your video prompts
        </Heading>
        <Text maxW="xl" fontSize="lg" textAlign="center">
          Check that these are the prompts you want people to answer for the
          gift receiver. Feel free to change, remove, or add new questions!
        </Text>
        <PromptsList id={id} />
      </Stack>
    </>
  );
};

export default PromptsStep;
