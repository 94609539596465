import React from "react";
import {
  Box,
  Button,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import DropCalendar from "../../layout/DropCalendar";

const minStartDate = () => {
  const min = 30;
  const start = moment().add(1, "hour").startOf("hour").toDate();
  const now = moment();
  const diff = moment(start).diff(now, "minutes");
  if (diff < min) return moment(start).add(min, "minutes").toDate();
  return start;
};

const defaultDeliveryDate = (() => {
  return moment(minStartDate()).add(72, "hours").subtract(1, "minute").toDate();
})();
interface SetDeliveryDateCardViewProps {
  colorScheme: string;
  subject: string;
  onClick: (deliveryDate: Date) => void;
}
export const SetDeliveryDateView: React.FC<SetDeliveryDateCardViewProps> = ({
  colorScheme,
  subject,
  onClick,
}: SetDeliveryDateCardViewProps) => {
  const [deliveryDate, setDeliveryDate] = React.useState<Date>(defaultDeliveryDate);
  const isDateValid = (date: Date) => {
    const _valid = !!(date && moment(date).isAfter());
    return _valid;
  };
  const handleDeliveryDateChange = (value: Date) => {
    setDeliveryDate(value);
  };

  return (
    <Box>
      <Heading as="h4" size="md" mb={3}>
        Invite storytellers
      </Heading>
      <Text fontWeight="bold" mb={3}>
        When do you plan on giving this gift to {subject}?
      </Text>
      <Box mb={3}>
      <DropCalendar
        useChakraInput
        startDate={deliveryDate}
        onChange={handleDeliveryDateChange}
        showTimeSelect={false}
        limitTo={{
          minDate: moment().add(72, "hours").startOf('day').toDate(),
          maxDate: moment().add(72, "hours").add(1, "year").endOf('day').toDate(),
        }}
      />
      </Box>
      <Button
        disabled={!isDateValid(deliveryDate)}
        colorScheme={colorScheme}
        onClick={() => {
          onClick(deliveryDate);
        }}
      >
        Continue
      </Button>
    </Box>
  );
};

export default SetDeliveryDateView;
