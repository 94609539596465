import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";

interface ProcessingCardViewProps {
  colorScheme: string;
  isConcierge: boolean;
  isFinal: boolean;
}
export const ProcessingCardView: React.FC<ProcessingCardViewProps> = ({
  colorScheme,
  isConcierge,
  isFinal,
}: ProcessingCardViewProps) => {
  return (
    <Box>
      <Heading as="h4" size="md" mb={3}>
        Your {isFinal && "final "}movie is being created!
      </Heading>
      <Text mb={3}>[text]</Text>
    </Box>
  );
};

export default ProcessingCardView;
