import React from "react";
import { Button, Divider, Flex, Stack } from "@chakra-ui/react";

const WizardNavigation = (props: WizardNavigationProps) => {
  const {
    onClickNext,
    onClickPrevious,
    canContinue,
    lastStep,
    canGoBack,
    running,
  } = props;

  return (
    <Stack pos="sticky" bottom="0" bgColor="white">
      <Divider />
      <Flex
        minH="full"
        minW="full"
        direction="row-reverse"
        align="center"
        justify="space-between"
        p="sm"
      >
        <Button
          type="submit"
          colorScheme="seaGreen"
          mr={0}
          bgColor={canContinue ? "seaGreen.500" : "seaGreen.200"}
          _hover={{ bgColor: canContinue ? "seaGreen.500" : "seaGreen.200" }}
          cursor={canContinue ? "pointer" : "not-allowed"}
          onClick={onClickNext}
          isLoading={running}
        >
          <span data-tag={lastStep?"last-step":""}>Continue</span>
        </Button>
        {canGoBack && (
          <Button
            variant="plain"
            color="black"
            onClick={onClickPrevious}
            disabled={!canGoBack || running}
          >
            <span>back</span>
          </Button>
        )}
      </Flex>
    </Stack>
  );
};

export default WizardNavigation;

interface WizardNavigationProps {
  onClickNext: (...args: any[]) => any;
  onClickPrevious: (...args: any[]) => any;
  canContinue: boolean;
  lastStep: boolean;
  canGoBack: boolean;
  running: boolean;
}
