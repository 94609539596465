import * as moment from "moment";
import { timestampToDate } from "./convert";



const getDateTimeFromSeconds = (seconds) => {
    const when = new Date(Date.UTC(1970, 0, 1)); // Epoch
    when.setUTCSeconds(seconds);
    return when;
}

const regx = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z$/
const isJSONDateString = (thing) => {    
    return (typeof thing === 'string' && regx.test(thing) && moment(thing,'YYYY-MM-DDTHH:mm:ss.sssZ').isValid())
}

export const hydrateDates = (thing) => {
  if(!thing) return thing;
  if(thing.toString().startsWith("Timestamp")) return timestampToDate(thing)
  if(isJSONDateString(thing))  return moment(thing).toDate()
  if(thing.hasOwnProperty('_seconds')) return getDateTimeFromSeconds(thing._seconds)
  if(thing.hasOwnProperty('seconds')) return getDateTimeFromSeconds(thing.seconds)
  if(thing.constructor === Object || thing.constructor === Array)
    for(const prop in thing) {
      thing[prop] = hydrateDates(thing[prop]);
    }
    
  return thing;
}

const getFutureDate = (from, amount = 1, unit = "day") => {
    const day = moment(from).add(amount, unit).toDate();
    return day;
}

export const adjustDateBetweenLateHours = (from = new Date()) => {
    let adjustedDate = from;
    const date = from;
    let hour = date.getHours();

    let duration = 0;
    if(hour > 22 || hour === 0) {
        if(hour === 0) hour = 24;
        duration = (hour === 24) ? 8 : (24-hour)+8;
        adjustedDate = getFutureDate(from, duration, "hour");
    }
    else if(hour < 8) {
        duration = 8-hour;
        adjustedDate = getFutureDate(from, duration, "hour");
    }

    return adjustedDate;
}