import React from "react";
import {
  Container,
  Center,
  Stack,
  Icon,
  Box,
  Heading,
  Text,
  Button,
  Image,
  IconButton,
  createIcon,
  useColorModeValue,
  Grid,
  GridItem,
  useToast,
  Flex,
  CircularProgressLabel,
  CircularProgress,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerProps,
  useDisclosure,
  useBoolean,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";

import { Story } from "../../../types/FinalVideoProps";
import { timestampToMoment } from "../../../utils/convert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy, faDownload, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import MuxImage, { getMuxImageUrl } from "../../layout/MuxImage";
import { FacebookShare, TwitterShare, EmailShare } from "../../layout/SocialShare";
import buildStoryUrl from "../../../utils/buildStoryUrl";
import getStoryStates from "../../../utils/story";
import { DownloadFromStorage } from "../../common/DownloadFromStorage";
import { Player } from "../../video/Player";
import UrlExists from "../../../utils/checkUrlExists";
import { useFirestore } from "react-redux-firebase";
import FinalVideoRevisionRequestForm from "./FinalVideoRevisionRequestForm";
import { useClickToCopy } from "../../../hooks/useClickToCopy";
import PurchaseAddOns from "./PurchaseAddOns";
import { useAnalytics } from "use-analytics";
import { buildIllustrationUrl } from "../../../utils/buildIllustrationUrl";

interface FinalVideoCardProps {
  story: Story;
  children?:React.ReactNode;
  showAlternateActions:boolean;
}
interface VideoApprovalActionsProps {
  story: Story;
  onApproval: () => void;
  onRejected: () => void;
}

const RevisionMessage = () => {
  return (<>
    <Text m={4}>We&apos;re getting to work on your movie revisions!</Text>
    <Text m={4}>Revisions are typically turned around in 2 - 3 business days.</Text>
    <Text m={4}>We will email you if we have any questions about your request!</Text>
  </>)
}

const ApprovalMessage = () => (<>
  <Text fontWeight="bold" m={4}>Your Quilted movie has been approved!</Text>
</>)

const FaceBookGraphMeta = ({ story }: { story: Story }) => {
  const url = buildStoryUrl(story);
  const image = getMuxImageUrl(story.finalVideo.muxPlaybackId);

  return (
    <Helmet>
      <title>Quilted | Final Video - {story.title}</title>
      <meta property="image" content={image} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={story.title} />
      <meta property="og:quote" content="" />
      <meta property="og:hashtag" content={"QuiltedStories"} />
      <meta property="og:image" content={image} />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="QuiltedStories" />
      <meta
        property="og:description"
        content={`Created by ${story.owner} for ${
          story.subject
        }, on ${timestampToMoment(story.dueDate).format("LL")}`}
      />
    </Helmet>
  );
};

function DownloadBox({
  path,
  filename,
  onComplete,
}: {
  path: string;
  filename: string;
  onComplete: () => void;
}) {
  const [downloading, setDownloading] = React.useState(0);
  const toast = useToast();

  const onDownloadProgress = (progress: number) => {
    console.log(progress);
    setDownloading(progress);
  };

  const onDownloadComplete = () => {
    setDownloading(0);
    toast &&
      toast({
        title: "Download complete!",
        description: "Your Quilted Movie has been downloaded.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    onComplete && onComplete();
  };

  const onDownloadError = (error: { message: string }) => {
    setDownloading(0);
    toast &&
      toast({
        title: "Download failed!",
        description:
          "There was an error downloading your video! If the problem persists, please contact support.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    onComplete && onComplete();
  };

  const onDownloadAbort = () => {
    setDownloading(0);
    toast &&
      toast({
        title: "Download aborted!",
        description: "Your download was cancelled!",
        status: "warning",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    onComplete && onComplete();
  };

  const downloadVideo = () => {
    const downloadPath = path;
    const downloadFilename = filename;
    setDownloading(1);
    return DownloadFromStorage(
      downloadPath,
      downloadFilename,
      onDownloadProgress,
      onDownloadComplete,
      onDownloadError,
      onDownloadAbort
    );
  };

  React.useEffect(() => {
    downloadVideo();
  }, []);

  return (
    <Box
      position={"absolute"}
      left={"50%"}
      top={"50%"}
      transform={"translateX(-50%) translateY(-50%)"}
    >
      <CircularProgress value={downloading} color="green.400">
        <CircularProgressLabel>{downloading}%</CircularProgressLabel>
      </CircularProgress>
    </Box>
  );
}


const VideoApprovalActions = ({
  story,
  onApproval,
  onRejected,
}: VideoApprovalActionsProps) => {
  const [rejected, setRejected] = React.useState(false);
  const firestore = useFirestore();

  const approve = () => {
    story.finalVideo.isApproved = true;
    firestore
      .collection("stories")
      .doc(story.id)
      .update({
        finalVideo: story.finalVideo,
      })
      .then(() => {
        onApproval && onApproval();
      });
  };

  const showRejected = () => {
    setRejected(true);
  };

  return (
    <Center px={6}>
      {rejected && <FinalVideoRevisionRequestForm story={story} onCancel={()=>{setRejected(false)}} onSubmitted={onRejected} />}
      {!rejected && (
      <Stack maxW="lg" spacing={{ base: 4, sm: 6 }} direction="column">
        <Button
          variant="solid"
          size={"lg"}
          leftIcon={<Icon as={FaApprove} h={4} w={4} color={"gray.300"} />}
          onClick={approve}
        >
          Approve This Movie
        </Button>

        <Button
          variant="outline"
          size={"lg"}
          colorScheme={"puce"}
          leftIcon={<Icon as={FaReject} h={4} w={4} color={"gray.300"} />}
          onClick={showRejected}
        >
          Revisions Required
        </Button>
      </Stack>
      )}
    </Center>
  );
};

interface MessageDrawerProps extends DrawerProps {
  title:string|React.ReactNode
}

function MessageDrawer({title, children,...rest}:MessageDrawerProps) {
  return (<Drawer placement="top" {...rest}>
    <DrawerOverlay />
    <DrawerContent>
      <DrawerHeader borderBottomWidth="1px" color={useColorModeValue("tumbleweed.500","tumbleweed.900")} textAlign="center">{title}</DrawerHeader>
      <DrawerBody textAlign="center">
        {children}
      </DrawerBody>
    </DrawerContent>
  </Drawer>)
}

function FinalVideoCard({ story, showAlternateActions, children }: FinalVideoCardProps) {
  const [playingVideo, setPlayingVideo] =
    React.useState<{ text: string; url: string | false }[] | null>();
  const [downloading, setDownloading] = React.useState(false);
  const toast = useToast();
  const url = buildStoryUrl(story);
  
  const onCopy = () => {
    toast({
      title: "Copied!",
      description: "Your Story URL was copied to your clipboard.",
      status: "info",
      duration: 5000,
      isClosable: true,
    })
  }
  const clickToCopyHandler = useClickToCopy(url, onCopy)

  
  const playVideo = () => {
    const { streamUrl } = story.finalVideo;

    const playlist = [{ text: "", url: UrlExists(streamUrl) && streamUrl }];
    setPlayingVideo(playlist);
  };

  
  return (
    <Container maxW={"7xl"}>
      <FaceBookGraphMeta story={story} />
      {playingVideo && (
        <Player onClose={() => setPlayingVideo(null)} playlist={playingVideo} />
      )}
      <Center py={6}>
        <Box
          w={"full"}
          bg={useColorModeValue("springWood.100", "springWood.900")}
          boxShadow={"2xl"}
          rounded={"lg"}
          p={6}
          overflow={"hidden"}
        >
          <Box position={"relative"} mt={-6} mx={-6} mb={6} overflow={"hidden"}>
            {downloading ? (
              <DownloadBox
                path={story.finalVideo.path}
                filename={`${story.slug}.mp4`}
                onComplete={() => {
                  setDownloading(false);
                }}
              />
            ) : (
              <IconButton
                aria-label={"Play Button"}
                variant={"ghost"}
                _hover={{
                  bg: "transparent",
                  transform: "scale(1.2) translateX(-40%) translateY(-40%)",
                  // transformOrigin: "center center"
                }}
                icon={<PlayIcon w={12} h={12} />}
                size={"lg"}
                color="airBlue.500"
                position={"absolute"}
                left={"50%"}
                top={"50%"}
                transform={"translateX(-50%) translateY(-50%)"}
                transition="transform .2s"
                onClick={playVideo}
              />
            )}
            <MuxImage
              as={Image}
              playbackId={story.finalVideo.muxPlaybackId}
              time={story.finalVideo.time || 3}
              crop={story.finalVideo.crop}
              dimensions={undefined}
              alt={"Hero Image"}
              fit={"cover"}
              align={"center"}
              w={"100%"}
              h={"100%"}
            />
          </Box>
          <Stack>
            <Box borderBottom="1px" borderBottomColor="springWood.600" pb={4}>
              <Heading color={useColorModeValue("body", "white")}>
                {story.title}
              </Heading>
              <Stack
                spacing="0"
                display="flex"
                alignItems="center"
                direction={{ base: "column", sm: "row" }}
                fontSize="large"
              >
                <Text>
                  Created by <strong>{story.owner || story.email}</strong>
                </Text>
                <Text pl={{ base: 0, sm: 1 }}>
                  for {story.subject}, on{" "}
                  {timestampToMoment(story.dueDate).format("LL")}
                </Text>
              </Stack>
            </Box>
            {children && children }
            {!showAlternateActions && (
            <Grid templateColumns="repeat(5, 1fr)" gap={4}>
              <GridItem colSpan={[5, 3]}>
                <Flex
                  direction={["column", "row"]}
                  justify="center"
                  alignItems="center"
                  fontSize="medium"
                >
                  <Box p={2}>
                    <Text fontWeight="bold">Share through:</Text>
                  </Box>
                  <Box p={2}>
                    <FacebookShare story={story} url={url} toast={toast} />
                  </Box>
                  <Box p={2}>
                    <TwitterShare story={story} url={url} />
                  </Box>
                  <Box p={2} flex={[null, 1]}>
                    <EmailShare story={story} url={url} />
                  </Box>
                </Flex>
              </GridItem>
              <GridItem colSpan={[5, 2]}>
                <Center px={6} pt={2}>
                  <Stack
                    maxW="lg"
                    spacing={{ base: 4, sm: 6 }}
                    direction="column"
                  >
                    <Button
                      variant="solid"
                      size={"lg"}
                      leftIcon={
                        <Icon as={FaDownload} h={4} w={4} color={"gray.300"} />
                      }
                      onClick={() => setDownloading(true)}
                      isLoading={downloading}
                    >
                      Download Movie
                    </Button>

                    <Button
                      variant="outline"
                      size={"lg"}
                      colorScheme={"airBlue"}
                      leftIcon={
                        <Icon as={FaCopy} h={4} w={4} color={"gray.300"} />
                      }
                      {...clickToCopyHandler}
                    >
                      Copy Movie Link
                    </Button>
                  </Stack>
                </Center>
              </GridItem>
              <GridItem colSpan={5}>
                <PurchaseAddOns story={story} />
              </GridItem>
            </Grid>
            )}
          </Stack>
        </Box>
      </Center>
  </Container>);
}

const FaApprove = () => <FontAwesomeIcon icon={faCheck} />;
const FaReject = () => <FontAwesomeIcon icon={faTimesCircle} />;
const FaDownload = () => <FontAwesomeIcon icon={faDownload} />;
const FaCopy = () => <FontAwesomeIcon icon={faCopy} />;

const PlayIcon = createIcon({
  displayName: "PlayIcon",
  viewBox: "0 0 58 58",
  d: "M28.9999 0.562988C13.3196 0.562988 0.562378 13.3202 0.562378 29.0005C0.562378 44.6808 13.3196 57.438 28.9999 57.438C44.6801 57.438 57.4374 44.6808 57.4374 29.0005C57.4374 13.3202 44.6801 0.562988 28.9999 0.562988ZM39.2223 30.272L23.5749 39.7247C23.3506 39.8591 23.0946 39.9314 22.8332 39.9342C22.5717 39.9369 22.3142 39.8701 22.0871 39.7406C21.86 39.611 21.6715 39.4234 21.5408 39.1969C21.4102 38.9705 21.3421 38.7133 21.3436 38.4519V19.5491C21.3421 19.2877 21.4102 19.0305 21.5408 18.8041C21.6715 18.5776 21.86 18.3899 22.0871 18.2604C22.3142 18.1308 22.5717 18.064 22.8332 18.0668C23.0946 18.0696 23.3506 18.1419 23.5749 18.2763L39.2223 27.729C39.4404 27.8619 39.6207 28.0486 39.7458 28.2713C39.8709 28.494 39.9366 28.7451 39.9366 29.0005C39.9366 29.2559 39.8709 29.507 39.7458 29.7297C39.6207 29.9523 39.4404 30.1391 39.2223 30.272Z",
});


export function FinalVideoCardAdmin({ story }: FinalVideoCardProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [revisionsRequested, setRevisionsRequested] = useBoolean();
  const { track } = useAnalytics();

  const storyStates = getStoryStates(story);
  React.useEffect(() => {
    const reject = storyStates.revisionsRequested ? setRevisionsRequested.on : setRevisionsRequested.off;
    reject();
  }, [storyStates.needsApproval, storyStates.revisionsRequested]);


  const onApproval = () => {
    track("Final movie approved", {
      storyId: story?.id,
      storyTitle: story?.title,
      storySubject: story?.subject,
      storySlug: story?.slug,
      templateId: story?.template?.id,
      templateName: story?.template?.title,
      templateIllustration: buildIllustrationUrl(story?.template?.illustration),
    });
    onOpen();
  };

  const onRejected = () => {
    track("Final movie rejected", {
      storyId: story?.id,
      storyTitle: story?.title,
      storySubject: story?.subject,
      storySlug: story?.slug,
      templateId: story?.template?.id,
      templateName: story?.template?.title,
      templateIllustration: buildIllustrationUrl(story?.template?.illustration),
    });
    setRevisionsRequested.on();
    onOpen();
  };

  return (
    <>
      <FinalVideoCard
        story={story}
        showAlternateActions={storyStates.needsApproval}
      >
        {storyStates.needsApproval && !revisionsRequested && (
          <VideoApprovalActions
            story={story}
            onApproval={onApproval}
            onRejected={onRejected}
          />
        )}
        {revisionsRequested && <RevisionMessage />}
      </FinalVideoCard>

      <MessageDrawer
        title={revisionsRequested ? "Thank You!" : "Movie Approved!"}
        isOpen={isOpen}
        onClose={onClose}
      >
        {revisionsRequested && <RevisionMessage />}
        {!revisionsRequested && <ApprovalMessage />}
      </MessageDrawer>
    </>
  );
}

export default FinalVideoCard;
