import {
  Box,
  Flex,
  Divider,
  Heading,
  Stack,
  FormControl,
  Input,
  FormLabel,
  FormHelperText,
  Text,
  FormErrorMessage,
  HStack,
  Image,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//@ts-ignore
import { useAnalytics } from "use-analytics";
import { useStoryManagerDispatch, useStoryManagerState } from "../StoryManager";
import { iStoryManagerStateContext } from "../../../types/StoryManagerState";
import ModalDialog, { ModalActions } from "../../common/ModalDialog";
import TemplateSelector from "./components/TemplateSelector";
import isDeepEqual from "fast-deep-equal/react";
import { Template } from "../../../types/Templates";
import { buildIllustrationUrl } from "../../../utils/buildIllustrationUrl";

const schema = yup.object().shape({
  subject: yup
    .string()
    .required(
      "Please provide a name for the person you're making this movie for."
    ),
  title: yup.string().required("Please give your project a title."),
  email: yup.string().email().required("Your email is required to continue."),
});

const SetupStep = ({ id }: { id: string }) => {
  const dispatch = useStoryManagerDispatch();
  // @ts-ignore
  const {
    state: { story, templates, authChecks },
  }: { state: iStoryManagerStateContext } = useStoryManagerState();

  const { register, setFocus, watch, formState, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  const { track } = useAnalytics();

  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const storyRef = useRef(story);
  const [currentTemplate, setCurrentTemplate] = useState<Template>();

  if (!isDeepEqual(storyRef.current, story)) {
    storyRef.current = story;
  }

  useEffect(() => {
    setValue("subject", story?.subject);
    setValue("title", story?.title);
    setValue(
      "email",
      (authChecks?.signedIn && authChecks?.auth.email) || story?.email
    );
    setLoading(false);
  }, []);

  useEffect(() => {
    setFocus("subject");
  }, [setFocus]);

  useEffect(() => {
    if (
      storyRef.current &&
      storyRef.current?.templateId !== currentTemplate?.id
    ) {
      const template = templates?.find(
        (t) => t.id === storyRef.current?.templateId
      );
      setCurrentTemplate(template);
    }
  }, [storyRef.current]);

  const [subject, title, email] = watch(["subject", "title", "email"]);

  const update = React.useCallback(() => {
    dispatch({
      type: "update",
      payload: {
        story: {
          ...story,
          title,
          subject,
          email,
          step: id,
        },
        emailExists: false,
        error: null,
        valid: {
          [id]: {
            title: !!title && !errors.title,
            subject: !!subject && !errors.subject,
            email: !!email && !errors.email,
            hasTemplate: !!currentTemplate?.id,
          },
        },
        changed: true,
      },
    });
  }, [title, subject, email]);

  React.useEffect(() => {
    if (!loading) update();
  }, [title, subject, email, loading]);

  if (!story) return <></>;

  return (
    <>
      <Stack spacing="8">
        <Heading as="h2" size="h2" variant="center">
          Start your Quilted movie!
        </Heading>
        <HStack
          justify="space-around"
          bgColor={`${
            currentTemplate?.accentColor ? currentTemplate.accentColor : "puce"
          }.50`}
          rounded="lg"
          p="lg"
          spacing="xl"
          w="full"
        >
          {currentTemplate ? (
            <Flex direction={["row-reverse", "row"]} justify="space-around">
              {currentTemplate.illustration && (
                <Image
                  py="6"
                  pl={["sm", "0"]}
                  src={buildIllustrationUrl(currentTemplate.illustration)}
                  maxW={["100px", "180px"]}
                  maxH={["100px", "180px"]}
                />
              )}
              <Stack
                spacing="1"
                maxW={["auto", "60%"]}
                align={currentTemplate.illustration ? "space-around" : "center"}
                textAlign={currentTemplate.illustration ? "left" : "center"}
              >
                <Text fontWeight="bold">Your selected occasion</Text>
                <Text variant="serif" fontSize="xl">
                  {currentTemplate.title}
                </Text>
                {currentTemplate.shortDescription && (
                  <Text>{currentTemplate.shortDescription}</Text>
                )}
                <Button
                  p={0}
                  alignSelf={
                    currentTemplate.illustration ? "flex-start" : "center"
                  }
                  textAlign="left"
                  variant="plain"
                  onClick={onOpen}
                >
                  <Text
                    color="tumbleweed.600"
                    textDecor="underline"
                    variant="serif"
                  >
                    Choose a different occasion
                  </Text>
                </Button>
              </Stack>
            </Flex>
          ) : (
            <Button
              p={0}
              alignSelf="flex-start"
              textAlign="left"
              variant="plain"
              onClick={onOpen}
            >
              <Text color="puce.800" textDecor="underline" variant="serif">
                Choose a template to get started
              </Text>
            </Button>
          )}
        </HStack>
        <form>
          <Stack spacing="8">
            <Divider />
            <FormControl id="subject" isInvalid={errors.subject}>
              <FormLabel variant="headingLabel">
                <strong>Who</strong> is this Quilted movie for?
              </FormLabel>
              <Input
                {...register("subject")}
                placeholder="Emily"
                onFocus={() => track("setup_editingSubject")}
              />
              <FormErrorMessage color="puce.700">
                {errors.subject && errors.subject.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl id="title" isInvalid={errors.title}>
              <FormLabel variant="headingLabel">
                What&apos;s the <strong>title</strong> of your Quilted movie?
              </FormLabel>
              <Input
                {...register("title")}
                placeholder="Birthday wishes for Emily"
                onFocus={() => track("setup_editingTitle")}
              />
              <FormHelperText>
                This will be seen by the people you send your Quilted response
                link to.
              </FormHelperText>
              <FormErrorMessage color="puce.700">
                {errors.title && errors.title.message}
              </FormErrorMessage>
            </FormControl>
            {!authChecks?.signedIn && (
              <FormControl id="email" isInvalid={errors.email}>
                <FormLabel variant="headingLabel">
                  What is your <strong>email address</strong>?
                </FormLabel>
                <Input
                  {...register("email")}
                  placeholder="name@domain.com"
                  onFocus={() => track("setup_editingEmail")}
                />
                <FormErrorMessage color="puce.700">
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
            )}
            {authChecks?.signedIn && (
              <FormControl id="email" hidden={true}>
                <Input {...register("email")} value={authChecks?.auth.email} />
              </FormControl>
            )}
          </Stack>
        </form>
      </Stack>
      <ModalDialog
        isOpen={isOpen}
        onClose={onClose}
        hideCloseButton
        size="full"
        modalContent={{
          w: "850px",
          maxW: "80vw",
          bgColor: "springWood",
        }}
      >
        <Flex direction="column" justify="space-around">
          <Box maxH={["80vh", "70vh"]} overflow="scroll" mb="3">
            <TemplateSelector id={id} />
          </Box>
          <ModalActions
            primaryButton={{ label: "select occasion", onClick: onClose }}
          />
        </Flex>
      </ModalDialog>
    </>
  );
};

export default SetupStep;
