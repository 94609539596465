import React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  StackDivider,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useAnalytics } from "use-analytics";

import StepCard from "../StepCard";
import ClipCircle from "./ClipCircle";

import CardImage from "../../../assets/your-responses.svg";
import { Player } from "../../video/Player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Response } from "../../story/StoryProvider";
import { useSearchParams } from "react-router-dom";

const ntw = require("number-to-words");

export type Prompt = {
  text: string;
  responses: Response[];
};

type cardProps = {
  colorScheme: string;
  prompts: Prompt[];
};

const MissingResponsesBlock = ({ colorScheme, prompts }: cardProps) => {
  const [showAll, setShowAll] = React.useState(false);

  const missing =
    prompts &&
    prompts.filter((prompt) => !prompt.responses || !prompt.responses.length);
  return (
    (missing && missing.length && (
      <Box>
        <Text mr={3} fontWeight="bold" as="span">
          <Text casing="capitalize" as="span">{`${String(
            ntw.toWords(missing.length)
          )}`}</Text>{" "}
          prompts don't have responses yet{showAll ? ":" : "."}
        </Text>
        {!showAll && (
          <Button
            colorScheme={"airBlue"}
            variant="link"
            size="sm"
            onClick={() => setShowAll(true)}
          >
            Show all
          </Button>
        )}
        {showAll && (
          <VStack
            my="1"
            divider={<StackDivider borderColor="gray.200" />}
            spacing={4}
            align="stretch"
          >
            {missing &&
              missing.map((prompt) => (
                <Box key={prompt.text}>
                  <Text>{prompt.text}</Text>
                </Box>
              ))}
          </VStack>
        )}
      </Box>
    )) ||
    null
  );
};

const ResponsesCard = ({ colorScheme, prompts }: cardProps) => {
  const { track } = useAnalytics();
  const [playlist, setPlaylist] =
    React.useState<
      { url: string; text: string; type: "image" | "video" }[] | null
    >(null);

  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    if (searchParams.has("play")) {
      const flattened = prompts.reduce(
        (acc, prompt) => acc.concat(prompt.responses),
        [] as Response[]
      );
      const found = flattened.find(
        (response) => response.id === searchParams.get("play")
      );
      const prompt = prompts.find(
        (prompt) =>
          !!prompt.responses.find(
            (response) => response.id === searchParams.get("play")
          )
      );

      if (found) {
        setPlaylist([
          {
            text: (prompt && prompt.text) || found.name || "",
            url: found.url,
            type: found.type || "video",
          },
        ]);
      }
    }
  }, [searchParams]);

  const playAll = (prompt: Prompt) => {
    const videos = prompt.responses.map((response) => ({
      text: prompt.text,
      url: response.url,
      type: response.type || "video",
    }));
    track("dashboard_userPlayedAll_prompt", { responses: videos.length });
    setPlaylist(videos);
  };

  return (
    <StepCard image={CardImage} colorScheme={colorScheme}>
      <Box>
        {playlist && (
          <Player onClose={() => setPlaylist(null)} playlist={playlist} />
        )}
        <Heading as="h4" size="md" mb={3}>
          Your responses
        </Heading>
        <VStack
          m="1"
          divider={<StackDivider borderColor="gray.200" />}
          spacing={4}
          align="stretch"
        >
          {prompts &&
            prompts
              .filter((prompt) => prompt.responses && !!prompt.responses.length)
              .map((prompt) => (
                <Box key={prompt.text}>
                  <Text mb={3}>{prompt.text}</Text>
                  <Flex
                    dir="row"
                    flexWrap="wrap"
                    display="inline-flex"
                    gap="10px"
                    css={{ gap: "8px" }}
                    width="full"
                  >
                    {prompt.responses && !!prompt.responses.length && (
                      <Tooltip
                        label="Play all"
                        aria-label={`Play all videos for "${prompt.text}"`}
                        colorScheme={colorScheme}
                        hasArrow
                      >
                        <IconButton
                          icon={<FontAwesomeIcon icon={"play"} />}
                          onClick={() => playAll(prompt)}
                          aria-label="play/pause button"
                          m="0"
                          w="55px"
                          h="55px"
                          rounded="full"
                          colorScheme={colorScheme}
                        />
                      </Tooltip>
                    )}
                    {prompt.responses.map((response) => (
                      <ClipCircle
                        colorScheme={response.processing ? "gray" : "airBlue"}
                        key={response.id}
                        thumbnail={
                          response.processing ? "" : response.thumbnail
                        }
                        name={response.name}
                        showPulse={response.processing}
                        disabled={response.processing}
                        icon={
                          response.type === "image"
                            ? ["far", "eye"]
                            : response.processing
                            ? "spinner"
                            : undefined
                        }
                        size="md"
                        onClick={() => {
                          if (!response.processing)
                            setPlaylist([
                              {
                                url: response.url,
                                text: prompt.text,
                                type: response.type || "video",
                              },
                            ]);
                        }}
                      />
                    ))}
                  </Flex>
                </Box>
              ))}
          <MissingResponsesBlock colorScheme={colorScheme} prompts={prompts} />
        </VStack>
      </Box>
    </StepCard>
  );
};

export default ResponsesCard;
