import React from "react";
import YoutubeVideo from "./YoutubeVideo";

const QuiltedVideo = () => (
  <YoutubeVideo youtubeVideoId="TzR9b5QW9sY" title="How Quilted Works" />
);

export const BabyShowerVideo = () => (
  <YoutubeVideo
    youtubeVideoId="N7Z95q356qA"
    title="Create lasting memories with Quilted"
  />
);

export default QuiltedVideo;
