import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

const IconSquiggle = ({ center, color, ...props }: iIconSquiggle) => {
  return (
    <Box
      textAlign={center ? "center" : "inherit"}
      as="span"
      display="inline-block"
      className="icon-squiggle"
      _before={{
        fontSize: "0.5rem",
        width: "54px",
        height: "10px",
        display: "block",
        mx: "auto",
        color: color || undefined,
      }}
      {...props}
    />
  );
};

export default IconSquiggle;

interface iIconSquiggle extends BoxProps {
  center?: boolean;
  color?: BoxProps["bgColor"];
}
