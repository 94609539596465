import { useCallback, useEffect, useRef, useState } from "react";
import { RecorderErrors } from "../../../types/ReactMediaRecorder";
import getMimeTypes from "./get-mimeTypes";
import getVideoInfo from "./get-video-info";

export function useReactMediaRecorder({
  audio = true,
  video = false,
  onStop = (url, blob, info) => {},
  onReady = () => {},
  blobPropertyBag,
  screen = false,
  mediaRecorderOptions = null,
  askPermissionOnMount = false,
}) {
  const mediaRecorder = useRef(null);
  const mediaChunks = useRef([]);
  const mediaStream = useRef(null);
  const cameras = useRef([]);
  const [status, setStatus] = useState("idle");
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [mediaBlobUrl, setMediaBlobUrl] = useState(null);
  const [error, setError] = useState("NONE");
  const facingMode = useRef("user");
  const facingModeSupported = useRef();

  const mimeTypes = getMimeTypes();

  const getCameras = () => {
    const filterDevices = (devices) => {
      devices.filter((device) => device.kind === "videoinput")
      .forEach((camera) => cameras.current.push(camera));
    };

    if (navigator && navigator.mediaDevices) {
      navigator.mediaDevices.enumerateDevices().then(filterDevices);
    }
  };

  const getMediaStream = useCallback(async () => {
    setStatus("acquiring_media");
    const requiredMedia = {
      audio: typeof audio === "boolean" ? !!audio : audio,
      video: typeof video === "boolean" ? { facingMode: facingMode.current } : { facingMode:facingMode.current, ...video },
    };
    try {
      const stream = await window.navigator.mediaDevices.getUserMedia(
        requiredMedia
      );
      mediaStream.current = stream;
      setStatus("idle");
      onReady && onReady();
    } catch (error) {
      setError(error.name);
      setStatus("recorder_error");
    }
  }, [audio, video, screen, facingMode]);

  const checkConstraints = useCallback((mediaType) => {
    const supportedMediaConstraints =
      navigator.mediaDevices.getSupportedConstraints();

    const unSupportedConstraints = Object.keys(mediaType).filter(
      (constraint) =>
        !(supportedMediaConstraints)[constraint]
    );

    if (unSupportedConstraints.length > 0) {
      console.error(
        `The constraints ${unSupportedConstraints.join(
          ","
        )} doesn't support on this browser. Please check your ReactMediaRecorder component.`
      );
    }
    if(facingModeSupported.current === undefined){
      facingModeSupported.current = !!supportedMediaConstraints.facingMode;
      console.log('Is facing mode supported?', facingModeSupported.current);
    }
  }, [facingModeSupported.current]);

  useEffect(() => {
    if (!window.MediaRecorder) {
      throw new Error("Unsupported Browser");
    }

    if (screen) {
      //@ts-ignore
      if (!window.navigator.mediaDevices.getDisplayMedia) {
        throw new Error("This browser doesn't support screen capturing");
      }
    }

    if (typeof audio === "object") {
      checkConstraints(audio);
    }
    if (typeof video === "object") {
      checkConstraints(video);
    }

    if (mediaRecorderOptions && mediaRecorderOptions.mimeType) {
      if (!MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)) {
        console.error(
          `The specified MIME type you supplied for MediaRecorder doesn't support this browser`
        );
      }
    }

    if (!mediaStream.current && askPermissionOnMount) {
      getMediaStream();
    }

    getCameras();

    return () => {
      if (mediaStream.current) {
        const tracks = mediaStream.current.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [
    audio,
    screen,
    video,
    getMediaStream,
    mediaRecorderOptions,
    askPermissionOnMount,
  ]);

  useEffect(() => {
    console.log('Rerendering ReactMediaRecorder');
  },[])

  useEffect(() => {
    if(status === "stopped" && mediaChunks.current && !!mediaChunks.current.length) {
      console.log('MediaRecorder[stopped] and mediaChunks available');
      const [chunk] = mediaChunks.current;
      const blobProperty = Object.assign(
        { type: chunk.type },
        blobPropertyBag || (video ? { type: mimeTypes[0].type } : { type: "audio/wav" })
      );
      const blob = new Blob(mediaChunks.current, blobProperty);
      const url = URL.createObjectURL(blob);
      if(mediaBlobUrl !== url) {
        setMediaBlobUrl((prev) => { prev && URL.revokeObjectURL(prev); return url; });
        getVideoInfo(url).then(({duration}) => {
          return { duration };
       }).catch(({name, message}) => {
         return { error: true, errorName: name, errorMessage:message };
       }).then((info) => {
         onStop(url, blob, info);
       });
      }
    }
  }, [status, mediaChunks.current])

  // Media Recorder Handlers
  const startRecording = async () => {
    console.log('Start recording', {chunks: mediaChunks.current && mediaChunks.current.length })
    setError("NONE");
    if (!mediaStream.current) {
      await getMediaStream();
    }
    if (mediaStream.current) {
      const isStreamEnded = mediaStream.current
        .getTracks()
        .some((track) => track.readyState === "ended");
      if (isStreamEnded) {
        await getMediaStream();
      }

      // User blocked the permissions (getMediaStream errored out)
      if (!mediaStream.current.active) {
        return;
      }

      const options = {};
      if (mediaRecorderOptions && mediaRecorderOptions.mimeType && !MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)) {
        console.error(
          `The specified MIME type you supplied for MediaRecorder doesn't support this browser`
        );
      }
      else if(mediaRecorderOptions && mediaRecorderOptions.mimeType) {
        options.mimeType = mediaRecorderOptions.mimeType
      }
      else {
        if(mimeTypes && !!mimeTypes.length) {
          options.mimeType = mimeTypes[0].mimeType;
        }
      }

      mediaChunks.current = [];
      mediaRecorder.current = new MediaRecorder(mediaStream.current, options);
      mediaRecorder.current.ondataavailable = onRecordingActive;
      mediaRecorder.current.onstop = onRecordingStop;
      mediaRecorder.current.onerror = () => {
        console.error('mediaRecorder.onerror',arguments);
        setError("NO_RECORDER");
        setStatus("recorder_error");
      };
      mediaRecorder.current.start();
      setStatus("recording");
    }
  };

  const onRecordingActive = ({ data }) => {
    console.log('MediaRecorder.ondataavailable', {data, chunks: mediaChunks.current && mediaChunks.current.length});
    if (data && data.size > 0)
      mediaChunks.current.push(data);
  };

  const onRecordingStop = () => {
    console.log('MediaRecorder.onstop', {chunks: mediaChunks.current && mediaChunks.current.length })
    setStatus("stopped");
  };

  const muteAudio = (mute) => {
    console.log('Mute audio', mute)
    setIsAudioMuted(mute);
    if (mediaStream.current) {
      mediaStream.current
        .getAudioTracks()
        .forEach((audioTrack) => (audioTrack.enabled = !mute));
    }
  };

  const pauseRecording = () => {
    console.log('Pause recording', {chunks: mediaChunks.current && mediaChunks.current.length })
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      setStatus("paused");
      mediaRecorder.current.pause();
    }
  };

  const resumeRecording = () => {
    console.log('Resume recording', {chunks: mediaChunks.current && mediaChunks.current.length })
    if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
      setStatus("recording");
      mediaRecorder.current.resume();
    }
  };

  const stopRecording = () => {
    console.log('Stop recording', {chunks: mediaChunks.current && mediaChunks.current.length })
    if (mediaRecorder.current) {
      if (mediaRecorder.current.state !== "inactive") {
        setStatus("stopping");
        mediaRecorder.current.stop();
        mediaStream.current &&
          mediaStream.current.getTracks().forEach((track) => track.stop());
      }
    }
  };

  const switchCamera = () => {
    stopRecording();
    facingMode.current = facingMode.current == 'user' ? 'environment' : 'user';
    askPermissionOnMount && getMediaStream();
  }

  const startMediaStream = () => {
    stopRecording();
    getMediaStream();
  }

  return {
    error: RecorderErrors[error],
    muteAudio: () => muteAudio(true),
    unMuteAudio: () => muteAudio(false),
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    switchCamera,
    startMediaStream,
    mediaBlobUrl,
    status,
    isAudioMuted,
    previewStream: mediaStream.current
      ? new MediaStream(mediaStream.current.getVideoTracks())
      : null,
    previewAudioStream: mediaStream.current
      ? new MediaStream(mediaStream.current.getAudioTracks())
      : null,
    clearBlobUrl: () => {
      if (mediaBlobUrl) {
        URL.revokeObjectURL(mediaBlobUrl);
      }
      setMediaBlobUrl(null);
      setStatus("idle");
    },
    cameras: cameras.current,
    facingMode: facingMode.current,
    facingModeSupported: facingModeSupported.current
  };
}

export const ReactMediaRecorder = (props) =>
  props.render(useReactMediaRecorder(props));

  export default ReactMediaRecorder;