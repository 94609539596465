import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const path = React.useRef(pathname);

  useEffect(() => {
    if(pathname !== path.current) {
      window.scrollTo(0, 0);
      path.current = pathname;
    };
  },[pathname]);

  return (null);
}

export default ScrollToTop;