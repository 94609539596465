const contentTypes = [
    
    { mimeType: "video/webm;codecs=vp9,opus", type: "video/webm", ext: ".webm" },
    { mimeType: "video/webm;codecs=vp9", type: "video/webm", ext: ".webm" },
    { mimeType: "video/mp4;codecs=avc1", type: "video/mp4", ext: ".mp4" },
    { mimeType: "video/mp4;", type: "video/mp4", ext: ".mp4" },
    { mimeType: "video/webm;codecs=h264", type: "video/webm", ext: ".webm" },
    { mimeType: "video/webm;codecs=vp8,vorbis", type: "video/webm", ext: ".webm" },
    { mimeType: "video/webm;codecs=vp8,opus", type: "video/webm", ext: ".webm" },
    { mimeType: "video/webm;codecs=vp8", type: "video/webm", ext: ".webm" },
    { mimeType: "video/webm", type: "video/webm", ext: ".webm" },
];

export default () => {
    const types = contentTypes.filter(contentType => MediaRecorder.isTypeSupported(contentType.mimeType))
    return types;
}