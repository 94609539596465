import React from "react";

import {
  Text,
  useToast,
  Stack,
  StackProps,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  useDisclosure,
  HStack,
  useTheme,
} from "@chakra-ui/react";
import ClickToCopy from "../common/ClickToCopy";
import QRCode from "../dashboard/invitations/QRCode";
import ModalDialog from "../common/ModalDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import buildStoryUrl from "../../utils/buildStoryUrl";

const InviteLinkBox = ({
  slug,
  label,
  hideDisclaimer,
  hideLabel,
  onCopy: onCopyFn,
  ...props
}: iInviteLinkBox) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const theme = useTheme();
  //@ts-ignore
  const airBlue = theme.colors.airBlue[500];
  const url = buildStoryUrl({ slug });
  const qrUrl = buildStoryUrl({ slug: `qr/${slug}` });

  const onCopy = () => {
    if (onCopyFn) onCopyFn();
    toast({
      title: "Copied!",
      description: "Your Story URL was copied to your clipboard.",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Stack
      round="small"
      background="light-1"
      pad={3}
      gap={3}
      align="center"
      justify="center"
      textAlign="center"
      w="full"
      {...props}
    >
      <Box bgColor="springWood.500" rounded="md" w="100%">
        <Stack
          rounded="md"
          direction="column"
          align="center"
          justify="center"
          px={3}
          pt={3}
          pb={1}
          maxW="600px"
          mx="auto"
        >
          {!hideLabel && (
            <Text fontSize="sm">
              You can invite someone at anytime during the process by copying
              and sending them the link below by email, text message, or social
              media platform.
            </Text>
          )}

          <Box
            w="full"
            p="xs"
            rounded="md"
            border="1px"
            borderColor="gray.300"
            bgColor="white"
          >
            <Text>
              <ClickToCopy onCopy={onCopy} url={url} />
            </Text>
          </Box>
          <ClickToCopy asButton onCopy={onCopy} url={url} />

          <Button variant="unstyled" fontWeight="normal" onClick={onOpen}>
            <HStack>
              <FontAwesomeIcon icon={["fas", "qrcode"]} color={airBlue} />
              <Text>Download QR Code</Text>
            </HStack>
          </Button>
          <ModalDialog isOpen={isOpen} onClose={onClose} heading="">
            <QRCode url={qrUrl} />
          </ModalDialog>
          {!hideDisclaimer && (
            <Text fontSize="xs" textAlign="center">
              *You will also be able to copy and send this link after launching
            </Text>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default InviteLinkBox;

interface iInviteLinkBox extends StackProps {
  slug: string;
  label?: string;
  hideDisclaimer?: boolean;
  hideLabel?: boolean;
  onCopy?: (...args: any[]) => any;
}
