import React, { useState, useEffect } from "react";
import { Button, Stack, Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import checkAuth from "../../utils/checkAuth";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { signin, signup, signinWithFacebook, updateProfile, getProfileFromProviders } from "../../utils/authHandlers";
import { useNavigate } from "react-router-dom";
import PageContainer from "../../layout/components/PageContainer";
import AuthForm from "./AuthForm";
import LoginPage from "./LoginPage";
import SignupPage from "./SignupPage";
import { useAnalytics } from "use-analytics";
import { useLocalStorage } from "../../hooks";
import assignPartners from "../partners/utils/assignPartners";

const AuthPage = (props) => {
  const { authMode } = props;
  const [partners, setPartners] = useLocalStorage("partner_ids");
  console.log({partners})

  const { auth, profile } = useSelector((state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }));
  const [authChecks, setAuthChecks] = useState();
  const [state, setState] = useState({
    errors: null,
    isDirty: false,
    isValid: false,
  });

  const [emailExists, setEmailExists] = useState();
  const [error, setError] = useState();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const navigate = useNavigate();
  const { track } = useAnalytics();

  const handleFacebookLogin = signinWithFacebook(firebase);
  const handleSignin = signin(firebase);
  const handleSignup = signup(firebase, firestore);

  useEffect(() => {
    setAuthChecks(checkAuth({ auth, profile }));
  }, [auth, profile]);

  const handleChange = (values) => {
    setState(values);
  };

  const assignPendingPartners = async (user) => {
    console.log("assigning pending partners", user, partners);
    if(partners.length > 0)
      await assignPartners(user, partners).then(() => {
        console.log("assigned pending partners");
        setPartners([]);
      }).catch((err) => console.log(err));
  }

  const onSubmit = async () => {
    setError();
    track(`Clicked continue on ${authMode} form`);
    switch (authMode) {
      case "login": {
        const pendingCredential = error && error.credential;
        await handleSignin(state, pendingCredential)
          .then(({user}) => assignPendingPartners(user))
          .catch((err) => {
            setError({ ...err, credential: pendingCredential });
          });
        break;
      }
      case "signup": {
        await handleSignup(state)
          .then((user) => assignPendingPartners(user))
          .catch((err) => {
            const emailExists = err.code === "auth/email-already-in-use";
            setError(err);
            setEmailExists(emailExists);
          });
        break;
      }
      default:
        break;
    }
  };

  const onModeChange = (authMode) => {
    setState({ ...state, isDirty: false });
    navigate(`/${authMode}`);
  };

  const onSigninWithFacebook = async () => {
    await handleFacebookLogin()
      .then(({additionalUserInfo,user}) => {
        return updateProfile(firestore)(user, getProfileFromProviders(additionalUserInfo));
      })
      .then((user) => assignPendingPartners(user))
      .catch((err) => {
        console.log('FB login error', err);
        if (err.code === "auth/account-exists-with-different-credential") {
          const auth = firebase.auth();
          // The pending Facebook credential. (error.credential)
          // The provider account's email address.
          var email = err.email;
          auth.fetchSignInMethodsForEmail(email).then(function (methods) {
            if (methods[0] === "password") {
              setEmailExists(emailExists);
              setError(err);
            }
          });
        } else {
          setError(err);
        }
      });
  };

  return (
    <PageContainer minW={undefined} maxW="90vw" w="5xl" h="full">
      {authMode === "login" && (
        <LoginPage
          authForm={
            <Stack>
              <AuthForm
                error={error}
                defaultAuthMode="login"
                authChecks={authChecks}
                emailExists={emailExists}
                onChange={handleChange}
                onModeChange={onModeChange}
                signinWithFacebook={onSigninWithFacebook}
                track={track}
              />
              <Flex
                minW="full"
                direction="row-reverse"
                align="center"
                justify="center"
                p="sm"
              >
                <Button
                  type="submit"
                  colorScheme="seaGreen"
                  mr={0}
                  sx={{
                    "&:disabled": {
                      opacity: 1,
                      backgroundColor: "seaGreen.200",
                    },
                    "&:disabled:hover": {
                      opacity: 1,
                      backgroundColor: "seaGreen.200",
                    },
                  }}
                  onClick={onSubmit}
                  disabled={!state.isDirty || !state.isValid}
                  // justifySelf="center"
                >
                  Log in
                </Button>
              </Flex>
            </Stack>
          }
        />
      )}
      {authMode === "signup" && (
        <SignupPage
          authForm={
            <Stack>
              <AuthForm
                error={error}
                defaultAuthMode="signup"
                authChecks={authChecks}
                emailExists={emailExists}
                onChange={handleChange}
                onModeChange={onModeChange}
                signinWithFacebook={onSigninWithFacebook}
                showAuthMethodSwitch={false}
                track={track}
              />
              <Flex
                minW="full"
                direction="row-reverse"
                align="center"
                justify="center"
                pt="md"
              >
                <Button
                  type="submit"
                  colorScheme="seaGreen"
                  mr={0}
                  sx={{
                    "&:disabled": {
                      opacity: 1,
                      backgroundColor: "seaGreen.200",
                    },
                    "&:disabled:hover": {
                      opacity: 1,
                      backgroundColor: "seaGreen.200",
                    },
                  }}
                  onClick={onSubmit}
                  disabled={!state.isDirty || !state.isValid}
                  // justifySelf="center"
                >
                  Get started
                </Button>
              </Flex>
            </Stack>
          }
        />
      )}
    </PageContainer>
  );
};

export default AuthPage;
