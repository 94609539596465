import React from "react";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/react";

import usePubSub from "./hooks/usePubSub";

import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import LoadingPage from "./pages/LoadingPage";
import withRoutableAnalytics from "./components/analytics/withRoutableAnalytics";
import withAnalyticsPageChange from "./components/analytics/withAnalyticsPageChange";
import AppRoutes from "./routes/AppRoutes";


export * from "../src/initFontAwesome";

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) return <LoadingPage />;
  return children;
}

const InnerApp = withRoutableAnalytics(
  withAnalyticsPageChange(() => {
    return (
      <AppRoutes />
    );
  })
);

const App = () => {
  let { PubSubContext, publish, subscribe, unsubscribe } = usePubSub();

  return (
    <PubSubContext.Provider value={{ publish, subscribe, unsubscribe }}>
      <Helmet>
        <title>Quilted</title>
        <meta name="description" content="Quilted stories" />
      </Helmet>
      <AuthIsLoaded>
        <BrowserRouter>
          <InnerApp />
        </BrowserRouter>
      </AuthIsLoaded>
    </PubSubContext.Provider>
  );
};

const mapStateToProps = () => {
  return {};
};

export default Sentry.withProfiler(connect(mapStateToProps)(App));
