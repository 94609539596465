/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import initRollout, { flags, RolloutProps } from "../../middleware/rollout";
import { FeatureFlags } from "./types";

const featureFlagContext = React.createContext({ ...flags });

export const useFeatureFlags = (): FeatureFlags => {
  const context = React.useContext(featureFlagContext);
  if (context === undefined) {
    throw new Error(
      "useFeatureFlags must be used within a FeatureFlagsProvider"
    );
  }
  return context;
};

const FeatureFlagsProvider: React.FC<RolloutProps> = ({
  children,
  namespace,
  apiKey,
  debug,
}) => {
  const [value, setValue] = useState({ ...flags });
  const { Provider } = featureFlagContext;

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    initRollout({ namespace, apiKey, debug })
      .then((_flags) => {
        setValue((data) => ({
          ...data,
          ..._flags,
        }));
      })
      .catch(() => {
        console.error("Feature flags are unavailable!");
      })
      .finally(() => !loaded && setLoaded(true));
  }, [namespace, apiKey, flags]);

  return loaded && <Provider value={value}>{children}</Provider>;
};

export default FeatureFlagsProvider;
