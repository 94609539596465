const getVideoInfo = url =>
  new Promise((resolve, reject) => {
    const videoTag = document.createElement('video')
    videoTag.preload = 'metadata'
    videoTag.muted = true
    videoTag.defaultMuted = true
    videoTag.playsInline = true
    videoTag.autoplay = true

    let resolved = false

    const handleTimeout = () => {
      if (!resolved) {
        console.log('Timeout on getVideoInfo!')
        resolved = true
        resolve({
          duration: null
        })
        videoTag.removeEventListener &&
          videoTag.removeEventListener('loadeddata', handleLoadedData)
      }
    }

    const timeout = setTimeout(handleTimeout, 1000)

    const handleLoadedData = () => {
      // Chromium duration Infinity bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
      if (videoTag.duration === Infinity) {
        videoTag.currentTime = Number.MAX_SAFE_INTEGER
        videoTag.ontimeupdate = () => {
          videoTag.ontimeupdate = null
          clearTimeout(timeout)
          resolve({ duration: videoTag.duration })
          resolved = true
          videoTag.currentTime = 0
        }
      }
      else {
        clearTimeout(timeout)
        resolve({ duration: videoTag.duration })
        resolved = true
      }
    }

    videoTag.addEventListener('loadeddata', handleLoadedData)
    videoTag.onerror = (event) => {
      console.log('getVideoInfo.onerror', event);
      reject(event.target.error);
    };
    videoTag.src = url
  })

export default getVideoInfo
