import React from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Stack,
  Container,
  Heading,
  Text,
} from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { BabyShowerVideo } from "../common/QuiltedVideo";
import HowItWorks from "../common/HowItWorks";

const SignupPage = (props: { authForm: React.ReactNode }) => {
  const navigate = useNavigate();

  return (
    <>
      <Flex
        w="full"
        px="sm"
        py="xs"
        my="sm"
        rounded="md"
        bgColor="airBlue.50"
        justify="center"
      >
        <HStack>
          <Text variant="serif">Already have an account?</Text>
          <Button
            variant="plain"
            fontFamily="serif"
            color="airBlue.700"
            p="0"
            onClick={() => navigate("/login")}
          >
            Log in
          </Button>
        </HStack>
      </Flex>
      <Flex
        w="full"
        h="full"
        direction={["column", "column", "row"]}
        py="lg"
        justify="space-between"
      >
        <Container textAlign="center" mb="lg">
          <Stack mr={!isMobile ? "xl" : 0} align="center">
            <Heading as="h2" size="h2" variant="center">
              Get started with Quilted
            </Heading>
            <Text>
              Quilted enables you and your loved ones to tell your favorite
              stories through curated short videos and create a final movie to
              be gifted for <br />
              any special occasion.
            </Text>
            {!isMobile && (
              <>
                <HowItWorks />
                <Box w="80%">
                  <BabyShowerVideo />
                </Box>
              </>
            )}
          </Stack>
        </Container>
        {isMobile && <BabyShowerVideo />}
        <Flex
          justify="center"
          h="full"
          direction="column"
          p="lg"
          shadow="lg"
          bgColor="springWood.100"
          rounded="lg"
          align="center"
        >
          {props.authForm}
        </Flex>
      </Flex>
    </>
  );
};

export default SignupPage;
