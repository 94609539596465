import config from '../config/config';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const sentryConfig = {dsn: "https://6a3fcf73caa24e04af43327c5f2c051d@sentry.io/1873031", ...(config.sentrySettings && config.sentrySettings.config) || {} }

if(config.sentrySettings && config.sentrySettings.includeTracing) {
    sentryConfig.integrations = [new Integrations.BrowserTracing()]
    sentryConfig.tracesSampleRate = 1.0
    console.log('Sentry: Including Tracing')
}

Sentry.init(sentryConfig);

export default Sentry;