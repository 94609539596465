/* eslint-disable react/prop-types */
import React from "react";
import {
  Box,
  Flex,
  Image,
  Show,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { MotionBox } from "../common/Motions";
import { themeColors } from "../../chakraTheme";
import { usePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface AnnouncementBoxProps {
  colorScheme: themeColors | string;
  icon: IconProp;
}

const AnnouncementBox: React.FC<AnnouncementBoxProps> = ({
  colorScheme: color,
  icon,
  children,
}) => {
  const colorScale = useColorModeValue("300", "50");
  const colorScaleIcon = useColorModeValue("500", "100");
  return (
    <Flex
      gap={4}
      borderColor={`${color}.${colorScale}`}
      borderWidth="2px"
      py={4}
      pl={4}
      pr={5}
      rounded="md"
      maxW="container.md"
    >
      <Show above="sm">
        <Box display="flex" alignItems="center" justifyContent="center">
          <Text color={`${color}.${colorScaleIcon}`}>
            <FontAwesomeIcon icon={icon} size="2x" />
          </Text>
        </Box>
      </Show>
      <Box flex="1">{children}</Box>
    </Flex>
  );
};

export const MotionAnnouncementBox: React.FC<{ key: any }> = ({ children }) => {
  const [isPresent, safeToRemove] = usePresence();
  const transition = { type: "spring", stiffness: 500, damping: 50, mass: 1 };
  const animations = {
    layout: true,
    initial: "out",
    style: {
      position: isPresent ? "static" : "absolute",
    },
    animate: isPresent ? "in" : "out",
    whileTap: "tapped",
    whileHover: "hover",
    whileFocus: "hover",
    variants: {
      in: { scaleY: 1, opacity: 1, zIndex: 0, transition: { duration: 0.5 } },
      out: { scaleY: 0, opacity: 0, zIndex: -1, transition: { duration: 0.5 } },
      tapped: { scale: 0.95, transition: { duration: 0.25 } },
      hover: { zIndex: 1 },
    },
    onAnimationComplete: () => !isPresent && safeToRemove && safeToRemove(),
    transition,
  };

  //@ts-ignore
  return <MotionBox {...animations}>{children}</MotionBox>;
};

export default AnnouncementBox;
