import { useActor } from "@xstate/react";
import React from "react";
import UserInfoForm from "../../../components/response/components/UserInfoForm";
import Respond from "../../../pages/StoryView/components/Respond";
import { useStoryViewContext } from "../../../pages/StoryView/components/StoryViewProvider";

const ResponseAuth = () => {
  const { story, auth: currentAuth, setAuth, service } = useStoryViewContext();
  const [state, send] = useActor(service);

  const identified = (identity: any) => {
    //TODO: Add tracking.
    send({ type: "IDENTIFIED", value: identity });
    setAuth(identity);
  };

  console.log("Rendering ResponseAuth Component", { currentAuth });

  if (!currentAuth && story) {
    return (
      <UserInfoForm
        title={story.title}
        owner={story.owner}
        onContinue={(value) => {
          identified(value);
        }}
      />
    );
  } else return null;
};

const RespondRoute = () => {
  const { auth: currentAuth } = useStoryViewContext();

  console.log("Rendering RespondRoute Component", { currentAuth });

  if (!!currentAuth)
    return (
      <>
        <Respond />
      </>
    );

  if (!currentAuth) {
    return <ResponseAuth />;
  } else return null;
};

export default RespondRoute;
