import React from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';

interface CompleteCardViewProps { colorScheme:string, onClick: () => void }
export const CompleteCardView:React.FC<CompleteCardViewProps> = ({colorScheme, onClick }:CompleteCardViewProps) => {
    return (<Box>
        <Heading as='h4' size='md' mb={3}>Record Your Responses</Heading>
        <Text mb={3}>You&apos;re recorded all of your responses, but you can re-record them if you&apos;d like.</Text>
        <Button colorScheme="blackAlpha" variant="outline" onClick={onClick}>Re-record my responses</Button>
    </Box>)
}

export default CompleteCardView;