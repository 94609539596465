import React, { useEffect } from "react";
import ModalDialog from "../../common/ModalDialog";
import {
  Flex,
  Divider,
  Stack,
  Text,
  HStack,
  Heading,
  Button,
  Image,
  Badge,
  Container,
  useBreakpointValue,
} from "@chakra-ui/react";
import svgDiy from "../../../assets/diy-option.png";
import svgConcierge from "../../../assets/concierge-option.png";
// @ts-ignore
import { useAnalytics } from "use-analytics";
import { Story } from "../../../types/StoryManagerState";
import { buildIllustrationUrl } from "../../../utils/buildIllustrationUrl";
import getPricing from "../../../utils/pricing";

const EditorMethodModal = ({
  isOpen,
  onClose,
  selectFunction,
  story,
}: EditorMethodModalProps) => {
  const { track } = useAnalytics();
  const responsiveValues: { stack: any; modalContent?: any } | undefined =
    useBreakpointValue({
      base: {
        stack: {
          spacing: 5,
          pb: 20,
          divider: <Divider orientation="horizontal" w="100%" />,
        },
      },
      lg: {
        stack: {
          spacing: 6,
          h: "400px",
          divider: <Divider orientation="vertical" h="100%" />,
        },
        modalContent: {
          width: "full",
          maxW: "950px",
        },
      },
    });

  useEffect(() => {
    if (isOpen) {
      track("finalVideo | Shown Method Modal", {
        storyId: story?.id,
        storyTitle: story?.title,
        storySubject: story?.subject,
        templateName: story?.template?.title,
        templateIllustration: buildIllustrationUrl(
          story?.template?.illustration
        ),
      });
    }
  }, [isOpen]);

  const { diy: diyPricing, concierge: conciergePricing } = getPricing(
    story.templateId as string
  );

  return (
    <ModalDialog
      heading="Start your final video"
      isOpen={isOpen}
      onClose={onClose}
      modalContent={responsiveValues?.modalContent}
    >
      <Stack spacing={5} textAlign="center">
        <Container>
          <Text>
            We have two options for creating your final video. This decision
            isn&apos;t final. If you start with our DIY builder, you can always
            switch to concierge later.
          </Text>
        </Container>
        <Stack
          justify="space-around"
          align="center"
          direction={["column", "row"]}
          {...responsiveValues?.stack}
        >
          <Stack flexBasis="1/2" flex="1" alignItems="center" maxW="425px">
            <HStack spacing="md">
              <Heading as="h3" size="h4">
                Build it yourself
              </Heading>
              <Badge colorScheme="majesty" fontSize="1rem" px="sm">
                <HStack>
                  {!!diyPricing?.discount && (
                    <Text as="s" fontWeight="thin">
                      {"$"}
                      {diyPricing?.price?.toFixed(2)}
                    </Text>
                  )}
                  <Text>
                    {"$"}
                    {(diyPricing?.price - (diyPricing?.discount || 0)).toFixed(
                      2
                    )}
                  </Text>
                </HStack>
              </Badge>
            </HStack>
            <Flex w="180px" h="180px" justify="center" align="center">
              <Image src={svgDiy} />
            </Flex>
            <Text fontSize="sm">
              Feeling creative? Our easy-to-use editor puts you in control.
              Customize your movie exactly how you want it and get the finished
              copy in a matter of hours.
            </Text>
            <Button onClick={() => selectFunction(false)} colorScheme="majesty">
              DIY Video Builder
            </Button>
            <Text color="gray.600" fontSize="xs" as="i">
              Payment of {"$"}
              {(diyPricing?.price - (diyPricing?.discount || 0)).toFixed(2)} is
              due when you are done editing and your movie is finalized.
            </Text>
          </Stack>
          <Stack flexBasis="1/2" flex="1" alignItems="center" maxW="425px">
            <HStack spacing="md">
              <Heading as="h3" size="h4">
                Have us build it
              </Heading>
              <Badge colorScheme="tumbleweed" fontSize="1rem" px="sm">
                <HStack>
                  {!!conciergePricing?.discount && (
                    <Text as="s" fontWeight="thin">
                      {"$"}
                      {conciergePricing?.price?.toFixed(2)}
                    </Text>
                  )}
                  <Text>
                    {"$"}
                    {(
                      conciergePricing?.price -
                      (conciergePricing?.discount || 0)
                    ).toFixed(2)}
                  </Text>
                </HStack>
              </Badge>
            </HStack>
            <Flex w="180px" h="180px" justify="center" align="center">
              <Image src={svgConcierge} />
            </Flex>
            <Text fontSize="sm">
              If you prefer a hands-off approach, let us do the editing for you.
              We&apos;ll make a few basic edits to make it look good, including
              trimming silence and adding background music.
            </Text>
            <Button
              colorScheme="tumbleweed"
              onClick={() => selectFunction(true)}
            >
              Concierge
            </Button>

            <Text color="gray.600" fontSize="xs" as="i">
              Payment of {"$"}
              {(
                conciergePricing?.price - (conciergePricing?.discount || 0)
              ).toFixed(2)}{" "}
              is due before we start editng your video.
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </ModalDialog>
  );
};

interface EditorMethodModalProps {
  selectFunction: (val: boolean) => void;
  isOpen: boolean;
  onClose: () => void;
  story: Story;
}

export default EditorMethodModal;
