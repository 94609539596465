import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import modalTheme from "./modalTheme";
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';

import boxBg1 from "./assets/box-bg-1.svg";
import boxBg2 from "./assets/box-bg-2.svg";

const breakpoints = createBreakpoints({
  sm: "30em",
  md: "48.75em",
  lg: "75em",
  xl: "80em",
  "2xl": "96em",
});

export const themeColors = {
  powder: "#F9F8F2",
  seaGreen: {
    50: "#F5F8F6",
    100: "#E7EEE7",
    200: "#CEDED0",
    250: "#B6CEB8",
    300: "#AAC5AC",
    400: "#92B595",
    500: "#86AC89",
    600: "#6D9C71",
    700: "#537955",
    800: "#426145",
    900: "#19241A",
  },
  airBlue: {
    50: "#EDF4F9",
    100: "#D2E4EF",
    200: "#B4D2E4",
    300: "#A5C9DF",
    400: "#87B8D4",
    500: "#75ADCD",
    600: "#5A9DC4",
    700: "#418AB4",
    800: "#316787",
    900: "#0B171E",
  },
  tumbleweed: {
    50: "#FDF9F5",
    100: "#F8E8DD",
    200: "#F2D2BB",
    300: "#EEC6AA",
    400: "#EBBB99",
    500: "#E7AF87",
    600: "#DD8E55",
    700: "#D77733",
    800: "#AA5B22",
    900: "#221207",
  },
  puce: {
    50: "#F9F1F2",
    100: "#EDD4D7",
    200: "#E6C6CC",
    300: "#DAAAB2",
    400: "#D39CA5",
    500: "#CD8C97",
    600: "#C1717E",
    700: "#B45565",
    800: "#71323C",
    900: "#2B1217",
  },
  majesty: {
    50: "#F1EDF3",
    100: "#DACEDE",
    200: "#C8B6CE",
    300: "#B69DBE",
    400: "#A385AD",
    500: "#9572A1",
    600: "#876293",
    700: "#70527A",
    800: "#4F3956",
    900: "#2D2031",
  },
  springWood: {
    50: "#FEFCFD",
    100: "#FDFBFB",
    200: "#FCF9F9",
    300: "#FBF8F5",
    400: "#FAF8F4",
    500: "#F9F8F2",
    600: "#CECDC8",
    700: "#A2A19D",
    800: "#767673",
    900: "#4B4B49",
  },
};

const RecordButton = {
  parts: ["box", "button", "meter"],
  baseStyle: {
    box: {
      position: "relative",
      width: "25vw",
      height: "25vw",
      maxWidth: "80px",
      maxHeight: "80px",
    },
    button: {
      position: "absolute",
      zIndex: 30,
      background: "puce.600",
      alignItems: "center",
      width: "25vw",
      height: "25vw",
      maxWidth: "78px",
      maxHeight: "78px",
      borderRadius: "50%",
      color: "white",
      _hover: {
        background: "puce.700",
        border: 0,
        _disabled: {
          background: "puce.100",
        },
      },
      _active: {
        background: "puce.400",
        border: 0,
      },
      _focus: {
        background: "puce.400",
        border: 0,
      },
    },
    meter: {
      position: "absolute",
      zIndex: 20,
      transform: "scale(1.15)",
      transformOrigin: "center 54%",
      opacity: 0.5,
    },
  },
  sizes: {
    xs: {
      meter: {
        transform: "scale(1.125)",
        transformOrigin: "center 63%",
      },
    },
    sm: {
      box: {
        width: "20vw",
        height: "20vw",
      },
      button: {
        width: "20vw",
        height: "20vw",
      },
      meter: {
        transform: "scale(1.25)",
      },
    },
    md: {
      box: {},
      button: {},
      meter: {
        transform: "scale(1.325)",
      },
    },
    lg: {
      box: {},
      button: {},
      meter: {
        transform: "scale(1.325)",
      },
    },
    xl: {
      box: {},
      button: {},
      meter: {
        transform: "scale(1.325)",
      },
    },
  },
  // Two variants: rounded and smooth
  variants: {
    base: {
      meter: {},
    },
    recording: {
      button: {
        background: "gray.600",
        _hover: {
          background: "gray.700",
          _disabled: {
            background: "gray.100",
          },
        },
        _active: { background: "gray.500" },
        _focus: { background: "gray.500" },
      },
      meter: {},
    },
  },
  defaultProps: {
    variant: "base",
    size: "md",
  },
};

const CustomStepsStyleConfig = {
  ...Steps,
  baseStyle: (props: any) => {
    return {
      ...Steps.baseStyle(props),
      label: {
        ...Steps.baseStyle(props).label,
        fontFamily: "body"
      }
    };
  },
  variants: {
    mobile: {
      step: {
        fontFamily: "body"
      },
      label: {
        fontFamily: "heading"
      }
    }
  },
};

export const theme = extendTheme(
  {
    initialColorMode: "light",
    useSystemColorMode: false,
    breakpoints,
    styles: {
      global: {
        th: {
          fontFamily: "body",
        },
        Text: {
          fontFamily: "body!important"
        }
      },
    },
    colors: {
      ...themeColors,
    },
    fonts: {
      heading: "Libre Baskerville",
      body: "Libre Franklin",
    },
    components: {
      Divider: {
        baseStyle: {
          borderColor: "gray.500",
        },
      },
      Button: {
        baseStyle: {
          rounded: "full",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "20px",
        },
        variants: {
          solid: {
            pl: "10",
            pr: "10",
          },
        },
      },
      Text: {
        variants: {
          serif: {
            fontFamily: "Libre Baskerville",
          },
        },
      },
      Table: {
        baseStyle: {
          th: {
            fontFamily: "body",
            textTransform: "capitalize",
          },
        },
      },
      Heading: {
        baseStyle: {
          lineHeight: 1.3,
          fontWeight: 400,
        },
        variants: {
          plain: {
            fontFamily: "Libre Baskerville",
            _after: {
              content: "none",
            },
          },
          sans: {
            fontFamily: "Libre Franklin",
            _after: {
              content: "none",
            },
          },
          center: {
            textAlign: "center",
            _after: {
              marginTop: "0",
              marginRight: "auto",
              marginBottom: "0",
              marginLeft: "auto",
            },
          },
          squiggle: {
            mb: "1rem",
            _after: {
              fontFamily: "quilted-fonts",
              content: `"\\21"`,
              fontSize: "0.5rem",
              width: "54px",
              height: "10px",
              display: "block",
              paddingTop: "16px",
            },
          },
        },
        sizes: {
          h1: {
            fontSize: "4xl",
          },
          h2: {
            fontSize: "3xl",
            // manually need to add bottom margin to overcome spacing issues when in a stacked container
            mb: "1rem",
            _after: {
              fontFamily: "quilted-fonts",
              content: `"\\21"`,
              fontSize: "0.5rem",
              width: "54px",
              height: "10px",
              display: "block",
              paddingTop: "16px",
            },
          },
          h3: {
            fontSize: "2xl",
          },
          h4: {
            fontSize: "xl",
          },
          h5: {
            fontSize: "lg",
          },
          h6: {
            fontSize: "md",
            fontFamily: "Libre Baskerville",
          },
        },
      },
      FormLabel: {
        variants: {
          smHeadingLabel: {
            fontFamily: "Libre Baskerville",
            fontWeight: 400,
            fontSize: "md",
          },
          headingLabel: {
            fontFamily: "Libre Baskerville",
            fontWeight: 400,
            fontSize: "lg",
          },
        },
      },
      Input: {
        defaultProps: {
          focusBorderColor: "airBlue.500",
        },
        baseStyle: {
          fontWeight: 400,
          borderRadius: "5px",
          fontSize: "lg",
        },
      },
      RecordButton,
      Modal: modalTheme,
      Steps: CustomStepsStyleConfig
    },
    space: {
      xs: "0.5rem",
      sm: "0.75rem",
      md: "1.25rem",
      lg: "1.75rem",
      xl: "2.25rem",
      xxl: "3rem",
    },
  },
  withDefaultColorScheme({
    colorScheme: "seaGreen",
    components: ["Button"],
  })
);

export type themeColors =
  | "seaGreen"
  | "tumbleweed"
  | "puce"
  | "airBlue"
  | "majesty";
export const themeColorSchemes = ["seaGreen", "tumbleweed", "puce", "airBlue"];

export const getColorScheme = (i: number) => {
  const limit = themeColorSchemes.length;
  const idx = i % limit;
  return themeColorSchemes[idx];
};

export const getBoxBg = (i: number) => {
  return i % 2 === 0 ? `url(${boxBg1})` : `url(${boxBg2})`;
};

//console.log("Chakra Theme", { theme });

export default theme;
