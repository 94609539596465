import React, { useEffect } from "react";
import {
  Flex,
  Container,
  Stack,
  Heading,
  Divider,
  Box,
  HStack,
  Text,
} from "@chakra-ui/react";
// @ts-ignore
import { useAnalytics } from "use-analytics";

const ProcessingScreenContent = () => {
  const { track } = useAnalytics();

  useEffect(() => {
    track("finalVideo | Shown Processing Screen");
  }, []);

  return (
    <Flex direction={["column","row"]} h="full" alignItems="center">
      <Container h="full" flexBasis="50%" textAlign="center">
        <Stack h="full" justify="center" align="center" p="lg">
          <Heading as="h2" size="h2" variant="center">
            We're building your movie!
          </Heading>
          <Text>
            Before you can view your finished movie, we need to assemble your
            final video. This typically takes 1-2 hours and we'll email you when
            it's ready for you to view.
          </Text>
        </Stack>
      </Container>
      <Divider orientation="vertical" h="80%" />
      <Container h="full" flexBasis="50%">
        <Stack h="full" justify="center" align="flex-start" p="lg" spacing="lg">
          <Heading as="h4" size="h4">
            What's next?
          </Heading>
          <Box ml="20px">
            <Stack borderLeft="1px solid grey" pl="20px" pb="1rem">
              <HStack ml="-30px">
                <Box w="20px" h="20px" bgColor="majesty.500" rounded="full" />
                <Heading
                  as="h5"
                  size="h5"
                  variant="sans"
                  fontWeight="bold"
                  fontSize="sm"
                >
                  Preview and perfect your movie
                </Heading>
              </HStack>
              <Text fontSize="xs">
                You'll have the chance to watch a preview of your movie to make
                sure it's just how you like it. You can go back and continue
                editing it if it's not quite right.
              </Text>
            </Stack>
            <Stack borderLeft="1px solid grey" pl="20px" pb="1rem">
              <HStack ml="-30px">
                <Box w="20px" h="20px" bgColor="puce.500" rounded="full" />
                <Heading
                  as="h5"
                  size="h5"
                  variant="sans"
                  fontWeight="bold"
                  fontSize="sm"
                >
                  Finalize and pay when you're happy
                </Heading>
              </HStack>
              <Text fontSize="xs">
                Once you've perfected your masterpiece, you'll be ready to pay
                for your final, high resolution, non-watermarked movie.
              </Text>
            </Stack>
            <Stack pl="20px" pb="1rem">
              <HStack ml="-30px">
                <Box w="20px" h="20px" bgColor="airBlue.500" rounded="full" />
                <Heading
                  as="h5"
                  size="h5"
                  variant="sans"
                  fontWeight="bold"
                  fontSize="sm"
                >
                  Share your movie with our physical products
                </Heading>
              </HStack>

              <Text fontSize="xs">
                At checkout, you'll be able to choose from several of our
                physical products to help share your story. We offer both a USB
                drive and a physical video card so you can bring your story to
                life with whomever you choose to share it with.
              </Text>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Flex>
  );
};

export default ProcessingScreenContent;
