import {
  Box,
  Heading,
  Stack,
  Text,
  Button,
  Container,
  useToast
} from "@chakra-ui/react";
import React from "react";
import { useFirestore } from "react-redux-firebase";
import { useNavigate } from "react-router-dom";
import PageContainer from "../../layout/components/PageContainer";
import StorySettings from "./settings/StorySettings";
import { StoryConsumer } from "./StoryProvider";
import { timestampToDate } from "../../utils/convert";

import isDeepEqual from "fast-deep-equal/react";
import { Helmet } from "react-helmet";

const StorySettingsView = (props) => {
  const firestore = useFirestore();
  const navigate = useNavigate();
  const toast = useToast();

  const { story } = props;
  const isLegacy = story.template.type === 0;
  const [saving, setSaving] = React.useState(false);
  const [reminders, setReminders] = React.useState(
    story && story.reminders
  );
  const [changed, setChanged] = React.useState(false);
  const [dueDate, setDueDate] = React.useState(story && story.dueDate);

  const onComplete = () => {
    toast({
      title: "Settings Updated!",
      description: "Your Story has been updated",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  }

  const save = React.useCallback(() => {
    if(!changed) return navigate(-1);

    setSaving(true);
    return firestore
      .collection("stories")
      .doc(story.id)
      .update({ reminders, dueDate })
      .then(() => {
        setSaving(false);
      })
      .catch((error) => { console.error(error)})
      .finally(() => { onComplete(); navigate(-1)});
  }, [firestore, reminders, dueDate, story.id, changed]);

  const onChange = (settings) => {
    setReminders(settings.reminders);
    setDueDate(settings.dueDate);
    const remindersChanged = !isDeepEqual(settings.reminders,story.reminders)
    const dueDateChanged = !isDeepEqual(timestampToDate(settings.dueDate),timestampToDate(story.dueDate));
    
    setChanged(remindersChanged || dueDateChanged);
  };

  return (
    <PageContainer>
      <Helmet>
        <title>Quilted | Manage Story Settings</title>
      </Helmet>
      <Stack justify="center" py="sm" spacing={8}>
        <Container textAlign="center">
          <Stack>
            <Heading as="h2" size="h2" variant="center">
              Story Settings
            </Heading>
            <Text>
              When would you like people to complete their submissions by? This
              will be shown to your storytellers and be used to set reminders.
            </Text>
          </Stack>
        </Container>
        <StorySettings dueDate={dueDate} reminders={reminders} isLegacy={isLegacy} onChange={onChange} />
        <Button
          onClick={save}
          isLoading={saving}
          loadingText="Saving"
        >Save</Button>
        </Stack>
    </PageContainer>
  );
};

const ManageStorySettings = () => {
  return (
    <StoryConsumer>
      {({ story }) => <StorySettingsView story={story} />}
    </StoryConsumer>
  );
};

export default ManageStorySettings;
