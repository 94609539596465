import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { connect } from "react-redux";

const PrivateOutlet = ({ auth, to }) => {
  let location = useLocation();

  if (!auth || !auth.isLoaded) return null;

  return !auth.isEmpty ? (
    <Outlet />
  ) : (
    <Navigate to={to || "/signup"} state={{ from: location }} replace />
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};
export default connect(mapStateToProps)(PrivateOutlet);
