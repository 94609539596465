import { useDisclosure } from "@chakra-ui/react";
import { WriteBatch } from "@firebase/firestore-types";
import React from "react";

import { useFirestore } from "react-redux-firebase";
import { useNavigate } from "react-router-dom";
import { finalMovieState } from "../components/dashboard";
import Dashboard from "../components/dashboard/Dashboard";
import EditorMethodModal from "../components/story/finalVideo/EditorMethodModal";
import FinalVideoView from "../components/story/FinalVideoView";
import {
  Response,
  StoryConsumer,
  Submission,
} from "../components/story/StoryProvider";
import { useAuth } from "../hooks";
import {
  InvitationsContext,
  Reminders,
  Storyteller,
} from "../machines/Dashboard/invitationsMachine";
import buildStoryUrl from "../utils/buildStoryUrl";
import { timestampToMoment } from "../utils/convert";
import getStoryStates from "../utils/story";

const StoryDashboard = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const auth = useAuth();
  const firestore = useFirestore();

  const saveInvitations = (
    id: string,
    contacts: Storyteller[],
    batch: WriteBatch
  ) => {
    if (!auth.currentUser) throw Error("Please sign in to save changes");
    const ref = firestore
      .collection("stories")
      .doc(id)
      .collection("invitations")
      .doc();
    const invitation = {
      createdAt: new Date(),
      contacts,
      status: "pending",
      uid: auth.currentUser.uid,
    };
    batch.set(ref, invitation);

    const refStory = firestore.collection("stories").doc(id);
    batch.update(refStory, {
      storytellers: firestore.FieldValue.arrayUnion(...contacts),
      inviteMethod: "invites",
      updatedAt: new Date(),
    });
  };

  const onSaveStorytellers = async (id: string, contacts: Storyteller[]) => {
    const batch = firestore.batch();
    saveInvitations(id, contacts, batch);
    return batch.commit();
  };

  const onSaveDueDate = async (id: string, dueDate: Date) =>
    onSaveChanges(id, { dueDate });
  const onSaveReminders = async (id: string, reminders: Reminders) =>
    onSaveChanges(id, { reminders });

  const onSaveChanges = (id: string, changes: { [key: string]: any }) => {
    return firestore
      .collection("stories")
      .doc(id)
      .update({ ...changes, updatedAt: new Date() })
      .then(() => true);
  };

  const onSaveInviteMethod = (id: string, inviteMethod: "invites" | "link") => {
    return onSaveChanges(id, { inviteMethod });
  };

  const matchResponsesToStoryteller = (
    storytellers: Storyteller[],
    submissions: Submission[]
  ) => {
    storytellers.forEach((storyteller) => {
      storyteller.responses = submissions
        .filter(
          (submission) => submission.submittedBy.email === storyteller.email
        )
        .map(
          (submission) =>
            ({
              ...submission,
              id: submission.id,
              name: submission.submittedBy.displayName || "unknown",
              url:
                submission.type === "image"
                  ? submission.content.downloadUrl
                  : submission.content.streamUrl || "",
              thumbnail:
                submission.type === "image"
                  ? submission.content.downloadUrl
                  : submission.content.thumbnailPath || "",
              type: submission.type,
              processing:
                submission.type === "image"
                  ? false
                  : !submission.content.muxPlaybackId,
            } as Response)
        );
    });
  };

  return (
    <StoryConsumer>
      {({ story, submissions, diyVideos }) => {
        const goToRequestFinalVideo = (concierge: boolean) => {
          const complete =
            story?.dueDate &&
            timestampToMoment(story?.dueDate).isSameOrBefore();
          const hasSubmissions = submissions && submissions.length > 0;
          navigate(
            `${
              hasSubmissions
                ? concierge
                  ? "final-video"
                  : "diy"
                : "no-submissions"
            }${hasSubmissions && concierge && !complete ? "/early/" : ""}`
          );
        };

        const getFinalVideoState = (diyVideos: any[]) => {
          const state = {
            editing: diyVideos.some((diy: any) =>
              ["editing"].includes(diy.state)
            ),
            reviewing: diyVideos.some((diy: any) =>
              ["reviewing"].includes(diy.state)
            ),
            processing: diyVideos.some((diy: any) =>
              ["process", "processing"].includes(diy.state)
            ),
            complete: diyVideos.some((diy: any) =>
              ["complete"].includes(diy.state)
            ),
            final: diyVideos.some((diy: any) => !!diy.paid),
          };
          return {
            ...state,
            started:
              state.editing ||
              state.processing ||
              state.reviewing ||
              state.complete,
          };
        };

        if (!story) return null;

        const {
          id,
          dueDate,
          storytellers,
          reminders,
          title,
          prompts,
          slug,
          subject,
          inviteMethod,
          templateId,
        } = story;
        storytellers && matchResponsesToStoryteller(storytellers, submissions);
        const isLegacy = story.template && story.template.type === 0;
        const forceConcierge = story.template && !!story.template.conciergeOnly;
        const storyUrl = buildStoryUrl({ slug });
        const storyState = getStoryStates(story);
        const diyVideoState = getFinalVideoState(diyVideos);

        const finalVideo: {
          state: finalMovieState;
          final: boolean;
          isConcierge: boolean;
        } = {
          state: storyState.isComplete
            ? "complete"
            : (storyState.needsApproval && storyState.isFulfilled) ||
              storyState.revisionsRequested ||
              diyVideoState.reviewing
            ? "review"
            : storyState.finalVideoStarted ||
              storyState.revisionsRequested ||
              diyVideoState.processing
            ? "processing"
            : diyVideoState.started
            ? "started"
            : "notStarted",
          final: storyState.isConcierge || diyVideoState.final,
          isConcierge: storyState.isConcierge,
        };

        const onFinalVideoClick = () => {
          if (finalVideo.isConcierge && storyState.finalVideoStarted)
            navigate("final-video/concierge");
          else if (forceConcierge) goToRequestFinalVideo(true);
          else onOpen();
        };

        const myResponseCount =
          submissions &&
          submissions.filter((submission) => !!submission.isMine).length;

        return finalVideo.state === "complete" ? (
          <FinalVideoView />
        ) : (
          <>
            <EditorMethodModal
              isOpen={isOpen}
              onClose={onClose}
              selectFunction={goToRequestFinalVideo}
              story={story as any}
            />
            <Dashboard
              title={title}
              subject={subject}
              dueDate={dueDate}
              storytellers={storytellers}
              reminders={reminders}
              prompts={prompts}
              storyUrl={storyUrl}
              isLegacy={isLegacy}
              hasResponses={!!(submissions && submissions.length)}
              inviteMethod={inviteMethod}
              myResponseCount={myResponseCount}
              finalVideoState={finalVideo.state}
              finalVideoIsConcierge={finalVideo.isConcierge}
              finalVideoIsFinal={finalVideo.final}
              forceConcierge={forceConcierge}
              templateId={templateId}
              onFinalVideoClick={onFinalVideoClick}
              onSaveDueDate={async ({ data }: InvitationsContext) =>
                data && data.dueDate && onSaveDueDate(id, data.dueDate)
              }
              onSaveStorytellers={async ({ data }: InvitationsContext) =>
                data && data.invites && onSaveStorytellers(id, data.invites)
              }
              onSaveReminders={async ({ data }: InvitationsContext) =>
                data && data.reminders && onSaveReminders(id, data.reminders)
              }
              onSaveInviteMethod={async ({ data }: InvitationsContext) =>
                data?.inviteMethod && onSaveInviteMethod(id, data.inviteMethod)
              }
            />
          </>
        );
      }}
    </StoryConsumer>
  );
};

export default StoryDashboard;
