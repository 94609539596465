import React from "react";
import FinalVideoCard from "../../../components/story/finalVideo/FinalVideoCard";
import { useStoryViewContext } from "../../../pages/StoryView/components/StoryViewProvider";

const FinalRoute = () => {
  const { story } = useStoryViewContext();
  //@ts-ignore
  return <FinalVideoCard story={story} />;
};

export default FinalRoute;
