import formatPhoneNumber from "./formatPhoneNumber";

export const getProfileFromProviders = (additionalUserInfo) => {
  const profile = {
    acceptedTerms: true,
    sendMarketing: true,
    userAgent: (navigator && navigator.userAgent) || null,
    providerInfo: JSON.stringify(additionalUserInfo)
  };
  switch (additionalUserInfo.providerId) {
    case "facebook.com":
      return {
        ...profile,
        displayName: additionalUserInfo.profile.name,
        firstName: additionalUserInfo.profile.first_name || additionalUserInfo.profile.name.split(" ")[0],
        lastName: additionalUserInfo.profile.last_name || additionalUserInfo.profile.name.split(" ")[1],
        initials: `${additionalUserInfo.profile.first_name && additionalUserInfo.profile.first_name[0]}${additionalUserInfo.profile.last_name && additionalUserInfo.profile.last_name[0]}`,
        photoURL: additionalUserInfo.profile.picture && additionalUserInfo.profile.picture.data && additionalUserInfo.profile.picture.data.url || null,
      };
    case "google.com":
      return {
        ...profile,
        displayName: additionalUserInfo.profile.name,
        photoURL: additionalUserInfo.profile.picture,
      };
    default:
      return { ...profile, };
  }
};

export const updateProfile = (firestore) => (user, profile) => {
  console.log("updateProfile", user, profile);
  user.updateProfile(profile);
  return firestore
    .collection("users")
    .doc(user.uid)
    .set({ ...profile, uid: user.uid }, { merge: true })
    .then(() => user);
}

export const signup = (firebase, firestore) => (newUser) => {
  console.dir({ firebase, firestore });
  console.log("newUser", newUser);
  if (newUser.phone) {
    const p = formatPhoneNumber.forDisplay(newUser.phone) || newUser.phone;
    newUser.phone = formatPhoneNumber.forStorage(p);
  }
  const profile = {
    email: newUser.email,
    firstName: newUser.firstName,
    lastName: newUser.lastName,
    initials: `${newUser.firstName && newUser.firstName[0]}${newUser.lastName && newUser.lastName[0]
      }`,
    displayName: `${newUser.firstName} ${newUser.lastName}`,
    phoneNumber: newUser.phone || null,
    acceptedTerms: newUser.acceptedTerms || false,
    sendMarketing: newUser.sendMarketing || false,
    userAgent: (navigator && navigator.userAgent) || null,
  };
  const auth = firebase.auth();
  const promise = new Promise((resolve, reject) => {
    if (auth.currentUser && auth.currentUser.isAnonymous) {
      const credential = firebase.auth.EmailAuthProvider.credential(
        newUser.email,
        newUser.password
      );
      auth.currentUser
        .linkWithCredential(credential)
        .then(resolve)
        .catch(reject);
    } else {
      auth
        .createUserWithEmailAndPassword(newUser.email, newUser.password)
        .then(resolve)
        .catch(reject);
    }
  });

  return promise.then((userCred) => {
    const user = userCred.user;
    return updateProfile(firestore)(user, profile);
  });
};

export const signinWithFacebook = (firebase) => () => {
  var provider = new firebase.auth.FacebookAuthProvider();
  provider.addScope("email");
  return firebase.auth().signInWithPopup(provider);
};

export const signin = (firebase) => (credentials, pendingCredentials) => {
  return firebase
    .auth()
    .signInWithEmailAndPassword(credentials.email, credentials.password)
    .then((user) => {
      return pendingCredentials
        ? user.linkWithCredential(pendingCredentials).then(() => user)
        : user;
    });
};

export default { signup: signup, signin, signinWithFacebook };
