import React, { useEffect, useState } from "react";
import { Box, Flex, FlexProps, Text, useDisclosure, calc, useToken } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useActor } from "@xstate/react";
import { VideoClipContext } from "../../types/DIYVideoBuilder";

type FitMode = "preserve" | "stretch" | "crop" | "smartcrop" | "pad";

export const getMuxImageUrl = (muxPlaybackId:string, dimensions = {width: 624, height: 468 }, fit_mode:FitMode = "preserve") => {
  let src = `https://image.mux.com/${muxPlaybackId}/thumbnail.jpg`
  try {
      const {width,height} = dimensions
      const qry = `${width ? 'width=' + width + '&' : ''}${height ? 'height=' + height + '&' : ''}fit_mode=${fit_mode}`
      src = `${src}?${qry}`
  }
  catch(error) {
      console.log(error);
  }

  return src;
}

type ClipCircleEvents = {
  onToggleEvent: () => void;
  onEditEvent?: () => void;
  onDoneEvent: () => void;
}

type ClipCircleBaseInterface = {
  type: "submission" | "prompt";
  state: "editing" | string;
  data: any;
}

export const ClipCircleBase = (props: ClipCircleBaseInterface & ClipCircleInterface & ClipCircleEvents) => {
  const { data, state, type, showPulse, onToggleEvent, onEditEvent, onDoneEvent } = props;

  const [puce600, seaGreen500, tumbleweed600, tumbleweed400] = useToken(
    // the key within the theme, in this case `theme.colors`
    'colors',
    // the subkey(s), resolving to `theme.colors.red.100`
    ['puce.600', 'seaGreen.500', 'tumbleweed.600', 'tumbleweed.400'],
    // a single fallback or fallback array matching the length of the previous arg
  )

  const {
    isOpen:isMenuOpen,
    onOpen:onMenuOpen,
    onClose:onMenuClose,
    onToggle:onMenuToggle,
  } = useDisclosure({ defaultIsOpen:false });
  


  const onDone = () => {
    onMenuClose();
    onDoneEvent();
  }

  const ClipButtons = ({show}:{show:boolean}) => {
    return (<>
      <Flex
        w="48px"
        h="48px"
        top={"calc(-60% + 1px)"}
        right={"calc(-80% + 1px)"}
        rounded="full"
        bgColor="powder"
        pos="absolute"
        justify="center"
        alignItems="center"
        border="2px solid"
        borderColor={data.include ? "puce.600" : "seaGreen.500"}
        p="1"
        zIndex="4"
        transition="200ms linear"
        opacity={show ? 1 : 0}
        cursor={show ? "pointer" : "default"}
        _hover={{ transform: "scale(1.1)" }}
        onClick={onToggleEvent}
      >
        <FontAwesomeIcon
          color={data.include ? puce600 : seaGreen500}
          icon={
            data.include
              ? ["far", "eye-slash"]
              : ["far", "eye"]
          }
          size="1x"
        />
      </Flex>
      {!!onEditEvent && <Flex
        w="48px"
        h="48px"
        top={"calc(-90%)"}
        right={"calc(10%)"}
        rounded="full"
        bgColor="powder"
        pos="absolute"
        justify="center"
        alignItems="center"
        border="2px solid"
        borderColor={data.include ? "tumbleweed.600" : "tumbleweed.400"}
        p="1"
        zIndex="4"
        transition="200ms linear"
        opacity={show ? 1 : 0}
        cursor={show ? "pointer" : "default"}
        _hover={{ transform: "scale(1.1)" }}
        onClick={onEditEvent}
      >
        <FontAwesomeIcon
          color={data.include ? tumbleweed600 : tumbleweed400}
          icon={
            ["fas", "pencil-alt"]
          }
          size="1x"
        />
      </Flex>}
      </>
    );
  }

  if (type === "submission") {
    return (
      <Box
        _hover={{
          transform: state !== "editing" ? "scale(1.07)" : undefined,
        }}
        transform={state === "editing" ? "scale(1.18)" : undefined}
        transition="200ms linear"
        pos="relative"
        onMouseLeave={() => onMenuClose()}
        zIndex={isMenuOpen?10:1}
      >
        <Box
          w="55px"
          h="55px"
          pos="relative"
          bgColor="springWood.500"
          bgImage={
            type === "submission"
              ? `${
                  // @ts-ignore
                  (data.content.muxPlaybackId && getMuxImageUrl(data.content.muxPlaybackId)) ||
                  // @ts-ignore
                  data.content.thumbnailUrl ||
                  // @ts-ignore
                  data.content.thumbnailPath
                }`
              : undefined
          }
          bgSize="cover"
          bgPos="center"
          rounded="full"
          borderColor={
            state === "editing"
              ? "seaGreen.500"
              : data.include !== false
              ? "airBlue.400"
              : "springWood.600"
          }
          borderWidth="4px"
          transition="200ms linear"
          boxShadow={state === "editing" ? "md" : undefined}
          onClick={() =>
           {
            const act = isMenuOpen || state === "editing" ? onDone : onMenuOpen;
            act();
           }
          }
          animation={showPulse ? "pulse 2s infinite" : undefined}
        >
          {/* @ts-ignore */}
          {type === "prompt" && (
            <Flex
              justify="center"
              alignItems="center"
              pos="absolute"
              w="full"
              h="full"
              transition="200ms linear"
              rounded="full"
              zIndex="2"
            >
              <Text fontSize="lg" fontFamily="serif" fontWeight="bold">
                Tt
              </Text>
            </Flex>
          )}
          {isMenuOpen || state === "editing" ? (
            <Flex
              justify="center"
              alignItems="center"
              w="full"
              h="full"
              pos="absolute"
              opacity="0"
              transition="200ms linear"
              rounded="full"
              zIndex="3"
              _hover={{
                bgColor: "rgba(255,255,255,.6)",
                opacity: 1,
                cursor: "pointer",
              }}
            >
              {/* @ts-ignore */}
              <FontAwesomeIcon icon={["fas", "times"]} size="lg" />
            </Flex>
          ) : (
            <Flex
              justify="center"
              alignItems="center"
              w="full"
              h="full"
              pos="absolute"
              bgColor={
                !data.include
                  ? "rgba(255,255,255,.4)"
                  : undefined
              }
              rounded="full"
              zIndex="3"
            />
          )}
        </Box>
        {type === "submission" && isMenuOpen && (<ClipButtons show={isMenuOpen} />)}
      </Box>
    );
  } else return null;
};

const ClipCircle = (props: ClipCircleInterface & { machineRef: any; }) => {
  const { machineRef, showPulse } = props;

  const [puce600, seaGreen500, tumbleweed600, tumbleweed400] = useToken(
    // the key within the theme, in this case `theme.colors`
    'colors',
    // the subkey(s), resolving to `theme.colors.red.100`
    ['puce.600', 'seaGreen.500', 'tumbleweed.600', 'tumbleweed.400'],
    // a single fallback or fallback array matching the length of the previous arg
  )

  const {
    isOpen:isMenuOpen,
    onOpen:onMenuOpen,
    onClose:onMenuClose,
    onToggle:onMenuToggle,
  } = useDisclosure({ defaultIsOpen:false });
  
  const [state, send]: any[] = useActor(machineRef);
  const submissionContext: VideoClipContext = state.context;

  const onEdit = () => {
    send({ type: "EDIT" });
  }

  const onDone = () => {
    console.log('onDone',{state})
    send({ type: "DONE" });
    onMenuClose();
  }

  const ClipButtons = ({show}:{show:boolean}) => {
    return (<>
      <Flex
        w="48px"
        h="48px"
        top={"calc(-60% + 1px)"}
        right={"calc(-80% + 1px)"}
        rounded="full"
        bgColor="powder"
        pos="absolute"
        justify="center"
        alignItems="center"
        border="2px solid"
        borderColor={submissionContext.data.include ? "puce.600" : "seaGreen.500"}
        p="1"
        zIndex="4"
        transition="200ms linear"
        opacity={show ? 1 : 0}
        cursor={show ? "pointer" : "default"}
        _hover={{ transform: "scale(1.1)" }}
        onClick={() =>
          send({
            type: "CHANGE",
            value: {
              ...submissionContext.data,
              include: !submissionContext.data.include,
            },
          })
        }
      >
        <FontAwesomeIcon
          color={submissionContext.data.include ? puce600 : seaGreen500}
          icon={
            submissionContext.data.include
              ? ["far", "eye-slash"]
              : ["far", "eye"]
          }
          size="1x"
        />
      </Flex>
      <Flex
        w="48px"
        h="48px"
        top={"calc(-90%)"}
        right={"calc(10%)"}
        rounded="full"
        bgColor="powder"
        pos="absolute"
        justify="center"
        alignItems="center"
        border="2px solid"
        borderColor={submissionContext.data.include ? "tumbleweed.600" : "tumbleweed.400"}
        p="1"
        zIndex="4"
        transition="200ms linear"
        opacity={show ? 1 : 0}
        cursor={show ? "pointer" : "default"}
        _hover={{ transform: "scale(1.1)" }}
        onClick={onEdit}
      >
        <FontAwesomeIcon
          color={submissionContext.data.include ? tumbleweed600 : tumbleweed400}
          icon={
            ["fas", "pencil-alt"]
          }
          size="1x"
        />
      </Flex>
      </>
    );
  }

  if (submissionContext.type === "submission") {
    return (
      <Box
        _hover={{
          transform: state.value !== "editing" ? "scale(1.07)" : undefined,
        }}
        transform={state.value === "editing" ? "scale(1.18)" : undefined}
        transition="200ms linear"
        pos="relative"
        onMouseLeave={() => onMenuClose()}
        zIndex={isMenuOpen?10:1}
      >
        <Box
          w="55px"
          h="55px"
          pos="relative"
          bgColor="springWood.500"
          bgImage={
            submissionContext.type === "submission"
              ? `${
                  // @ts-ignore
                  (submissionContext.data.content.muxPlaybackId && getMuxImageUrl(submissionContext.data.content.muxPlaybackId)) ||
                  // @ts-ignore
                  submissionContext.data.content.thumbnailUrl ||
                  // @ts-ignore
                  submissionContext.data.content.thumbnailPath
                }`
              : undefined
          }
          bgSize="cover"
          bgPos="center"
          rounded="full"
          borderColor={
            state.value === "editing"
              ? "seaGreen.500"
              : submissionContext.data.include !== false
              ? "airBlue.400"
              : "springWood.600"
          }
          borderWidth="4px"
          transition="200ms linear"
          boxShadow={state.value === "editing" ? "md" : undefined}
          onClick={() =>
           {
            const act = isMenuOpen || state.value === "editing" ? onDone : onMenuOpen;
            act();
           }
          }
          animation={showPulse ? "pulse 2s infinite" : undefined}
        >
          {/* @ts-ignore */}
          {submissionContext.type === "prompt" && (
            <Flex
              justify="center"
              alignItems="center"
              pos="absolute"
              w="full"
              h="full"
              transition="200ms linear"
              rounded="full"
              zIndex="2"
            >
              <Text fontSize="lg" fontFamily="serif" fontWeight="bold">
                Tt
              </Text>
            </Flex>
          )}
          {isMenuOpen || state.value === "editing" ? (
            <Flex
              justify="center"
              alignItems="center"
              w="full"
              h="full"
              pos="absolute"
              opacity="0"
              transition="200ms linear"
              rounded="full"
              zIndex="3"
              _hover={{
                bgColor: "rgba(255,255,255,.6)",
                opacity: 1,
                cursor: "pointer",
              }}
            >
              {/* @ts-ignore */}
              <FontAwesomeIcon icon={["fas", "times"]} size="lg" />
            </Flex>
          ) : (
            <Flex
              justify="center"
              alignItems="center"
              w="full"
              h="full"
              pos="absolute"
              bgColor={
                !submissionContext.data.include
                  ? "rgba(255,255,255,.4)"
                  : undefined
              }
              rounded="full"
              zIndex="3"
            />
          )}
        </Box>
        {submissionContext.type === "submission" && isMenuOpen && (<ClipButtons show={isMenuOpen} />)}
      </Box>
    );
  } else return null;
};

export default ClipCircle;

interface ClipCircleInterface extends FlexProps {
  thumbnail?: string;
  showPulse?: boolean;
}
