import React from "react";
import {
  Flex,
  Heading,
  Link,
  Text,
} from "@chakra-ui/react";
import { useAuth } from "../../hooks";

const StoryNotFound = () => {
    const auth = useAuth();

    return (
    <Flex
        justify="center"
        my="lg"
        h="full"
        direction="column"
        p="lg"
        shadow="lg"
        bgColor="springWood.100"
        rounded="lg"
        align="center"
      >
        <Heading as="h2" size="h2" variant="center">The Story you are trying to view<br /> could not be found</Heading>
        <Text align="center" my="4" fontSize="lg" fontWeight="bold">
          <span>Please contact the person who set up this story and make sure the link you were provided is correct.</span>
        </Text>
        {auth && auth.currentUser && (
          <Text align="center" my="4" fontSize="md">
          <span>If you are the person who set up this story, <Link href="/my-stories" color='airBlue.500'>go back to the dashboard</Link> and try accessing your story from there.</span>
        </Text>
        )}
    </Flex>
    );
  }

  export default StoryNotFound;