import React from "react";
import { useStoryViewContext, useResponseAuth } from "./StoryViewProvider";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";

import { useActor } from "@xstate/react";
import PromptsView from "../../../components/response/PromptsView";
import { useNavigate } from "react-router-dom";
import CaptureResponse from "../../../components/response/components/CaptureResponse";
import RespondedView from "../../../components/response/RespondedView";
import CurrentUploads from "../../../components/response/components/CurrentUploads";

const Respond = () => {
  const { story, auth: identity, service } = useStoryViewContext();
  const buildQuery = () => {
    const where: Array<[string, "==", string | boolean]> = [
      ["storyId", "==", String(story?.id)],
    ];
    if (identity && (identity.uid || identity.email)) {
      if (identity.uid) where.push(["submittedBy.uid", "==", identity.uid]);
      if (identity.email)
        where.push(["submittedBy.email", "==", identity.email]);
    } else where.push(["public", "==", true]);
    const _query = {
      collection: "submissions",
      where,
    };
    return _query;
  };
  const query = buildQuery();
  useFirestoreConnect(query);

  const submissions = useSelector(
    (state: any) => state.firestore.ordered.submissions
  );
  console.log("Rendering Respond Component", submissions);

  const actor = useActor(service);
  const [state, send] = actor;
  const navigate = useNavigate();

  const fetch = React.useCallback(() => {
    if (
      !state.matches("responding") &&
      !state.matches("recording") &&
      !state.matches("done")
    ) {
      send("BACK");
      navigate("../");
    } else {
      send("CANCEL");
    }
  }, [state.value, send]);

  React.useEffect(() => {
    send({
      type: "LOAD_RESPONSES",
      data: submissions,
    });
  }, [submissions]);

  React.useEffect(() => {
    fetch();

    //@ts-ignore
    window.onpopstate = () => {
      console.log("Window Popped");
      send("CANCEL");
    };
  }, []);

  return (
    <div>
      {state.context.uploads &&
        state.context.uploads.filter((u) => !u.isDone).length > 0 && (
          <CurrentUploads />
        )}
      {(story &&
        state.matches("responding") &&
        state.matches({ responding: "active" })) ||
      state.matches({ responding: "complete" }) ? (
        <PromptsView
          story={state.context.story as any}
          responseState={state.value.responding}
        />
      ) : state.matches("recording") ? (
        <CaptureResponse />
      ) : state.matches("done") ? (
        <RespondedView
          onClick={() => send("CONTINUE")}
          progress={state.context.uploadProgress}
        />
      ) : (
        <>waiting to respond...{state.value}</>
      )}
    </div>
  );
};

export default Respond;
