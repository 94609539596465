import React from "react";
import { useMachine } from "@xstate/react";
import {
  Box,
  Button,
  Center,
  CircularProgress,
  Heading,
  Text,
} from "@chakra-ui/react";
import { useAnalytics } from "use-analytics";
import invitationsMachine, {
  InvitationsContext,
  Reminders,
} from "../../../machines/Dashboard/invitationsMachine";
import InitialCardView from "./InitialCardView";
import SetDeliveryDateView from "./SetDeliveryDateView";
import StepCard from "../StepCard";
import SetDueDateView from "./SetDueDateView";
import SelectInvitationMethodView from "./SelectInvitationMethodView";
import ShowLinkView from "./ShowLinkView";
import AddStorytellersView from "./AddStorytellersView";
import SetRemindersView from "./SetRemindersView";
import CompletedView from "./CompletedView";
import InvitationsDoneView from "./InvitationsDoneView";

import CardImage from "../../../assets/send-invitations.svg";
import { Dictionary } from "react-redux-firebase";
import { buildIllustrationUrl } from "../../../utils/buildIllustrationUrl";
import { StoryConsumer } from "../../story/StoryProvider";

type invitationsCardProps = {
  colorScheme: string;
  dueDate?: Date;
  storytellers?: any[];
  reminders?: Reminders;
  subject: string;
  isLegacy: boolean;
  promptCount: number;
  inviteMethod?: "invites" | "link";
  onSaveDueDate: (context: InvitationsContext) => Promise<unknown>;
  onSaveStorytellers: (context: InvitationsContext) => Promise<unknown>;
  onSaveReminders: (context: InvitationsContext) => Promise<unknown>;
  onSaveInviteMethod: (context: InvitationsContext) => Promise<unknown>;
};

const InvitationsCard = ({
  colorScheme,
  dueDate,
  storytellers,
  reminders,
  subject,
  isLegacy,
  promptCount,
  inviteMethod,
  onSaveDueDate,
  onSaveStorytellers,
  onSaveReminders,
  onSaveInviteMethod,
}: invitationsCardProps) => {
  const { track } = useAnalytics();

  const trackEvents: Dictionary<{ key: string; value?: (data: any) => any }> = {
    USE_LINK: {
      key: "dashboard:user_selectedInviteMethod",
      value: () => ({ method: "link" }),
    },
    ADD_STORYTELLERS: {
      key: "dashboard:user_selectedInviteMethod",
      value: () => ({ method: "invites" }),
    },
    "done.invoke.saveStorytellers": {
      key: "dashboard:user_finishedSendingInvites",
    },
    "done.invoke.saveInviteMethod": {
      key: "dashboard:user_finishedSendingInvites",
    },
    SET_REMINDERS: {
      key: "dashboard:user_setReminders",
      value: ({ reminders }: { reminders: Reminders }) => {
        return {
          remindersSet: !!(
            reminders?.dayAfter ||
            reminders?.dayBefore ||
            reminders?.dueDate ||
            reminders?.midway ||
            reminders?.weekBefore ||
            reminders?.weekly
          ),
        };
      },
    },
  };

  const myMachine = invitationsMachine.withConfig(
    {
      services: {
        onSaveDueDate,
        onSaveStorytellers,
        onSaveReminders,
        onSaveInviteMethod,
      },
      actions: {
        track: (context: any, event: any) => {
          const tracker = trackEvents[event.type];
          if (tracker) {
            const params = tracker.value ? tracker.value(event) : {};
            track(tracker.key, params);
          }
        },
      },
    },
    { data: { dueDate, storytellers, reminders, inviteMethod } }
  );

  const [state, dispatch] = useMachine(myMachine);

  const tasks = {
    setDueDate: !!dueDate,
    addedStorytellers: !!storytellers || !!inviteMethod,
    setReminders: !!reminders,
  };

  return (
    <StoryConsumer>
      {({ story }) => {
        return (
          <StepCard image={CardImage} colorScheme={colorScheme}>
            {state.matches({ start: "idle" }) ? (
              <InitialCardView
                colorScheme={colorScheme}
                subject={subject}
                tasks={tasks}
                onClick={() => {
                  dispatch("BEGIN");
                  track("dashboard_openedStorytellerCard", {
                    storyId: story?.id,
                    storyTitle: story?.title,
                    storySubject: story?.subject,
                    storySlug: story?.slug,
                  });
                }}
              />
            ) : state.matches({ start: "delivery" }) ? (
              <SetDeliveryDateView
                colorScheme={colorScheme}
                subject={subject}
                onClick={(deliveryDate) => {
                  dispatch("SET_DELIVERY_DATE", { deliveryDate });
                  track("dashboard:user_setGiftDate", {
                    deliveryDate,
                    storyId: story?.id,
                    storyTitle: story?.title,
                    storySubject: story?.subject,
                    storySlug: story?.slug,
                  });
                }}
              />
            ) : state.matches({ start: { dueDate: "saving" } }) ||
              state.matches({ start: { addStorytellers: "saving" } }) ||
              state.matches({ start: { setReminders: "saving" } }) ||
              state.matches({ start: { useLink: "saving" } }) ||
              state.matches({ completed: "saving" }) ? (
              <Box>
                <Center>
                  <CircularProgress
                    isIndeterminate
                    color={`${colorScheme}.500`}
                    size="80px"
                  />
                </Center>
                <Center>
                  <Heading my={3} as="h4" size="md">
                    saving
                  </Heading>
                </Center>
              </Box>
            ) : state.matches({ start: { dueDate: "error" } }) ||
              state.matches({ start: { addStorytellers: "error" } }) ||
              state.matches({ start: { setReminders: "error" } }) ||
              state.matches({ start: { useLink: "error" } }) ||
              state.matches({ completed: "error" }) ? (
              <Box>
                <Heading mb={3} as="h4" size="md">
                  There was an error saving your invitations
                </Heading>
                <Text mb={3}>{state.context.error}</Text>
                <Button onClick={() => dispatch("RETRY")}>Try again</Button>
              </Box>
            ) : state.matches({ start: "dueDate" }) ? (
              <SetDueDateView
                colorScheme={colorScheme}
                deliveryDate={state.context.data.deliveryDate}
                onClick={(dueDate) => {
                  dispatch("SET_DUE_DATE", { dueDate });
                  track("dashboard:user_setDueDate", {
                    dueDate,
                    storyId: story?.id,
                    storyTitle: story?.title,
                    storySubject: story?.subject,
                    storySlug: story?.slug,
                  });
                }}
              />
            ) : state.matches({ start: "selectMethod" }) ? (
              <SelectInvitationMethodView
                colorScheme={colorScheme}
                onInviteClick={() => dispatch("ADD_STORYTELLERS")}
                onLinkClick={() => dispatch("USE_LINK")}
              />
            ) : state.matches({ start: "useLink" }) ? (
              <ShowLinkView
                colorScheme={colorScheme}
                slug={story?.slug}
                onClick={() => {
                  dispatch("DONE");
                  track("dashboard:storyLaunched", {
                    storyId: story?.id,
                    storyTitle: story?.title,
                    storySubject: story?.subject,
                    storySlug: story?.slug,
                    templateId: story?.template?.id,
                    templateName: story?.template?.title,
                    templateIllustration: buildIllustrationUrl(
                      story?.template?.illustration
                    ),
                  });
                }}
              />
            ) : state.matches({ start: "addStorytellers" }) ||
              state.matches({ completed: "addStorytellers" }) ? (
              <AddStorytellersView
                colorScheme={colorScheme}
                limit={1}
                storytellers={storytellers || []}
                invites={state.context.data.invites}
                onClick={(contacts) => {
                  dispatch("SET_STORYTELLERS", { storytellers: contacts });
                  track("dashboard:storyLaunched", {
                    storyId: story?.id,
                    storyTitle: story?.title,
                    storySubject: story?.subject,
                    storySlug: story?.slug,
                    templateId: story?.template?.id,
                    templateName: story?.template?.title,
                    templateIllustration: buildIllustrationUrl(
                      story?.template?.illustration
                    ),
                  });
                }}
                onBackClick={() => dispatch("CANCEL")}
              />
            ) : state.matches({ start: "setReminders" }) ? (
              <SetRemindersView
                colorScheme={colorScheme}
                isLegacy={isLegacy}
                inviteMethod={state.context.data.inviteMethod}
                onClick={(reminders) =>
                  dispatch("SET_REMINDERS", { reminders })
                }
              />
            ) : state.matches({ start: "done" }) ? (
              <InvitationsDoneView
                colorScheme={colorScheme}
                onClick={() => {
                  dispatch("FINISHED");
                  track("dashboard:user_finishedStorytellerCard", {
                    storyId: story?.id,
                    storyTitle: story?.title,
                    storySubject: story?.subject,
                    storySlug: story?.slug,
                    templateId: story?.template?.id,
                    templateName: story?.template?.title,
                    templateIllustration: buildIllustrationUrl(
                      story?.template?.illustration
                    ),
                  });
                }}
              />
            ) : state.matches("completed") ? (
              <CompletedView
                colorScheme={colorScheme}
                slug={story?.slug}
                storytellers={storytellers || []}
                promptCount={promptCount}
                onClick={() => dispatch("ADD_MORE_STORYTELLERS")}
              />
            ) : state.matches("init") ? (
              "loading"
            ) : (
              <Box>
                <Heading mb={3} as="h4">
                  We are having trouble loading your story invitations.
                </Heading>
              </Box>
            )}
          </StepCard>
        );
      }}
    </StoryConsumer>
  );
};

export default InvitationsCard;
