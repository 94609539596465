
function hasGetUserMedia() {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
}

function hasMediaRecorder() {
    return !!window.MediaRecorder;
}

const canRecord = hasGetUserMedia() && hasMediaRecorder();

export default { canRecord, hasGetUserMedia, hasMediaRecorder };