import React from "react";
import { Layer } from "grommet";
import {
  Stack,
  Text,
  Button,
  IconButton,
  Box,
} from "@chakra-ui/react";
import Dropzone from "react-dropzone";

import {
  StackedResponsiveImageCard,
  SubjectImage,
} from "../layout/ImageResponsive";
import ImageCropper from "../cropper/ImageCropper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as PhotoDropTargetImg } from "../../assets/photo-drop-target.svg";
import uploadMachine from "../../machines/uploadMachine";
import { useMachine, } from "@xstate/react";

import { assign } from "xstate";


const UploadRecipientPhoto = ({ storyId, recipientPhoto, onUpload = (path:string) => {console.log("File uploaded", path)} }:{ storyId:string, recipientPhoto?:string, onUpload: (path:string) => void}) => {
  const actions = {
    recordProgress: assign((context, event) => {
      try {
        console.log('Record Progress!')
        //@ts-ignore
        return { progress: event.value }
      } catch (error) {
        return {};
      }
    }),
    cancelled: assign({ progress: 0, data: undefined }),
    complete: () => { console.log('complete.')}
  }
  const [state, send] = useMachine(uploadMachine.withConfig({ actions: { ...actions } }));

  const [dropActive, setDropActive] = React.useState(false);
  const [photo,setPhoto] = React.useState(recipientPhoto)
  const [uploadRecipientPhoto, setUploadRecipientPhoto] = React.useState(!recipientPhoto);
  const [uploadedRecipientFile, setUploadedRecipientFile] = React.useState<any>(false);

  React.useEffect(() => {
    if(state.matches("done") && state.context.result)
    {
      const {data} = state.context.result;
      setPhoto(data.downloadURL);
      onUpload && onUpload(data.downloadURL);
      onComplete();
    }
  }, [state.matches("done")])

  const onComplete = React.useCallback(() => {
    setUploadRecipientPhoto(false);
    setUploadedRecipientFile(undefined);
    setDropActive(false);
  }, [uploadedRecipientFile])

  const handleFileUpload = (files:any[]) => {
    if (files.length === 0) return;
    setUploadedRecipientFile(files[0]);
    
  };

  const uploadRecipientFile = (file:any) => {
    const { name, size, type } = file;
    send("START", {
      value: {
        path: `assets/${storyId}/${name}`, file, metadata: { size, contentType: type, visibility: "public" }
      }
    })

    /*const task = uploadToStorage(
      `assets/${storyId}/${name}`,
      file,
      { size, contentType: type, visibility: "public" },
      (progress:number) => {
        console.log("Image upload status", { progress });
      }
    ) as Promise<any>;

    return task
      .then((info:any) => {
        setPhoto(info.downloadURL);
        onUpload(info.downloadURL);
        onComplete();
      })
      .catch((error:any) => {
        console.log(error);
      });
      */
  };

  const handleFileRejected = (files:any[]) => {
    if (files.length === 0) return;
    const {
      file: { name },
    } = files[0];
    console.log({ name, files });
    setDropActive(false);
  };

  return (
      <>
        {!uploadRecipientPhoto && photo && (
          <StackedResponsiveImageCard
            anchor="top-right"
            margin={{ horizontal: "auto", bottom: "medium" }}
            flex={false}
          >
            <SubjectImage
              src={photo}
              alt={`recipient's photo`}
              resize={false}
            />
            <IconButton
              bgColor="white"
              color="seaGreen"
              _hover={{ bgColor: "seaGreen.200" }}
              icon={<FontAwesomeIcon icon="pencil-alt" />}
              onClick={() => {
                setUploadRecipientPhoto(true);
                send('RESET')
              }}
              aria-label="edit photo"
            />
          </StackedResponsiveImageCard>
        )}
        {uploadRecipientPhoto && (
          <Dropzone
            accept="image/jpeg, image/png"
            onDrop={(acceptedFiles, fileRejections) => {
              handleFileUpload(acceptedFiles);
              handleFileRejected(fileRejections);
            }}
            onDragEnter={() => {
              setDropActive(true);
            }}
            onDragLeave={() => {
              setDropActive(false);
            }}
            onDropRejected={() => {
              setDropActive(false);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <Stack
                bgColor="springWood.500"
                px="xl"
                py="lg"
                align="center"
                justify="center"
                border="1px"
                borderStyle="dashed"
                borderColor="tumbleweed.600"
                direction="column"
                minH="225px"
                spacing="5"
                {...getRootProps() as unknown}
              >
                <input {...getInputProps()} />
                <PhotoDropTargetImg />
                <Text fontSize="lg" textAlign="center">
                  Drag &apos;n&apos; drop the photo here, OR
                </Text>
                <Button>Select photo</Button>
              </Stack>
            )}
          </Dropzone>
        )}
        {uploadedRecipientFile && state.matches("idle") && (
          <Layer responsive={false}>
            <ImageCropper
              file={uploadedRecipientFile}
              onComplete={(file:any) => {
                uploadRecipientFile(file); 
              }}
            />
          </Layer>
        )}
        {state.matches("uploading") && false && (<Box><Button colorScheme="puce" onClick={() => {setUploadedRecipientFile(undefined);send("CANCEL");}}>Cancel</Button></Box>)}
      </>
    );
};
export default UploadRecipientPhoto;
