import { createMachine, assign, sendParent } from "xstate";

interface RecordingContext {
  retries: number;
  recording?: any;
  data: any;
  useInput?: boolean;
}

interface RecordingMachineData {
  blob: any;
  url: string;
  info?: any;
}

export type RecordingMachineEvent =
  {
    type: "READY"
  } |
  {
    type: "RECORD"
  } |
  {
    type: "RETAKE"
  } |
  {
    type: "DONE"
  } |
  {
    type: "REJECT"
  } |
  {
    type: "CANCEL"
  } |
  {
    type: "RETRY"
  } |
  {
    type: "QUIT"
  } |
  {
    type: "APPROVE",
    data: RecordingMachineData
  }

export const recordingMachine = createMachine<RecordingContext, RecordingMachineEvent>({
  id: 'recordingMachine',
  initial: 'idle',
  context: {
    retries: 0,
    useInput: false,
    data: {}
  },
  states: {
    idle: {
      on: {
        READY: 'ready'
      }
    },
    ready: {
      on: {
        RECORD: 'recording',
        REJECT: 'failure',
        CANCEL: { actions: sendParent('CANCEL') }
      }
    },
    recording: {
      entry: sendParent((ctx) => ({
        type: 'ANALYTICS',
        track: 'response:recorder_recordingStarted',
        properties: { isFileUpload: ctx.useInput }
      })),
      on: {
        DONE: 'review',
        REJECT: 'failure',
        CANCEL: 'shutdown'
      }
    },
    review: {
      on: {
        RETAKE: {
          target: 'ready',
          actions: sendParent({ type: 'ANALYTICS', track: 'response:recorder_videoReset' })
        },
        CANCEL: {
          actions: sendParent('CANCEL')
        },
        APPROVE: {
          target: 'done',
          actions: [
            'announce',
            sendParent((context, event) => ({ type: 'ANALYTICS', track: 'response:recorder_videoAccepted', properties: event.data.info })),
            assign({ data: (context, event) => event.data })
          ]
        }
      }
    },
    failure: {
      on: {
        RETRY: {
          target: 'ready',
          actions: ['announce', assign({
            retries: (context, event) => context.retries + 1
          })]
        }
      }
    },
    shutdown: {
      entry: ['announce',
        assign({
          data: (context, event) => ({})
        })]
    },
    done: {
      entry: ['announce'],
      type: 'final',
      data: (context: RecordingContext, event: any) => ({ ...context.data })
    }
  },
  on: {
    QUIT: {
      actions: sendParent('CANCEL'),
      target: 'idle',
    }
  }
}, {
  actions: {
    announce: (context, event) => {
      console.log("Recording machine - ", event.type, { context, event });
    },
  }
});

export default recordingMachine;