import React from "react";
import { useMachine, useActor } from "@xstate/react";
import {
  Stack,
  Text,
  Button,
  Icon,
  IconProps,
  Box,
  Flex,
  Spacer,
  IconButton,
  HStack,
  Divider,
} from "@chakra-ui/react";
import Dropzone from "react-dropzone";
import { ReactComponent as PhotoDropTargetImg } from "../../assets/photo-drop-target.svg";
import manageStoryAssetsMachine, {
  Asset,
} from "../../machines/manageStoryAssetsMachine";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";

const RemoveIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" color="puce.600" {...(props as unknown)}>
      <path
        fill="currentColor"
        d="M0 12C0 5.37188 5.37188 0 12 0C18.6281 0 24 5.37188 24 12C24 18.6281 18.6281 24 12 24C5.37188 24 0 18.6281 0 12ZM8.20312 9.75469L10.4109 11.9578L8.20312 14.2031C7.76719 14.6438 7.76719 15.3563 8.20312 15.7547C8.64375 16.2328 9.35625 16.2328 9.75469 15.7547L11.9578 13.5891L14.2031 15.7547C14.6438 16.2328 15.3563 16.2328 15.7547 15.7547C16.2328 15.3563 16.2328 14.6438 15.7547 14.2031L13.5891 11.9578L15.7547 9.75469C16.2328 9.35625 16.2328 8.64375 15.7547 8.20312C15.3563 7.76719 14.6438 7.76719 14.2031 8.20312L11.9578 10.4109L9.75469 8.20312C9.35625 7.76719 8.64375 7.76719 8.20312 8.20312C7.76719 8.64375 7.76719 9.35625 8.20312 9.75469Z"
      />
    </Icon>
  );
};

const AssetManager = ({
  asset,
  position,
}: {
  asset: Asset;
  position: number;
}) => {
  const [state, send]: any[] = useActor(asset.ref);

  const getAssetType = (type: string) => {
    const types: {
      [key: string]: {
        color: "majesty" | "seaGreen";
        icon: [IconPrefix, IconName];
      };
    } = {
      image: { color: "majesty", icon: ["fas", "image"] },
      video: { color: "seaGreen", icon: ["fas", "film"] },
      audio: { color: "majesty", icon: ["fas", "music"] },
      unknown: { color: "majesty", icon: ["fas", "image"] },
    };
    return types[type] || types.image;
  };

  const assetType = getAssetType(asset.type);

  return (
    <Flex
      alignItems="center"
      backgroundColor="gray.200"
      bgGradient={`linear(to-l, gray.200, ${assetType.color}.100)`}
      borderWidth="1px"
      borderColor="#858585"
      borderRadius="md"
      minWidth="max-content"
    >
      <Box maxW="md" px="2" overflow="hidden">
        <Stack spacing={4} direction="row">
          <Text color={`${assetType.color}.500`}>
            <FontAwesomeIcon icon={assetType.icon} size="lg" />
          </Text>
          <Text noOfLines={1}>{asset.name}</Text>
        </Stack>
      </Box>
      <Spacer />
      <Box px="2">
        <HStack spacing={4}>
          <Text>
            {state.matches("upload") &&
              `uploading: ${
                state.context.progress && state.context.progress.toFixed(0)
              }%`}
          </Text>
          <IconButton
            variant="unstyled"
            size="sm"
            onClick={() => {
              state.matches("upload")
                ? send({ type: "CANCEL", position })
                : send({ type: "DELETE", position });
            }}
            aria-label={state.matches("upload") ? "CANCEL" : "DELETE"}
            icon={<RemoveIcon boxSize={6} />}
          />
        </HStack>
      </Box>
    </Flex>
  );
};

const ManageStoryAssets = ({
  storyId,
  assets,
}: {
  storyId: string;
  assets: any[];
}) => {
  const [state, send] = useMachine(
    manageStoryAssetsMachine.withContext({ storyId, assets: [] })
  );
  const [dropActive, setDropActive] = React.useState(false);

  React.useEffect(() => {
    if (assets && assets.length) {
      send({
        type: "LOAD",
        assets,
      });
    }
  }, []);

  const handleFilesUpload = (files: any[]) => {
    if (files.length === 0) return;
    send({ type: "UPLOAD", files });
  };
  const handleFilesRejected = (files: any[]) => {
    if (files.length === 0) return;
    setDropActive(false);
  };

  return (
    <Box
      bgColor="springWood.500"
      px="xl"
      py="lg"
      align="center"
      justify="center"
      border="1px"
      borderStyle="dashed"
      borderColor="tumbleweed.600"
    >
      <Dropzone
        accept="image/jpeg, image/png, video/*, audio/*"
        onDrop={(acceptedFiles, fileRejections) => {
          handleFilesUpload(acceptedFiles);
          handleFilesRejected(fileRejections);
        }}
        onDragEnter={() => {
          setDropActive(true);
        }}
        onDragLeave={() => {
          setDropActive(false);
        }}
        onDropRejected={() => {
          setDropActive(false);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <Stack
            bgColor="springWood.500"
            px="xl"
            py="lg"
            align="center"
            justify="center"
            direction="column"
            minH="225px"
            spacing="5"
            {...(getRootProps() as unknown)}
          >
            <input {...getInputProps()} />
            <PhotoDropTargetImg />
            <Text fontSize="lg" textAlign="center">
              Drop files here, OR
            </Text>
            <Button>Select Files</Button>
          </Stack>
        )}
      </Dropzone>
      {state.context.assets && !!state.context.assets.length && <Divider />}
      <Stack mt={6} spacing={3}>
        {state.context.assets.map((asset, i) => (
          <AssetManager asset={asset} position={i} key={i} />
        ))}
      </Stack>
    </Box>
  );
};

export default ManageStoryAssets;
