import React from 'react';
import { Box, Grid, Text } from 'grommet';
import Countdown from 'react-countdown';

import { timestampToDate } from '../../utils/convert';


const StoryCountdown = ({dueDate, completedMessage = "Story Past Due!"}) => (
    <Countdown date={timestampToDate(dueDate)} renderer={({
        days,
        hours,
        minutes,
        seconds,
        completed
    }) => {
        if (completed) {
            // Render a completed state
            return <Box round="small" background="accent-5" align="center" justify="center" pad="small"
            data-testid="countdown"><Text size="small" weight="bold">{completedMessage}</Text></Box>;
        } else {
            // Render a countdown
            return <Grid
            data-testid="countdown" rows={['xxsmall', 'auto']} columns={{
                count: 4,
                size: 'xxsmall'
            }} gap="xsmall">
                <Box round="small" background="accent-5" align="center" justify="center" pad="xxsmall"><Text size="small" weight="bold">{days}</Text></Box>
                <Box round="small" background="accent-5" align="center" justify="center" pad="xxsmall"><Text size="small" weight="bold">{hours}</Text></Box>
                <Box round="small" background="accent-5" align="center" justify="center" pad="xxsmall"><Text size="small" weight="bold">{minutes}</Text></Box>
                <Box round="small" background="accent-5" align="center" justify="center" pad="xxsmall"><Text size="small" weight="bold">{seconds}</Text></Box>
                <Box align="center" justify="start"><Text size="xsmall">days</Text></Box>
                <Box align="center" justify="start"><Text size="xsmall">hours</Text></Box>
                <Box align="center" justify="start"><Text size="xsmall">mins</Text></Box>
                <Box align="center" justify="start"><Text size="xsmall">secs</Text></Box>
            </Grid>;
        }
    }} />
);

export default StoryCountdown;