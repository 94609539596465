import React from "react";
import {
  Box,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

interface SelectInvitationMethodViewProps {
  colorScheme: string;
  onInviteClick: () => void;
  onLinkClick: () => void;
}
export const SelectInvitationMethodView: React.FC<SelectInvitationMethodViewProps> =
  ({
    colorScheme,
    onInviteClick,
    onLinkClick,
  }: SelectInvitationMethodViewProps) => {
    return (
      <Box>
        <Heading as="h4" size="md" mb={3}>
          Invite storytellers
        </Heading>
        <Text fontWeight="bold" mb={3}>
          How would you like to invite storytellers?
        </Text>
        <Text mb={3}>
          We make it easy to invite storytellers by sending them an email or
          text, or just copy and paste a link to invite them yourself.
        </Text>
        <SimpleGrid columns={{ sm: 1, md: 2 }} mb={3} spacing={6}>
          <Box
            as="button"
            alignItems="center"
            maxW="548px"
            rounded="lg"
            bg="white"
            px={4}
            py={6}
            borderWidth="medium"
            borderColor="white"
            onClick={onInviteClick}
            _hover={{ borderColor: "tumbleweed.500", cursor: "pointer" }}
          >
            <Text fontWeight="bold" mb={3} textAlign="center">
              Invite Storytellers for me
            </Text>
            <Text textAlign="center">
              We&apos;ll send storytellers an email or SMS, then help you send
              reminders and keep track of who has responded.
            </Text>
          </Box>
          <Box
            as="button"
            alignItems="center"
            maxW="548px"
            rounded="lg"
            bg="white"
            px={4}
            py={6}
            borderWidth="medium"
            borderColor="white"
            onClick={onLinkClick}
            _hover={{ borderColor: "tumbleweed.500", cursor: "pointer" }}
          >
            <Text fontWeight="bold" mb={3} textAlign="center">
              I&apos;ll send a link
            </Text>
            <Text textAlign="center">
              We&apos;ll give you a link to share so you can invite and manage
              storytellers yourself.
            </Text>
          </Box>
        </SimpleGrid>
        <Text mb={3} fontSize="small">
          No matter which option you choose now, you can always invite
          storytellers or access your link in the dashboard at any time.
        </Text>
      </Box>
    );
  };

export default SelectInvitationMethodView;
