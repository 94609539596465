import React, { useRef } from "react";
import {
  Img,
  Flex,
  Text,
  SimpleGrid,
  AspectRatio,
  Stack,
  Divider,
  Heading,
  Collapse,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import {
  iStoryManagerStateContext,
  Story,
} from "../../../../types/StoryManagerState";
import { Template } from "../../../../types/Templates";
import {
  useStoryManagerDispatch,
  useStoryManagerState,
} from "../../StoryManager";
// @ts-ignore
import { useAnalytics } from "use-analytics";
import { BrowserView, MobileView } from "react-device-detect";
import isDeepEqual from "fast-deep-equal/react";
import { buildIllustrationUrl } from "../../../../utils/buildIllustrationUrl";
import getTemplateMetadata from "../../../../utils/getTemplateMetadata";

const TemplateBox = ({
  active,
  template,
  onSelect,
}: {
  active: boolean;
  template: Template;
  onSelect: (...args: any[]) => any;
}) => {
  return (
    <Flex
      direction={["row", "column"]}
      justify="center"
      rounded="lg"
      cursor="pointer"
      p={["sm", "sm"]}
      bgColor={
        template.accentColor ? `${template.accentColor}.50` : "airBlue.50"
      }
      bgImage={
        !template.illustration ? `/img/package-icons/dot-bg.svg` : undefined
      }
      border="2px"
      borderColor={
        active
          ? `${template.accentColor || "airBlue"}.600`
          : `${template.accentColor || "airBlue"}.50`
      }
      onClick={() => onSelect(template)}
    >
      {template.illustration && (
        <Img
          src={buildIllustrationUrl(template.illustration)}
          p="4"
          pl={["sm", "4"]}
          maxW={["150px", "100%"]}
          maxH={["150px", "60%"]}
        />
      )}
      <Stack spacing="0" justify="center" textAlign="center">
        {/* <Text fontWeight="bold" alignSelf="flex-start"> */}
        <Text fontWeight="bold">{template.title}</Text>
        {template.shortDescription && (
          <Text fontSize={["sm", "xs"]} lineHeight="1.5em">
            {template.shortDescription}
          </Text>
        )}
      </Stack>
    </Flex>
  );
};

const TemplateSelector = ({ id }: { id: string }) => {
  const dispatch = useStoryManagerDispatch();
  // @ts-ignore
  const {
    state: { story, templates },
  }: { state: iStoryManagerStateContext } = useStoryManagerState();

  // @ts-ignore
  const { track } = useAnalytics();

  const [valid, setValid] = useState({});
  const [loading, setLoading] = useState(true);

  const [sortedTemplates, setTemplates] = useState<Template[] | undefined>();
  const [templateId, setTemplateId] = useState<string>(story?.templateId || "");
  const [currentTemplate, setTemplate] = useState<Template | undefined>();

  const { isOpen, onToggle } = useDisclosure();

  const storyRef = useRef(story);

  if (!isDeepEqual(storyRef.current, story)) {
    storyRef.current = story;
  }

  useEffect(() => {
    if (!!templates && templates.length) {
      const sorted = templates.sort(comparer);
      setTemplates(sorted);
      setLoading(false);
    }
  }, [templates]);

  useEffect(() => {
    if (!loading && !templateId && storyRef.current?.templateId) {
      const existingTemplate = templates?.find((t) => {
        t.id === storyRef.current?.templateId;
      });
      if (existingTemplate) {
        setTemplate(existingTemplate);
        setTemplateId(existingTemplate.id);
      }
    }
  }, [storyRef.current, loading]);

  const update = useCallback(() => {
    if (currentTemplate) {
      const updatedTemplate: {
        templateId: Template["id"];
        prompts: Template["prompts"];
        template: Story["template"];
        welcomeMessage: Template["welcomeMessage"];
      } = {
        templateId: currentTemplate.id,
        prompts: currentTemplate.prompts,
        template: getTemplateMetadata(currentTemplate),
        welcomeMessage: currentTemplate.welcomeMessage,
      };

      dispatch({
        type: "update",
        payload: {
          story: {
            ...story,
            ...updatedTemplate,
          },
          error: null,
          valid: {
            [id]: validate(templateId),
          },
          changed: true,
        },
      });
    }
  }, [templateId, currentTemplate]);

  useEffect(() => {
    validate(templateId);
    update();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!loading) {
      update();
    }
  }, [templateId]);

  const validate = (_templateId: string) => {
    const results = {
      ...valid,
      templateId: !!(_templateId && _templateId.length),
    };
    setValid(results);
    return results;
  };

  const handleTemplateChange = (template: Template) => {
    setTemplateId(template.id);
    setTemplate(template);
    track("setup_templateChanged", {
      templateId: template.id,
      templateName: template.title,
      templateIllustration: buildIllustrationUrl(template.illustration),
    });
  };

  useEffect(() => {
    const featuredSelected = sortedTemplates
      ?.filter((t: Template) => {
        return !!t.featured;
      })
      .filter((t: Template) => {
        return templateId === t.id;
      });
    if (
      templateId &&
      !!featuredSelected &&
      !featuredSelected?.length &&
      !isOpen
    )
      onToggle();
  });

  const comparer = (a: Template, b: Template) => {
    let comparison = 0;
    if (a.title.charAt(0) === '"') {
      comparison = 2;
    } else if (a.title > b.title) {
      comparison = 1;
    } else if (a.title < b.title) {
      comparison = -1;
    }
    return comparison;
  };

  const TemplateView = ({ template }: { template: Template }) => (
    <>
      <BrowserView>
        <AspectRatio maxW="400px" ratio={1} key={template.id}>
          <TemplateBox
            active={templateId === template.id}
            template={template}
            onSelect={(t) => handleTemplateChange(t)}
          />
        </AspectRatio>
      </BrowserView>
      <MobileView>
        <TemplateBox
          key={template.id}
          active={templateId === template.id}
          template={template}
          onSelect={(t) => handleTemplateChange(t)}
        />
      </MobileView>
    </>
  );

  if (sortedTemplates) {
    return (
      <>
        <Heading as="h4" size="h4" textAlign="center">
          Featured packages
        </Heading>
        <SimpleGrid minChildWidth="225px" spacing="20px" w="full">
          {sortedTemplates
            .filter((template: Template) => {
              return !!template.featured;
            })
            .map((t: Template) => {
              return <TemplateView template={t} key={t.id} />;
            })}
        </SimpleGrid>
        {/* <Divider /> */}
        {!isOpen ? (
          <Button
            variant="outline"
            onClick={onToggle}
            colorScheme="majesty"
            alignSelf="center"
          >
            show all packages
          </Button>
        ) : (
          <Divider />
        )}
        <Collapse in={isOpen}>
          <SimpleGrid minChildWidth="225px" spacing="20px" w="full">
            {sortedTemplates
              .filter((template: Template) => {
                return !template.featured;
              })
              .map((t: Template) => {
                return <TemplateView template={t} key={t.id} />;
              })}
          </SimpleGrid>
        </Collapse>
      </>
    );
  } else return <></>;
};

export default TemplateSelector;
