import { modalAnatomy as parts } from "@chakra-ui/anatomy"
import { ComponentStyleConfig } from "@chakra-ui/react"
import type {
  PartsStyleFunction,
  SystemStyleFunction,
  SystemStyleObject,
} from "@chakra-ui/theme-tools"
import { mode } from "@chakra-ui/theme-tools"


const baseStyleDialog: SystemStyleFunction = (props) => {
  const { colorScheme: c, scrollBehavior } = props;

  return {
    borderRadius: "md",
    bg: mode(`${c}.50`, `${c}.500`)(props),
    color: "inherit",
    my: "3.75rem",
    zIndex: "modal",
    maxH: scrollBehavior === "inside" ? "calc(100% - 7.5rem)" : undefined,
    boxShadow: mode("lg", "dark-lg")(props),
  }
}

const baseStyleHeader: SystemStyleObject = {
    fontSize: "3xl",
    fontFamily: "Libre Baskerville",
    // manually need to add bottom margin to overcome spacing issues when in a stacked container
    mb: "1rem",
    textAlign: "center",
    _after: {
      marginTop: "0",
      marginRight: "auto",
      marginBottom: "0",
      marginLeft: "auto",
      fontFamily: "quilted-fonts",
      content: `"\\21"`,
      fontSize: "0.5rem",
      width: "54px",
      height: "10px",
      display: "block",
      paddingTop: "16px",
    },
}

const baseStyleFooter: SystemStyleObject = {
  justifyContent: "center"
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  dialog: baseStyleDialog(props),
  header: baseStyleHeader,
  footer: baseStyleFooter,
})

const modalTheme:ComponentStyleConfig = {
    parts: parts.keys,
    variants:{
        quilted: baseStyle
    }
}

export default modalTheme;