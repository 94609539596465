import { useActor } from "@xstate/react";
import React, { useEffect, useRef, useState } from "react";
import { Flex, HStack, Stack, StackProps, Progress, AspectRatio, useBreakpointValue } from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import VideoCropper from "./VideoCropper";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VideoClipContext } from "../../types/DIYVideoBuilder";

const progressInterval = 100;

const VideoEditor = (props: VideoEditorProps) => {
  const { activeClipMachine, wrapperProps } = props;

  const [showCropper, setShowCropper] = useState(false);

  // Video trim values based on pixel values of slider handles
  const [videoStart, setVideoStart] = useState(0);
  const [videoEnd, setVideoEnd] = useState<number>();

  const trimRef = useRef(null);
  const [trimWidth, setTrimWidth] = useState<number>();

  const playerRef = useRef(null);
  const [ready,setReady] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState<number>();
  // Video trim values based on ratio
  const [videoIn, setVideoIn] = useState<number>(0);
  const [videoOut, setVideoOut] = useState<number>(1);
  // Video duration in seconds
  const [duration, setDuration] = useState<number>();

  const [playerWidth, setPlayerWidth] = useState<number>();
  const [playerHeight, setPlayerHeight] = useState<number>();

  const [hasTrimmedVideo, setVideoTrimmed] = useState(false);

  const [state, send]: any[] = useActor(activeClipMachine);
  const clipContext: VideoClipContext = state.context;

  const videoSize = useBreakpointValue({ base: { width: "100%", height: "auto" }, lg: {} });

  const resetTrimHandles = () => {
    // @ts-ignore
    setVideoStart(
      // @ts-ignore
      clipContext.data.cutFrom && duration
        ? // @ts-ignore
          (clipContext.data.cutFrom * trimWidth) / duration
        : 0
    );
    // @ts-ignore
    setVideoEnd(
      // @ts-ignore
      clipContext.data.cutTo && duration
        ? // @ts-ignore
          (clipContext.data.cutTo * trimWidth) / duration
        : trimWidth
    );
    // @ts-ignore
    setVideoIn(
      // @ts-ignore
      clipContext.data.cutFrom && duration
        ? // @ts-ignore
          clipContext.data.cutFrom / duration
        : 0
    );
    setVideoOut(
      // @ts-ignore
      clipContext.data.cutTo && duration ? clipContext.data.cutTo / duration : 1
    );
  };

  useEffect(() => resetTrimHandles(), [duration]);

  useEffect(() => {
    setShowCropper(!!(duration && trimWidth && videoEnd));
  });

  useEffect(() => {
    if (hasTrimmedVideo && duration) {
      send({
        type: "CHANGE",
        value: {
          ...clipContext.data,
          cutFrom: duration * videoIn,
          cutTo: duration * videoOut,
        },
      });
    }
  }, [duration, videoIn, videoOut]);

  useEffect(() => {
    // @ts-ignore
    const trimW = trimRef?.current?.clientWidth;
    setTrimWidth(trimW);
    // @ts-ignore
    setPlayerWidth(playerRef?.current?.wrapper.clientWidth);
    // @ts-ignore
    setPlayerHeight(playerRef?.current?.wrapper.clientHeight);
    console.log('VideoEditor.useEffect')
  });

  useEffect(() => {
    if (trimWidth) {
      setVideoEnd(trimWidth);
    }
  }, [trimWidth]);

  useEffect(() => {
    setVideoTrimmed(true);
    if (videoStart >= 0 && trimWidth) {
      setVideoIn(videoStart / trimWidth);
    }
  }, [videoStart]);

  useEffect(() => {
    setVideoTrimmed(true);
    if (videoEnd && trimWidth) setVideoOut(videoEnd / trimWidth);
  }, [videoEnd]);

  const seek = (prog?: number) => {
    // @ts-ignore
    ready && playerRef?.current?.seekTo(prog || videoIn);
  };

  useEffect(() => {
    // if (!!progress) {
    //   if (videoIn > progress) seek();
    // } else seek();

    seek();
  }, [ready, videoIn]);

  useEffect(() => {
    if (!!progress) {
      if (videoOut < progress) seek(videoOut);
    } else seek(videoIn);
  }, [videoOut]);

  useEffect(() => {
    setVideoTrimmed(false);
  }, [activeClipMachine]);

  return (
    <Stack
      spacing="lg"
      m="lg"
      sx={wrapperProps}
      h="full"
      align="center"
      justify="center"
      position="relative"
    >
      {/* @ts-ignore */}
      {clipContext.data.content.streamUrl && (
        <Stack
          spacing="lg"
          justify="center"
          alignItems="center"
          maxH="100%"
          maxW="100%"
          overflow="hidden"
          borderRadius="sm"
        >
          <ReactPlayer
            style={{ zIndex: 1 }}
            playsinline={true}
            // @ts-ignore
            url={clipContext.data.content.streamUrl}
            playing={playing}
            controls={false}
            ref={playerRef}
            onReady={() => setReady(true)}
            onDuration={(dur) => setDuration(dur)}
            onClick={() => setPlaying(!playing)}
            progressInterval={progressInterval}
            {...videoSize}
            onProgress={(prog) => {
              setProgress(prog.played);
              if (prog.played >= videoOut) {
                setPlaying(false);
                seek();
              }
            }}
          />
          {/* {!playing && (
              <Box
                w={`${playerWidth}px` || "full"}
                h={`${playerHeight}px` || "full"}
                zIndex="2"
                pos="absolute"
                top="0"
                left="0"
                bgColor="rgba(0,0,0,.4)"
                onClick={() => setPlaying(!playing)}
              />
            )} */}
          <HStack w="full" visibility={showCropper ? "visible" : "hidden"}>
            <IconButton
              icon={<FontAwesomeIcon icon={playing ? "pause" : "play"} />}
              onClick={() => setPlaying(!playing)}
              aria-label="play/pause button"
              m="0"
              w="32px"
              h="32px"
              flexBasis="32px"
              rounded="full"
              bgColor={playing ? "puce.400" : "seaGreen.600"}
            />
            <Flex ref={trimRef} flex="1">
              {showCropper && (
                <VideoCropper
                  sliderMin={videoStart}
                  // @ts-ignore
                  sliderMax={videoEnd}
                  setSliderMax={setVideoEnd}
                  setSliderMin={setVideoStart}
                  videoIn={videoIn}
                  videoOut={videoOut}
                  duration={duration}
                  progress={progress}
                  progressInterval={progressInterval}
                  playing={playing}
                  onSeek={(val: number) => seek(val)}
                  trackWidth={trimWidth ? trimWidth / 16 : 10}
                />
              )}
            </Flex>
          </HStack>
        </Stack>
      )}

      {/* @ts-ignore */}
      {!clipContext.data.content.streamUrl &&  (<Flex>Video missing!</Flex>)}
    </Stack>
  );
};

export default VideoEditor;

interface VideoEditorProps {
  activeClipMachine: any;
  wrapperProps?: StackProps;
}
