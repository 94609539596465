import React from 'react';

import { Box, Text } from 'grommet';

const FIREBASE_ERROR_CODES = {
    'app-deleted' : 'There was a problem connecting to the application.',
    'app-not-authorized' : 'The app identified by the domain is not authorized.',
    'argument-error' : 'There was an error with your account, please try again or contact support',
    'invalid-api-key' : 'There was a problem connecting to the application.',
    'invalid-user-token' : 'Your session has expired or is no longer valid. Please first sign out, then sign in again.',
    'invalid-tenant-id' : 'There was a problem connecting to the application.',
    'network-request-failed' : 'A network error (such as timeout, interrupted connection or unreachable host) has occurred. Please check your internet connection and try again.',
    'operation-not-allowed' : 'There was a problem connecting to the application.',
    'requires-recent-login' : 'Your session has expired or is no longer valid. Please first sign out, then sign in again.',
    'too-many-requests' : 'There was a problem connecting to the application. Please trying again after a few moments.',
    'unauthorized-domain' : 'There was a problem connecting to the application.',
    'user-disabled' : 'There was an error with your account, please contact support',
    'user-token-expired' : 'Your session has expired or is no longer valid. Please first sign out, then sign in again.',
    'web-storage-unsupported' : 'Your browser does not support web storage or has been disabled. Please enable web storage or use a current browser.',
    'email-already-in-use': 'This email address is already registered. Please login using this email address.',
    'account-exists-with-different-credential': 'This email address is already registered using a different login provider. Please login using this email address.',
    'wrong-password': 'Incorrect password. Please try again or contact support.',
    'weak-password': 'Your password must be at least 6 characters.'
}

const AuthErrorWidget = ({error}) => {
    const [message,setMessage] = React.useState();

    React.useEffect(() => {
        console.log('AuthErrorWidget',error);
        const getMessage = () => {
            const code = String(error && error.code).replace('auth/','');
            return (code && FIREBASE_ERROR_CODES.hasOwnProperty(code)) ? FIREBASE_ERROR_CODES[code] : "An unknown error has occured, please try again or contact support.";
        }
        setMessage(getMessage());
    },[error]);


    return (<Box fill="horizontal" align="center" justify="start" background="status-error" pad="medium" margin="medium">
        <Text size="medium" weight="bold">{message}</Text>
    </Box>);
}

export default AuthErrorWidget;