import React from 'react';
import { Box, Button, Heading, List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';

interface InitialCardViewProps { colorScheme:string, subject:string, tasks:{ setDueDate:boolean, addedStorytellers:boolean, setReminders:boolean }, onClick: () => void }
export const InitialCardView:React.FC<InitialCardViewProps> = ({colorScheme, subject, tasks, onClick }:InitialCardViewProps) => {
    return (<Box>
        <Heading as='h4' size='md' mb={3}>Invite storytellers</Heading>
        <Text mb={3}>The first step in creating a Quilted gift is to invite everyone in {subject}&apos;s life to contribute.</Text>
        <List spacing={1} mb={3}>
            <ListItem>
                <ListIcon as={MdCheckCircle} color={`${tasks.setDueDate?'green.500':'gray.300'}`} fontSize="2xl" />
                Add a due date
            </ListItem>
            <ListItem>
                <ListIcon as={MdCheckCircle} color={`${tasks.addedStorytellers?'green.500':'gray.300'}`} fontSize="2xl" />
                Send invites
            </ListItem>
            <ListItem>
                <ListIcon as={MdCheckCircle} color={`${tasks.setReminders?'green.500':'gray.300'}`} fontSize="2xl" />
                Set up reminders
            </ListItem>
        </List>
        <Button colorScheme={colorScheme} onClick={onClick}>{ (tasks.setDueDate || tasks.addedStorytellers || tasks.setReminders) ? "Continue":"Get Started" }</Button>
    </Box>)
}

export default InitialCardView;