import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
  Checkbox,
  Link,
  Skeleton,
  Collapse,
  useDisclosure,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AuthErrorWidget from "./AuthErrorWidget";
import {
  AuthWidgetProps,
  SignInFormProps,
  SignInOnChangeProps,
  SignUpFormProps,
  SignupOnChangeProps,
} from "../../types/AuthWidget";

yup.addMethod(
  yup.string,
  "phone",
  function (messageError = "Phone number is not valid") {
    const phoneRegExp =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return this.test("phone", messageError, (value) => {
      if (value && value.length > 0) {
        return phoneRegExp.test(value);
      }
      return true;
    });
  }
);

export const SigninForm = ({
  emailValue,
  passwordValue,
  onChange,
}: SignInFormProps) => {
  const [loading, setLoading] = useState(true);
  const schema = yup.object().shape({
    email: yup.string().trim().email().required("Please enter your email address"),
    password: yup.string().required("Password is required"),
  });

  const { register, setFocus, setValue, watch, formState } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const { errors, isDirty, isValid } = formState;

  const [email, password] = watch(["email", "password"]);

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  useEffect(() => {
    if (!email && emailValue) {
      setValue("email", emailValue.trim(), {
        shouldValidate: true,
        shouldDirty: false,
        shouldTouch: false,
      });
    }
    if (!password && passwordValue) {
      setValue("password", passwordValue, {
        shouldValidate: true,
        shouldDirty: false,
        shouldTouch: false,
      });
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!loading)
      onChange && onChange({ email:email.trim(), password, errors, isDirty, isValid });
  }, [email, password, errors, loading]);

  return (
    <Skeleton isLoaded={!loading}>
      <form>
        <Stack spacing="6">
          <FormControl id="email" isInvalid={errors.email}>
            <FormLabel variant="smHeadingLabel">Email</FormLabel>
            <Input {...register("email")} placeholder="youremail@domain.com" data-testid="email" />
            <FormErrorMessage color="puce.700" data-testid="email-error" >
              {errors.email?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl id="password" isInvalid={errors.password}>
            <FormLabel variant="smHeadingLabel">Your Password</FormLabel>
            <Input
              {...register("password")}
              placeholder="Enter a password"
              type="password"
              data-testid="password"
            />
            <FormErrorMessage color="puce.700">
              {errors.password?.message}
            </FormErrorMessage>
          </FormControl>
          <Text align="center">
            <Link href="/reset-password">Forgot your password?</Link>
          </Text>
        </Stack>
      </form>
    </Skeleton>
  );
};

export const SignupForm = ({
  firstNameValue,
  lastNameValue,
  emailValue,
  phoneValue,
  passwordValue,
  acceptedTermsValue,
  sendMarketingValue,
  emailExists,
  onChange
}: SignUpFormProps) => {
  const [loading, setLoading] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const schema = yup.object().shape({
    firstName: yup.string().required("Please enter your first name"),
    lastName: yup.string().required("Please enter your last name"),
    email: yup.string().trim().email().required("Please enter your email address"),
    // @ts-ignore
    phone: yup.string().phone(),
    password: yup.string().required("Password is required"),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    acceptedTerms: yup.boolean().required().isTrue(),
    sendMarketing: yup.boolean(),
  });

  const { register, setFocus, setValue, watch, formState } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const { errors, isDirty, isValid } = formState;

  useEffect(() => {
    if (!email && emailValue) {
      setValue("email", emailValue.trim(), {
        shouldValidate: true,
        shouldDirty: false,
        shouldTouch: false,
      });
    }
    if (!password && passwordValue) {
      setValue("password", passwordValue, {
        shouldValidate: true,
        shouldDirty: false,
        shouldTouch: false,
      });
    }
    if (!firstName && firstNameValue) {
      setValue("firstName", firstNameValue, {
        shouldValidate: true,
        shouldDirty: false,
        shouldTouch: false,
      });
    }
    if (!lastName && lastNameValue) {
      setValue("lastName", lastNameValue, {
        shouldValidate: true,
        shouldDirty: false,
        shouldTouch: false,
      });
    }
    if (!phone && phoneValue) {
      setValue("phone", phoneValue, {
        shouldValidate: true,
        shouldDirty: false,
        shouldTouch: false,
      });
    }
    if (!acceptedTerms && acceptedTermsValue) {
      setValue("acceptedTerms", acceptedTermsValue, {
        shouldValidate: true,
        shouldDirty: false,
        shouldTouch: false,
      });
    }
    if (!sendMarketing && sendMarketingValue) {
      setValue("sendMarketing", sendMarketingValue, {
        shouldValidate: true,
        shouldDirty: false,
        shouldTouch: false,
      });
    }
    setLoading(false);
  }, []);

  const [
    firstName,
    lastName,
    email,
    phone,
    password,
    passwordConfirmation,
    acceptedTerms,
    sendMarketing,
  ] = watch([
    "firstName",
    "lastName",
    "email",
    "phone",
    "password",
    "passwordConfirmation",
    "acceptedTerms",
    "sendMarketing",
  ]);

  useEffect(() => {
    setFocus("firstName");
  }, [setFocus]);

  useEffect(() => {
    if (!!password?.length) {
      onOpen();
    } else onClose();
  }, [password]);

  useEffect(() => {
    if (!loading) {
      onChange &&
        onChange({
          email:email.trim(),
          phone,
          firstName,
          lastName,
          password,
          passwordConfirmation,
          acceptedTerms,
          sendMarketing,
          errors,
          isDirty,
          isValid,
        });
    }
  }, [
    loading,
    email,
    phone,
    firstName,
    lastName,
    password,
    passwordConfirmation,
    acceptedTerms,
    sendMarketing,
  ]);

  return (
    <form>
      <Stack spacing="4">
        <HStack align="flex-start">
          <FormControl id="firstName" isInvalid={errors.firstName}>
            <FormLabel variant="smHeadingLabel">First Name</FormLabel>
            <Input {...register("firstName")} placeholder="Andre"
              data-testid="firstName" />
            <FormErrorMessage color="puce.700">
              {errors.firstName?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl id="lastName" isInvalid={errors.lastName}>
            <FormLabel variant="smHeadingLabel">Last Name</FormLabel>
            <Input {...register("lastName")} placeholder="Davis"
              data-testid="lastName" />
            <FormErrorMessage color="puce.700">
              {errors.lastName?.message}
            </FormErrorMessage>
          </FormControl>
        </HStack>
        <FormControl id="email" isInvalid={errors.email || emailExists}>
          <FormLabel variant="smHeadingLabel">Email</FormLabel>
          <Input {...register("email")} placeholder="youremail@domain.com"
              data-testid="email" />
          <FormErrorMessage color="puce.700">
            {errors.email?.message}
          </FormErrorMessage>
        </FormControl>
        <Stack>
          <FormControl id="password" isInvalid={errors.password}>
            <FormLabel variant="smHeadingLabel">Create a Password</FormLabel>
            <Input
              {...register("password")}
              placeholder="Enter a password"
              type="password"
              data-testid="password"
            />
            <FormErrorMessage color="puce.700">
              {errors.password?.message}
            </FormErrorMessage>
          </FormControl>
          <Collapse in={isOpen}>
            <FormControl
              id="passwordConfirmation"
              isInvalid={errors.passwordConfirmation}
            >
              <Input
                {...register("passwordConfirmation")}
                placeholder="Enter your password again"
                type="password"
                data-testid="passwordConfirmation"
              />
              <FormErrorMessage color="puce.700">
                {errors.passwordConfirmation?.message}
              </FormErrorMessage>
            </FormControl>
          </Collapse>
        </Stack>

        <Stack>
          <FormControl id="acceptedTerms" isInvalid={errors.acceptedTerms}>
            <Checkbox
              {...register("acceptedTerms")}
              colorScheme="airBlue"
              isInvalid={errors.acceptedTerms}
              data-testid="acceptTerms"
            >
              I agree to the Quilted{" "}
              <Link
                href="https://quilted.io/policies/terms-of-service"
                target="_blank"
                textDecor="underline"
                title="Quilted Terms of Service"
              >
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link
                href="https://quilted.io/policies/privacy-policy"
                target="_blank"
                textDecor="underline"
              >
                Privacy Policy
              </Link>
            </Checkbox>
          </FormControl>
          <FormControl id="sendMarketing" isInvalid={errors.sendMarketing}>
            <Checkbox
              defaultChecked
              {...register("sendMarketing")}
              colorScheme="airBlue"
              data-testid="sendMarketing"
            >
              Share Quilted updates with me
            </Checkbox>
          </FormControl>
        </Stack>
      </Stack>
    </form>
  );
};

export const AuthForm = ({
  defaultAuthMode = "signup",
  authChecks,
  emailExists,
  error,
  signinWithFacebook,
  onChange,
  onModeChange,
  showAuthMethodSwitch = true,
  track
}: AuthWidgetProps) => {
  const [authMode, setAuthMode] = useState(defaultAuthMode);
  const [loading, setLoading] = useState(true);
  const [hasFocused, setHasFocused] = useState(false);

  useEffect(() => {
    if (loading && !authChecks?.signedIn) {
      setAuthMode(emailExists ? "login" : defaultAuthMode);
      setLoading(false);
    }
  }, [authChecks, emailExists]);

  const handleChanges = (
    changes: SignInOnChangeProps | SignupOnChangeProps
  ) => {
    onChange(changes);
    if (!hasFocused) {
      track(`Started filling out ${authMode} form`);
      setHasFocused(true);
    }
  };

  useEffect(() => {
    if (!loading) onModeChange(authMode);
  }, [authMode, loading]);

  return (
    <Skeleton isLoaded={!loading}>
      <Stack spacing="6">
        {signinWithFacebook && (
          <>
            <Button
              colorScheme="gray"
              borderColor="gray.300"
              fontWeight="500"
              variant="outline"
              w="full"
              sx={{ p: "md" }} // Forces override of default button prop
              onClick={() => {
                track("signup_startFacebookSignup");
                signinWithFacebook();
              }}
            >
              <HStack>
                <FontAwesomeIcon
                  size="1x"
                  icon={["fab", "facebook"]}
                  color="#1977F3"
                />
                <Text fontSize="lg">Continue with Facebook</Text>
              </HStack>
            </Button>
            <HStack spacing="lg">
              <Divider borderColor="gray.500" />
              <Text variant="serif" textTransform="uppercase">
                or
              </Text>
              <Divider borderColor="gray.500" />
            </HStack>
          </>
        )}
        {error && <AuthErrorWidget error={error} />}
        {showAuthMethodSwitch && (
          <HStack
            direction="row"
            bgColor="airBlue.50"
            py="1"
            px="4"
            textAlign="center"
            rounded="lg"
            justify="center"
          >
            <Text variant="serif" my="auto">
              {authMode === "login"
                ? "Need to create an account?"
                : "Already have an account?"}
            </Text>
            <Button
              variant="plain"
              fontFamily="serif"
              color="airBlue.700"
              p="0"
              onClick={() =>
                setAuthMode(authMode === "login" ? "signup" : "login")
              }
            >
              {authMode === "login" ? (
                <span>Sign up</span>
              ) : (
                <span>Log in</span>
              )}
            </Button>
          </HStack>
        )}
        {authMode === "login" && <SigninForm onChange={handleChanges} />}
        {authMode === "signup" && (
          <SignupForm onChange={handleChanges} emailExists={emailExists} />
        )}
      </Stack>
    </Skeleton>
  );
};

export default AuthForm;
