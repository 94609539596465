import React from "react";

import { useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { Outlet, useParams } from "react-router-dom";
import LoadingPage from "../LoadingPage";
import StoryNotFound from "../../components/response/StoryNotFound";
import StoryViewProvider from "./components/StoryViewProvider";
import PageContainer from "../../layout/components/PageContainer";
import { Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { useAnalytics } from "use-analytics";

import { useInterpret } from "@xstate/react";
import respondMachine, {
  RespondMachineInterpreterType,
} from "../../machines/Response/respondMachine";

type StorySlugParams = {
  slug: string;
};

const StoryViewComponent = ({
  service,
}: {
  service: RespondMachineInterpreterType;
}) => {
  const { slug } = useParams<StorySlugParams>();
  useFirestoreConnect([
    {
      collection: "stories",
      where: ["slug", "==", slug],
    },
  ]);

  const story: any = useSelector(
    (state: any) =>
      state.firestore.data.stories &&
      Object.keys(state.firestore.data.stories)
        .map((key) => {
          return { id: key, ...state.firestore.data.stories[key] };
        })
        .find((s) => s.slug === slug),
    (current, previous) => {
      if (!current && !previous) {
        console.log("No data to compare, are same.");
        return true;
      } else if (!!current && !!previous) {
        console.log("Compare story state.", {
          eq: current.state === previous.state,
        });
        return current.state === previous.state;
      } else {
        console.log("No data to compare with current, are not same.", {
          current,
          previous,
        });
        return false;
      }
    }
  );

  return (
    <>
      {isLoaded(story) ? (
        isEmpty(story) ? (
          <StoryNotFound />
        ) : (
          <StoryViewProvider story={story} service={service}>
            <Helmet>
              <title>Quilted Stories - {story.title}</title>
              <meta
                name="description"
                content={`${story.title} - a Quilted Story`}
              />
            </Helmet>
            <Outlet />
          </StoryViewProvider>
        )
      ) : (
        <LoadingPage title="Unfolding Story..." />
      )}
    </>
  );
};

const StoryView = () => {
  console.log("Render StoryView Component");

  const { track, identify } = useAnalytics();

  const responseService = useInterpret(
    respondMachine.withConfig({
      actions: {
        sendAnalytics: (context, event) => {
          if (event.type !== "ANALYTICS") return;
          switch (event.action) {
            case "identify":
              if (!context.identity?.uid) {
                console.error("Cannot identify. No user identifier provided.");
                break;
              }
              identify(context.identity.uid, {
                $email: context.identity.email,
                email: context.identity.email,
                name: context.identity.displayName,
                // role and unsubscribed_from_emails are required for syncing to Intercom
                role: "user",
                unsubscribed_from_emails:
                  context.identity.optInMarketing !== undefined
                    ? !context.identity.optInMarketing
                    : undefined,
                ...event.properties,
              });
              break;
            case "track":
            default:
              if (!event.track) {
                console.error("Cannot track. No tracking event provided.");
                break;
              } else if (!context.story) {
                console.error("Cannot track. Story data missing.");
                break;
              }
              track(event.track, {
                storyId: context.story.id,
                storyTitle: context.story.title,
                storyOwner: context.story.owner,
                storySubject: context.story.subject,
                activePromptId:
                  context.activePrompt?.id || context.activePrompt?.idx,
                activePromptText: context.activePrompt?.text,
                ...event.properties,
              });
              break;
          }
        },
      },
    })
  );

  return (
    <PageContainer minW={undefined} maxW="90vw" w="3xl">
      <Box w="full" h="full">
        <StoryViewComponent service={responseService as any} />
      </Box>
    </PageContainer>
  );
};

export default StoryView;
