import { createMachine, assign, DoneInvokeEvent, send } from "xstate";
import { pure, sendParent } from "xstate/lib/actions";
import { uploadToStorageCancellable } from "../utils/firebase-storage";
import { assertEventType } from "./helpers";


interface UploadFileModel { path: string, file: any, metadata: any, [key: string]: any } //File|Blob

interface UploadContext {
  data?: UploadFileModel | null;
  progress: number;
  error?: null | string;
  result?: any;
}


export type UploadMachineEvent =
  {
    type: "START",
    value: UploadFileModel
  } |
  {
    type: "PROGRESS",
    value: number
  } |
  {
    type: "RETRY"
  } |
  {
    type: "RESET"
  } |
  {
    type: "CANCEL"
  } |
  {
    type: "COMPLETE",
    data: {
      downloadURL: string,
      bytesTransferred: number,
      totalBytes: number,
      state: string,
    }
  }
  | DoneInvokeEvent<UploadFileModel & { result: any }>

export type UploadMachineState =
  | { value: "idle"; context: Partial<UploadContext> & { progress: number } }
  | {
    value: "uploading";
    context: UploadContext;
  }
  | { value: "failure"; context: UploadContext }
  | { value: "cancel"; context: UploadContext }
  | { value: "done"; context: UploadContext & { result: any } };

export const uploadMachine = createMachine<UploadContext, UploadMachineEvent, UploadMachineState>({
  id: 'uploadMachine',
  initial: 'idle',
  context: {
    progress: 0,
    data: null,
    error: null
  },
  states: {
    idle: {
      entry: ['announce'],
      on: {
        START: {
          actions: [
            'announce',
            'onStart'
          ]
        },
        EXIT: {
          target: 'exit'
        }
      },
      always: [
        {
          target: "uploading",
          cond: (context) => !!context.data,
        }
      ]
    },
    uploading: {
      entry: ['announce'],
      invoke: {
        id: 'upload',
        src: 'upload'
      },
      on: {
        PROGRESS: {
          actions: ['recordProgress']
        },
        CANCEL: {
          actions: ['announce', 'cancelled'],
          target: 'idle'
        },
        COMPLETE: {
          target: 'done',
          actions: assign((context, event) => ({ result: event, progress: 100 }))
        },
        ERROR: {
          target: 'failure',
          actions: assign({ error: (context, event) => event.data })
        }
      }
    },
    failure: {
      entry: ['announce'],
      on: {
        RETRY: {
          target: 'idle',
          actions: ['announce']
        }
      }
    },
    done: {
      entry: ['announce', 'complete'],
      on: {
        RESET: {
          actions: assign({ data: undefined, progress: 0 }),
          target: 'idle'
        },
        EXIT: {
          target: 'exit'
        }
      }
    },
    exit: {
      type: 'final'
    }
  },
  on: {
    RESET: {
      target: '.idle',
      actions: assign({
        progress: 0,
        data: undefined,
        results: undefined,
        error: undefined
      })
    }
  },
},
  {
    services: {
      upload: (context, event) => (callback, onRecieve) => {
        let uploadState: "starting" | "running" | "paused" | "cancelled" = "starting";
        const onProgress = ({ progress, state }: { progress: number, state: any }) => {
          uploadState = state;
          if (state === 'running') callback({ type: 'PROGRESS', value: progress })
        };

        const [uploader, cancel] = uploadToStorageCancellable(context.data?.path, context.data?.file, context.data?.metadata, onProgress) as [Promise<{ downloadURL: string, uploadTaskSnapshot: any }>, () => void]

        onRecieve((event) => {
          if (event.type === 'CANCEL') {
            console.log('Cancel upload!');
            cancel();
          }
        })

        uploader.then((snapshot) => {
          const { downloadURL, uploadTaskSnapshot } = snapshot;
          callback(
            {
              type: "COMPLETE",
              data: {
                downloadURL,
                bytesTransferred: uploadTaskSnapshot.bytesTransferred,
                totalBytes: uploadTaskSnapshot.totalBytes,
                state: uploadTaskSnapshot.state,
              }
            }
          );
        }).catch(error => {
          callback({ type: "ERROR", data: error });
        });

        return () => {
          if (uploadState === 'running') {
            console.log('Cancel upload!');
            cancel();
          }
        }
      }
    },
    actions: {
      announce: (context, event) => {
        console.log("Upload machine - ", { context, event });
      },
      onStart: assign((context, event) => {
        try {
          assertEventType(event, "START");
          const { value } = event;
          console.log({ ...value });
          return { data: value, progress: 1 };
        } catch (error) {
          console.error(error)
          return {};
        }
      }),
      recordProgress: pure((context, event) => {
        if (event.type !== "PROGRESS") return;
        assertEventType(event, 'PROGRESS');
        return [
          assign({ progress: event.value }),
          sendParent({
            type: "UPLOAD.PROGRESS",
            data: { ...context.data, progress: event.value || 0 }
          })
        ] as Array<any>
      }),
      complete: pure((context, event) => {
        assertEventType(event, 'COMPLETE');
        const result =
        {
          type: "UPLOAD.COMPLETE",
          data: { ...context.data, result: context.result }
        }

        return sendParent(result);
      }),
      cancelled: pure((context, event) => {
        if (event.type !== "CANCEL") return;
        assertEventType(event, 'CANCEL');
        return [
          assign({ progress: 0, data: undefined }),
          sendParent({ ...event, type: 'UPLOAD.CANCELLED' }, {
            delay: 1000
          })
        ] as Array<any>
      })
    },
  });



export const createUploadMachine = ({
  id,
  data
}: { id: string, data: UploadFileModel }) =>
  uploadMachine.withConfig({ _key: String(id) }, { progress: 0, data })

export default uploadMachine;