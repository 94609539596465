import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faCheckDouble,
  faPlus,
  faSdCard,
  faSignOutAlt,
  faBars,
  faFileAlt,
  faAddressCard,
  faMobileAlt,
  faEnvelope,
  faUserTimes,
  faUserShield,
  faQuestion,
  faAngleDown,
  faCalendarAlt,
  faPlay,
  faPencilAlt,
  faStar,
  faCloudUploadAlt,
  faAt,
  faIdBadge,
  faCamera,
  faVideo,
  faTrash,
  faPause,
  faChevronRight,
  faUserFriends,
  faBell,
  faFilm,
  faTimes,
  faTimesCircle,
  faStop,
  faUpload,
  faImage,
  faMusic,
  faPhotoVideo,
  faPhotoFilm,
  faQrcode,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import {
  faCopy,
  faEdit,
  faEye,
  faEyeSlash,
  faQuestionCircle,
  faTrashAlt,
  faWindowClose,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faCheckDouble,
  faPlus,
  faSdCard,
  faSignOutAlt,
  faFacebook,
  faCopy,
  faEdit,
  faTrash,
  faPencilAlt,
  faTrashAlt,
  faWindowClose,
  faBars,
  faFileAlt,
  faAddressCard,
  faMobileAlt,
  faEnvelope,
  faUserTimes,
  faUserShield,
  faQuestion,
  faAngleDown,
  faCalendarAlt,
  faPlay,
  faPause,
  faStop,
  faStar,
  faCloudUploadAlt,
  faAt,
  faMobileAlt,
  faIdBadge,
  faCamera,
  faVideo,
  faEye,
  faEyeSlash,
  faQuestionCircle,
  faChevronRight,
  faUserFriends,
  faQuestionCircle,
  faBell,
  faFilm,
  faTimes,
  faTimesCircle,
  faUpload,
  faImage,
  faMusic,
  faPhotoVideo,
  faPhotoFilm,
  faQrcode,
  faSpinner
);

export default { library };
