
export const setPlaylist = (playlist) => {
    return (dispatch) => {
        dispatch({type: 'SET_PLAYLIST', playlist });
    };
}


export const setPlayingIndex = (index) => {
    return (dispatch) => {
        dispatch({type: 'SET_PLAYING_INDEX', index });
    };
}