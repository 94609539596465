import React from 'react'
import { Box, Button, Heading, Layer, Paragraph, Text } from 'grommet'
import { CircleQuestion, LinkPrevious, Star } from 'grommet-icons'
import TipsForRecording from './TipsForRecording'
import HelpBlock from './HelpBlock'
import YoutubeVideo from './YoutubeVideo'


export const RecordingTipsModal = ({showing, onClose, ...props}) => {
    const onClosing = () => {
        onClose && onClose()
    }

    return showing && (
        <Layer
          onEsc={onClosing}
          onClickOutside={onClosing}
        >
            <Box direction="column" margin={{ "bottom":"small" }} pad="small" align="center" justify="center" flex={{shrink:0}} fill={true} overflow="scroll" height={{max: "100vh"}}>
                <Box flex overflow="scroll">
                    <TipsForRecording>
                        <Paragraph fill={true} textAlign="center"><Text weight="bold">Below is a shortened example of some responses!</Text></Paragraph>
                        <YoutubeVideo youtubeVideoId="502sL0wfOzg" title="Quilted Example Video" />
                        <Box fill="horizontal" direction="column" align="center" justify="center" pad="small">
                            <Button fill="horizontal" icon={<LinkPrevious color="white" />} label={<Text color="white" weight="bold">Back to Story</Text>} color="accent-1" type="submit" primary={true} onClick={onClosing} />
                        </Box>
                    </TipsForRecording>
                </Box>
            </Box>
        </Layer>
      )
}


export const HelpModal = ({showing, onClose, ...props}) => {
    const onClosing = () => {
        onClose && onClose()
    }

    return showing && (
        <Layer
          onEsc={onClosing}
          onClickOutside={onClosing}          
        >
            <Box direction="column" margin={{ "bottom":"small" }} pad="small" align="center" justify="center" flex={{shrink:0}} fill={true}>
                <Box flex>
                    <HelpBlock>
                        <Box fill="horizontal" direction="column" align="center" justify="center" pad="small">
                            <Button fill="horizontal" icon={<LinkPrevious color="white" />} label={<Text color="white" weight="bold">Back to Story</Text>} color="accent-1" type="submit" primary={true} onClick={onClosing} />
                        </Box>
                    </HelpBlock>
                </Box>
            </Box>
        </Layer>
      )
}


const HelpBlocks = () => {
    const [showTips, setShowTips] = React.useState(false);
    const [showHelp, setShowHelp] = React.useState(false);

    const onTipsClosed = () => {
        setShowTips(false)
    }
    const onHelpClosed = () => {
        setShowHelp(false)
    }

    return (
    <Box align="center" justify="center" margin={{ bottom: "medium" }}>
        <Box align="stretch" justify="center" gap="small" pad="none" direction="row" fill>
            <Box background="light-1" round="xsmall" pad={{ "top": "medium", "bottom": "small", "horizontal": "small" }} align="center" justify="start" basis="1/2"
             onClick={() => { setShowTips(true); }}>
                <Box responsive={false} margin={{vertical:"medium"}}><Star size="xlarge" color="flag" /></Box>
                <Heading level={5} margin="xsmall" textAlign="center">How to Record an Amazing Video</Heading>
            </Box>
            <Box background="light-1" round="xsmall" pad={{ "top": "medium", "bottom": "small", "horizontal": "small" }} align="center" justify="start" basis="1/2"
             onClick={() => { setShowHelp(true); }}>
                <Box responsive={false} margin={{vertical:"medium"}}><CircleQuestion size="xlarge" color="accent-3" /></Box>
                <Heading level={5} margin="xsmall" textAlign="center">Need Help?</Heading>
            </Box>
        </Box>
        <RecordingTipsModal showing={showTips} onClose={onTipsClosed} />
        <HelpModal showing={showHelp} onClose={onHelpClosed} />
    </Box>)
}

export default HelpBlocks;