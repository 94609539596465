import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Stack,
  Checkbox,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  Center,
  Container,
} from "@chakra-ui/react";
//import { useAnalytics } from "use-analytics";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export type UserInfoFormData = {
  displayName: string;
  email: string;
  optInMarketing: boolean;
};

const UserInfoForm = ({
  title,
  owner,
  onContinue,
}: {
  title: string;
  owner: string;
  onContinue: (data:UserInfoFormData) => void;
}) => {
  const schema = yup.object().shape({
    email: yup.string().trim().email().required("Please enter your email address"),
    displayName: yup.string().required("displayName is required"),
  });

  const { register, setFocus, handleSubmit, watch, formState } = useForm<UserInfoFormData>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const { errors, isDirty, isValid } = formState;

  const [email, displayName, optInMarketing] = watch([
    "email",
    "displayName",
    "optInMarketing",
  ]);

  useEffect(() => {
    setFocus("displayName");
  }, [setFocus]);

  // @ts-ignore
  // const { track } = useAnalytics();

  const onSubmit = handleSubmit(data => { onContinue(data); });
  return (
    <Flex
    justify="center"
    my="lg"
    h="full"
    direction="column"
    p="lg"
    shadow="lg"
    bgColor="springWood.100"
    rounded="lg"
    align="center">
      <Heading as="h2" size="h2" variant="center">
        {title}
      </Heading>
      <Container>
        <Text my="4" align="center" lineHeight="short">
          Enter your name and email address to get started. {!!owner ? (`We'll share this
          with ${owner} so they know who has contributed.`) : "We'll share this with the story creator so they know who has contributed."}
        </Text>
      </Container>
      <form onSubmit={onSubmit}>
        <Stack>
          <FormControl
            id="displayName"
            isInvalid={!!errors.displayName}
            isRequired
          >
            <FormLabel htmlFor="displayName">
              What is your <Text as="strong">name</Text>?
            </FormLabel>
            <Input
              {...register("displayName")}
              placeholder="Name"
              type="text"
              data-testid="name"
            />
            <FormErrorMessage color="puce.700">
              {errors.displayName?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl id="email" isInvalid={!!errors.email}>
            <FormLabel htmlFor="email">
              What is your <Text as="strong">email</Text>?
            </FormLabel>
            <Input {...register("email")} placeholder="youremail@domain.com" data-testid="email" />
            <FormErrorMessage color="puce.700" data-testid="email-error">
              {errors.email?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl id="optInMarketing">
            <Checkbox iconColor='seaGreen.600' size='sm' defaultIsChecked={true} {...register("optInMarketing")}>
              Sign me up for the Quilted newsletter and send me a discount code
              to create a story of my own.
            </Checkbox>
          </FormControl>
          <Center>
            <Button colorScheme="airBlue" type="submit" mt="8" data-testid="continue">
              Continue
            </Button>
          </Center>
        </Stack>
      </form>
    </Flex>
  );
};

export default UserInfoForm;
