import React, { useEffect, useState } from "react";
import moment from "moment";

import useLocalStorage from "../../hooks/useLocalStorage";
import {
  useShopifyDispatch,
  useShopifyState,
} from "../shopify/ShopifyProvider";
import { useStoryContext } from "./StoryProvider";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Stack,
  Text,
  chakra,
  useColorModeValue,
  FormHelperText,
  Textarea,
  RadioGroup,
  Radio,
  Skeleton,
} from "@chakra-ui/react";

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputMask from "react-input-mask";

import DropCalendar from "../../components/layout/DropCalendar";

import config from "../../config/config";
import Products from "../shopify/Products";
import useAuth from "../../hooks/useAuth";
import { useFirestore } from "react-redux-firebase";

const GetMinDays = () => {
  return (config && config.app && config.app.finalVideoMinDays) || 3;
};

const Divider = () => (
  <Box visibility={{ base: "hidden", sm: "visible" }} aria-hidden="true">
    <Box py={5}>
      <Box
        borderTop="solid 1px"
        borderTopColor={useColorModeValue("gray.200", "whiteAlpha.200")}
      ></Box>
    </Box>
  </Box>
);

const FormComponent = ({ onSubmit, running }) => {
  return (
    <Box p={10}>
        <Stack>
          <Box px={[4, 0]}>
            <Heading as="h2" size="h2" variant="center">
              Complete Your Purchase
            </Heading>
          </Box>
          {config.shopify.showPhysicalProduct && (
            <>
              <Box mt={[10, 0]}>
                <Stack px={4} py={5} spacing={6} p={{ sm: 6 }}>
                  <div>
                    <FormControl>
                      <FormLabel variant="headingLabel">
                        Turn Your Quilted Movie Into a <strong>Keepsake</strong>
                      </FormLabel>
                      <Text>
                        Before checking out, you can also add a physical copy of
                        your movie to your order. These are perfect for giving
                        away as a meaningful gift!
                      </Text>
                      <Products />
                    </FormControl>
                  </div>
                </Stack>
              </Box>
              <Divider />
            </>
          )}
          <Flex
            minH="full"
            minW="full"
            direction="row-reverse"
            align="center"
            justify="end"
            p="sm"
          >
            <Button type="submit" isLoading={running} onClick={onSubmit}>
              Continue
            </Button>
          </Flex>
        </Stack>
    </Box>
  );
};

const RequestFinalVideoForm = (props) => {
  const minDays = GetMinDays();
  
  const [submitted, setSubmitted] = React.useState(false);
  const [running, setRunning] = React.useState(false);

  const [loaded, setLoaded] = React.useState(false);

  const storyState = useStoryContext();
  const dispatch = useShopifyDispatch();
  const shopifyState = useShopifyState();

  const { currentUser } = useAuth();
  const firestore = useFirestore();

  const saveCheckoutId = () => {
    const storyId = storyState.story.id;
    return firestore
      .collection("stories")
      .doc(storyId)
      .update({
        purchase: { checkoutId: shopifyState.checkout.id, status: "started" },
      });
  };

  const saveFinalVideoRequest = () => {
    const storyId = storyState.story.id;
    const finalVideoRequest = {
      checkoutId: shopifyState.checkout.id,
      state: "checkout",
      email: currentUser.email,
      uid: currentUser.uid,
      name: currentUser.displayName,
    };
    return firestore
      .collection("finalVideoRequests")
      .doc(storyId)
      .set(finalVideoRequest);
  };

  const save = async () => {
    await saveCheckoutId();
    await saveFinalVideoRequest();
  };

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  const handleSubmit = () => {
    setRunning(true);
    save()
      .then(() => {
        console.log({ template: storyState.story.template });
        const variantId = storyState.story.template.variantId; // match to shopify product variant Id's
        const customAttributes = [];
        const payload = submitted
          ? {}
          : { variantId, quantity: 1, customAttributes };
        const action = submitted ? "checkout" : "save_and_checkout";
        dispatch({
          type: action,
          payload,
        });
      })
      .finally(() => {
        setSubmitted(true);
        setRunning(false);
      });
  };

  return (
    <Skeleton isLoaded={loaded}>
      <FormComponent onSubmit={handleSubmit} running={running} />
    </Skeleton>
  );
};

export default RequestFinalVideoForm;
