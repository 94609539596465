import { createMachine, assign, sendParent } from "xstate";
import { pure } from "xstate/lib/actions";
import {
  VideoClipContext,
  VideoClipMachineEvent,
} from "../../types/DIYVideoBuilder";

export const createVideoClipMachine = ({
  id,
  idx,
  type,
  data,
}: VideoClipContext) =>
  createMachine<VideoClipContext, VideoClipMachineEvent>(
    {
      id: "videoClipMachine",
      tags: ["clip", type],
      context: {
        id,
        idx,
        type,
        data,
        prevData: data,
      },
      initial: "idle",
      states: {
        idle: {
          entry: ["announce"],
          on: {
            CHANGE: {
              actions: [
                assign({
                  data: (_, event) => event.value,
                }),
                "commit",
              ],
            },
            EDIT: {
              target: "editing",
              actions: ["setActive"],
            },
          },
        },
        editing: {
          entry: ["announce", assign({ prevData: (context) => context.data })],
          on: {
            CHANGE: {
              actions: [
                assign({
                  data: (_, event) => event.value,
                }),
                "commit",
              ],
            },
            DONE: {
              actions: ["announce", "commit", "done"],
              target: "idle",
            },
            CANCEL: {
              actions: ["announce", "cancel", "commit"],
              target: "idle",
              cond: (context) => !!context.prevData,
            },
          },
        },
      },
    },
    {
      actions: {
        announce: (context, event) => {
          // console.log("Video Clip machine - ", { context, event });
        },
        cancel: assign((context) => {
          return context.prevData ? { data: context.prevData } : {};
        }),
        commit: sendParent((context) => ({
          type: "CLIP.COMMIT",
          clip: context,
        })),
        done: sendParent((context) => ({
          type: "CLIP.DONE",
          clip: context,
        })),
        setActive: sendParent((context) => ({
          type: "CLIP.ACTIVE",
          clip: context,
        })),
      },
    }
  );
