import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

import { normalizeColor } from '../../utils/colors';

const StyledText = styled.span`
    text-shadow: 1px 2px 8px ${props => normalizeColor((props.shadowColor && props.shadowColor) || 'dark-1', props.theme)};
`;

const TextShadow = ({children, ...rest}) => {
    return (<StyledText {...rest}>
            {children}
    </StyledText>)
}


TextShadow.propTypes = {
    /* eslint-enable react/no-unused-prop-types */
    shadowColor: PropTypes.string
  };

  export default TextShadow;
