import React from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';

interface IncompleteCardViewProps { colorScheme:string, responses:number, prompts:number, onClick: () => void }
export const IncompleteCardView:React.FC<IncompleteCardViewProps> = ({colorScheme, responses, prompts, onClick }:IncompleteCardViewProps) => {
    return (<Box>
        <Heading as='h4' size='md' mb={3}>Continue telling your story</Heading>
        <Text mb={3}>You&apos;ve responded to {responses} out of {prompts} prompts in your story. Are you ready to add the rest of your responses?</Text>
        <Button colorScheme={colorScheme} onClick={onClick}>Continue Recording</Button>
    </Box>)
}

export default IncompleteCardView;