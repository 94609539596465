import React from 'react'
import { connect } from 'react-redux'
import Client from 'shopify-buy'
import { useToast } from "@chakra-ui/react"
import Loading from '../common/Loading'

const client = Client.buildClient({
    storefrontAccessToken: '8545c10543f49b6d0aa6d57e8292be2f',
    domain: 'quiltedstories.myshopify.com'
  });

const StateContext = React.createContext({ client: undefined, checkout: undefined, isCartOpen: false, products: [], discountCode: undefined });
const DispatchContext = React.createContext();

const shopifyReducer = (state, action) => {
    switch (action.type) {
        case "setProducts":
            return { ...state, products: action.payload }
        case "setCheckout":
            return { ...state, checkout: action.payload, isCartOpen: true }
        case "addDiscount":
            return { ...state, discountCode: action.payload.discountCode }
        default:
            return state;
    }
}

export const useShopifyState = () => {
    const context = React.useContext(StateContext)
    if (context === undefined) {
      throw new Error('useShopifyState must be used within a ShopifyProvider')
    }
    return context
}

export const useShopifyDispatch = () => {
    const context = React.useContext(DispatchContext)
    if (context === undefined) {
      throw new Error('useShopifyDispatch must be used within a ShopifyProvider')
    }
    return context
}

export const useShopify = () => {
    return [useShopifyState(), useShopifyDispatch()];
}

export const ShopifyProvider = ({children, auth, storyId, diyVideoId, ...props}) => {
    const [state, dispatch] = React.useReducer(shopifyReducer, { client, products: [] });
    const toast = useToast();

    const addVariantToCart = (variantId, quantity, customAttributes, checkoutId = state.checkout.id) => {
        const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10), customAttributes}]

        return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
            dispatch({
                type: "setCheckout",
                payload: res,
            });
            return res;
        });
    }

    const removeLineItemInCart = (lineItemId) => {
        const checkoutId = state.checkout.id

        return client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
            dispatch({
                type: "setCheckout",
                payload: res,
            });
            return res;
        });
    }

    const applyDiscount = (discountCode, checkoutId = state.checkout.id) => {
        return client.checkout.addDiscount(checkoutId, discountCode).then(res => {
            dispatch({
                type: "setCheckout",
                payload: res,
            });
            return res;
        });
    }

    const dispatchMiddleware = React.useCallback((action) => {
        console.log('ShopifyProvider.dispatchMiddleware', action);
        const {variantId, quantity, customAttributes, lineItemId} = action.payload || {};
        switch (action.type) {
            case "fetch":
                console.log('Fetch cart...')
                if(!state.isCartOpen && action.payload && action.payload.checkoutId)
                client.checkout.fetch(action.payload.checkoutId).then((res) => {
                    dispatch({
                      type: "setCheckout",
                      payload: res,
                    });
                });
                break;
            case "open":
                console.log('Open cart...')
                if(!state.isCartOpen && action.payload && action.payload.email && action.payload.customAttributes)
                client.checkout.create(action.payload)
                .then((res) => {
                    if(action.concierge) {
                        const concierge_variantId = 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDk3ODE3MjUxMDM2Ng==';
                        return addVariantToCart(concierge_variantId, 1, undefined, res.id);
                    }
                    return res;
                })
                .then((res) => {
                   dispatch({
                      type: "setCheckout",
                      payload: res,
                    });
                  });
                break;
            case "addToCart":
                addVariantToCart(variantId, quantity, customAttributes)
                break;
            case "removeLineItem": {
                removeLineItemInCart(lineItemId);
                break;
            }
            case "checkout":
                window.location.assign(state.checkout.webUrl);
                break;
            case "save_and_checkout":
                addVariantToCart(variantId, quantity, customAttributes).then(res => {
                    const {discountCode} = state;
                    if(discountCode)   {
                        console.log('Adding discount code', action.payload);
                        return applyDiscount(discountCode, state.checkout.id);
                    }
                    return res;
                })
                .then(res => {
                      console.log('Checking out...')
                      window.location.assign(res.webUrl);
                    })
                    .catch(error => {
                        console.log(error)
                        toast({
                            title: "Could not check out!",
                            description: "There was an error checking out. If the problem persists, please contact support.",
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          })
                    });
                break;
            default:
                dispatch(action);
                break;
        }
    }, [state])

    React.useEffect(() => {
        const customAttributes = [{key:'storyId', value: storyId }];
        diyVideoId && customAttributes.push({ key: 'diyVideoId', value: diyVideoId})
        dispatchMiddleware({
            type:"open",
            payload: { email: auth.email, customAttributes },
            concierge: !diyVideoId
        });

        // Fetch add-ons collection by ID, including its products, limit to 6
        const collectionId = 'Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI2NDAwNTQ1MTkzNA==';
        client.collection.fetchWithProducts(collectionId, {productsFirst: 6}).then((collection) => {
            dispatch({
                type: 'setProducts',
                payload: collection.products,
            });
        }).catch((err)=> {
            console.error(err);
        });
    }, [])

    return (<StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatchMiddleware}>
            {!state.isCartOpen && (<Loading />)}
            {state.isCartOpen && children}
        </DispatchContext.Provider>
      </StateContext.Provider>)
}

const mapStateToProps = (state) => {
    const data = {
      auth: state.firebase.auth
    };

    return data;
}

export default connect(mapStateToProps)(ShopifyProvider)