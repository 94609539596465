import React from 'react'
import PropTypes from 'prop-types'

const Running = ({start,max,speed,indicator}) => {
    
    const fill = (count) => {
      return Array(count).fill(indicator||'.').join('');
    }
    
    const [state,setState] = React.useState({count:start||-1,text:fill(start||0)})
    
    const calculate = React.useCallback((count) => {
        const _max = max||3;
        count = Math.min(count+1, _max);
        const text = Array(count).fill('.').join('')
        if(count === _max) count = 0;
        setState({count,text});
    }, [max]);


    React.useEffect(() => {
        const timer = setTimeout(() => {
          calculate(state.count);
        }, (speed||1) * 1000);
        return () => clearTimeout(timer);
      }, [speed, calculate, state]);

    return (state.text);
};





Running.propTypes = {
  start: PropTypes.number,
  max: PropTypes.number,
  speed: PropTypes.number,
  indicator: PropTypes.string
}

export default Running;