import React from "react";

import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useDisclosure,
  Collapse,
  Stack,
  Container,
  Image,
} from "@chakra-ui/react";
import { BabyShowerVideo } from "../common/QuiltedVideo";
import HowItWorks from "../common/HowItWorks";
import { useAnalytics } from "use-analytics";
import { useNavigate } from "react-router-dom";

const SeeMoreDetails = ({ track }: { track: any }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box
      bgColor="seaGreen.200"
      rounded="lg"
      p={["sm", "md", "lg"]}
      w="full"
      m="lg"
      maxW="xl"
    >
      <Heading size="h5">What is Quilted?</Heading>
      <Text my="4" lineHeight="short">
        Quilted enables you and your loved ones to tell your favorite stories
        through curated short videos and create a final movie to be gifted for
        any special occasion.
      </Text>
      <Button
        colorScheme="blackAlpha"
        variant="outline"
        size="sm"
        data-testid="more"
        onClick={() => {
          onToggle();
          !isOpen && track("response:user_viewedQuiltedInfo");
        }}
      >
        {isOpen ? <span>Close</span> : <span>Learn More</span>}
      </Button>
      <Collapse in={isOpen}>
        <Stack p="md" bgColor="powder" align="center" mt="lg" rounded="md">
          <Container textAlign="center">
            <Stack>
              <Text>
                Quilted enables you and your loved ones to tell your favorite
                stories through curated short videos and create a final movie to
                be gifted for any special occasion.
              </Text>
              <Text>
                After everyone adds their responses to this gift, the person who
                sent you this gift will handle creating the final video, filled
                with clips from you and additional friends and family.
              </Text>
            </Stack>
          </Container>
          <HowItWorks hideHeading />
          <Box w="80%">
            <BabyShowerVideo />
          </Box>
        </Stack>
      </Collapse>
    </Box>
  );
};

const StoryIntroView = ({
  story,
  onClick,
}: {
  story?: any;
  onClick: () => void;
}) => {
  const { track } = useAnalytics();
  const navigate = useNavigate();

  return (
    <Flex
      justify="center"
      my="lg"
      h="full"
      direction="column"
      p="lg"
      shadow="lg"
      bgColor="springWood.100"
      rounded="lg"
      align="center"
    >
      <Heading as="h2" size="h2" variant="center">
        {story?.title}
      </Heading>
      {story?.recipientPhoto && (
        <Box bgColor="springWood.900" my="4" p="4">
          <Image src={story?.recipientPhoto} objectFit="contain" />
        </Box>
      )}
      <Text align="center" lineHeight="short" mb="2" maxW="xl">
        {story?.welcomeMessage}
      </Text>
      <Text align="center" maxW="sm" lineHeight="short">
        <i>
          This Quilted story includes {story?.prompts.length} question
          {story?.prompts.length > 1 ? "s" : ""}, which you will be asked to
          answer via video.
        </i>
      </Text>
      {/* <Button mt="md" colorScheme="blackAlpha" variant="ghost" size="sm" onClick={onNoVidDialogOpen}>Prefer not to use video?</Button> */}
      <SeeMoreDetails track={track} />
      <Button
        colorScheme="airBlue"
        onClick={() => {
          track("response:user_startedResponding");
          navigate("respond");
          onClick();
        }}
        role="button"
        data-testid="continue"
      >
        Get Started
      </Button>
    </Flex>
  );
};

export default StoryIntroView;
