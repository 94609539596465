import {
  Button,
  Box,
  Heading,
  HStack,
  Text,
  Stack,
  Progress,
  useDisclosure,
  Container,
  UnorderedList,
  ListItem,
  Flex,
  useBreakpointValue,
  VStack,
  StackDivider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  IconButton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { createModel } from "xstate/lib/model";

import { useMachine } from "@xstate/react";

import Prompt from "./Prompt";
import VideoEditor from "./VideoEditor";

import { Story } from "../../types/StoryManagerState";
import {
  ClipsEntity,
  EditlySpec,
  VideoSubmission,
} from "../../types/DIYVideoBuilder";
import EmptyEditorState from "./EmptyEditorState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelpModal from "./HelpModal";
import ProcessingScreenContent from "./ProcessingScreen";

import { useFirestore } from "react-redux-firebase";
import buildEditlySpec from "../../utils/buildEditlySpec";
import { diyVideoBuilderMachine } from "../../machines/DIYVideoBuilder/diyVideoBuilderMachine";
import { useStoryContext } from "../story/StoryProvider";
// @ts-ignore
import { useAnalytics } from "use-analytics";
import ModalDialog, { ModalActions } from "../common/ModalDialog";
import VideoEditorMenu from "./VideoEditorMenu";

import { VerifiedPartner } from "../partners/types";

const PreviewGuardModal = (props: {
  isOpen: boolean;
  onContinue: () => void;
  onCancel: () => void;
}) => {
  const { isOpen, onContinue, onCancel } = props;

  return (
    <ModalDialog onClose={onCancel} isOpen={isOpen}>
      <Container>
        <Stack spacing="md">
          <Heading as="h2" size="h2" fontSize="lg" variant="center">
            Ready to preview?
          </Heading>
          <Text>
            If you feel good about your movie, hit the "Start Preview" button to
            start processing your movie. We'll email you when the preview is
            ready to review. Before continuing, there are a few things you
            should know:
          </Text>
          <Container>
            <UnorderedList>
              <ListItem>
                Depending on how many clips you've included, your preview may
                take up to an hour or two to process.
              </ListItem>
              <ListItem>
                While your movie is processing, you won't be able to make any
                edits.
              </ListItem>
              <ListItem>
                If you aren't happy with your preview, you can continue editing
                or start over from the beginning.
              </ListItem>
            </UnorderedList>
          </Container>
          <ModalActions
            primaryButton={{ onClick: onContinue, label: "Start preview" }}
            secondaryButton={{
              onClick: onCancel,
              label: "back to editing",
              variant: "outline",
            }}
          />
        </Stack>
      </Container>
    </ModalDialog>
  );
};

const ProjectEditor = (props: ProjectEditorProps) => {
  const { selectedProject, partners } = props;
  const [firstLoad, setFirstLoad] = useState(true);
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const firestore = useFirestore();

  const storyContext = useStoryContext();
  // @ts-ignore
  const {
    story,
    submissions,
  }: { story: Story; submissions: VideoSubmission[] } = storyContext;

  const [showPrompts, setShowPrompts] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const {
    isOpen: isHelpOpen,
    onOpen: onHelpOpen,
    onClose: onHelpClose,
  } = useDisclosure();

  const {
    isOpen: isPreviewGuardOpen,
    onOpen: onPreviewGuardOpen,
    onClose: onPreviewGuardClose,
  } = useDisclosure();

  const { track } = useAnalytics();

  // @ts-ignore
  const buildPrompts = () => {
    return story?.prompts?.map((prompt, index) => {
      return {
        ...prompt,
        // @ts-ignore
        id: prompt.id || prompt.idx,
        include: true,
        submissions: submissions
          .filter(
            // @ts-ignore
            (submission) => submission.promptIndex === prompt.idx
          )
          .map((submission) => {
            return {
              ...submission,
              include: true,
              type: "submission",
            };
          }),
      };
    });
  };

  const buildPromptsFromConfig = () => {
    return story?.prompts?.map((prompt, index) => {
      return {
        ...prompt,
        // @ts-ignore
        id: prompt.id || prompt.idx,
        include: true,
        submissions: submissions
          .filter(
            // @ts-ignore
            (submission) => submission.promptIndex === prompt.idx
          )
          .map((submission) => {
            const existingClips = selectedProject.processingConfig.clips.filter(
              (clip: ClipsEntity) => clip.submissionId === submission.id
            );
            return {
              ...submission,
              include: !!existingClips.length,
              cutFrom:
                (existingClips.length && existingClips[0].layers[0].cutFrom) ||
                undefined,
              cutTo:
                (existingClips.length && existingClips[0].layers[0].cutTo) ||
                undefined,
              type: "submission",
            };
          }),
      };
    });
  };

  const model = createModel({
    id: selectedProject === null ? null : selectedProject.id || undefined,
    storyId: story.id,
    createdAt: selectedProject?.createdAt || new Date(),
    updatedAt: new Date(),
    paid: selectedProject?.paid || false,
    title: selectedProject?.title || story.title,
    titleDuration: 5,
    prompts:
      selectedProject === null ? buildPrompts() : buildPromptsFromConfig(),
  });

  // @ts-ignore
  const fetchData = (context, event) =>
    new Promise((resolve, reject) => {
      resolve(model.initialContext);
    });

  const myStoryMachine = diyVideoBuilderMachine.withConfig({
    services: {
      fetchData,
    },
  });

  // @ts-ignore
  const [state, send] = useMachine(myStoryMachine, { devTools: true });
  const { prompts, activeClipMachine } = state.context;

  const checkForPartner = () => {
    debugger;
    let partner: VerifiedPartner | undefined;
    if (!partners || partners.length === 0) {
      return undefined;
    } else if (selectedProject?.partnerId) {
      partner = partners.find((partner) => {
        return partner.id === selectedProject?.partnerId;
      });
    } else {
      partner = partners.find(
        (partner) => partner.packageId === story.templateId
      );
    }
    if (partner) {
      return { partnerId: partner.id };
    } else {
      return undefined;
    }
  };

  const saveProject = async (finalCommit: boolean) => {
    setIsSaving(true);
    track(
      finalCommit
        ? "finalVideo | Requested DIY Video Preview"
        : "finalVideo | Saved DIY project"
    ),
      {
        projectId: state.context.id,
        storyId: state.context.storyId,
        storyTitle: state.context.title,
      };
    // @ts-ignore
    const editlySpec: EditlySpec = buildEditlySpec(state);
    const doc = {
      processingConfig: editlySpec,
      state: finalCommit ? "process" : "editing",
      createdAt: state.context.createdAt,
      updatedAt: state.context.updatedAt,
      ...checkForPartner(),
    };

    if (!state.context.id) {
      return await firestore
        .collection("stories")
        .doc(state.context.storyId)
        .collection("diyVideos")
        .add({ ...doc, createdAt: new Date() })
        .then((diyDoc) => {
          // console.log("new story", diyDoc.id);
          send({ type: "CHANGE", value: { id: diyDoc.id } });
          if (finalCommit) send({ type: "PROCESS" });
          setIsSaving(false);
        });
    } else {
      return await firestore
        .collection("stories")
        .doc(state.context.storyId)
        .collection("diyVideos")
        .doc(state.context.id)
        .set({ ...doc, updatedAt: new Date() }, { merge: false })
        .then(() => {
          if (finalCommit) send({ type: "PROCESS" });
          setIsSaving(false);
        });
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (state.value === "idle") {
      if (
        selectedProject?.state === "process" ||
        selectedProject?.state === "processing"
      ) {
        send({ type: "PROCESS" });
        // @ts-ignore
      } else send({ type: "FETCH", variables: { id: 1 } });
    }

    if (state.value === "ready") {
      setShowPrompts(true);
    } else setShowPrompts(false);
  }, [state.value, send]);

  useEffect(() => {
    console.log("DIY video state", state);
  }, [state]);

  const PromptListView = () => {
    return (
      <VStack
        m="1"
        divider={<StackDivider borderColor="gray.200" />}
        spacing={4}
        align="stretch"
      >
        {prompts &&
          prompts.map((prompt) => (
            <Prompt
              key={prompt.idx}
              prompt={prompt}
              totalPrompts={prompts.length}
              hasStartedEditing={!!activeClipMachine}
            />
          ))}
      </VStack>
    );
  };

  return (
    <>
      <PreviewGuardModal
        onCancel={onPreviewGuardClose}
        isOpen={isPreviewGuardOpen}
        onContinue={() => {
          saveProject(true);
          onPreviewGuardClose();
        }}
      />
      {isSmallScreen ? (
        <>
          {showPrompts && (
            <Flex dir="row" justify="space-between" align="center" my="sm">
              <Box flex={1}>
                <Heading as="h4" size="h5">
                  Your Prompts
                </Heading>
              </Box>
              <HStack mr="0">
                <IconButton
                  variant="outline"
                  aria-label="Save Project"
                  icon={<FontAwesomeIcon icon={"sd-card"} />}
                  onClick={() => saveProject(false)}
                  disabled={isSaving}
                />
                <Button
                  onClick={onPreviewGuardOpen}
                  disabled={isSaving}
                  rounded="md"
                  size="sm"
                  pl="20px"
                  pr="20px"
                >
                  Preview movie
                </Button>
              </HStack>
            </Flex>
          )}
          <Flex
            direction="column"
            bg="springWood.200"
            shadow="lg"
            basis="1"
            flex={1}
            alignSelf="stretch"
            css={{ transition: "all 2s ease" }}
            key="promptsColumn"
            overflow="scroll"
          >
            <PromptListView />
            <Drawer
              isOpen={firstLoad}
              onClose={() => setFirstLoad(false)}
              size="full"
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerBody>
                  <EmptyEditorState />
                </DrawerBody>
              </DrawerContent>
            </Drawer>
            <Drawer
              isOpen={state.value === "processing" || !!activeClipMachine}
              size="full"
              onClose={() => {
                console.log("Closing somehow");
              }}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerBody>
                  {state.value === "processing" && <ProcessingScreenContent />}
                  {activeClipMachine && (
                    <>
                      <VideoEditor
                        wrapperProps={{ maxH: "calc(100vh - 180px)" }}
                        key={activeClipMachine.id}
                        activeClipMachine={activeClipMachine}
                      />
                      <VideoEditorMenu activeClipMachine={activeClipMachine} />
                    </>
                  )}
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </Flex>
        </>
      ) : (
        <>
          <HelpModal onClose={onHelpClose} isOpen={isHelpOpen} />
          <Flex
            direction="column"
            alignSelf="stretch"
            flex={1}
            align="stretch"
            justify="start"
            width="100%"
            pt="md"
            pb="sm"
          >
            {showPrompts && (
              <Flex dir="row" justify="space-between" align="flex-end" mb="sm">
                <Box basis="450px" minW="350px" maxW="550px" flex={1}>
                  <Heading as="h4" size="h5">
                    Your Prompts
                  </Heading>
                </Box>
                <HStack mr="0">
                  <Button
                    variant="ghost"
                    onClick={() => {
                      track("diy_video:opened_help_modal");
                      onHelpOpen();
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon
                      icon={["far", "question-circle"]}
                      size="lg"
                    />
                  </Button>
                  <Button
                    variant="outline"
                    rounded="md"
                    onClick={() => saveProject(false)}
                    disabled={isSaving}
                  >
                    Save project
                  </Button>
                  <Button
                    onClick={onPreviewGuardOpen}
                    disabled={isSaving}
                    rounded="md"
                  >
                    Preview movie
                  </Button>
                </HStack>
              </Flex>
            )}
            <HStack flex={1} spacing="24px" align="flex-start">
              {showPrompts && (
                <Flex
                  direction="column"
                  bg="springWood.200"
                  shadow="lg"
                  basis="1/3"
                  minW="250px"
                  maxW={["50vw", "350px", "375px"]}
                  flex={1}
                  alignSelf="stretch"
                  css={{ transition: "all 2s ease" }}
                  key="promptsColumn"
                  maxH="calc(100vh - 180px)"
                  overflow="scroll"
                >
                  <PromptListView />
                </Flex>
              )}
              <Flex
                direction="column"
                bg="springWood.200"
                shadow="lg"
                flex={2}
                minW="350px"
                alignSelf="stretch"
                overflow="scroll"
                maxH={
                  showPrompts ? "calc(100vh - 180px)" : "calc(100vh - 80px)"
                }
              >
                {state.value === "processing" ? (
                  <ProcessingScreenContent />
                ) : activeClipMachine ? (
                  <VideoEditor
                    wrapperProps={{ maxH: "calc(100vh - 180px)" }}
                    key={activeClipMachine.id}
                    activeClipMachine={activeClipMachine}
                  />
                ) : (
                  <Flex
                    justify={["center", "center", "start", "start", "center"]}
                    align={["center", "center", "start", "start", "center"]}
                    h={["unset", "unset", "unset", "full"]}
                    pt="md"
                  >
                    {state.value === "ready" ? (
                      <EmptyEditorState />
                    ) : (
                      <Stack align="center" justify="center">
                        <Box w="300px">
                          <Progress size="xs" isIndeterminate />
                        </Box>
                        <Text fontWeight="light">Loading your videos</Text>
                      </Stack>
                    )}
                  </Flex>
                )}
              </Flex>
            </HStack>
          </Flex>
        </>
      )}
    </>
  );
};

export default ProjectEditor;

interface ProjectEditorProps {
  selectedProject: any;
  partners?: VerifiedPartner[];
}
