import React, { Component } from "react";
import AudioVisualiser from "./AudioVisualizer";
import audioContext from "./AudioContext";
import AnimationFrame from "./AnimationFrame";

class AudioAnalyser extends Component {
  constructor(props) {
    super(props);
    this.state = { audioData: new Uint8Array(0), frameCount: 1 };
    this.tick = this.tick.bind(this);
    this.dataArray = new Uint8Array(0);
    this.rafId = 0;
    this.binCount = 0;
    this.frameCount = 1;
    this.decibels = props.decibels || {min: -100, max: -30};
  }

  componentDidMount() {
    this.audioContext = new audioContext();
    this.analyser = this.audioContext.createAnalyser();
    this.analyser.fftsize = 32768;    
    this.analyser.minDecibels = this.decibels.min;
    this.analyser.maxDecibels = this.decibels.max;
    this.binCount = this.analyser.frequencyBinCount;
    this.dataArray = new Uint8Array(this.binCount);
    this.source = this.audioContext.createMediaStreamSource(this.props.audio);
    this.source.connect(this.analyser);
    this.rafId = AnimationFrame.request(this.tick);
  }

  tick() {
    this.analyser.getByteFrequencyData(this.dataArray);
    this.setState({ audioData: this.dataArray, frameCount: this.frameCount });
    this.rafId = AnimationFrame.request(this.tick);
    this.frameCount++;
  }

  componentWillUnmount() {
    AnimationFrame.cancel(this.rafId);
    this.analyser.disconnect();
    this.source.disconnect();
  }

  render() {
    return <AudioVisualiser audioData={this.state.audioData} frameCount={this.state.frameCount} />;
  }
}

export default AudioAnalyser;
