import React from "react";
import { Route, Routes } from "react-router-dom";
import StoryIntro from "../../../pages/StoryView/components/StoryIntro";

import StoryView from "../../../pages/StoryView/StoryView";
import FinalRoute from "./FinalRoute";
import RespondRoute from "./RespondRoute";

const StoryRoutes = () => {
  return (
    <Routes>
      <Route element={<StoryView />}>
        <Route index element={<StoryIntro />} />
        <Route path="respond/*" element={<RespondRoute/>} />
        <Route path="final/*" element={<FinalRoute/>} />
      </Route>
    </Routes>
  );
};

export default StoryRoutes;
