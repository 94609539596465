import { assign, createMachine, DoneInvokeEvent, send } from "xstate";
import { Response } from "../../components/story/StoryProvider";

import { assertEventType } from "../helpers";

export interface Reminders {
  weekly: boolean;
  midway: boolean;
  dayBefore: boolean;
  weekBefore: boolean;
  dueDate: boolean;
  dayAfter: boolean;
}

interface deliveryDateModel {
  deliveryDate: Date;
}
interface dueDateModel {
  dueDate: Date;
}
interface RemindersModel {
  reminders: Reminders;
}

type storytellerBase = {
  name: string;
  responses?: Response[];
  contactPreference?: "SMS" | "EMAIL";
  email?: string;
  phone?: string;
  [key: string]: any;
};

type storytellerSMS = storytellerBase & {
  phone: string;
  contactPreference: "SMS";
};

type storytellerEMAIL = storytellerBase & {
  contactPreference: "EMAIL";
  email: string;
};

export type Storyteller = storytellerBase | storytellerSMS | storytellerEMAIL;
interface StorytellersModel {
  storytellers: Storyteller[];
}
interface InvitesModel {
  invites?: Storyteller[];
}

interface InviteMethodModel {
  inviteMethod?: "invites" | "link";
}

interface InvitationsModel
  extends deliveryDateModel,
    dueDateModel,
    RemindersModel,
    StorytellersModel,
    InvitesModel,
    InviteMethodModel {
  [key: string]: any;
}

export interface InvitationsContext {
  data?: Partial<InvitationsModel> | null;
  error?: null | string;
}

type SetDeliveryDateEvent = {
  type: "SET_DELIVERY_DATE";
  deliveryDate: Date;
};

type SetDueDateEvent = {
  type: "SET_DUE_DATE";
  dueDate: Date;
};

type SetStorytellersEvent = {
  type: "SET_STORYTELLERS";
  storytellers: any[];
};

type SetRemindersEvent = {
  type: "SET_REMINDERS";
  reminders: RemindersModel;
};

export type InvitationsEvents =
  | {
      type: "BEGIN";
    }
  | {
      type: "READY";
    }
  | {
      type: "RETRY";
    }
  | {
      type: "CANCEL";
    }
  | {
      type: "ADD_STORYTELLERS";
    }
  | {
      type: "USE_LINK";
    }
  | {
      type: "FINISHED";
    }
  | { type: "error.platform"; data: any }
  | SetDeliveryDateEvent
  | SetDueDateEvent
  | SetStorytellersEvent
  | SetRemindersEvent
  | DoneInvokeEvent<{ data: any }>;

type InvitationsServices = {
  onSaveDueDate: {
    data: unknown;
  };
  onSaveStorytellers: {
    data: unknown;
  };
  onSaveInviteMethod: {
    data: unknown;
  };
  onReminders: {
    data: unknown;
  };
};

type RepositoryState =
  | { value: "init"; context: Partial<InvitationsContext> }
  | { value: "start"; context: Partial<InvitationsContext> }
  | { value: { start: "idle" }; context: Partial<InvitationsContext> }
  | { value: { start: "delivery" }; context: Partial<InvitationsContext> }
  | {
      value: { start: "dueDate" };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
        };
      };
    }
  | {
      value: { start: { dueDate: "idle" } };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
        };
      };
    }
  | {
      value: { start: { dueDate: "saving" } };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
          dueDate: Date;
        };
      };
    }
  | {
      value: { start: { dueDate: "error" } };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
          dueDate: Date;
        };
        error: string;
      };
    }
  | {
      value: { start: "selectMethod" };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
          dueDate: Date;
        };
      };
    }
  | {
      value: { start: "addStorytellers" };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          reminders?: RemindersModel;
        };
      };
    }
  | {
      value: { start: { addStorytellers: "idle" } };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
          dueDate: Date;
        };
      };
    }
  | {
      value: { start: { addStorytellers: "saving" } };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
          dueDate: Date;
        } & InvitesModel;
      };
    }
  | {
      value: { start: { addStorytellers: "error" } };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
          dueDate: Date;
        } & InvitesModel;
        error: string;
      };
    }
  | {
      value: { start: "useLink" };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
          dueDate: Date;
        };
      };
    }
  | {
      value: { start: { useLink: "idle" } };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
          dueDate: Date;
        };
      };
    }
  | {
      value: { start: { useLink: "saving" } };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
          dueDate: Date;
          inviteMethod: "link";
        };
      };
    }
  | {
      value: { start: { useLink: "error" } };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
          dueDate: Date;
        };
        error: string;
      };
    }
  | {
      value: { start: "setReminders" };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
          dueDate: Date;
          inviteMethod: "link" | "invites";
          reminders?: any[];
        };
      };
    }
  | {
      value: { start: { setReminders: "idle" } };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
          dueDate: Date;
          inviteMethod: "link" | "invites";
          reminders?: any[];
        };
      };
    }
  | {
      value: { start: { setReminders: "saving" } };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
          dueDate: Date;
          inviteMethod: "link" | "invites";
          reminders: RemindersModel;
        };
      };
    }
  | {
      value: { start: { setReminders: "error" } };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
          dueDate: Date;
          inviteMethod: "link" | "invites";
          reminders: RemindersModel;
        };
        error: string;
      };
    }
  | { value: { start: "done" }; context: InvitationsContext }
  | { value: "completed"; context: InvitationsContext }
  | {
      value: { completed: "idle" };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
          dueDate: Date;
          inviteMethod: "link" | "invites";
          reminders: RemindersModel;
        };
      };
    }
  | {
      value: { completed: "addStorytellers" };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
          dueDate: Date;
        } & StorytellersModel;
      };
    }
  | {
      value: { completed: "saving" };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
          dueDate: Date;
          inviteMethod: "link" | "invites";
          reminders: RemindersModel;
        } & InvitesModel;
      };
    }
  | {
      value: { completed: "error" };
      context: Partial<InvitationsContext> & {
        data: Partial<InvitationsModel> & {
          deliveryDate: Date;
          dueDate: Date;
          inviteMethod: "link" | "invites";
          reminders: RemindersModel;
        } & InvitesModel;
        error: string;
      };
    };

const machineConfig = {
  schema: {
    context: {} as InvitationsContext,
    events: {} as InvitationsEvents,
    services: {} as InvitationsServices,
  },
  id: "Invitations",
  initial: "init",
  states: {
    init: {
      always: [
        {
          cond: "hasCompleted",
          target: "completed",
        },
        {
          target: "start",
        },
      ],
    },
    start: {
      initial: "idle",
      states: {
        idle: {
          on: {
            BEGIN: [
              {
                cond: "hasDueDateAndStorytellers",
                target: "setReminders",
              },
              {
                cond: "hasDueDate",
                target: "selectMethod",
              },
              {
                target: "delivery",
              },
            ],
          },
        },
        delivery: {
          on: {
            SET_DELIVERY_DATE: {
              actions: "setDeliveryDate",
              target: "dueDate",
            },
          },
        },
        dueDate: {
          initial: "idle",
          states: {
            idle: {
              on: {
                SET_DUE_DATE: {
                  actions: "setDueDate",
                  target: "saving",
                },
              },
            },
            saving: {
              invoke: {
                id: "saveDueDate",
                src: "onSaveDueDate",
                onDone: {
                  actions: ["saved"],
                },
                onError: {
                  actions: "setError",
                  target: "error",
                },
              },
              on: {
                SAVED: {
                  target: "#Invitations.start.selectMethod",
                },
              },
            },
            error: {
              on: {
                RETRY: {
                  actions: "setError",
                  target: "idle",
                },
              },
            },
          },
        },
        selectMethod: {
          on: {
            ADD_STORYTELLERS: {
              target: "addStorytellers",
              actions: "track",
            },
            USE_LINK: {
              target: "useLink",
              actions: "track",
            },
          },
        },
        addStorytellers: {
          initial: "idle",
          states: {
            idle: {
              on: {
                SET_STORYTELLERS: {
                  actions: "setStorytellers",
                  target: "saving",
                },
              },
            },
            saving: {
              invoke: {
                id: "saveStorytellers",
                src: "onSaveStorytellers",
                onDone: {
                  actions: ["saved", "clearInvites", "track"],
                },
                onError: {
                  actions: "setError",
                  target: "error",
                },
              },
              on: {
                SAVED: {
                  target: "#Invitations.start.setReminders",
                },
              },
            },
            error: {
              on: {
                RETRY: {
                  actions: "setError",
                  target: "idle",
                },
              },
            },
          },
        },
        useLink: {
          initial: "idle",
          states: {
            idle: {
              on: {
                DONE: {
                  actions: "setInviteMethodLink",
                  target: "saving",
                },
              },
            },
            saving: {
              invoke: {
                id: "saveInviteMethod",
                src: "onSaveInviteMethod",
                onDone: {
                  actions: ["saved", "track"],
                },
                onError: {
                  actions: "setError",
                  target: "error",
                },
              },
              on: {
                SAVED: {
                  target: "#Invitations.start.setReminders",
                },
              },
            },
            error: {
              on: {
                RETRY: {
                  actions: "setError",
                  target: "idle",
                },
              },
            },
          },
        },
        setReminders: {
          initial: "idle",
          states: {
            idle: {
              on: {
                SET_REMINDERS: {
                  actions: ["setReminders", "track"],
                  target: "saving",
                },
              },
            },
            saving: {
              invoke: {
                id: "saveReminders",
                src: "onSaveReminders",
                onDone: {
                  actions: ["saved"],
                },
                onError: {
                  actions: "setError",
                  target: "error",
                },
              },
              on: {
                SAVED: {
                  target: "#Invitations.start.done",
                },
              },
            },
            error: {
              on: {
                RETRY: {
                  actions: "setError",
                  target: "idle",
                },
              },
            },
          },
        },
        done: {
          on: {
            FINISHED: {
              target: "#Invitations.completed",
            },
          },
        },
      },
    },
    idle: {
      on: {
        READY: {
          target: "init",
        },
      },
    },
    completed: {
      initial: "idle",
      states: {
        idle: {
          on: {
            ADD_MORE_STORYTELLERS: {
              target: "addStorytellers",
            },
          },
        },
        addStorytellers: {
          on: {
            SET_STORYTELLERS: {
              actions: "setStorytellers",
              target: "saving",
            },
          },
        },
        saving: {
          invoke: {
            id: "saveMoreStorytellers",
            src: "onSaveStorytellers",
            onDone: {
              actions: ["saved", "clearInvites"],
            },
            onError: {
              actions: "setError",
              target: "error",
            },
          },
          on: {
            SAVED: {
              target: "idle",
            },
          },
        },
        error: {
          on: {
            RETRY: {
              actions: "setError",
              target: "idle",
            },
          },
        },
      },
    },
  },
  on: {
    CANCEL: {
      target: "#Invitations.start.selectMethod",
    },
  },
};

const invitationsMachine = createMachine<
  InvitationsContext,
  InvitationsEvents,
  RepositoryState,
  InvitationsServices
>(machineConfig, {
  actions: {
    track: (context, event) => {
      console.log("track", event);
    },
    saved: send("SAVED", { delay: 1000 }),
    setError: assign<InvitationsContext, InvitationsEvents>({
      error: (context, event) => {
        try {
          assertEventType(event, "error.platform");
          return event.data;
        } catch (error) {
          return null;
        }
      },
    }),
    setDeliveryDate: assign<InvitationsContext, InvitationsEvents>({
      data: (context, event) => {
        assertEventType(event, "SET_DELIVERY_DATE");
        return { ...context.data, deliveryDate: event.deliveryDate };
      },
    }),
    setDueDate: assign<InvitationsContext, InvitationsEvents>({
      data: (context, event) => {
        assertEventType(event, "SET_DUE_DATE");
        return { ...context.data, dueDate: event.dueDate };
      },
    }),
    setStorytellers: assign<InvitationsContext, InvitationsEvents>({
      data: (context, event) => {
        assertEventType(event, "SET_STORYTELLERS");
        console.log("setStorytellers", event);
        return {
          ...context.data,
          invites: event.storytellers,
          inviteMethod: "invites",
        };
      },
    }),
    setReminders: assign<InvitationsContext, InvitationsEvents>({
      data: (context, event) => {
        assertEventType(event, "SET_REMINDERS");
        return { ...context.data, reminders: event.reminders };
      },
    }),
    clearInvites: assign<InvitationsContext, InvitationsEvents>({
      data: (context, event) => {
        return { ...context.data, invites: undefined };
      },
    }),
    setInviteMethodLink: assign<InvitationsContext, InvitationsEvents>({
      data: (context, event) => {
        return { ...context.data, inviteMethod: "link" };
      },
    }),
  },
  guards: {
    hasCompleted: (context: InvitationsContext) => {
      const check = !!(
        context.data &&
        context.data.dueDate &&
        (context.data.inviteMethod || context.data.storytellers) &&
        context.data.reminders
      );
      console.log("Has completed?", { check, context });
      return check;
    },
    hasDueDateAndStorytellers: (context: InvitationsContext) => {
      const check = !!(
        context.data &&
        context.data.dueDate &&
        (context.data.inviteMethod || context.data.storytellers)
      );
      return check;
    },
    hasDueDate: (context: InvitationsContext) => {
      const check = !!(context.data && context.data.dueDate);
      console.log("Has due date?", { check, context });
      return check;
    },
  },
  services: {
    onSaveDueDate: async (context: InvitationsContext, event) => {
      console.log("onSaveDueDate not implemented!");
      throw new Error("Not Implemented.");
    },
    onSaveStorytellers: async (context: InvitationsContext, event) => {
      console.log("onSaveStorytellers not implemented!");
      throw new Error("Not Implemented.");
    },
    onSaveReminders: async (context: InvitationsContext, event) => {
      console.log("onSaveReminders not implemented!");
      throw new Error("Not Implemented.");
    },
    onSaveInviteMethod: async (context: InvitationsContext, event) => {
      console.log("onSaveInviteMethod not implemented!");
      throw new Error("Not Implemented.");
    },
  },
});

export default invitationsMachine;
