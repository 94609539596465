import React from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useMachine } from "@xstate/react";
import firebase from "firebase";
import { useFirestore } from "react-redux-firebase";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import debounce from "lodash.debounce";
import useAuth from "../../../hooks/useAuth";
import Products from "../../shopify/Products";

import machine, {
  ConciergePurchaseContext,
  ConciergePurchaseEvents,
  ConciergeSettings,
} from "../../../machines/Purchase/conciergePurchaseMachine";
import LoadingPage from "../../../pages/LoadingPage";
import UploadRecipientPhoto from "../UploadRecipientPhoto";
import ManageStoryAssets from "../ManageStoryAssets";
import { Asset } from "../../../machines/manageStoryAssetsMachine";
import SubmissionCustomizer from "./SubmissionCustomizer";
import { useStoryContext } from "../StoryProvider";
import {
  useShopifyDispatch,
  useShopifyState,
} from "../../shopify/ShopifyProvider";

import { hydrateDates } from "../../../utils/dates";
import config from "../../../config/config";
export type PurchaseConciergeParams = {
  storyId: string;
  recipientPhoto?: string;
  assets?: Asset[];
  prompts?: any[];
} & ConciergeSettings;

export type ConciergePurchaseDataModel = {
  id: string;
  recipientPhoto?: string;
  prompts: any[];
  assets?: Asset[];
  purchase: {
    state: "customize" | "addContent" | "checkout";
    cartId?: string;
    [key: string]: any;
  };
} & ConciergeSettings;

export const ConciergePurchaseConverter: firebase.firestore.FirestoreDataConverter<ConciergePurchaseDataModel> =
  {
    toFirestore(data: ConciergePurchaseDataModel) {
      const updates = JSON.parse(
        JSON.stringify({
          assetNotes: data.assetNotes,
          videoMessage: data.videoMessage,
          videoMessagePosition: data.videoMessagePosition,
          positionMyVideos: data.positionMyVideos,
          recipientPhoto: data.recipientPhoto,
          conciergeNotes: data.conciergeNotes,
          purchase: {
            startedAt: firebase.firestore.Timestamp.now(),
            state: data.purchase.state,
          },
          updatedAt: firebase.firestore.Timestamp.now(),
        })
      );
      hydrateDates(updates);
      return updates;
    },
    fromFirestore(snapshot) {
      const data = snapshot.data() as ConciergePurchaseDataModel;
      if (!!data.purchase) data.purchase.storyId = snapshot.id;
      return data;
    },
  };

const FinalVideoRequestConverter: firebase.firestore.FirestoreDataConverter<any> =
  {
    toFirestore(data: any): firebase.firestore.DocumentData {
      const {
        recipientPhoto,
        videoMessage,
        videoMessagePosition,
        positionMyVideos,
        assetNotes,
        conciergeNotes,
        checkoutId,
        email,
        name,
        recipientEmail,
        shareWith,
        state,
        uid,
      } = data;
      const finalVideoRequest = {
        ...JSON.parse(
          JSON.stringify({
            recipientPhoto,
            videoMessage,
            videoMessagePosition,
            positionMyVideos,
            assetNotes,
            conciergeNotes,
            checkoutId,
            email,
            name,
            recipientEmail,
            shareWith,
            state,
            uid,
          })
        ),
        updatedAt: firebase.firestore.Timestamp.now().toDate(),
      };
      hydrateDates(finalVideoRequest);
      return finalVideoRequest;
    },
    fromFirestore(snapshot) {
      const data = snapshot.data();
      hydrateDates(data);
      return data;
    },
  };

const PurchaceConciergeDesc = () => (
  <Box my={6} mx={"auto"} w={["full", "container.md"]}>
    <Text textAlign={["start", "center"]} fontFamily="body">
      Before we start making your final movie, we need a few details. You can
      customize your movie here, or even add additional photos and videos for us
      to include. All customizations are optional.
    </Text>
  </Box>
);

type onChangeCallback = {
  onChange: (
    changes: Partial<ConciergePurchaseDataModel>
  ) => Promise<void> | void;
};

const PurchaseConciergeCustomize = ({
  storyId,
  recipientPhoto,
  videoMessage,
  videoMessagePosition,
  positionMyVideos,
  prompts,
  onChange,
}: PurchaseConciergeParams & onChangeCallback) => {
  const [photo, setPhoto] = React.useState(recipientPhoto);
  const [message, setMessage] =
    React.useState<string | undefined>(videoMessage);
  const [messagePosition, setMessagePosition] =
    React.useState<"beginning" | "end" | undefined>(videoMessagePosition);
  const [myVideoPosition, setMyVideoPosition] =
    React.useState<"first" | "last" | "no preference" | undefined>(
      positionMyVideos
    );

  const callback = debounce(
    (
      photo?: string,
      message?: string,
      messagePosition?: "beginning" | "end",
      myVideoPosition?: "first" | "last" | "no preference"
    ) => {
      onChange &&
        onChange({
          recipientPhoto: photo,
          videoMessage: message,
          videoMessagePosition: messagePosition,
          positionMyVideos: myVideoPosition,
        });
    },
    500
  );
  const handleChanges = React.useCallback(callback, []);

  React.useEffect(() => {
    handleChanges(photo, message, messagePosition, myVideoPosition);
  }, [photo, message, messagePosition, myVideoPosition]);

  return (
    <Stack gap={6}>
      <PurchaceConciergeDesc />
      <Stack>
        <Box>
          <FormLabel variant="headingLabel">
            Add a <strong>photo</strong> of the gift receiver{" "}
            <Text as="i" fontSize="xs">
              (optional)
            </Text>
          </FormLabel>
          <Text mt={0} mb={3} textAlign="start" fontFamily="body">
            This photo will be shown towards the beginning of your final movie.
          </Text>
        </Box>
        <UploadRecipientPhoto
          storyId={storyId}
          recipientPhoto={photo}
          onUpload={(path) => {
            setPhoto(path);
          }}
        />
      </Stack>
      <Stack>
        <FormLabel variant="headingLabel">
          Where would you like <strong>your videos</strong> to be included in
          the final movie?
        </FormLabel>
        <RadioGroup
          name="myVideoPosition"
          onChange={(position) => {
            if (
              position === "first" ||
              position === "last" ||
              position === "no preference"
            )
              setMyVideoPosition(position);
          }}
          defaultValue="noPreference"
          value={myVideoPosition}
          textAlign="start"
        >
          <Stack spacing={2} direction="column">
            <Radio colorScheme="gray" value="no preference">
              It doesn&apos;t matter where my videos are shown
            </Radio>
            <Radio colorScheme="seaGreen" value="first">
              Show my videos <strong>first</strong>
            </Radio>
            <Radio colorScheme="seaGreen" value="last">
              Show my videos <strong>last</strong>
            </Radio>
          </Stack>
        </RadioGroup>
      </Stack>
      <Stack>
        <FormLabel variant="headingLabel">
          <strong>Add a message</strong> to be shown in your video{" "}
          <Text as="i" fontSize="xs">
            (optional)
          </Text>
        </FormLabel>
        <Textarea
          placeholder="Add a message to your video (optional)"
          value={message}
          onChange={(event: any) => {
            event.target && setMessage(event.target.value);
          }}
        />
        <RadioGroup
          name="myMessagePosition"
          isDisabled={!message}
          onChange={(position) => {
            if (position === "beginning" || position === "end")
              setMessagePosition(position);
          }}
          defaultValue="beginning"
          value={messagePosition}
          textAlign="start"
        >
          <Stack spacing={2} direction="column">
            <Radio colorScheme="seaGreen" value="beginning">
              Show this message at the <strong>beginning</strong> of my video
            </Radio>
            <Radio colorScheme="seaGreen" value="end">
              Show this message at the <strong>end</strong> of the video
            </Radio>
          </Stack>
        </RadioGroup>
      </Stack>
      {prompts && (
        <Stack>
          <FormLabel variant="headingLabel">
            <strong>Exclude</strong> videos from your final movie{" "}
            <Text as="i" fontSize="xs">
              (optional)
            </Text>
          </FormLabel>
          <Text mt={0} mb={3} textAlign="start" fontFamily="body">
            If you&apos;d like, you can exclude videos or individuals from your
            final movie.
          </Text>
          <Box>
            <SubmissionCustomizer storyId={storyId} prompts={prompts} />
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

const PurchaseConciergeAddContent = ({
  storyId,
  assets,
  assetNotes,
  onChange,
}: PurchaseConciergeParams & onChangeCallback) => {
  const [message, setMessage] = React.useState<string | undefined>(assetNotes);
  const callback = debounce((message?: string) => {
    onChange &&
      onChange({
        assetNotes: message,
      });
  }, 500);
  const handleChanges = React.useCallback(callback, []);

  React.useEffect(() => {
    handleChanges(message);
  }, [message]);
  return (
    <Stack gap={6}>
      <PurchaceConciergeDesc />
      <Stack>
        <Box>
          <FormLabel variant="headingLabel">
            Add additional photos and videos
          </FormLabel>
          <Text mt={0} mb={3} textAlign="start" fontFamily="body">
            If you have certain photos or videos that would make your final
            movie extra special, you can upload them here to be sent to our
            concierge. (limit 10 files)
          </Text>
          <Text fontWeight="bold" textAlign="start" fontFamily="body">
            Please note that we can&apos;t use any videos that include
            copyrighted music.
          </Text>
        </Box>
        <ManageStoryAssets storyId={storyId} assets={assets || []} />
      </Stack>
      <Stack>
        <FormLabel variant="headingLabel">
          <strong>Add some notes</strong> about these files
        </FormLabel>
        <Text mt={0} mb={3} textAlign="start" fontFamily="body">
          Tell us where these files should appear in the video and anything else
          we should know about them. We will try to accomodate all reasonable
          requests, but we&apos;ll reach out if we have questions.
        </Text>
        <Textarea
          placeholder="Add your notes here..."
          value={message}
          onChange={(event: any) => {
            event.target && setMessage(event.target.value);
          }}
        />
      </Stack>
    </Stack>
  );
};

const PurchaseExtras = ({
  conciergeNotes,
  onChange,
}: PurchaseConciergeParams & onChangeCallback) => {
  const [notes, setNotes] = React.useState<string | undefined>(conciergeNotes);
  const callback = debounce((notes?: string) => {
    onChange &&
      onChange({
        conciergeNotes: notes,
      });
  }, 500);
  const handleChanges = React.useCallback(callback, []);

  React.useEffect(() => {
    handleChanges(notes);
  }, [notes]);

  return (
    <Stack gap={6}>
      <PurchaceConciergeDesc />
      <Stack>
        <FormLabel variant="headingLabel">
          Add a <strong>note</strong> for your concierge{" "}
          <Text as="i" fontSize="xs">
            (optional)
          </Text>
        </FormLabel>
        <Text mt={0} mb={3} textAlign="start" fontFamily="body">
          If there is anything else you want your concierge to know, feel free
          to share it here. You can also let us know if there is a specific date
          you need your final movie by. We can&apos;t honor all requests, but
          we&apos;ll do our best!
        </Text>
        <Textarea
          placeholder="Add a note for your concierge (optional)"
          value={notes}
          onChange={(event: any) => {
            event.target && setNotes(event.target.value);
          }}
        />
      </Stack>
      {config.shopify.showPhysicalProduct && (
        <>
          <Divider />
          <Stack>
            <Box>
              <FormLabel variant="headingLabel">
                Turn Your Quilted Movie Into a <strong>Keepsake</strong>
              </FormLabel>
              <Text mt={0} mb={3} textAlign="start" fontFamily="body">
                Before checking out, you can also add a physical copy of your
                movie to your order. These are perfect for giving away as a
                meaningful gift!
              </Text>
              <Products />
            </Box>
          </Stack>
        </>
      )}
    </Stack>
  );
};

const PurchaseConcierge = ({ storyId }: PurchaseConciergeParams) => {
  const { nextStep, prevStep, activeStep, setStep } = useSteps({
    initialStep: 0,
  });

  const firestore = useFirestore();
  const { currentUser } = useAuth();
  const dispatch = useShopifyDispatch();
  const storyState = useStoryContext();
  const { story, submissions } = storyState as unknown as {
    story: ConciergePurchaseDataModel;
    submissions: any[];
  };

  const [data, setData] =
    React.useState<Partial<ConciergePurchaseDataModel>>(story);
  const [submitted, setSubmitted] = React.useState(false);
  const [running, setRunning] = React.useState(false);

  const stepVariant = useBreakpointValue({ base: "mobile", md: "" });

  const saveFinalVideoRequest = () => {
    const storyId = story.id;
    const finalVideoRequest = {
      ...JSON.parse(JSON.stringify(data)),
      state: "checkout",
      email: currentUser?.email || null,
      uid: currentUser?.uid || null,
      name: currentUser?.displayName || null,
    };
    return firestore
      .collection("finalVideoRequests")
      .doc(storyId)
      .withConverter(FinalVideoRequestConverter)
      .set(finalVideoRequest);
  };

  const save = async () => {
    await saveData(data);
    await saveFinalVideoRequest();
  };

  const saveData = async (changes: Partial<ConciergePurchaseDataModel>) => {
    console.log("Save data", changes);
    const storyData = {
      recipientPhoto: state?.context?.data?.recipientPhoto,
      ...data,
      ...changes,
      purchase: {
        ...changes.purchase,
        state: state.value.toString() as unknown as
          | "customize"
          | "addContent"
          | "checkout",
      },
    };
    delete storyData.assets;
    setData(storyData);
    try {
      return await firestore
        .collection("stories")
        .doc(storyId)
        .withConverter(ConciergePurchaseConverter)
        .set(JSON.parse(JSON.stringify(storyData)), { merge: true })
        .catch(console.log);
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = () => {
    setRunning(true);
    save()
      .then(() => {
        const variantId = storyState.story.template.variantId; // match to shopify product variant Id's
        const payload = submitted
          ? {}
          : { variantId, quantity: 1, customAttributes: [] };
        const action = submitted ? "checkout" : "save_and_checkout";
        dispatch({
          type: action,
          payload,
        });
      })
      .finally(() => {
        setSubmitted(true);
        setRunning(false);
      });
  };

  const myMachine = machine.withConfig({
    services: {
      fetchData: async () => {
        try {
          if (!story) throw new Error("Story not found.");
          return story;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
    },
    actions: {
      track: (
        context: ConciergePurchaseContext,
        event: ConciergePurchaseEvents
      ) => {
        /**
               const tracker = trackEvents[event.type];
                if (tracker) {
                    const params = tracker.value ? tracker.value(event) : {};
                    track(tracker.key, params);
                }
            */
      },
    },
  });

  const [state, send] = useMachine(myMachine);
  const steps = [
    {
      label: "Customize",
      state: "customize",
      component: (
        <PurchaseConciergeCustomize
          storyId={storyId}
          recipientPhoto={state?.context?.data?.recipientPhoto}
          videoMessage={state?.context?.data?.videoMessage}
          videoMessagePosition={state?.context?.data?.videoMessagePosition}
          positionMyVideos={state?.context?.data?.positionMyVideos}
          prompts={state?.context?.data?.prompts}
          onChange={saveData}
        />
      ),
    },
    {
      label: "Add Additional Content",
      state: "addContent",
      component: (
        <PurchaseConciergeAddContent
          storyId={storyId}
          assets={state?.context?.data?.assets || []}
          assetNotes={state?.context?.data?.assetNotes}
          onChange={saveData}
        />
      ),
    },
    {
      label: "Add A Note",
      state: "extras",
      component: (
        <PurchaseExtras
          storyId={storyId}
          conciergeNotes={state?.context.data?.conciergeNotes}
          onChange={saveData}
        />
      ),
    },
  ];

  React.useEffect(() => {
    const step = steps.findIndex((step) => step.state === state.value);
    if (step >= 0 && activeStep !== step) setStep(step);
    else if (state.value === "completeCheckout") setStep(activeStep + 1);
  }, [state.value]);

  const canContinue = activeStep < steps.length;
  const canGoBack = activeStep > 0;

  const onContinue = () => {
    if (canContinue && activeStep < steps.length - 1) {
      nextStep();
      send("CONTINUE");
    } else {
      //TODO: Checkout.
      handleSubmit();
    }
  };

  const onBack = () => {
    if (canGoBack) {
      prevStep();
      send("BACK");
    }
  };

  return (
    <Box my="lg">
      <Heading as="h2" size="h2" variant="center">
        Complete Your Movie
      </Heading>
      {state.value === "loading" || state.value === "init" ? (
        <LoadingPage />
      ) : (
        <Stack mb={6} spacing={6}>
          <Flex flexDir="column" width="100%">
            <Steps
              activeStep={activeStep}
              colorScheme="seaGreen"
              variant={stepVariant}
            >
              {steps.map(({ label, component }, index) => (
                <Step label={label} key={label}>
                  {component}
                </Step>
              ))}
            </Steps>
          </Flex>
          <Stack pos="sticky" bottom="0" bgColor="white">
            <Divider />
            <Flex
              minH="full"
              minW="full"
              direction="row-reverse"
              align="center"
              justify="space-between"
              p="sm"
            >
              <Button
                type="submit"
                colorScheme="seaGreen"
                mr={0}
                bgColor={canContinue ? "seaGreen.500" : "seaGreen.200"}
                _hover={{
                  bgColor: canContinue ? "seaGreen.500" : "seaGreen.200",
                }}
                cursor={canContinue ? "pointer" : "not-allowed"}
                onClick={onContinue}
                isLoading={activeStep > steps.length - 1}
              >
                <span
                  data-tag={activeStep === steps.length - 1 ? "last-step" : ""}
                >
                  Continue
                </span>
              </Button>
              {canGoBack && (
                <Button
                  variant="plain"
                  color="black"
                  onClick={onBack}
                  disabled={!canGoBack}
                  isLoading={state.value === "completeCheckout"}
                >
                  <span>back</span>
                </Button>
              )}
            </Flex>
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

export default PurchaseConcierge;
