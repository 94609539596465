/* eslint-disable react/prop-types */
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import LoadingPage from "../../pages/LoadingPage";
import { Box, Heading } from "@chakra-ui/react";
import { FirebaseAuth } from "react-firebaseui";

type FitMode = "preserve" | "stretch" | "crop" | "smartcrop" | "pad";

export const getMuxImageUrl = (
  muxPlaybackId: string,
  dimensions = { width: 624, height: 468 },
  fit_mode: FitMode = "preserve"
) => {
  let src = `https://image.mux.com/${muxPlaybackId}/thumbnail.jpg`;
  try {
    const { width, height } = dimensions;
    const qry = `${width ? "width=" + width + "&" : ""}${
      height ? "height=" + height + "&" : ""
    }fit_mode=${fit_mode}`;
    src = `${src}?${qry}`;
  } catch (error) {
    console.log(error);
  }

  return src;
};

export const getMuxStreamUrl = (playbackId: string) => {
  return `https://stream.mux.com/${playbackId}.m3u8`;
};

export interface Identity {
  displayName?: string;
  email?: string;
  uid?: string;
}
export interface Submission {
  id: string;
  submissionDate: Date;
  storyId: string;
  promptIndex: number;
  content: {
    streamUrl?: string;
    muxPlaybackId?: string;
    thumbnailPath: string;
    downloadUrl: string;
    highestQuality?: "high" | "medium" | "low";
  };
  submittedBy: Identity;
  version?: number;
  isMine?: boolean;
  type?: "video" | "image";
}

export interface Response {
  id: string;
  name: string;
  thumbnail: string;
  url: string;
  type?: "video" | "image";
  processing: boolean;
}

export interface Prompt {
  id: string | number;
  idx: number;
  text: string;
  responses: Response[];
}
export interface Reminders {
  weekly: boolean;
  midway: boolean;
  dayBefore: boolean;
  weekBefore: boolean;
  dueDate: boolean;
  dayAfter: boolean;
}

export interface Story {
  id: string;
  title: string;
  subject: string;
  dueDate?: Date;
  prompts: Array<Prompt>;
  reminders?: Reminders;
  inviteMethod?: "invites" | "link";
  state: "setup" | "launch" | "running" | "paid" | "fulfilled" | "complete";
  [key: string]: any;
}

interface DiyVideo {
  id: string;
}

type ContextType =
  | {
      story?: Story;
      submissions: Submission[];
      diyVideos: DiyVideo[];
      ready: false;
      auth?: FirebaseAuth;
    }
  | {
      story: Story;
      submissions: Submission[];
      diyVideos: DiyVideo[];
      ready: true;
      auth?: FirebaseAuth;
    };

const StoryContext = React.createContext<ContextType>({
  story: undefined,
  submissions: [],
  diyVideos: [],
  ready: false,
  auth: undefined,
});

export const useStoryContext = () => {
  const context = React.useContext(StoryContext);
  if (context === undefined) {
    throw new Error("useStoryContext must be used within a StoryProvider");
  }
  return context;
};

const { Consumer, Provider } = StoryContext;

export const StoryConsumer = Consumer;
//@ts-ignore
export const StoryProvider = ({ children, ...props }) => {
  const { story, submissions, diyVideos, ready, auth } = props;

  const loading = <LoadingPage />;
  const notFound = (
    <Box w="full">
      <Heading as="h2">Story not found</Heading>
    </Box>
  );

  if (ready && isEmpty(story)) return notFound;

  return ready ? (
    <Provider value={{ story, submissions, diyVideos, ready, auth }}>
      {children}
    </Provider>
  ) : (
    loading
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  const id = ownProps.params.id;
  const data: {
    story?: Story;
    submissions?: Submission[];
    diyVideos?: DiyVideo[];
    ready: boolean;
    populated: boolean;
    auth: any;
  } = {
    auth: state.firebase.auth,
    story: undefined,
    submissions: undefined,
    diyVideos: undefined,
    ready: false,
    populated: false,
  };

  const allLoaded = (dataItems: any[]) => {
    return [...dataItems].every((item) => isLoaded(item));
  };

  if (
    !isLoaded(data.story) &&
    state.firestore.data["stories"] &&
    state.firestore.data["stories"][id]
  ) {
    const o = state.firestore.data["stories"][id];
    data.story = { id, ...o };
    //@ts-ignore
    data.story.prompts = o.prompts.map((prompt, idx) => {
      return { ...prompt, idx, responses: [] };
    });
  }

  const flagOwnedSubmissions = () => {
    data.submissions &&
      data.submissions.forEach((submission) => {
        if (
          submission.submittedBy &&
          data.auth.uid === submission.submittedBy.uid
        )
          submission.isMine = true;
      });
  };

  if (!isLoaded(data.submissions) && state.firestore.ordered.submissions) {
    const submissions = [
      ...state.firestore.ordered.submissions,
    ] as Submission[];
    //Fix video URL's
    submissions
      .filter((submission) => {
        return (
          ((!submission.type || submission.type === "video") &&
            !!submission.content.streamUrl) ||
          !!submission.content.muxPlaybackId
        );
      })
      .forEach((submission) => {
        if (submission.content.muxPlaybackId) {
          (submission.content.streamUrl =
            submission.content.streamUrl ||
            getMuxStreamUrl(submission.content.muxPlaybackId) ||
            ""),
            (submission.content.thumbnailPath =
              submission.type === "image"
                ? submission.content.downloadUrl
                : submission.content.muxPlaybackId &&
                  getMuxImageUrl(submission.content.muxPlaybackId));
        }
      });
    data.submissions = submissions;
    flagOwnedSubmissions();
  }

  if (!isLoaded(data.diyVideos) && state.firestore.ordered.diyVideos) {
    const diyVideos = [...state.firestore.ordered.diyVideos];
    data.diyVideos = diyVideos;
  }

  if (allLoaded([data.story, data.submissions])) {
    //@ts-ignore
    data.submissions
      .filter((submission) => {
        return (
          !!(submission.type === "image") ||
          !!submission.content.streamUrl ||
          !!submission.content.muxPlaybackId
        );
      })
      .forEach((submission) => {
        const response: Response = {
          id: submission.id,
          name: submission.submittedBy.displayName || "unknown",
          url: submission.content.streamUrl || submission.content.downloadUrl,
          thumbnail:
            submission.type === "image"
              ? submission.content.downloadUrl
              : submission.content.thumbnailPath || "",
          type: submission.type || "video",
          processing:
            submission.type === "image"
              ? false
              : ((!submission.type || submission.type === "video") &&
                  !!submission.content.streamUrl) ||
                !!submission.content.muxPlaybackId,
        };
        //@ts-ignore
        data.story.prompts[submission.promptIndex].responses.push(response);
      });
  }
  console.log({ submissions: data.submissions });
  data.ready = allLoaded([data.story, data.submissions, data.diyVideos]);

  return data;
};

export default compose(
  firestoreConnect((props: any) => [
    {
      collection: "stories",
      doc: props.params.id,
    },
    {
      collection: "submissions",
      where: [["storyId", "==", props.params.id]],
    },
    {
      collection: "stories",
      doc: props.params.id,
      subcollections: [{ collection: "diyVideos" }],
      storeAs: `diyVideos`,
    },
  ]),
  connect(mapStateToProps)
)(StoryProvider);
