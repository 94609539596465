import { createMachine, assign, send, spawn } from "xstate";
import { pure, sendParent } from "xstate/lib/actions";
import { removeFromStorage } from "../utils/firebase-storage";
import uploadMachine from "./uploadMachine";
import { nanoid } from "nanoid";

export type Asset = {
    name: string;
    path: string;
    type: 'image' | 'video' | 'audio' | string;
    metadata: any;
    [key: string]: any;
};

const createAssetMachine = (id: string, asset: Asset, file: any) => {
    return createMachine(
        {
            id,
            initial: "init",
            context: {
                id,
                asset, // asset passed in
                file, //file passed in
            },
            states: {
                init: {
                    always: [
                        { target: "upload", cond: (context) => !!context.file },
                        { target: "loaded" },
                    ],
                },
                upload: {
                    invoke: {
                        id: "uploader",
                        src: uploadMachine,
                        data: {
                            data: (context, event) => ({
                                path: context.asset.path,
                                file: context.file,
                                metadata: context.asset.metadata,
                            }),
                        },
                    },
                    on: {
                        CANCEL: {
                            actions: [
                                "announce",
                                send((context, event) => ({ ...event, type: "CANCEL" }), {
                                    to: "uploader",
                                }),
                            ],
                        },
                        "UPLOAD.PROGRESS": {
                            actions: ["uploadProgress"],
                        },
                        "UPLOAD.COMPLETE": {
                            actions: [
                                "announce",
                                "uploadComplete",
                                send({ type: "EXIT" }, { to: "uploader" }),
                                send((context, event) => ({ ...event, type: "COMPLETE" }), {
                                    delay: 1000,
                                }),
                            ],
                        },
                        "UPLOAD.CANCELLED": {
                            actions: [
                                "announce",
                                "uploadCancelled",
                                send({ type: "EXIT" }, { to: "uploader" }),
                                send((context, event) => ({ ...event, type: "REMOVE" }), {
                                    delay: 1000,
                                }),
                            ],
                        },
                        COMPLETE: {
                            target: "loaded",
                        },
                        REMOVE: {
                            target: "remove",
                        },
                    },
                },
                loaded: {
                    on: {
                        REFRESH: "init",
                        DELETE: "delete",
                    },
                },
                delete: {
                    invoke: {
                        id: "remover",
                        src: "removeAsset",
                        onDone: {
                            actions: ["announce"],
                            target: "remove",
                        },
                        onError: {
                            actions: ["announce", assign({
                                errorMessage: (context, event) => {
                                    return event.data;
                                }
                            })]
                        },
                    },
                },
                failure: {
                    on: {
                        RETRY: "init",
                    },
                },
                remove: {
                    entry: [
                        sendParent((context) => ({ type: "REMOVE", id: context.id })),
                    ],
                },
            },
        },
        {
            services: {
                removeAsset: (context, event) => {
                    return removeFromStorage(context.asset.path);
                },
            },
            actions: {
                announce: (context, event) => {
                    console.log("Asset machine - ", { context, event });
                },
                uploadProgress: pure((context, event) => {
                    if (event.type !== "UPLOAD.PROGRESS") return {};
                    return [
                        assign({ progress: event.data.progress }),
                        sendParent({ type: "UPLOAD.COMPLETE" }),
                    ] as Array<any>;
                }),
                uploadComplete: pure((context, event) => {
                    if (event.type !== "UPLOAD.COMPLETE") return {};
                    const { data } = event.data.result;

                    return [
                        assign({
                            progress: 100,
                            file: undefined,
                            asset: { ...context.asset, ...data },
                        }),
                        sendParent({ type: "UPLOAD.COMPLETE" }),
                    ] as Array<any>;
                }),
                uploadCancelled: pure((context, event) => {
                    if (event.type !== "UPLOAD.CANCELLED") return {};
                    return [
                        assign({
                            progress: 0,
                            file: undefined,
                            asset: { name: "cancelled" },
                        }),
                        sendParent({ ...event, type: "UPLOAD.CANCELLED" }),
                    ] as Array<any>;
                }),
            },
        }
    );
};

const myMachineConfig = {
    initial: "idle",
    context: {
        storyId: "",
        assets: [],
    },
    states: {
        idle: {
            on: {
                LOAD: {
                    actions: assign((context, event) => {
                        const assets = [...context.assets];
                        event.assets &&
                            event.assets.map((info) => {
                                // spawn a new asset actor and
                                // save it in the assets object
                                const id = nanoid();
                                const asset = spawn(createAssetMachine(id, info, null));
                                assets.push({
                                    ref: asset,
                                    id,
                                    ...info,
                                });
                            });
                        return {
                            assets,
                        };
                    }),
                },
            },
        },
        uploading: {},
    },
    on: {
        UPLOAD: {
            target: ".uploading",
            actions: assign((context, event) => {
                const assets = [...context.assets];
                event.files &&
                    event.files.map((file) => {
                        // spawn a new asset actor and
                        // save it in the assets object
                        const { name, size, type } = file;
                        const info: Asset = {
                            name,
                            path: `assets/${context.storyId}/${name}`,
                            type: type.split('/')[0],
                            metadata: { size, contentType: type, visibility: "public" },
                        };
                        const id = nanoid();
                        const asset = spawn(createAssetMachine(id, info, file));
                        assets.push({
                            ref: asset,
                            id,
                            ...info,
                        });
                    });
                return {
                    assets,
                };
            }),
        },
        REMOVE: {
            actions: assign((context, event) => {
                const assets = [...context.assets];

                const found = assets.findIndex(asset => asset.id === event.id);
                console.log(found);
                const removed = assets.splice(found, 1);
                return {
                    assets,
                };
            }),
        }
    },
};

const manageStoryAssetsMachine = createMachine({
    ...myMachineConfig,
});

export default manageStoryAssetsMachine;
