import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveImage } from './ImageResponsive';
import { useBreakpointValue } from '@chakra-ui/media-query';

export const getMuxImageUrl = (playbackId) => {
    return `https://image.mux.com/${playbackId}/thumbnail.jpg`
}


const formatMuxImageQuery = (width,height,time,fit) => {
    const getTimeParam = () => {
        return time ? `time=${time}&` : null;
    }
    const w = String(width).replace('px','')
    const h = String(height).replace('px','')
    return `${width ? 'width=' + w + '&' : ''}${height ? 'height=' + h + '&' : ''}${getTimeParam()}fit_mode=${fit}`
}
/**
 * <MuxImage playBackId='fFvi00ZixDxUI71VsC1eIA8Ohzx6MM8ZdVIdG9p601wg4' alt={alt} />
 */
const MuxImage = ({playbackId, dimensions, time, crop='preserve',...props}) => {
    const { as: Component=ResponsiveImage} = props;
    const sizes = {
        base:{
            width: 250,
            height: 187
        }, sm: {
            width: 250,
            height: 187
        }, md: {
            width: 624,
            height: 468
        }, lg: {
            width: 624,
            height: 468
        }, xl: {
            width: 624,
            height: 468
        }
    }
    const {width,height} = useBreakpointValue(dimensions||sizes) || sizes.base
    let src = getMuxImageUrl(playbackId)

    try {
        src = `${src}?${formatMuxImageQuery(width,height,time,crop)}`
    }
    catch(error) {
        console.log(error);
    }

    return <Component {...{ ...props, src }} />
}

MuxImage.propTypes = {
    as: PropTypes.object,
    playbackId: PropTypes.string.isRequired,
    time: PropTypes.number,
    crop:  PropTypes.oneOf(['preserve', 'stretch ', 'crop', 'pad', 'smartcrop']),
    dimensions: PropTypes.shape({
        width: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        height: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    }),
}

export default MuxImage;
