
import { Button, Modal, UseModalProps, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, SimpleGrid, Box, Heading, useBreakpointValue, Stack } from '@chakra-ui/react'
import React from 'react'

interface FinalMovieInfoModal extends UseModalProps {
    colorScheme:string;
    isOpen: boolean;
    onClose(): void;
}

const FinalMovieInfoModal = ({isOpen,onClose,...restOfModal}:FinalMovieInfoModal) => {

    const size = useBreakpointValue(["full", "xl", "2xl"]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...restOfModal} variant="quilted" size={size}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as="h2">DIY vs. Concierge</ModalHeader>
          <ModalBody>
            <Text textAlign="center" mb={3}>After you finish collecting resonses, you have two options for creating your final video gift: concierge and DIY. There are several differences between these options, as well as different timeframes for completing the final video.</Text>
            <SimpleGrid columns={{ sm: 1, md: 2 }} mb={3} spacing={[12,6]}>
                <Stack gap={3}>
                    <Heading size="md" textAlign="center" w="full">DIY</Heading>
                    <Text textAlign="center">Using our DIY editor allows you to create your final movie quickly and easily.</Text>
                    <Text textAlign="center">You’ll have the ability to add or exclude any of your clips, or trim them to include only your favorite parts.</Text>
                    <Text textAlign="center" fontWeight="bold">Suggested due date: 2-3 days</Text>
                    <Text textAlign="center">If using DIY, we suggest you set your due date 2-3 days before you plan on giving your gift. This gives you time to review and edit all of your submissions. Our editor also takes several hours to generate your final, full-quality movie.</Text>
                </Stack>
                <Stack gap={3}>
                    <Heading size="md" textAlign="center" w="full">Concierge</Heading>
                    <Text textAlign="center">Our concierge service is the easiest way to create your final movie. We’ll make a few basic edits to make it look it’s best, including trimming silence and adding background music.</Text>
                    <Text textAlign="center" fontWeight="bold">Suggested due date: 3 business days</Text>
                    <Text textAlign="center">If using concierge, set your due date at least 3 business days before you plan on giving your gift. 5 days if it works for your schedule. This will ensure we have time to review your clips and create your final movie.</Text>
                </Stack>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='seaGreen' onClick={onClose}>
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}

export default FinalMovieInfoModal