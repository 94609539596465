import React, { useState, useRef, useEffect } from "react";
import QRCodeStyling, { FileExtension } from "qr-code-styling";
import qrcodeoptions from "../../../config/qrcodeoptions";
import {
  Box,
  Button,
  Heading,
  ListItem,
  OrderedList,
  Select,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";

const qrCode = new QRCodeStyling({ ...qrcodeoptions, width: 150, height: 150 });

interface props {
  url: string;
}
const QRCode = ({ url }: props) => {
  const [fileExt, setFileExt] = useState<FileExtension>("png");
  const ref = useRef<typeof Box>();

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: url,
    });
  }, [url]);

  const onExtensionChange = (event: any) => {
    setFileExt(event.target.value);
  };

  const onDownloadClick = () => {
    new QRCodeStyling({ ...qrcodeoptions, data: url }).download({
      extension: fileExt,
    });
  };

  return (
    <Box m={1} ml="auto" mr="auto">
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        spacing={4}
        align="center"
      >
        <Box>
          <Heading size="lg">Your QR Code</Heading>
          <Text my={2}>
            Use your personal QR code for invitations, mailers, posters, signs,
            stickers, and more!
          </Text>
          <Text mb={2}>
            Anyone that scans this QR Code will be taken directly to your story
            and can begin submitting their responses.
          </Text>
        </Box>
        <Box background="white">
          <Box ref={ref as unknown as typeof Box} />
        </Box>
        <VStack spacing={4} align="center">
          <Button
            colorScheme="white"
            variant="outline"
            onClick={onDownloadClick}
          >
            Download
          </Button>
          <Select
            onChange={onExtensionChange}
            value={fileExt}
            placeholder="Change file type"
            size="sm"
          >
            <option value="jpeg">JPEG</option>
            <option value="png">PNG</option>
            <option value="webp">WEBP</option>
            <option value="svg">SVG</option>
          </Select>
        </VStack>
        <Box>
          <Text fontWeight="bold">Instructions for scanning a QR code:</Text>
          <OrderedList>
            <ListItem>Open your phone&apos;s camera app</ListItem>
            <ListItem>
              Point your phone/camera at the QR code for a moment, you will need
              to be close enough or zoom in for the camera to read the QR Code
            </ListItem>
            <ListItem>
              Click the pop-up notification to view the content
            </ListItem>
          </OrderedList>
        </Box>
      </VStack>
    </Box>
  );
};

export default QRCode;
