import React from "react";
import { Button, ButtonProps } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

export const HistoryBackButton = (props:ButtonProps) => {
  const navigate = useNavigate();

  return <BackButton onClick={()=>navigate(-1)} {...props} />
}

const BackButton = (props: ButtonProps) => {
  return (
    <Button variant="plain" type="button" {...props}>
      <FontAwesomeIcon icon="arrow-left" />
    </Button>
  );
};

export default BackButton;
