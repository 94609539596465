import React from "react";

import { Box, Progress, Stack, Text } from "@chakra-ui/react";
import { useActor } from "@xstate/react";
import { useStoryViewContext } from "../../../pages/StoryView/components/StoryViewProvider";

const CurrentUploads = () => {
  const { service } = useStoryViewContext();
  const actor = useActor(service);
  const [state] = actor;
  const { uploads, uploadProgress: progress } = state.context;

  return (
    (!!uploads.length && !!uploads.filter((u) => !u.isDone).length && (
      <Box bg="puce.50" p="3" rounded="sm">
        <Stack spacing={1}>
          <Progress value={progress} size="xs" colorScheme="puce" />
          <Text fontSize="sm">
            {uploads.length} video(s) still uploading. Do not close this page.
          </Text>
        </Stack>
      </Box>
    )) ||
    null
  );
};

export default CurrentUploads;
