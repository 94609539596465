import React from "react";
import { Box, Flex, FlexProps, Text, Tooltip } from "@chakra-ui/react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
interface ClipCircleInterface extends FlexProps {
  thumbnail: string;
  name: string;
  showPulse?: boolean;
  disabled?: boolean;
  colorScheme: string;
  size?: "sm" | "md" | "lg";
  icon?: IconProp;
  onClick: () => void;
}

interface SizeSetting {
  w: string;
  h: string;
  borderWidth: "medium" | "thick" | "thin" | string;
}
interface SizeSettings {
  sm: SizeSetting;
  md: SizeSetting;
  lg: SizeSetting;
}

const ClipCircle = ({
  thumbnail,
  name,
  showPulse,
  disabled,
  colorScheme,
  size = "md",
  icon = "play",
  onClick,
}: ClipCircleInterface) => {
  const [isVisible, setIsVisible] = React.useState(disabled || false);

  function onMouseOver() {
    setIsVisible(true);
  }
  function onMouseOut() {
    setIsVisible(false);
  }
  const sizeSettings: SizeSettings = {
    sm: {
      w: "40px",
      h: "40px",
      borderWidth: "4px",
    },
    md: {
      w: "55px",
      h: "55px",
      borderWidth: "4px",
    },
    lg: {
      w: "70px",
      h: "70px",
      borderWidth: "6px",
    },
  };

  return (
    <Tooltip
      label={name}
      aria-label={name}
      colorScheme={colorScheme}
      hasArrow
      onOpen={onMouseOver}
      onClose={onMouseOut}
    >
      <Box
        _hover={{
          transform: "scale(1.07)",
        }}
        transition="200ms linear"
        pos="relative"
        zIndex={1}
        cursor="pointer"
      >
        <Box
          w={sizeSettings[size].w}
          h={sizeSettings[size].h}
          pos="relative"
          bgColor="springWood.500"
          bgImage={
            isVisible
              ? `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url('${thumbnail}')`
              : thumbnail
          }
          bgSize="cover"
          bgPos="center"
          rounded="full"
          borderColor={`${colorScheme}.400`}
          borderWidth={sizeSettings[size].borderWidth}
          transition="200ms linear"
          boxShadow={undefined}
          onClick={onClick}
          animation={showPulse ? "pulse 2s infinite" : undefined}
        >
          <Flex
            justify="center"
            alignItems="center"
            w="full"
            h="full"
            pos="absolute"
            rounded="full"
            zIndex="3"
          />
          <Flex
            justify="center"
            alignItems="center"
            w="full"
            h="full"
            pos="absolute"
            rounded="full"
            zIndex="4"
          >
            {isVisible && (
              <Text as="span" color={!disabled ? `white` : "black"}>
                <FontAwesomeIcon
                  icon={icon}
                  size={size as any}
                  spinPulse={disabled}
                />
              </Text>
            )}
          </Flex>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default ClipCircle;
