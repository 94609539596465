import React from "react";
import { Center, CenterProps, Flex, FlexProps } from "@chakra-ui/layout";

const PageContainer = (props: PageContainerProps) => {
  const { children, wrapperProps, ...containerProps } = props;
  return (
    <Center {...wrapperProps}>
      <Flex
        direction="column"
        align="center"
        justify="start"
        alignSelf="stretch"
        overflow="visible"
        mb="sm"
        p="medium"
        flexShrink={0}
        minW={undefined}
        maxW="90vw"
        w="3xl"
        {...containerProps}
      >
        {children}
      </Flex>
    </Center>
  );
};

export default PageContainer;

interface PageContainerProps extends FlexProps {
  children: React.ReactNode;
  wrapperProps?: CenterProps;
}
