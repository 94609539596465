import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Flex,
    Skeleton,
} from "@chakra-ui/react";

import config from '../../../config/config'

import ShopifyProvider, { useShopifyDispatch, useShopifyState } from '../../shopify/ShopifyProvider'
import Products from '../../shopify/Products'

const PurchaseAddOnsForm = () => {
    const [running, setRunning] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [hasItemsInCart, setHasItemsInCart] = useState(false);
    const dispatch = useShopifyDispatch();
    const state = useShopifyState() as { checkout?: {lineItems?:Array<any>}, isCartOpen: boolean };

    const handleSubmit = () => {
        setRunning(true)
        dispatch({
            type: 'checkout'
        });

        setSubmitted(true);
        setRunning(false);
    }

    useEffect(() => {   
        console.dir({shopifyState:state});
        if(state.isCartOpen && state.checkout?.lineItems && state.checkout?.lineItems.length > 0) {
            setHasItemsInCart(true);
        }
        else {
            setHasItemsInCart(false);
        }
    }, [state]);

    return (
        <Box>
            <Products />
            <Flex
                minH="full"
                minW="full"
                direction="row-reverse"
                align="center"
                justify="end"
                p="sm"
            >
                <Button type="submit" isLoading={running} disabled={!hasItemsInCart || submitted} onClick={() => handleSubmit() }>Checkout</Button>
            </Flex>
        </Box>
    )
}

const PurchaseAddOns = ({story}:{story:{id:string}}) => {
    const [loaded, setLoaded] = React.useState(false);

    useEffect(() => {
        story && setLoaded(true);
    }, [story])

    return config.shopify.showPhysicalProduct ? (
        <Skeleton isLoaded={loaded}>
            <ShopifyProvider storyId={story.id} name="purchaseAddOns">
                <PurchaseAddOnsForm />
            </ShopifyProvider>
        </Skeleton>
    )
        :
        (<></>);
}

export default PurchaseAddOns
