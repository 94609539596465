import React from "react";
import { Flex, Stack, Heading } from "@chakra-ui/react";

const LoginPage = (props: { authForm: React.ReactNode }) => {
  return (
    <Stack align="center" my="lg">
      <Heading as="h2" size="h2" variant="center">
        Log in to Quilted
      </Heading>
      <Flex
        justify="center"
        my="lg"
        h="full"
        direction="column"
        p="lg"
        shadow="lg"
        bgColor="springWood.100"
        rounded="lg"
        align="center"
      >
        {props.authForm}
      </Flex>
    </Stack>
  );
};

export default LoginPage;
