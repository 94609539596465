import { useActor } from "@xstate/react";
import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Stack,
  Text,
  HStack,
  useTheme,
  Badge,
} from "@chakra-ui/react";
import {
  Prompt as PromptType,
  PromptContext,
} from "../../types/DIYVideoBuilder";
import ClipCircle from "./ClipCircle";

const Prompt = ({
  prompt,
  totalPrompts,
  hasStartedEditing,
  ...props
}: {
  prompt: PromptType;
  totalPrompts: number;
  hasStartedEditing: boolean;
}) => {
  const [state, send]: any[] = useActor(prompt.ref);
  const promptContext: PromptContext = state.context;

  const theme = useTheme();

  const getClipCountString = (included: boolean) => {
    const clipCount = promptContext.submissions.filter(
      (submission) => submission.include === included
    ).length;

    return `${clipCount.toString()} ${clipCount === 1 ? "clip" : "clips"} ${
      included ? "included" : "hidden"
    }`;
  };

  const { text, clips, isClipActive } = promptContext;

  return (
    <Stack
      p="sm"
      m="xs"
      boxShadow={
        isClipActive ? `0 0 0 3px ${theme.colors.seaGreen[200]}` : undefined
      }
      rounded="lg"
      // border={isClipActive ? "1px solid" : undefined}
      borderColor="seaGreen.500"
      bgColor={isClipActive ? "white" : undefined}
    >
      <Box>
        <Badge textTransform="none" fontWeight="normal" fontSize="xs">
          Prompt {prompt.idx + 1} of {totalPrompts}
        </Badge>
      </Box>
      <Text fontWeight="bold" fontSize="md">
        {prompt.text}
      </Text>
      <HStack fontStyle="italic">
        <Text fontSize="xs">{getClipCountString(true)}</Text>
        <Text fontSize="xs" fontStyle="normal">
          |
        </Text>
        <Text fontSize="xs">{getClipCountString(false)}</Text>
      </HStack>
      {state.value === "idle" ? text : <></>} -{" "}
      {/* <button
        onClick={() => send({ type: state.value === "idle" ? "EDIT" : "DONE" })}
      >
        {state.value}
      </button> */}
      <Flex
        dir="row"
        flexWrap="wrap"
        display="inline-flex"
        gap="10px"
        css={{ gap: "8px" }}
      >
        {clips &&
          clips.map(
            (slide, idx) =>
              slide && (
                <ClipCircle
                  key={idx}
                  showPulse={prompt.idx == 0 && idx === 1 && !hasStartedEditing}
                  machineRef={slide}
                />
              )
          )}
      </Flex>
    </Stack>
  );
};

export default Prompt;
