import React from 'react';
import { Box, Button, Flex, Heading, Img } from '@chakra-ui/react';
interface InvitationsDoneViewProps { colorScheme:string, onClick: () => void }

export const InvitationsDoneView = ({colorScheme, onClick }:InvitationsDoneViewProps) => {
    return (<Box>
        <Flex direction="column" align="center" justify="center" gap={4}>
        <Img
          src="/img/package-icons/mountain.svg"
          p="4"
          pb={["sm", "4"]}
          maxW={["150px", "80%"]}
          maxH={["150px", "40%"]}
        />
        <Heading as='h4' size='md' mb={3} textAlign="center">Your storytellers have been invited!</Heading>
        <Button colorScheme={colorScheme} onClick={onClick}>Done</Button>
        </Flex>
    </Box>)
}

export default InvitationsDoneView;