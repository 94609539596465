import { createMachine, assign, spawn, SendAction } from "xstate";

import { pure, send } from "xstate/lib/actions";
import recordingMachine from "../recordingMachine";
import { createPromptMachine } from "./promptMachine";
import { createUploadMachine } from "../uploadMachine";
import getFileExtension from "../../utils/getFileExtensionFromMime";
import { v4 as uuidv4, v5 as uuidv5 } from "uuid";

const getFileId = (
  id: string | undefined | null,
  storyId: string | undefined,
  promptIndex: number,
  user: string
) => {
  const generate = () => {
    return uuidv5(
      `/videos/response/${user}/${storyId}/${promptIndex}`,
      uuidv5.URL
    );
  };
  return id || generate();
};

export interface Identity {
  displayName?: string;
  email?: string;
  uid?: string;
}
export interface Response {
  id: string;
  promptIndex: number;
  storyId: string;
  submissionDate: Date;
  content: {
    thumbnailPath: string;
    downloadUrl: string;
    streamUrl?: string;
    muxPlaybackId?: string;
  };
  submittedBy: Identity;
  type?: "video" | "image";
}

export interface Prompt {
  id: string | number;
  idx: number;
  text: string;
  response?: Response | null;
  ref?: any;
  processing?: boolean;
}
export interface Story {
  id: string;
  title: string;
  owner: string;
  subject: string;
  welcomeMessage: string;
  prompts: Array<Prompt>;
  state: "setup" | "launch" | "running" | "paid" | "fulfilled" | "complete";
}

interface Context {
  story: Story;
  identity: Identity | null;
  activePrompt?: Prompt;
  uploads: Array<any>;
  uploadProgress: number;
  error?: string;
}

export type ResponseMachineEvent =
  | {
      type: "FETCH";
    }
  | {
      type: "RETRY";
    }
  | {
      type: "READY";
    }
  | {
      type: "RESPOND";
    }
  | {
      type: "IDENTIFIED";
      value: Identity;
    }
  | {
      type: "ANALYTICS";
    }
  | {
      type: "CHANGE";
    }
  | {
      type: "CONTINUE";
    }
  | {
      type: "DONE";
    }
  | {
      type: "CANCEL";
    }
  | {
      type: "PROMPT.ACTIVE";
      data: { id: number; useInput?: boolean };
    }
  | {
      type: "done.invoke.fetchStory";
      data: Context;
    }
  | {
      type: "done.invoke.fetchResponses";
      data: Array<Response>;
    }
  | {
      type: "done.invoke.fetchAuth";
      data: Identity;
    }
  | {
      type: "done.invoke.recordingMachine";
      data: { blob: any };
    }
  | {
      type: "LOAD_RESPONSES";
      data: Array<Response>;
    }
  | {
      type: "UPLOAD.PROGRESS";
      data: any;
    }
  | {
      type: "UPLOAD.COMPLETE";
      data: any;
    };

const storySetup = (context: Context, event: any) => {
  const { story } = context;
  const inSetup = story.state === "setup";
  return inSetup;
};

const storyRunning = (context: Context, event: any) => {
  const { story } = context;
  const isRunning = story.state === "running";
  return isRunning;
};

const storyComplete = (context: Context, event: any) => {
  const { story } = context;
  const isComplete = story.state === "complete";
  return isComplete;
};

const respondingStates = {
  initial: "determine",
  states: {
    determine: {
      on: {
        "": [{ target: "complete", cond: "isComplete" }, { target: "active" }],
      },
    },
    active: {
      always: [{ target: "complete", cond: "isComplete" }],
    },
    complete: {
      entry: [
        send({ type: "ANALYTICS", track: "response:allPromptsComplete" }),
      ],
      always: [{ target: "active", cond: "isIncomplete" }],
    },
  },
};

export const responseMachine = createMachine<Context, ResponseMachineEvent>(
  {
    id: "response",
    initial: "idle",
    context: {
      story: {
        id: "unknown",
        title: "",
        owner: "",
        subject: "",
        welcomeMessage: "",
        prompts: [],
        state: "setup",
      },
      identity: null,
      uploads: [],
      uploadProgress: 0,
    },
    states: {
      idle: {
        on: {
          FETCH: {
            target: "loading",
          },
        },
      },
      loading: {
        invoke: {
          id: "fetchStory",
          src: "fetchStory",
          onDone: {
            actions: "loadData",
            target: "init",
          },
          onError: {
            actions: "announce",
            target: "error",
          },
        },
      },
      init: {
        entry: [
          "announce",
          assign({
            story: (context) => {
              console.log("Rehydrating prompts");

              // 'Rehydrate' persisted prompts with machines
              const _prompts = context.story.prompts.map((prompt, idx) => ({
                ...prompt,
                idx,
                ref: spawn(
                  createPromptMachine({
                    ...prompt,
                    idx,
                    id: idx,
                  })
                ),
              }));

              return {
                ...context.story,
                prompts: _prompts,
              };
            },
          }),
        ],
        always: [
          { target: "ready", cond: storyRunning },
          { target: "final", cond: storyComplete },
          { target: "notLaunched", cond: storySetup },
          {
            target: "error",
            actions: assign({
              error: (context, event) => "Could not determine story state.",
            }),
          },
        ],
        exit: send({ type: "ANALYTICS", track: "response:loaded" }),
      },
      error: {
        on: {
          RETRY: "loading",
        },
      },
      notLaunched: {
        entry: ["announce"],
      },
      ready: {
        entry: send({ type: "ANALYTICS", track: "response:readyToRespond" }),
        on: {
          RESPOND: [
            {
              target: "responding",
              cond: (context: Context) => !!context.identity,
            },
            { target: "identify" },
          ],
        },
      },
      identify: {
        entry: send({ type: "ANALYTICS", track: "response:contactForm_shown" }),
        invoke: {
          id: "fetchAuth",
          src: "fetchAuth",
          onDone: {
            actions: ["announce", "identified", "storeIdentity"],
          },
          onError: {
            actions: "announce",
            target: "error",
          },
        },
        on: {
          IDENTIFIED: {
            actions: ["storeIdentity", "identified"],
          },
        },
        always: [
          {
            target: "responding",
            cond: (context: Context) => {
              const hasIdentity = !!context.identity;
              console.log("Check condition", hasIdentity);
              return hasIdentity;
            },
          },
        ],
        exit: send({
          type: "ANALYTICS",
          action: "response:contactForm_submitted",
        }),
      },
      responding: {
        // No tracking events in this state since the user enters this state after every recording
        id: "responding",
        on: {
          CHANGE: {
            actions: assign({ identity: (_, event) => null }),
          },
        },
        always: [
          { target: "identify", cond: (context: Context) => !context.identity },
        ],
        ...respondingStates,
      },
      recording: {
        entry: ["announce"],
        invoke: {
          id: "recordingMachine",
          src: recordingMachine,
          onDone: {
            target: "done",
            actions: [
              "announce",
              "spawnUpload",
              send(
                { type: "PROCESSING" },
                { to: (context) => context.activePrompt?.ref }
              ),
            ],
          },
          data: {
            useInput: (context: Context, event: ResponseMachineEvent) => {
              return event.type === "PROMPT.ACTIVE" && !!event.data.useInput;
            },
          },
        },
        on: {
          CANCEL: {
            target: "responding",
            actions: [
              "cancel",
              send({
                type: "ANALYTICS",
                track: "response:recorder_recordingCancelled",
              }),
            ],
          },
          DONE: {
            target: "done",
          },
        },
      },
      upload: {
        entry: ["announce"],
      },
      done: {
        entry: [
          "announce",
          send((ctx) => ({
            type: "ANALYTICS",
            track: "response:user_finishedRecording",
            properties: {
              promptId: ctx.activePrompt?.id,
              promptText: ctx.activePrompt?.text,
            },
          })),
        ],
        on: {
          CONTINUE: "responding",
        },
      },
      final: {
        type: "final",
      },
    },
    on: {
      ANALYTICS: {
        actions: ["sendAnalytics"],
      },
      "PROMPT.ACTIVE": {
        actions: ["announce", "setPromptActive", "toggleActivePrompts"],
        target: "recording",
      },
      "UPLOAD.PROGRESS": {
        actions: ["announce", "uploadProgress"],
      },
      "UPLOAD.COMPLETE": {
        actions: ["announce", "uploadComplete"],
      },
      LOAD_RESPONSES: {
        actions: ["announce", "loadResponses"],
      },
    },
  },
  {
    services: {
      fetchStory: async () => {
        console.log("fetchStory not implemented!");
        throw new Error("Not Implemented.");
      },
      fetchAuth: async () => {
        console.log("fetchAuth not implemented!");
        throw new Error("Not Implemented.");
      },
      fetchResponses: async () => {
        console.log("fetchResponses not implemented!");
        throw new Error("Not Implemented.");
      },
      saveResponse: async (context: Context, event) => {
        console.log("saveResponse not implemented!");
        throw new Error("Not Implemented.");
      },
      saveIdentity: async (context: Context, event) => {
        console.log("saveIdentity not implemented!");
        throw new Error("Not Implemented.");
      },
    },
    actions: {
      announce: (context, event) => {
        console.log("Response machine - ", event.type, { context, event });
      },
      loadData: assign((context: Context, event) => {
        if (event.type !== "done.invoke.fetchStory") return {};
        return {
          ...event.data,
        };
      }),
      loadResponses: pure((context: Context, event) => {
        if (
          event.type !== "done.invoke.fetchResponses" &&
          event.type !== "LOAD_RESPONSES"
        )
          return undefined;
        const { story } = context;
        const { prompts } = story;
        const _prompts = [...prompts];
        const actions: Array<any> = [];
        if (event.data) {
          for (let index = 0; index < _prompts.length; index++) {
            const prompt = _prompts[index];
            const response = event.data.find(
              (response) => response.promptIndex === index
            );
            prompt.response = response || null;
            actions.push(
              send({ type: "SET_RESPONSE", response }, { to: prompt.ref })
            );
          }
        }
        actions.push(
          assign({
            story: { ...story, prompts: _prompts },
          })
        );
        return actions;
      }),
      setPromptActive: assign((context: Context, event) => {
        if (event.type !== "PROMPT.ACTIVE") return {};
        return {
          activePrompt: context.story.prompts[event.data.id],
        };
      }),
      toggleActivePrompts: pure((context: Context, event) => {
        if (event.type !== "PROMPT.ACTIVE") return undefined;
        const others = context.story.prompts.filter(
          (prompt) => event.data.id !== prompt.idx
        );
        return others.map((actor) => {
          return send({ type: "IDLE" }, { to: actor.ref });
        });
      }),
      uploadProgress: assign((context: Context, event) => {
        if (event.type !== "UPLOAD.PROGRESS") return {};
        const uploads = [...context.uploads];
        const upload = uploads[event.data.idx];
        if (upload) {
          upload.progress = event.data.progress;
          upload.isDone = event.data.progress === 100;
        }

        let progress = 0;
        const count = uploads.length;
        const total = uploads.reduce(function (runningTotal, cur) {
          return runningTotal + (cur.progress || 0);
        }, 0);

        progress = total / count;
        return { uploads, uploadProgress: progress };
      }),
      uploadComplete: assign((context, event) => {
        if (event.type !== "UPLOAD.COMPLETE") return {};
        const uploads = [...context.uploads];
        uploads.splice(event.data.idx, 1);

        let progress = 0;
        const count = uploads.length;
        const total = uploads.reduce(function (runningTotal, cur) {
          return runningTotal + (cur.progress || 0);
        }, 0);
        progress = total / count;
        return { uploads, uploadProgress: progress };
      }),
      identified: assign((context, event) => {
        if (event.type === "IDENTIFIED") return { identity: event.value };
        else if (event.type === "done.invoke.fetchAuth")
          return {
            identity: event.data,
          };
        else return {};
      }),
      spawnUpload: assign({
        uploads: (context, event) => {
          if (
            event.type !== "done.invoke.recordingMachine" ||
            !context.activePrompt ||
            !context.identity ||
            !event.data
          )
            return context.uploads;
          const contentType = event.data.blob.type;
          const fileId = getFileId(
            context.activePrompt.response?.id,
            context.story.id,
            context.activePrompt.idx,
            String(context.identity.uid || context.identity.email)
          );
          const fileMetadata = {
            contentType,
            customMetadata: {
              id: fileId,
              prompt: context.activePrompt?.idx,
              story: context.story.id,
              ...context.identity,
            },
          };
          const filename = `${fileId}.${getFileExtension(contentType)}`;
          //@ts-ignore
          const file = new File([event.data.blob], filename, {
            type: contentType,
          });
          const machine = spawn(
            createUploadMachine({
              id: `upload${context.activePrompt.id}`,
              data: {
                path: `videos/${context.story.id}/responses/${context.activePrompt?.idx}/${fileId}/${filename}`,
                file,
                metadata: fileMetadata,
                idx: context.uploads.length,
              },
            })
          );
          const _uploads = [
            ...context.uploads,
            {
              ...event.data,
              identity: context.identity,
              prompt: { ...context.activePrompt },
              ref: machine,
            },
          ];
          return _uploads;
        },
      }),
      storeIdentity: () => {
        throw Error("Not implemented.");
      },
      sendAnalytics: () => {
        throw Error("sendAnalytics Not implemented.");
      },
    },
    guards: {
      isComplete: (context, event) => {
        console.log("IsComplete?");
        const complete = context.story.prompts.every(
          (prompt) => !!prompt.response
        );
        return complete;
      },
      isIncomplete: (context, event) => {
        const complete = context.story.prompts.every(
          (prompt) => !!prompt.response
        );
        return !complete;
      },
    },
  }
);

export default responseMachine;
