import firebase from "firebase";

const progress = (onProgress) => (snapshot) => {
    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //console.log('uploadToStorage: Upload is ' + progress + '% done');
    switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('uploadToStorage: Upload is paused');
            break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
            //console.log('uploadToStorage: Upload is running');
            break;
        default:
            //console.log('uploadToStorage: Upload state:', snapshot.state);
            break;
    }
    //console.log('onProgress', { onProgress })
    onProgress && onProgress({ state: snapshot.state, progress });
}

export const uploadToStorageCancellable = (storagePath, file, metadata, onProgress) => {
    //uploadTask.cancel()
    const storage = firebase.storage().ref();
    // Create a reference to the file by storagePath
    const fileRef = storage.child(storagePath);
    var uploadTask = fileRef.put(file, metadata);

    uploadTask.on('state_changed', progress(onProgress));
    const task = uploadTask.then((snapshot) => {
        //console.log('uploadToStorage: Uploaded a blob or file!');
        return snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log('uploadToStorage: File available at', downloadURL);
            return { downloadURL, uploadTaskSnapshot: snapshot };
        });
    });
    const cancel = () => {
        uploadTask.cancel()
    };

    return [task, cancel];
}

export const uploadToStorage = (storagePath, file, metadata, onProgress) => {
    const [task, cancel] = uploadToStorageCancellable(storagePath, file, metadata, onProgress);
    return task;
}

export const getDownloadUrl = (storagePath) => {
    const storage = firebase.storage().ref();
    return storage.child(storagePath).getDownloadURL();
}

export const removeFromStorage = (storagePath) => {
    const storage = firebase.storage().ref();
    const file = storage.child(storagePath);
    return file.delete();
}

export default {
    uploadToStorage, uploadToStorageCancellable, getDownloadUrl, removeFromStorage
}