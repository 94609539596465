import React from "react";

import SetupStep from "./Steps/SetupStep";
import SignupStep from "./Steps/SignupStep";
import PromptsStep from "./Steps/PromptsStep";
import StorytellersStep from "./Steps/StorytellerStep";
import TemplateStep from "./Steps/TemplateStep";
import ReviewStep from "./Steps/ReviewStep";

import { StoryManagerSteps } from "../../types/StoryManagerState";

export const storyManagerSteps: StoryManagerSteps = [
  {
    id: "template",
    component: <TemplateStep id="template" />,
    trackingEvent: "setup_selectedTemplate",
  },
  {
    id: "setup",
    component: <SetupStep id="setup" />,
    trackingEvent: "setup_clickedContinue",
  },
  {
    id: "signup",
    component: <SignupStep id="signup" />,
    trackingEvent: "signup_clickedContinue",
  },
  {
    id: "prompts",
    component: <PromptsStep id="prompts" />,
    trackingEvent: "prompts_clickedContinue",
  },
  {
    id: "review",
    component: <ReviewStep id="review" />,
    trackingEvent: "review_clickedContinue",
  },
  {
    id: "storytellers",
    component: <StorytellersStep id="storytellers" />,
    trackingEvent: "clickLaunch",
  },
];

export default storyManagerSteps;