import React, { useState, useEffect } from 'react';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Spacer,
    Stack,
    Button,
    Heading,
    Text,
    Tag,
    useColorModeValue,
  } from '@chakra-ui/react';
// @ts-ignore
import { useAnalytics } from "use-analytics";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import PageContainer from '../layout/components/PageContainer';
import { useFirestore } from 'react-redux-firebase';

const StatusAlert = ({code, status}) => {

    const statuses = {
        'complete': { status: 'success', title: 'Request Complete', description: "We've successfully removed your account." },
        'running': { status: 'info', title: 'Request is still processing', description: "We're currently in the process of removing your account." },
        'unknown': { status: 'warning', title: 'Status unknown', description: "We've could not retrieve the status for your code. Please check that the code entered is the correct code. Otherwise, please contact support." },
        'error': { status: 'error', title: 'Request Error', description: "We've run into an error removing your account. Please contact support." }
    }

    const detail = statuses[status] || statuses.unknown;

    return (
      <Alert
        status={detail.status}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="left"
        height="200px"
      >
        <AlertIcon />
        <AlertTitle mr={2}>{detail.title}</AlertTitle>
        <AlertDescription>
          <Text>{detail.description}</Text>
          <Text fontWeight="bold" mt={4}>
            Confirmation code: <Tag size={"md"}>{code}</Tag>
          </Text>
        </AlertDescription>
      </Alert>
    );
}

const FacebookDeauthorizationPage = () => {
    const firestore = useFirestore();

    const schema = yup.object().shape({
        code: yup.string().required("Please enter your confirmation code")
    });
    const [loading, setLoading] = useState(true);
    const [request,setRequest] = React.useState();

      const { register, setFocus, setValue, watch, formState } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
      });
      const { errors, isDirty, isValid } = formState;
    
      const [code] = watch(["code"]);
    
      useEffect(() => {
        setFocus("code");
      }, [setFocus]);
    
      useEffect(() => {
        setLoading(false);
      }, []);
    
      // @ts-ignore
      const { track } = useAnalytics();

    const onReset = () => {
        setRequest()
        setValue("code", null, {
            shouldValidate: true,
            shouldDirty: false,
            shouldTouch: false,
          });
        setFocus("code");
    }

    const onSubmit = () =>  {
        isValid && setLoading(true)
        isValid && firestore.collection('deleteUserRequest').doc(code).get().then(doc => {
            if(!doc.exists) setRequest({status: 'missing'})
            else {
                setRequest(doc.data());
            }
            setLoading(false)
        });
    }

    return (<PageContainer>
        <form>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Account Deletion Status</Heading>
          {!request && (<Text fontSize={'lg'} color={'gray.600'}>
            Enter your confirmation code
          </Text>)}
          {request && (<StatusAlert status={request.status} code={code} />)}
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <FormControl id="code">
              <FormLabel>Confirmation Code</FormLabel>
              <Input type="code" {...register("code")} />
            </FormControl>
            <Flex mt={4}>
            <Button type="reset" colorScheme="gray" variant="link" onClick={onReset}>Reset</Button>
            <Spacer/>
            <Button colorScheme="majesty" isLoading={loading}
            onClick={onSubmit}
              disabled={!isDirty || !isValid}
              >
                Check Status
              </Button>
            </Flex>
          </Stack>
        </Box>
      </Stack>
    </form>
    </PageContainer>);
}


export default FacebookDeauthorizationPage;