/* eslint-disable react/prop-types */
import { AddIcon, CheckCircleIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Grid,
  GridItem,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  VisuallyHidden,
} from "@chakra-ui/react";
import React, { FC } from "react";

export const PriceComparisonGrid: FC<any> = () => {
  const colorScaleDark = useColorModeValue("700", "200");
  const borderColor = useColorModeValue("white", "gray.800");
  const { getDisclosureProps, getButtonProps, isOpen } = useDisclosure();
  const buttonProps = getButtonProps();
  const disclosureProps = getDisclosureProps();
  type Feature = {
    text: string;
    diy: boolean;
    concierge: boolean;
    featured?: boolean;
  };
  const features: Feature[] = [
    {
      text: "Exclude specific people or clips",
      diy: true,
      concierge: true,
    },
    {
      text: "Quick turnaround time (<12 hours)",
      diy: true,
      concierge: false,
    },
    {
      text: "Select specific start and end points for each clip",
      diy: true,
      concierge: false,
    },
    {
      text: "Save time! Our editor handles the heavy lifting to create your perfect video",
      diy: false,
      concierge: true,
    },
    {
      text: "Any silence from the beginning or ending of clip trimmed for you",
      diy: false,
      concierge: true,
    },
    {
      text: "We add background music*",
      diy: false,
      concierge: true,
    },
    {
      text: "Basic edits are made to improve and normalize audio and video",
      diy: false,
      concierge: true,
    },
    {
      text: "Upload additional photos or videos to be included in your final movie",
      diy: false,
      concierge: true,
      featured: true,
    },
    {
      text: "Add a custom message or text to your movie",
      diy: false,
      concierge: true,
    },
  ];

  return (
    <Stack p="3" gap={4}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Button
          leftIcon={
            isOpen ? <MinusIcon w={3} h={3} /> : <AddIcon w={3} h={3} />
          }
          colorScheme="airBlue"
          fontWeight="normal"
          variant="link"
          {...buttonProps}
        >
          Show package comparison
        </Button>
      </Box>
      <Box {...disclosureProps} borderRadius="">
        <Grid templateColumns="1fr repeat(2, 120px)" gap={0}>
          <GridItem
            bg={`airBlue.${colorScaleDark}`}
            borderTopColor={borderColor}
            borderLeftColor={borderColor}
            borderTopWidth="10px"
            borderLeftWidth="10px"
            borderTopStartRadius="2xl"
            pt="4"
          >
            <VisuallyHidden>Features</VisuallyHidden>
          </GridItem>
          <GridItem
            borderTopColor={`airBlue.${colorScaleDark}`}
            borderLeftColor={`airBlue.${colorScaleDark}`}
            borderTopWidth="5px"
            borderLeftWidth="5px"
            borderTopStartRadius="lg"
            textAlign="center"
            bg="white"
            pt="4"
            pl="4"
          >
            <Box
              borderBottomColor="gray.200"
              borderBottomWidth="2px"
              borderRightColor="gray.200"
              borderRightWidth="thin"
              p="2"
              pt="0"
            >
              <Text fontWeight="bold">DIY</Text>
            </Box>
          </GridItem>
          <GridItem
            borderTopColor={`airBlue.${colorScaleDark}`}
            borderRightColor={`airBlue.${colorScaleDark}`}
            borderTopWidth="5px"
            borderRightWidth="5px"
            borderTopEndRadius="lg"
            textAlign="center"
            bg="white"
            pt="4"
            pr="4"
          >
            <Box
              borderBottomColor="gray.200"
              borderBottomWidth="2px"
              borderLeftColor="gray.200"
              borderLeftWidth="thin"
              p="2"
              pt="0"
            >
              <Text fontWeight="bold">Concierge</Text>
            </Box>
          </GridItem>
          {features.map((feature, i, arr) => (
            <>
              <GridItem
                bg={`airBlue.${colorScaleDark}`}
                borderLeftColor={borderColor}
                borderLeftWidth="10px"
                pb="2"
                px="8"
              >
                <Text
                  fontSize="smaller"
                  color="white"
                  fontWeight={(feature.featured && "bold") || undefined}
                >
                  {feature.text}
                </Text>
              </GridItem>
              <GridItem
                borderLeftColor={`airBlue.${colorScaleDark}`}
                borderLeftWidth="5px"
                textAlign="center"
                bg="white"
                pl="4"
              >
                <Box
                  borderBottomColor="gray.200"
                  borderBottomWidth={arr.length - 1 === i ? "0" : "2px"}
                  borderRightColor="gray.200"
                  borderRightWidth="thin"
                  p="2"
                  h="full"
                >
                  <VisuallyHidden>{feature.diy.toString()}</VisuallyHidden>
                  {feature.diy && (
                    <CheckCircleIcon color={`airBlue.${colorScaleDark}`} />
                  )}
                </Box>
              </GridItem>
              <GridItem
                borderRightColor={`airBlue.${colorScaleDark}`}
                borderRightWidth="5px"
                textAlign="center"
                bg="white"
                pr="4"
              >
                <Box
                  borderBottomColor="gray.200"
                  borderBottomWidth={arr.length - 1 === i ? "0" : "2px"}
                  borderLeftColor="gray.200"
                  borderLeftWidth="thin"
                  p="2"
                  h="full"
                >
                  <VisuallyHidden>
                    {feature.concierge.toString()}
                  </VisuallyHidden>
                  {feature.concierge && (
                    <CheckCircleIcon color={`airBlue.${colorScaleDark}`} />
                  )}
                </Box>
              </GridItem>
            </>
          ))}
          <GridItem
            bg={`airBlue.${colorScaleDark}`}
            height="20px"
            borderBottomColor={borderColor}
            borderLeftColor={borderColor}
            borderBottomWidth="10px"
            borderLeftWidth="10px"
            borderBottomStartRadius="2xl"
            pb="2"
          ></GridItem>
          <GridItem
            height="20px"
            borderBottomColor={`airBlue.${colorScaleDark}`}
            borderLeftColor={`airBlue.${colorScaleDark}`}
            borderBottomWidth="5px"
            borderLeftWidth="5px"
            borderBottomStartRadius="lg"
            textAlign="center"
            bg="white"
            pb="2"
          ></GridItem>
          <GridItem
            height="20px"
            borderBottomColor={`airBlue.${colorScaleDark}`}
            borderRightColor={`airBlue.${colorScaleDark}`}
            borderBottomWidth="5px"
            borderRightWidth="5px"
            borderBottomEndRadius="lg"
            textAlign="center"
            bg="white"
            pb="2"
          ></GridItem>
        </Grid>
      </Box>
    </Stack>
  );
};

export default PriceComparisonGrid;
