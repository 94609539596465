import moment from 'moment';

export const timestampToDate = (value)  => {
    const isTimestamp = value && value.toString().startsWith("Timestamp");
    return isTimestamp ? value.toDate() : (value || new Date());
} 

export const timestampToMoment = (value) => {
    return moment(timestampToDate(value));
}

export const forceLowercaseProps = (obj) => {
    const json = JSON.stringify(obj);
    return JSON.parse(json, function(prop, value) {
            var lower = prop.toLowerCase().trim();
            if(prop === lower) return value;
            else this[lower] = value;
        });
}