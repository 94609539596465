import { useActor } from "@xstate/react";
import React from "react";

import { Story } from "../../../machines/Response/respondMachine";
import { useAuth, useLocalStorage } from "../../../hooks";
import { RespondMachineInterpreterType } from "../../../machines/Response/respondMachine";
import { Auth } from "../../../types/Authcheck";
import { StoryNotLaunchedView } from "../../ViewStory";
import { useNavigate } from "react-router-dom";

export type StoryViewContext = {
  story: Story;
  auth: Auth;
  service: RespondMachineInterpreterType;
};

type StoryViewContextActions = {
  setAuth: (auth: Auth) => void;
};

const StoryViewContext = React.createContext<
  Partial<StoryViewContext> & StoryViewContextActions
>({
  story: undefined,
  auth: undefined,
  //service: undefined,
  setAuth: (auth: Auth) => {
    console.log(auth);
  },
});

export const useStoryViewContext = () => {
  const context = React.useContext(StoryViewContext);
  if (context === undefined) {
    throw new Error("useStoryContext must be used within a StoryProvider");
  }
  return context as StoryViewContext & StoryViewContextActions;
};

export const useResponseAuth = () => {
  const auth = useAuth();

  const getUserInfo = () => {
    try {
      if (auth.currentUser) {
        const { uid, email, displayName } = auth.currentUser;
        return { uid, email, displayName } as Auth;
      } else return undefined;
    } catch (err) {
      return undefined;
    }
  };

  const [contactInfo, setContactInfo] = useLocalStorage("contactInfo", null);
  const [currentAuth, setCurrentAuth] = React.useState<Auth>(
    (getUserInfo() || contactInfo || undefined) as Auth
  );
  const setResponseAuth = (identity: Auth) => {
    console.log("setResponseAuth called", identity);
    setCurrentAuth(identity);
    setContactInfo(identity);
  };

  React.useEffect(() => {
    if (!currentAuth) {
      if (!!auth && !!auth.currentUser) setResponseAuth(getUserInfo() as Auth);
      else if (!!contactInfo) setCurrentAuth(contactInfo as Auth);
    }
    console.log("useEffect useResponseAuth Component", contactInfo);
  }, []);
  const out: [Auth, (identity: Auth) => void] = [currentAuth, setResponseAuth];

  return out;
};

const { Provider } = StoryViewContext;
const StoryViewProvider: React.FC<
  Pick<StoryViewContext, "story" | "service"> & { children: React.ReactNode }
> = ({
  children,
  story,
  service,
}: Pick<StoryViewContext, "story" | "service"> & {
  children: React.ReactNode;
}) => {
  const [currentAuth, setCurrentAuth] = useResponseAuth();
  console.log("Rendering StoryProvider Component", currentAuth);
  const actor = useActor(service);
  const [state, send] = actor;
  const navigate = useNavigate();

  React.useEffect(() => {
    send({
      type: "START",
      data: { story: story as unknown as any, identity: currentAuth },
    });
  }, []);

  React.useEffect(() => {
    if (state.matches("final")) {
      navigate("./final");
    }
  }, [state.value]);

  return (
    <Provider
      value={{ story, service, auth: currentAuth, setAuth: setCurrentAuth }}
    >
      {state.matches("notLaunched") ? (
        <StoryNotLaunchedView story={story as any} auth={currentAuth} />
      ) : !state.matches("idle") ? (
        children
      ) : (
        <></>
      )}
    </Provider>
  );
};

export default StoryViewProvider;
