export enum DIYVideoState {
  EDITING = "editing",
  PROCESS = "process",
  PROCESSING = "processing",
  COMPLETE = "complete",
}
export enum SubmissionQuality {
  HIGH = "high",
  MEDIUM = "medium",
  LOW = "low",
}

export interface VideoSubmission {
  id: string;
  position: number;
  include: boolean;
  cutFrom?: number; // Seconds: 0
  cutTo?: number; // Seconds: end of video
  promptIndex: number;
  content: {
    muxPlaybackId: string;
    thumbnailUrl: string;
    streamUrl: string;
    highestQuality?: SubmissionQuality;
  };
  submittedBy: {
    email?: string;
    displayName?: string;
  };
}

export function isSubmission(object: any): object is VideoSubmission {
  return "content" in object;
}
export interface Prompt {
  id: string | number;
  idx: number;
  text: string;
  position: number;
  include: boolean;
  duration: number;
  submissions: Array<VideoSubmission>;
  ref?: any;
}

export function isPrompt(object: any): object is Prompt {
  return "text" in object;
}

export interface DIYVideoConfiguration {
  id: string;
  storyId: string;
  createdAt: Date;
  updatedAt: Date;
  state: DIYVideoState;
  paid: boolean;
  title: string;
  titleDuration: number;
  prompts: Array<Prompt>;
}

export interface DIYVideoMachineContext extends DIYVideoConfiguration {
  activeClipMachine?: any;
  prevActiveClipMachine?: any;
}
export interface VideoClipContext {
  id: string | number;
  idx: number;
  type: "prompt" | "submission";
  data: Prompt | VideoSubmission;
  prevData?: Prompt | VideoSubmission;
}

export interface PromptContext extends Prompt {
  id: number | string;
  prevText: string;
  clips: any[];
  isClipActive?: boolean;
}

export type VideoClipMachineEvent =
  | {
      type: "EDIT";
      id: string | number;
    }
  | {
      type: "DONE";
    }
  | {
      type: "CANCEL";
    }
  | {
      type: "CHANGE";
      value: Prompt | VideoSubmission;
    };

export type PromptMachineEvent =
  | {
      type: "TEXT_CHANGE";
      value: string;
    }
  | {
      type: "PROMPT.ACTIVE";
      id: number;
    }
  | {
      type: "CLIP.ACTIVE" | "CLIP.COMMIT" | "CLIP.DONE";
      clip: VideoClipContext;
    };

export type DIYVideoMachineEvent =
  | {
      type: "FETCH";
      arguments: any;
    }
  | {
      type: "CHANGE";
      value: {
        id?: string;
        title?: string;
      };
    }
  | {
      type: "PROCESS";
    }
  | { type: "PROMPT.COMMIT"; prompt: any }
  | {
      type: "DONE";
    }
  | {
      type: "done.invoke.fetchData";
      data: DIYVideoMachineContext;
    };

/*
 * Editly spec documentation: https://github.com/mifi/editly#edit-spec
 */
export enum Positions {
  TOP = "top",
  BOTTOM = "bottom",
  CENTER = "center",
  TOP_LEFT = "top-left",
  TOP_right = "top-right",
  CENTER_LEFT = "center-left",
  CENTER_RIGHT = "center-right",
  BOTTOM_LEFT = "bottom-left",
  BOTTOM_RIGHT = "bottom-right",
}
export interface EditlySpec {
  outPath: string;
  width: number;
  height: number;
  fps: number;
  defaults?: EditlySpecDefaults;
  clips: ClipsEntity[] | null;
  allowRemoteRequests?: boolean;
  keepSourceAudio?: boolean;
  audioNorm?: AudioNorm;
  // Testing options:
  enableFfmpegLog?: boolean;
  verbose?: boolean;
  fast?: boolean;
}
export interface EditlySpecDefaults {
  transition: Transition | null;
  layer?: LayerDefaultEntity;
  duration?: number;
}
export interface Transition {
  name?: string;
  duration?: number;
  audioOutCurve?: string;
  audioInCurve?: string;
}
export interface LayerDefaultEntity {
  fontPath?: string | null;
  textColor?: string;
  background?: Background | null;
}
export interface ClipsEntity {
  duration?: number;
  transition?: Transition | null;
  submissionId?: string;
  layers: (TitleBackgroundLayer | VideoLayer | ImageOverlayLayer)[] | null;
}
export interface TitleLayer {
  type: "title";
  text: string;
  textColor?: string; //HEX COLOR; default: #FFFFFF
  fontPath?: string;
  position?: Positions | Position | null;
}
export interface SubTitleLayer {
  type: "subtitle";
  text: string;
  textColor?: string; //HEX COLOR; default: #FFFFFF
  fontPath?: string;
}
export interface TitleBackgroundLayer {
  type: "title-background";
  text: string;
  textColor?: string; //HEX COLOR; default: #FFFFFF
  background?: Background;
  fontPath?: string;
}
export enum ResizeModes {
  CONTAIN = "contain",
  CONTAIN_WITH_BLUR = "contain-blur",
  COVER = "cover",
  STRETCH = "stretch",
}
export interface VideoLayer {
  type: "video";
  path: string;
  cutFrom?: number; // Seconds: 0 to end of video
  cutTo?: number; // Seconds: end of video
  resizeMode?: ResizeModes;
}
export interface ImageOverlayLayer {
  type: "image-overlay";
  path: string;
  width?: number | null;
  height?: number | null;
  position: Positions | Position;
}
export interface Background {
  type: string;
  color?: string;
  colors?: string[] | null;
}
export interface Position {
  x: number; // Range 0.0-1.0 relative to screen width
  y: number; // Range 0.0-1.0 relative to screen height
  originX?: "left" | "right"; //left
  originY: "top" | "bottom"; //"top"
}
export interface AudioNorm {
  enable: boolean;
  gaussSize: number; // Default: 5
  maxGain: number; //Default: 30
}
