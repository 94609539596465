import boxBg1 from './assets/box-bg-1.svg';
import boxBg2 from './assets/box-bg-2.svg';


export const theme = {
  "name": "QUIL",
  "rounding": 4,
  "spacing": 24,
  "global": {
    "colors": {
      "brand": "#E7AF87",
      "text": {
        "light": "#264654",
        "dark": "#FFFFFF"
      },
      "border": {
        "light": "#264654",
        "dark": "#F9F9F8"
      },
      "accent-1": "#86AC89",
      "accent-2": "#75ADCD",
      "accent-3": "#CD8C97",
      "accent-4": "#9572A1",
      "accent-5": "#426E81",
      "neutral-1": "#86AC89",
      "neutral-2": "#75ADCD",
      "neutral-3": "#CD8C97",
      "neutral-4": "#9572A1",
      "neutral-5": "#979797",
      "neutral-6": "#FFFFFF",
      "control": {
        "light": "#264654",
        "dark": "#E7AF87"
      },
      "dark-1": "#264654",
      "dark-2": "#494F52",
      "dark-3": "#979797",
      "dark-4": "#F1F0EA",
      "light-1": "#F1F0EA",
      "light-2": "#F9F8F2",
      "light-3": "#EEEEEC",
      "light-4": "#CDC8CF",
      "light-5": "#DDDCD7",
      "menu": "#426E81",
      "menu-disabled": "#8fbbce",
      "status-critical": "#D95B6A",
      "status-warning": "#FBC333",
      "status-ok": "#A9D95B",
      "status-unknown": "#CCCCCC",
      "status-waiting": "#AAAAAA",
      "background" : {
        "light": "#FFFFFF",
        "dark": "#264654"
      },
      "background-contrast": {
        "dark": "#F9F8F2",
        "light": "#264654"
      },
      "background-light": "#F9F8F2",
      "focus": "#938C96",
      "flag": "#F9EA63",
    },
    "font": {
      "family": "Libre Franklin",
      "size": "14px",
      "height": "24px",
      "maxWidth": "432px"
    },
    "control": {
      "border": {
        "radius": "4px"
      }
    },
    "borderSize": {
      "xsmall": "1px",
      "small": "2px",
      "medium": "4px",
      "large": "12px",
      "xlarge": "24px"
    },
    "breakpoints": {
      "small": {
        "value": 768,
        "borderSize": {
          "xsmall": "1px",
          "small": "2px",
          "medium": "4px",
          "large": "6px",
          "xlarge": "12px"
        },
        "edgeSize": {
          "none": "0px",
          "hair": "1px",
          "xxsmall": "2px",
          "xsmall": "3px",
          "small": "6px",
          "medium": "12px",
          "large": "24px",
          "xlarge": "48px"
        },
        "size": {
          "xxsmall": "24px",
          "xsmall": "48px",
          "small": "96px",
          "medium": "192px",
          "large": "384px",
          "xlarge": "768px",
          "full": "100%"
        }
      },
      "medium": {
        "value": 1536
      },
      "large": {}
    },
    "edgeSize": {
      "none": "0px",
      "hair": "1px",
      "xxsmall": "3px",
      "xsmall": "6px",
      "small": "12px",
      "medium": "24px",
      "large": "48px",
      "xlarge": "96px",
      "responsiveBreakpoint": "small"
    },
    "input": {
      "padding": "12px",
      "weight": "unset"
    },
    "spacing": "24px",
    "size": {
      "xxsmall": "48px",
      "xsmall": "96px",
      "smaller": "144px",
      "small": "192px",
      "medium": "384px",
      "large": "768px",
      "xlarge": "1152px",
      "xxlarge": "1536px",
      "full": "100%"
    }
  },
  "heading": {
    "level": {
      "1": {
        "font": {
            "weight":500,
            "lineHeight": "115%"
        },
        "small": {
          "size": "34px",
          "height": "33.6px",
          "maxWidth": "816px"              
        },
        "medium": {
          "size": "48px",
          "height": "58px",
          "maxWidth": "1200px"
        },
        "large": {
          "size": "82px",
          "height": "88px",
          "maxWidth": "1968px"
        },
        "xlarge": {
            "size": "114px",
            "height": "120px",
            "maxWidth": "2736px"
        }
      },
      "2": {
        "small": {
          "size": "22px",
          "height": "32px",
          "maxWidth": "624px"
        },
        "medium": {
          "size": "28px",
          "height": "33.6px",
          "maxWidth": "816px"
        },
        "large": {
          "size": "50px",
          "height": "56px",
          "maxWidth": "1200px"
        },
        "xlarge": {
          "size": "66px",
          "height": "72px",
          "maxWidth": "1584px"
        }
      },
      "3": {
        "small": {
          "size": "22px",
          "height": "28px",
          "maxWidth": "528px",          
        },
        "medium": {
          "size": "28px",
          "height": "32px",
          "maxWidth": "624px",
        },
        "large": {
          "size": "34px",
          "height": "40px",
          "maxWidth": "816px"
        },
        "xlarge": {
          "size": "42px",
          "height": "48px",
          "maxWidth": "1008px"
        },
        "font": {
          "family": "Playfair Display",
          "weight": 400
        }
      },
      "4": {
        "small": {
          "size": "18px",
          "height": "24px",
          "maxWidth": "432px"
        },
        "medium": {
          "size": "19px",
          "height": "24px",
          "maxWidth": "432px"
        },
        "large": {
          "size": "28px",
          "height": "34px",
          "maxWidth": "432px"
        },
        "xlarge": {
          "size": "34px",
          "height": "40px",
          "maxWidth": "816px"
        },
        "font": {
          "family": "Playfair Display"
        }
      },
      "5": {
        "small": {
          "size": "16px",
          "height": "22px",
          "maxWidth": "384px"
        },
        "medium": {
          "size": "18px",
          "height": "22px",
          "maxWidth": "384px"
        },
        "large": {
          "size": "18px",
          "height": "22px",
          "maxWidth": "384px"
        },
        "xlarge": {
          "size": "28px",
          "height": "34px",
          "maxWidth": "432px"
        },
        "font": {
          "weight": 300
        }
      },
      "6": {
        "small": {
          "size": "14px",
          "height": "20px",
          "maxWidth": "336px"
        },
        "medium": {
          "size": "16px",
          "height": "20px",
          "maxWidth": "336px"
        },
        "large": {
          "size": "18px",
          "height": "20px",
          "maxWidth": "336px"
        },
        "xlarge": {
          "size": "18px",
          "height": "20px",
          "maxWidth": "336px"
        }
      }
    },
    "font": {
      "family": "Libre Franklin"
    }
  },
  "icon": {
    "size": {
      "xsmall": '12px',
      "small": '14px',
      "medium": '16px',
      "xmedium": '20px',
      "large": '24px',
      "xlarge": '48px',
      "xxlarge": "96px"
    },
    "extend": undefined,
  },
  "button": {
    "border": {
      "radius": "50px",
      "color": "transparent"
    },
    "padding": {
      "vertical":"10px"
    },
    "text": {
      "light": "#264654",
      "dark": "#FFFFFF",
    },
    "primary": {
      "color": {
        "light": "#E7AF87",
        "dark": "#FFFFFF"
    },
    "small": {
        "width":"10px"
        }
    },
  },
  "checkBox": {
    "check": {
      "radius": "4px"
    },
    "toggle": {
      "radius": "4px"
    }
  },
  "radioButton": {
    "size": "18px",
    "check": {
      "radius": "100%"
    }
  },
  "rangeInput":{ "track": { "color":"dark-3" } },
  "calendar": {
    "small": {
      "fontSize": "14px",
      "lineHeight": 1.375,
      "daySize": "27.428571428571427px"
    },
    "medium": {
      "fontSize": "18px",
      "lineHeight": 1.45,
      "daySize": "54.857142857142854px"
    },
    "large": {
      "fontSize": "30px",
      "lineHeight": 1.11,
      "daySize": "109.71428571428571px"
    }
  },
  "clock": {
    "analog": {
      "hour": {
        "width": "8px",
        "size": "24px"
      },
      "minute": {
        "width": "4px",
        "size": "12px"
      },
      "second": {
        "width": "3px",
        "size": "9px"
      },
      "size": {
        "small": "72px",
        "medium": "96px",
        "large": "144px",
        "xlarge": "216px",
        "huge": "288px"
      }
    },
    "digital": {
      "text": {
        "xsmall": {
          "size": "10px",
          "height": 1.5
        },
        "small": {
          "size": "14px",
          "height": 1.43
        },
        "medium": {
          "size": "18px",
          "height": 1.375
        },
        "large": {
          "size": "22px",
          "height": 1.167
        },
        "xlarge": {
          "size": "26px",
          "height": 1.1875
        },
        "xxlarge": {
          "size": "34px",
          "height": 1.125
        }
      }
    }
  },
  "paragraph": {
    "xsmall": {
      "size": "14px",
      "height": "18px",
      //"maxWidth": "244px"
    },
    "small": {
      "size": "16px",
      "height": "20px",
      //"maxWidth": "336px"
    },
    "medium": {
      "size": "18px",
      "height": "24px",
      //"maxWidth": "432px"
    },
    "large": {
      "size": "22px",
      "height": "28px",
      //"maxWidth": "528px"
    },
    "xlarge": {
      "size": "26px",
      "height": "32px",
      //"maxWidth": "624px"
    },
    "xxlarge": {
      "size": "34px",
      "height": "40px",
      //"maxWidth": "816px"
    },
    "extend": "font-weight:100"
  },
  "text": { 
    "xxsmall": {
      "size": "12px",
      "height": "14px",
      "maxWidth": "288px"
    },
    "xsmall": {
      "size": "14px",
      "height": "18px",
      "maxWidth": "288px"
    },
    "small": {
      "size": "16px",
      "height": "20px",
      "maxWidth": "336px"
    },
    "medium": {
      "size": "18px",
      "height": "24px",
      "maxWidth": "432px"
    },
    "large": {
      "size": "22px",
      "height": "28px",
      "maxWidth": "528px"
    },
    "xlarge": {
      "size": "26px",
      "height": "32px",
      "maxWidth": "624px"
    },
    "xxlarge": {
      "size": "34px",
      "height": "40px",
      "maxWidth": "816px"
    }
  },
  "anchor": {
      "fontWeight": 600,
      "textDecoration": "underline",
      "hover": {
        "textDecoration": "none"
      }
  },
  "select": {
      "icons": {
          "color": "dark-1",
      },
      "background": "white"
  },
  "formField": {
      "border": {
          "position": "outer",
          "side":"none"
      },
      "extend": (props) => { 
        return "border-color:transparent;border-radius : 8px; box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.12); background-color:#FFFFFF;color:#000000"
      }
  },
  "layer": {
    "background":{"dark": "white", "light": "white"}
  }
};

const box_themes = [
  { 
      "box" : {
        "dark": true,
        "color": "neutral-1",
        "position": "center",
        "image": `url(${boxBg2})`
      },
      "button" : "light-1",
      "icon" : "dark-1",
      "accent": "neutral-4"
  },
  { 
      "box" : {
        "dark": true,
        "color": "neutral-4",
        "position": "center",
        "image": `url(${boxBg1})`
      },
      "button" : "light-1",
      "icon" : "dark-1",
      "accent": "neutral-1"
  },
  { 
      "box" : {
        "dark": true,
        "color": "neutral-2",
        "position": "center",
        "image": `url(${boxBg2})`
      },
      "button" : "light-1",
      "icon" : "dark-1",
      "accent": "neutral-3"
  },
  { 
      "box" : {
        "dark": true,
        "color": "neutral-3",
        "position": "center",
        "image": `url(${boxBg1})`
      },
      "button" : "light-1",
      "icon" : "dark-1",
      "accent": "neutral-2"
  },
  { 
      "box" : {
        "dark": true,
        "color": "brand",
        "position": "center",
        "image": `url(${boxBg1})`
      },
      "button" : "light-1",
      "icon" : "dark-1",
      "accent": "neutral-2"
  }
];

export const getBoxTheme = (i) => {
  const limit = box_themes.length-1
  const idx = i % limit;
  return box_themes[idx];
}

export const getBoxThemeWithColor = (i, color) => {
  const boxTheme = getBoxTheme(i);
  const newTheme = { ...boxTheme, box: { ...boxTheme.box, color }};
  
  return newTheme;
}


export default theme;