import React from "react";

import { AnalyticsInstance } from "analytics";
import { AnalyticsProvider } from "use-analytics";

import { useLocation } from "react-router-dom";
import { matchPath } from "react-router-dom";
import AnalyticsInitializer from "./AnalyticsInitializer";

type AnalyticsRule = {
  plugins: string & Array<any>;
  route: string;
  enabled: boolean;
};

type RoutableAnalyticsConfig = {
  instance: AnalyticsInstance;
  rules: Array<AnalyticsRule>;
};

interface RuleMatch extends AnalyticsRule {
  matched?: boolean;
}

function isAnalyticsInstance(object: any): object is AnalyticsInstance {
  return !!object && "plugins" in object;
}

export const RoutableAnalytics = ({
  children,
  config,
}: {
  children: any;
  config: RoutableAnalyticsConfig;
}) => {
  const { rules, instance } = config;
  const location = useLocation();
  const [ready,setReady] = React.useState(false);

  const evaluate = React.useCallback(() => {
    console.log('RoutableAnalytics.evaluate')
    const _rules: Array<RuleMatch> = [...rules] as Array<RuleMatch>;
    const toggle = _rules
      .map((rule) => {
        if (!!rule.matched) return rule; //If rule has already evaluated as true.
        const matched = matchPath(rule.route, location.pathname);
        console.log({rule, matched, location});
        rule = { ...rule, matched: !!matched };
        return rule;
      })
      .filter((rule) => rule.matched);
    toggle.forEach((rule) => {
      if (rule.enabled)
        instance.plugins.enable(rule.plugins, () => {
          console.log("Enabled analytics for", rule.plugins);
        });
      else
        instance.plugins.disable(rule.plugins, () => {
          console.log("Disabled analytics for", rule.plugins);
        });
    });
  }, [location, rules]);

  React.useEffect(() => {
    const listeners: Array<() => void> = [];
    if(instance){
      console.group('Analytics')
      console.assert(
        isAnalyticsInstance(instance),
        "'analytics' is not an instance of AnalyticsInstance!"
      );
      console.groupEnd()
      listeners.push(
        instance.on("ready", ({ payload, ...rest }) => {
          setReady(true);
        })
      );
      listeners.push(
        instance.on("enablePlugin", ({ payload, ...rest }: any) => {
          console.group('Analytics')
          console.log("enablePlugin.payload", payload, rest);
          console.groupEnd()
        })
      );
      listeners.push(
        instance.on("disablePlugin", ({ payload, ...rest }: any) => {
          console.group('Analytics')
          console.log("disablePlugin.payload", payload, rest);
          console.groupEnd()
        })
      );
    }
    return () => {
      listeners.forEach((listener) => listener());
    };
  }, []);

  React.useEffect(() => {
    console.group('Analytics')
    if(!!ready) evaluate();
    console.groupEnd()
  }, [location, ready]);

  if(!instance) return children;
  return (
    <AnalyticsProvider instance={instance}>
      {children && children}
    </AnalyticsProvider>
  );
};

export default function withRoutableAnalytics(
  WrappedComponent: any
) {
  return class extends React.Component {
    displayName = "withRoutableAnalytics";
    constructor(props: any) {
      super(props);
    }

    render() {
      return (<AnalyticsInitializer>
          {(config:RoutableAnalyticsConfig) => (
          <RoutableAnalytics config={config} {...this.props}>
            <WrappedComponent />
          </RoutableAnalytics>
          )}
        </AnalyticsInitializer>);
    }
  };
}
