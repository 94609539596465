
const initState = {
    watchedVideo: false,
    submissionComplete: undefined
}

const storytellerReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SET_WATCHED_VIDEO': {
          state.watchedVideo = action.watched;
          return { ...state };
        }
        case 'OPEN_SUBMISSION_SAVED':
        case 'SUBMISSION_SAVED': {
            state.submissionComplete = action.submission;
            return { ...state };
        }
        default:
            return state;
      }  
}


export default storytellerReducer;