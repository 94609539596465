// Import Rollout SDK
import * as Rox from "rox-browser";
import { FeatureFlags } from "../components/featureFlags/types";
import {
  isIOS,
  isAndroid,
  isChrome,
  isSafari,
  deviceType,
  browserName,
  browserVersion,
} from "react-device-detect";
import firebase from "firebase";

// Create a Roxflag in the flags container class
export const flags: Rox.RoxContainer & FeatureFlags = {
  iosForceFileUpload: new Rox.Flag(),
  includePricingInSetupStep: new Rox.RoxNumber(0),
  conciergeAnnouncementLanguage: new Rox.RoxString(
    `{ "copy": "Make your final movie even more special! We recently released the ability to upload additional photos and videos to be included in your final movie. This feature is concierge-only for now.", "cta": "Learn more about our concierge offering" }`
  ),
  allowSMS: new Rox.Flag(true),
};

const initialized: string[] = [];

export interface RolloutProps {
  namespace: string;
  apiKey: string;
  debug?: boolean;
}

async function initRollout({ namespace, apiKey, debug }: RolloutProps) {
  debug && Rox.showOverrides("bottom right" as any);
  if (initialized.some((init) => init === namespace)) return flags;
  const options: Rox.RoxSetupOptions = {
    debugLevel: (debug && "verbose") || undefined,
  };

  Rox.register(namespace, flags);

  //Rox.setCustomNumberProperty("numberProperty", 100)
  Rox.setCustomStringProperty("deviceType", deviceType);
  Rox.setCustomStringProperty("browserName", browserName);
  Rox.setCustomStringProperty("browserVersion", browserVersion);
  Rox.setCustomBooleanProperty("isIOS", isIOS);
  Rox.setCustomBooleanProperty("isAndroid", isAndroid);
  Rox.setCustomBooleanProperty("isSafari", isSafari);
  Rox.setCustomBooleanProperty("isChrome", isChrome);

  firebase.auth().onAuthStateChanged((user) => {
    if (!!user) {
      user.email && Rox.setCustomStringProperty("email", user.email);
      user.emailVerified &&
        Rox.setCustomBooleanProperty("emailVerified", user.emailVerified);
      user.providerId &&
        Rox.setCustomStringProperty("authProvider", user.providerId);
    }
  });

  await Rox.setup(apiKey, options);

  Rox.unfreeze(namespace);

  initialized.push(namespace);

  return flags;
}

export default initRollout;
