import React from "react";
import { useShopifyDispatch } from "../../shopify/ShopifyProvider";
import { isEmpty, isLoaded } from "react-redux-firebase";
import useVerifiedPartners from "../hooks/useVerifiedPartners";

const ApplyPartnerCoupons = () => {
  const partnerCoupons = useVerifiedPartners();
  const dispatch = useShopifyDispatch();

  React.useEffect(() => {
    if (
      isLoaded(partnerCoupons) &&
      !isEmpty(partnerCoupons) &&
      partnerCoupons.length === 1
    ) {
      console.log("Auto-apply partner coupon", partnerCoupons[0]);
      dispatch({
        type: "addDiscount",
        payload: { discountCode: partnerCoupons[0].coupon },
      });
    }
  }, [partnerCoupons]);

  return null; //TODO: if more than one partner, show a list of coupons to choose from.
};

export default ApplyPartnerCoupons;
