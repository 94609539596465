import React, {Component} from 'react';
import ProductCard from './ProductCard';
import VariantSelector from './VariantSelector';

class Product extends Component {
  constructor(props) {
    super(props);

    let defaultOptionValues = {};
    this.props.product.options.forEach((selector) => {
      defaultOptionValues[selector.name] = selector.values[0].value;
    });
    this.state = { selectedOptions: defaultOptionValues };

    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.findImage = this.findImage.bind(this);
  }

  findImage(images, variantId) {
    const primary = images[0];

    const image = images.filter(function (image) {
      return image.variant_ids.includes(variantId);
    })[0];

    return (image || primary).src;
  }

  handleOptionChange(event) {
    const target = event.target
    let selectedOptions = this.state.selectedOptions;
    selectedOptions[target.name] = target.value;

    const selectedVariant = this.props.client.product.helpers.variantForOptions(this.props.product, selectedOptions)

    this.setState({
      selectedVariant: selectedVariant,
      selectedVariantImage: selectedVariant.attrs.image
    });
  }

  handleQuantityChange(event) {
    this.setState({
      selectedVariantQuantity: event.target.value
    });
  }

  findVariantInCart(variant) {
    const found = this.props.checkout && this.props.checkout.lineItems && [...this.props.checkout.lineItems].find(item => {
      return item.variant.id === variant.id;
    })
    console.log({client:this.props.client,checkout:this.props.checkout,found,variant});
    return found;
  }

  render() {

    let variantImage = this.state.selectedVariantImage || this.props.product.images[0]
    let variant = this.state.selectedVariant || this.props.product.variants[0]
    let variantQuantity = this.state.selectedVariantQuantity || 1
    let variantInCart = this.findVariantInCart(variant);
    let variantSelectors = this.props.product.options.map((option) => {
      return (
        <VariantSelector
          handleOptionChange={this.handleOptionChange}
          key={option.id.toString()}
          option={option}
        />
      );
    });

    let buttonAction = !!variantInCart ? () => this.props.removeLineItemInCart(variantInCart.id) : () => this.props.addVariantToCart(variant.id, variantQuantity)


    const { title, description } = this.props.product
    const {price} = variant;
    const{src} = variantImage;
    return (<ProductCard product={{title, description, price, image: src, inCart: variantInCart }} onClick={buttonAction}>{variantSelectors}</ProductCard>)

    /*
    const responsiveSettings = {
      xsmall: {
        container: {
          direction: "column",
          justify:"between",
          align:"center",
          pad:{"vertical": "large","horizontal":"medium"},
          flex:false
        },
        body: {
          container: {
            justify:"center",
            align:"center",
            fill:"horizontal"
          },
          content: {
            justify:"center",
            align:"center",
            fill:"horizontal"
          },
          image: {
            width: "220px",
            height: "220px"
          }
        },
        actions: {
          fill:"horizontal",
          direction:"column",
          align:"center",
          justify:"center",
          pad:"small",
          flex:{shrink:0}
        }
      },
      small: {
        container: {
          direction: "column",
          justify:"between",
          align:"center",
          pad:{"vertical": "large","horizontal":"medium"}
        },
        body: {
          container: {
            justify:"center",
            align:"center",
            fill:"horizontal"
          },
          content: {
            justify:"center",
            align:"center",
            fill:"horizontal"
          },
          image: {
            width: "220px",
            height: "220px"
          }
        },
        actions: {
          fill: "horizontal"
        }
      },
      medium: {
        container: {
          direction: "row",
          justify:"between",
          align:"center",
          pad:{"vertical": "medium","horizontal":"medium"}
        },
        body: {
          container: {
            direction: "row",
            justify:"center",
            align:"center",
            fill:"vertically",
            basis: "auto"
          },
          content: {
            justify:"center",
            align:"center",
            fill:true
          },
          image: {
            width: "180px",
            height: "180px"
          }
        },
        actions: {
          fill: "vertically",
          justify:"center",
          align:"center",
          basis: "1/4"
        }
      },
      large: {
        container: {
          direction: "row",
          justify:"between",
          align:"center",
          pad:{"vertical": "medium","horizontal":"medium"}
        },
        body: {
          container: {
            direction: "row",
            justify:"center",
            align:"center",
            fill:"vertically",
            basis: "auto"
          },
          content: {
            justify:"center",
            align:"center",
            fill:true
          },
          image: {
            width: "180px",
            height: "180px"
          }
        },
        actions: {
          fill: "vertically",
          justify:"center",
          align:"center",
          basis: "1/4"
        }
      },
      xlarge: {
        container: {
          direction: "row",
          justify:"between",
          align:"center",
          pad:{"vertical": "medium","horizontal":"medium"}
        },
        body: {
          container: {
            direction: "row",
            justify:"center",
            align:"center",
            fill:"vertically",
            basis: "auto"
          },
          content: {
            justify:"center",
            align:"center",
            fill:true
          },
          image: {
            width: "180px",
            height: "180px"
          }
        },
        actions: {
          fill: "vertically",
          justify:"center",
          align:"center",
          basis: "1/4"
        }
      }
    };
    return (<ResponsiveContext.Consumer>
      {(size) => (
        <Box
            background="white"
            border={{ "color": "light-4", "size": "small", "style": "solid", "side": "all" }}
            elevation="small"
            round="small"
            { ...responsiveSettings[size].container}
        >
          <Box
              { ...responsiveSettings[size].body.container}>
              {this.props.product.images.length ? <Box { ...responsiveSettings[size].body.image}><Image src={variantImage.src} alt={`${this.props.product.title} product shot`} fit="contain" fill={true} /></Box> : null}
              <Box { ...responsiveSettings[size].body.content}>
                <Heading level={2} color="accent-1" margin={{"bottom":"none"}} textAlign="center">{this.props.product.title}</Heading>
                <Text color="accent-1" textAlign="center" margin={{"bottom":"small"}}>${variant.price}</Text>
                <Paragraph textAlign="center" margin={{"bottom":"small"}}>{this.props.product.description}</Paragraph>
                {variantSelectors}
              </Box>
          </Box>
          <Box { ...responsiveSettings[size].actions}>
              <Button label={<Text color="white" weight="bold" size="small">{buttonLabel}</Text>} color={!variantInCart&&"accent-1"} primary={true} onClick={buttonAction} />
          </Box>
      </Box>)}
    </ResponsiveContext.Consumer>);
    */
  }
}

export default Product;
