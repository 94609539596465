import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Flex,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import * as gromicons from "grommet-icons";

import MenuButton from "../../layout/components/MenuButton";
import BackButton from "../layout/BackButton";

import { ReactComponent as Logo } from "../../assets/quilted-color.svg";
import ColoredSvg from "../layout/ColoredSVG";
import { useStoryManager } from "./StoryManager";
import { isEmpty } from "react-redux-firebase";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavbarMenuContext = React.createContext({
  visible: false,
  setVisible: (isVisible) => {
    null;
  },
});

const CreateStoryMenu = ({ onSaveFn }) => {
  return (
    <NavbarMenuContext.Consumer>
      {({ visible, setVisible }) =>
        visible && (
          <Drawer
            placement="top"
            onClose={() => setVisible(false)}
            isOpen={visible}
          >
            <DrawerOverlay />
            <DrawerContent>
              <HStack
                direction="column"
                align="center"
                justify="stretch"
                py="sm"
                px="md"
                spacing="sm"
              >
                <Button
                  leftIcon={<gromicons.FormPrevious size="large" />}
                  fill="horizontal"
                  onClick={() => setVisible(false)}
                  variant="plain"
                />
                <Stack p="sm" alignItems="stretch" w="full" spacing="sm">
                  <MenuButton
                    label="Save and continue"
                    onClick={() => {
                      onSaveFn(false);
                      setVisible(false);
                    }}
                    icon={
                      <Box px="sm">
                        <FontAwesomeIcon icon="sd-card" size="1.25em" />
                      </Box>
                    }
                  />
                  <MenuButton
                    label="Save and exit setup"
                    onClick={() => {
                      console.log("Save and Exit Setup!");
                      onSaveFn(true);
                      setVisible(false);
                    }}
                    icon={
                      <Box px="sm">
                        <FontAwesomeIcon icon="sign-out-alt" size="1.25em" />
                      </Box>
                    }
                  />
                </Stack>
              </HStack>
            </DrawerContent>
          </Drawer>
        )
      }
    </NavbarMenuContext.Consumer>
  );
};

const CreateStoryMenuButton = ({ disabled, isLoading }) => {
  return (
    <NavbarMenuContext.Consumer>
      {({ visible, setVisible }) => (
        <Button
          variant="plain"
          disabled={disabled}
          onClick={() => {
            setVisible(!visible);
          }}
          rightIcon={<FontAwesomeIcon icon="sd-card" size="1.25em" />}
          isLoading={isLoading}
        >
          <span>save</span>
        </Button>
      )}
    </NavbarMenuContext.Consumer>
  );
};

const check = (variable) => {
  const isBoolean = typeof variable === "boolean";
  const isObject = typeof variable === "object";
  const notEmpty = !isEmpty(variable);
  const notTrue = !variable;
  const allKeysValid =
    isObject && Object.keys(variable).every((k) => variable[k]);
  const absValue = !!variable;
  return isBoolean
    ? absValue
    : isObject && notEmpty && (notTrue || allKeysValid);
};

const getValidation = (id, obj) => {
  const isBoolean = typeof obj === "boolean";
  if (isBoolean) return obj;
  const isObject = typeof obj === "object";
  if (!id || isEmpty(obj)) return true;
  if (isObject && obj.hasOwnProperty(id)) return obj[id];
  return obj || true;
};

const CreateStoryNavbar = ({ step, canGoBack, running, ...props }) => {
  const [context, dispatch] = useStoryManager();
  const {
    state: { steps, valid, authChecks },
  } = context;
  const [visible, setVisible] = React.useState(false);

  const navigate = useNavigate();

  const validation = { valid: getValidation(step.id, valid) };
  const isValid = check(validation.valid);

  const exit = () => {
    navigate("/my-stories");
  };

  const onClick = (id, shouldExit) => {
    dispatch({
      type: "save",
      payload: { story: { ...context.state.story, step: id } },
      callback: shouldExit
        ? exit
        : () => {
            null;
          },
    });
    console.log("save and exit");
  };

  const LogoBox = ({ asLink = false }) => {
    return (
      <Box flexBasis="1/2" display="flex" flexGrow={0}>
        {asLink ? (
          <a href="https://quilted.io" a11yTitle="Go to quilted.io website">
            <ColoredSvg width={{ min: "100px", max: "200px" }}>
              <Logo />
            </ColoredSvg>
          </a>
        ) : (
          <ColoredSvg width={{ min: "100px", max: "200px" }}>
            <Logo />
          </ColoredSvg>
        )}
      </Box>
    );
  };

  const indexOf = (step, steps) => {
    const inSteps = steps.map((s) => s.id);
    return inSteps.indexOf(step.id);
  };

  return (
    <NavbarMenuContext.Provider value={{ visible, setVisible }}>
      <Flex direction="row" align="center" justify="between" py="md" {...props}>
        <Flex
          as="header"
          direction="row"
          align="center"
          justify="space-between"
          w="full"
        >
          <Flex flexBasis="25%" direction="row" justify="flex-start">
            {((authChecks && authChecks.signedIn) ||
              indexOf(step, steps) > 0) && (
              <BackButton onClick={props.onClickPrevious} disabled={running || !canGoBack} />
            )}
          </Flex>
          <LogoBox
            flexBasis="50%"
            flexGrow={1}
            asLink={!indexOf(step, steps)}
          />
          <Flex flexBasis="25%" display="flex" justify="flex-end">
            {authChecks && authChecks.signedIn && !authChecks.isAnon ? (
              <CreateStoryMenuButton disabled={!isValid || running} />
            ) : (
              <Button variant="plain" onClick={() => navigate("/signin")}>
                <Text variant="serif" fontWeight="bold">
                  <span>Sign In</span>
                </Text>
              </Button>
            )}
          </Flex>
        </Flex>
        <CreateStoryMenu
          onSaveFn={(shouldExit) => onClick(step.id, shouldExit)}
        />
      </Flex>
    </NavbarMenuContext.Provider>
  );
};

export default CreateStoryNavbar;
