/* eslint-disable react/prop-types */
import React from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from "@chakra-ui/react";
import InviteLinkBox from "../../invites/InviteLinkBox";
import { Storyteller } from "../../../machines/Dashboard/invitationsMachine";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClipCircle from "../responses/ClipCircle";
import { Player } from "../../video/Player";
import { MotionTr } from "../../common/Motions";
import { usePresence } from "framer-motion";
import { useAnalytics } from "use-analytics";

const ResponsesRow = ({
  storyteller,
  colorScheme,
  onClick,
}: {
  storyteller: Storyteller;
  colorScheme: string;
  onClick: (
    videos: Array<{ url: string; text: string; type: "image" | "video" }>
  ) => void;
}) => {
  const { track } = useAnalytics();

  return (
    <Flex
      dir="row"
      flexWrap="wrap"
      display="inline-flex"
      gap="10px"
      css={{ gap: "8px" }}
      width="full"
    >
      {storyteller.responses && !!storyteller.responses.length && (
        <Tooltip
          label="Play all"
          aria-label={`Play all videos for "${storyteller.name}"`}
          colorScheme={colorScheme}
          hasArrow
        >
          <IconButton
            icon={<FontAwesomeIcon icon={"play"} />}
            onClick={() => {
              track("dashboard_userPlayedAll_recipient", {
                responses: storyteller.responses?.length || 0,
              });
              storyteller.responses &&
                onClick(
                  storyteller.responses.map((response) => ({
                    url: response.url,
                    text: storyteller.name,
                    type: response.type,
                  }))
                );
            }}
            aria-label="play/pause button"
            m="0"
            w="40px"
            h="40px"
            rounded="full"
            colorScheme={colorScheme}
          />
        </Tooltip>
      )}
      {storyteller.responses &&
        !!storyteller.responses.length &&
        storyteller.responses?.map((response) => (
          <ClipCircle
            colorScheme={response.processing ? "gray" : "airBlue"}
            key={response.id}
            thumbnail={response.processing ? "" : response.thumbnail}
            name={response.name}
            showPulse={response.processing}
            disabled={response.processing}
            icon={
              response.type === "image"
                ? ["far", "eye"]
                : response.processing
                ? "spinner"
                : undefined
            }
            size="sm"
            onClick={() => {
              if (!response.processing)
                onClick([
                  {
                    url: response.url,
                    text: storyteller.name,
                    type: response.type,
                  },
                ]);
            }}
          />
        ))}
    </Flex>
  );
};

export const MotionRow: React.FC<{ key: any }> = ({ children }) => {
  const [isPresent, safeToRemove] = usePresence();
  const transition = { type: "spring", stiffness: 500, damping: 50, mass: 1 };
  const animations = {
    layout: true,
    initial: "out",
    style: {
      position: isPresent ? "static" : "absolute",
    },
    animate: isPresent ? "in" : "out",
    whileTap: "tapped",
    variants: {
      in: { scaleY: 1, opacity: 1, transition: { duration: 0.5 } },
      out: { scaleY: 0, opacity: 0, zIndex: -1, transition: { duration: 0.5 } },
      tapped: { scale: 0.98, opacity: 0.5, transition: { duration: 0.5 } },
    },
    onAnimationComplete: () => !isPresent && safeToRemove && safeToRemove(),
    transition,
  };
  //@ts-ignore
  return <MotionTr {...animations}>{children}</MotionTr>;
};

interface ShowLinkViewProps {
  colorScheme: string;
  slug: string;
  storytellers: Storyteller[];
  promptCount: number;
  onClick: () => void;
}
export const CompletedView: React.FC<ShowLinkViewProps> = ({
  colorScheme,
  slug,
  storytellers,
  promptCount,
  onClick,
}: ShowLinkViewProps) => {
  const limit = 5;
  const [showing, setShowing] = React.useState(limit);

  const [playlist, setPlaylist] =
    React.useState<{ url: string; text: string }[] | null>(null);

  return (
    <Box>
      <Heading as="h4" size="md" mb={3}>
        Invite storytellers
      </Heading>
      <Text mb={3}>
        See who’s responded to your story and the status of the people whom
        you&apos;ve invited.
      </Text>
      <Text mb={3}>
        You can invite more people by sending them your story link or inviting
        them using the button below.
      </Text>
      {playlist && (
        <Player onClose={() => setPlaylist(null)} playlist={playlist} />
      )}
      <InviteLinkBox slug={slug} hideDisclaimer hideLabel mb={6} />
      {!storytellers.length && (
        <VStack gap={4} mb={6}>
          <Box
            alignItems="center"
            maxW="548px"
            bg="white"
            px={8}
            py={12}
            borderWidth="thin"
            borderColor={`${colorScheme}.500`}
          >
            <Text fontSize="xs">
              You&apos;ll see a list of storytellers here once you send invites
              or receive responses.
            </Text>
          </Box>
          <Divider color="black" />
        </VStack>
      )}
      {!!storytellers.length && (
        <TableContainer mb={3}>
          <Table size="sm" variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th textColor="blackAlpha.600">Responses</Th>
              </Tr>
            </Thead>
            <Tbody>
              {storytellers.slice(0, showing).map((storyteller, index) => (
                <MotionRow key={`${storyteller.name}_${index}`}>
                  <Td
                    {...(index === storytellers.slice(0, showing).length - 1
                      ? { borderColor: "gray.800" }
                      : { borderColor: "gray.300" })}
                  >
                    {storyteller.name}
                  </Td>
                  <Td
                    {...(index === storytellers.slice(0, showing).length - 1
                      ? { borderBottomColor: "gray.800" }
                      : { borderBottomColor: "gray.300" })}
                  >
                    {storyteller.responses && !!storyteller.responses.length ? (
                      <ResponsesRow
                        storyteller={storyteller}
                        colorScheme={colorScheme}
                        onClick={(videos) => {
                          setPlaylist([...videos]);
                        }}
                      />
                    ) : (
                      "No responses yet"
                    )}
                  </Td>
                </MotionRow>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Flex>
        <Button variant="outline" colorScheme="blackAlpha" onClick={onClick}>
          Invite more storytellers
        </Button>
        <Spacer />
        {storytellers.length > limit && (
          <Button
            variant="outline"
            colorScheme={colorScheme}
            onClick={() => setShowing(showing + limit)}
            size="xs"
            disabled={storytellers.length <= showing}
          >
            Show more
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default CompletedView;
