import {
  Badge,
  Box,
  Center,
  Container,
  Divider,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { FC } from "react";
import getPricing from "../../utils/pricing";
import ConciergeService from "./ConciergeService";
import DIYService from "./DIYService";
import PriceComparisonGrid from "./PriceComparisonGrid";

export const ServicesDetail: React.FC<{
  templateId: string;
}> = ({ templateId }: { templateId: string }) => {
  const { diy: diyPricing, concierge: conciergePricing } =
    getPricing(templateId);
  console.log({ templateId });
  return (
    <Box my="4">
      <Stack gap="4" mb="8" pb="4">
        <Heading as="h2" size="h2" variant="center">
          DIY vs. Concierge
        </Heading>
        <Text textAlign="center">
          After you finish collecting responses, you have two options for
          creating your final video gift: concierge and DIY. There are several
          differences between these two options, as well as different timeframes
          for completing the final video.
        </Text>
        <Box>
          <Stack gap="2" align="start">
            <DIYService prices={diyPricing} />
            <ConciergeService prices={conciergePricing} />
          </Stack>
        </Box>
      </Stack>
      <PriceComparisonGrid />
    </Box>
  );
};

export default ServicesDetail;
