import { useBreakpointValue } from "@chakra-ui/react";
import React, { useRef, useEffect } from "react";
import styled from "@emotion/styled";


const withStreamDimensions = (WrappedComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        streamDimensions: null
      };
    }

    async componentDidMount() {
      if(this.props.stream) await streamSize(this.props.stream).then((streamDimensions)=> this.setState({streamDimensions})).catch(console.error);
    }

    render() {
      //if(!this.state.streamDimensions) return null;
      return <WrappedComponent streamDimensions={this.state.streamDimensions} {...this.props} />;
    }
  };

  /**
   * @method streamSize : get MediaStream video dimensions
   * @param {MediaStream} stream : some stream with a video track
   */
  function streamSize(stream) {
    return new Promise(
      (resolve, reject) => {
        console.log('Stream active',stream.active);
        if (!stream.active) reject("Stream not active");

        let video = document.createElement("video");

        video.muted = true;

        video.srcObject = stream;

        video.onloadedmetadata = () => {
          let dimensions = {
            width: video.videoWidth,
            height: video.videoHeight,
            aspectRatio: video.videoWidth / video.videoHeight
          };

          video = null;

          resolve(dimensions);
        };
      }
    );
  }
}

const differenceWithinLimit = (a,b,limit) => {
  if(!!a && !!b) return (Math.abs(a - b) < limit);
  else return false;
}


const StyledVideoPlayer = styled.video`
  width: 100%;
  height: 100%;
  object-fit: ${(props) => (differenceWithinLimit(props.streamDimensions?.aspectRatio.toFixed(2), Number(props.aspectRatio).toFixed(2), 0.01) ? "cover" : "contain")};
  transform: ${(props) => (props.facingMode === "user" ? "scaleX(-1);" : "")};  
`;

const VideoPlayer = React.forwardRef((props,ref) => {
  return <StyledVideoPlayer {...{...props, ref}} aspectRatio={ref.current && (ref.current.clientWidth / ref.current.clientHeight).toFixed(2) || 1} />
})


const VideoPreview = withStreamDimensions(({ stream, isFullscreen, facingMode, streamDimensions }) => {
  const videoRef = useRef(null);
  
  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    } else {
      videoRef.current = null;
    }
  }, [stream]);

  if (!stream) {
    return null;
  }
  return (
    <VideoPlayer
      ref={videoRef}
      autoPlay
      playsInline
      muted
      controls={false}
      facingMode={facingMode}
      isFullscreen={isFullscreen}
      streamDimensions={streamDimensions}
    />
  );
});

export default VideoPreview;
