/* eslint-disable react/prop-types */
import React, { useRef, useState } from "react";
import { useActor } from "@xstate/react";
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  IconButton,
  Image,
  ImageProps,
  Input,
  Portal,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import AudioAnalyser from "../../audio/AudioAnalyser";
import { ReactMediaRecorderRenderProps } from "../../../types/ReactMediaRecorder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BrowserView, MobileView } from "react-device-detect";
import styled from "@emotion/styled";
import { useStoryViewContext } from "../../../pages/StoryView/components/StoryViewProvider";
import getVideoInfo from "../../video/recorder/get-video-info";
import AcceptRecordingButton from "../../video/recorder/AcceptRecordingButton";
import getVideoQuality from "../../video/recorder/get-video-quality";
import { useReactMediaRecorder } from "../../video/recorder/ReactMediaRecorder";
import VideoPreview from "../../video/recorder/VideoPreview";
import CameraFacingButton from "../../video/recorder/CameraFacingButton";
import RecordButton from "../../video/recorder/RecordButton";

interface ResponseProps {
  url: string;
  blob: any;
}

interface ReviewElProps {
  isFullscreen?: boolean;
}

const ReviewPhotoEl = styled.img<ReviewElProps>`
  width: 100%;
  height: 100%;
  object-fit: ${(props) => (props.isFullscreen ? "cover" : "contain")};
`;

const ReviewVideoEl = styled.video<ReviewElProps>`
  width: 100%;
  height: 100%;
  object-fit: ${(props) => (props.isFullscreen ? "cover" : "contain")};
`;

interface VideoRecorderProps {
  machine: any;
}

const LayoutView: React.FC = ({
  children,
  useInput,
}: {
  children?: React.ReactNode;
  useInput?: boolean;
}) => {
  return (
    <>
      <BrowserView>
        <Box pos="relative" maxH="100%" maxW="100%" minH="350px">
          <Box
            sx={useInput ? undefined : { bgColor: "gray.600", shadow: "md" }}
          >
            {children}
          </Box>
        </Box>
      </BrowserView>
      <MobileView>
        <Box pos="relative" maxH="100vh" maxW="100vw">
          <Portal>
            <Box
              pos="fixed"
              top="0"
              left="0"
              w="100vw"
              h="100%"
              maxH="100vh"
              bgColor="gray.600"
            >
              {children}
            </Box>
          </Portal>
        </Box>
      </MobileView>
    </>
  );
};

const ReviewVideo: React.FC<{ url: string; isFullscreen: boolean }> = ({
  url,
  isFullscreen,
}) => {
  const [isPlaying, setPlaying] = useState(false);
  const videoObject = useRef();
  const toggleVideo = () => {
    // @ts-ignore
    // prettier-ignore
    videoObject && videoObject.current?.paused ? videoObject.current.play() : videoObject.current.pause();
    setPlaying(!isPlaying);
  };

  return (
    <>
      <ReviewVideoEl
        src={url}
        muted={false}
        playsInline
        autoPlay={false}
        controls={false}
        isFullscreen={isFullscreen}
        // @ts-ignore
        ref={videoObject}
        onEnded={() => setPlaying(false)}
      />
      <IconButton
        w="70px"
        h="70px"
        left="calc(50% - 35px)"
        variant="outline"
        pos="absolute"
        top="calc(50% - 35px)"
        zIndex="overlay"
        opacity={isPlaying ? 0 : 1}
        aria-label="play back video"
        color="white"
        onClick={toggleVideo}
        icon={<FontAwesomeIcon icon="play" size="2x" />}
        _hover={{ background: "none" }}
        _active={{ background: "none" }}
      />
    </>
  );
};

const ReviewPhoto: React.FC<{ url: string }> = ({ url }) => {
  return (
    <Box>
      <ReviewPhotoEl src={url} alt="Uploaded Image" />
    </Box>
  );
};

const VideoResponse: React.FC<VideoRecorderProps> = ({ machine }) => {
  const [state, send]: any[] = useActor(machine);
  const [constraints, setConstraints] = useState<boolean | any>({
    ...getVideoQuality(),
  });

  const [recording, setRecording] =
    useState<ResponseProps & { info?: any; type: "video" }>();
  const bottom = useBreakpointValue({ base: "10%", md: "15%" });

  const {
    previewStream,
    previewAudioStream,
    error,
    startRecording,
    stopRecording,
    switchCamera,
    startMediaStream,
    cameras,
    facingMode,
    facingModeSupported,
    mediaBlobUrl,
  }: ReactMediaRecorderRenderProps = useReactMediaRecorder({
    video: constraints,
    askPermissionOnMount: true,
    onStop: (url: any, blob: any, info?: any) => {
      setRecording({ url, blob, info, type: "video" });
    },
    onReady: () => {
      send("READY");
    },
    blobPropertyBag: null,
  }) as ReactMediaRecorderRenderProps;

  const isFullscreen = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
  });

  React.useEffect(() => {
    return () => {
      console.log("Stop Recording (init)");
      stopRecording();
    };
  }, []);

  React.useEffect(() => {
    if (state.matches("shutdown")) {
      console.log("Stop Recording (shutdown)");
      send("QUIT");
    }
  }, [state.matches("shutdown")]);

  return (
    <>
      {state.matches("review")
        ? recording && (
            <ReviewVideo url={recording?.url} isFullscreen={!!isFullscreen} />
          )
        : previewStream && (
            <VideoPreview
              stream={previewStream}
              isFullscreen={isFullscreen}
              facingMode={facingMode}
            />
          )}
      <Box
        pos="absolute"
        background="linear-gradient(0deg,rgba(0, 0, 0, 0.8) 0%,rgba(0, 0, 0, 0) 100%)"
        bottom={0}
        w="full"
        h={`calc(${bottom} + 100px)`}
      />
      <Box
        position="absolute"
        bottom={state.matches("review") ? "8" : bottom}
        width="full"
        zIndex="overlay"
      >
        <Flex align="center" justify="center">
          {state.matches("ready") ? (
            <Grid templateColumns="repeat(3, 1fr)" gap={6} w="full">
              <Center>
                {!!facingModeSupported && (
                  <CameraFacingButton
                    alignSelf="center"
                    onClick={() => switchCamera()}
                    aria-label="change camera direction"
                    direction={facingMode}
                  />
                )}
              </Center>
              <Center w="full">
                <RecordButton
                  onClick={() => {
                    startRecording();
                    send("RECORD");
                  }}
                />
              </Center>
            </Grid>
          ) : state.matches("recording") ? (
            <RecordButton
              progress={0}
              variant="recording"
              onClick={() => {
                stopRecording();
                send("DONE");
              }}
            >
              <FontAwesomeIcon inverse size="2x" icon={"stop"} />
            </RecordButton>
          ) : state.matches("review") ? (
            <VStack spacing="md">
              <AcceptRecordingButton
                aria-label="Accept Recording"
                onClick={() => {
                  send({ type: "APPROVE", data: recording });
                }}
              >
                Save
              </AcceptRecordingButton>
              <Button
                size="sm"
                color="gray.300"
                variant="outline"
                onClick={() => {
                  startMediaStream();
                  send("RETAKE");
                }}
              >
                Restart recording
              </Button>
            </VStack>
          ) : state.matches("done") ? (
            <>done</>
          ) : null}
        </Flex>
      </Box>
      {state.matches("recording") && previewAudioStream && (
        <Box position="absolute" bottom={-1} width="full">
          <AudioAnalyser
            audio={previewAudioStream}
            decibels={{ min: -100, max: -30 }}
          />
        </Box>
      )}
    </>
  );
};

const InputResponse: React.FC<any> = () => {
  const { service } = useStoryViewContext();
  const parentService = useActor(service);
  const [parentState] = parentService;
  const { recordingMachine } = parentState.children;
  const [state, send]: any[] = useActor(recordingMachine);
  const { story } = parentState.context;
  const allowPhoto = !!story?.template.allowPhoto;

  let inputRef = React.createRef<typeof Input>();
  const [response, setResponse] =
    useState<ResponseProps & { info?: any; type: "image" | "video" }>();
  const [error, setError] = useState<string | null>(null);

  const bottom = useBreakpointValue({ base: "10%", md: "15%" });
  const isFullscreen = useBreakpointValue({ base: true, md: false });

  const handleOpenInput = React.useCallback(() => {
    if (inputRef) {
      // @ts-ignore
      inputRef.value = null;
      // @ts-ignore
      inputRef.click();
    }
  }, [inputRef]);

  React.useEffect(() => {
    if (state.matches("idle") && !!inputRef) send("READY");
    if (state.matches("ready") && !!inputRef) {
      handleOpenInput();
      send("RECORD");
    }
    if (state.matches("shutdown")) {
      send("QUIT");
    }
  }, [state.value, inputRef]);

  const handleFileSelected: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    // @ts-ignore
    const files = e.target.files || e.dataTransfer.files;
    if (files.length === 0) return;

    const file = files[0];

    // @ts-ignore
    e.target.value = null;

    if (
      !/^video/.test(file.type || file.mediaType) ||
      (allowPhoto && !/^image/.test(file.type || file.mediaType))
    ) {
      setError(
        allowPhoto
          ? "The file selected must be a valid media file. JPEG, PNG, MP4, MOV, WEBM"
          : "The file selected must be a valid video file. MP4, MOV, WEBM"
      );
      send("RETAKE");
    }

    // @ts-ignore
    const url = URL.createObjectURL(file);

    if (/^video/.test(file.type || file.mediaType)) {
      getVideoInfo(url)
        .then(({ duration }) => {
          return { duration };
        })
        .catch(({ name, message }) => {
          return { error: true, errorName: name, errorMessage: message };
        })
        .then((info) => {
          setResponse((prev) => {
            //@ts-ignore
            prev && URL.revokeObjectURL(prev.url);
            return {
              blob: file,
              url,
              info,
              type: "video",
            };
          });
          send("DONE");
        });
    } else {
      setResponse((prev) => {
        //@ts-ignore
        prev && URL.revokeObjectURL(prev.url);
        return {
          blob: file,
          url,
          info: {
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModifiedDate,
          },
          type: "image",
        };
      });
      send("DONE");
    }
  };

  return (
    <>
      <Input
        ref={(ref) => (inputRef = ref as any)}
        key="videoInput"
        type="file"
        accept={allowPhoto ? "video/*,image/*" : "video/*"}
        style={{ display: "none" }}
        variant="unstyled"
        onChange={handleFileSelected}
      />
      {state.matches("review") &&
        response &&
        (response.type === "image" ? (
          <ReviewPhoto url={response.url} />
        ) : (
          <ReviewVideo url={response.url} isFullscreen={!!isFullscreen} />
        ))}
      <Box
        position="absolute"
        bottom={state.matches("review") ? "8" : bottom}
        width="full"
        zIndex="overlay"
      >
        <Flex align="center" justify="center">
          {state.matches("error") && <>{state.context.error}</>}
          {state.matches("ready") || state.matches("recording") ? (
            <Stack align="center" spacing=".25rem">
              <Button
                leftIcon={<FontAwesomeIcon icon={"upload"} />}
                onClick={() => {
                  handleOpenInput();
                }}
              >
                Add Media
              </Button>
              <Text fontSize="sm">
                <i>or</i>
              </Text>
              <Button
                variant="link"
                colorScheme="puce"
                onClick={() => send("CANCEL")}
              >
                go back
              </Button>
            </Stack>
          ) : state.matches("review") ? (
            <VStack spacing="md">
              <AcceptRecordingButton
                aria-label="Accept Recording"
                onClick={() => {
                  send({ type: "APPROVE", data: response });
                }}
              >
                Save
              </AcceptRecordingButton>
              <Button
                size="sm"
                color="gray.300"
                variant="outline"
                name="RetakeVideo"
                onClick={() => {
                  send("RETAKE");
                }}
              >
                Start over
              </Button>
            </VStack>
          ) : null}
        </Flex>
      </Box>
    </>
  );
};

const CaptureResponse: React.FC<any> = ({ children }) => {
  const { service, story } = useStoryViewContext();
  const parentService = useActor(service);
  const [parentState] = parentService;
  const { recordingMachine } = parentState.children;
  const [state]: any[] = useActor(recordingMachine);
  const {
    context: { useInput },
  } = state;

  return (
    <Flex w="full" justify="center" align="stretch" pt="lg">
      <Box w="full" maxW="800px" maxH="80vh" bg={useInput ? "none" : "black"}>
        {/* @ts-ignore */}
        <LayoutView useInput={useInput}>
          {useInput ? (
            <InputResponse />
          ) : (
            <VideoResponse machine={recordingMachine} />
          )}
          {children}
        </LayoutView>
      </Box>
    </Flex>
  );
};

export default CaptureResponse;
