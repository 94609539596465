import { ReactElement } from "react";
interface MediaDeviceInfo {
  readonly deviceId: string;
  readonly groupId: string;
  readonly kind: "audioinput" | "audiooutput" | "videoinput";
  readonly label: string;
  toJSON(): any;
}
export type ReactMediaRecorderRenderProps = {
  error: string;
  muteAudio: () => void;
  unMuteAudio: () => void;
  startRecording: () => void;
  pauseRecording: () => void;
  resumeRecording: () => void;
  stopRecording: () => void;
  switchCamera: () => void;
  startMediaStream: () => void;
  mediaBlobUrl: null | string;
  status: StatusMessages;
  isAudioMuted: boolean;
  previewStream: any | null; //MediaStream
  previewAudioStream: any | null; //MediaStream
  clearBlobUrl: () => void;
  cameras: Array<MediaDeviceInfo>;
  facingMode: 'user' | 'environment',
  facingModeSupported: boolean | undefined
};

export type ReactMediaRecorderHookProps = {
  audio?: boolean | any; //MediaTrackConstraints
  video?: boolean | any; //MediaTrackConstraints
  screen?: boolean;
  onStop?: (blobUrl: string, blob: any /*Blob*/) => any;
  onReady?: () => any;
  blobPropertyBag?: any; //BlobPropertyBag
  mediaRecorderOptions?: any | null; //MediaRecorderOptions
  askPermissionOnMount?: boolean;
};
export type ReactMediaRecorderProps = ReactMediaRecorderHookProps & {
  render: (props: ReactMediaRecorderRenderProps) => ReactElement;
};

export type StatusMessages =
  | "media_aborted"
  | "permission_denied"
  | "no_specified_media_found"
  | "media_in_use"
  | "invalid_media_constraints"
  | "no_constraints"
  | "recorder_error"
  | "idle"
  | "acquiring_media"
  | "delayed_start"
  | "recording"
  | "stopping"
  | "stopped"
  | "paused";

export enum RecorderErrors {
  AbortError = "media_aborted",
  NotAllowedError = "permission_denied",
  NotFoundError = "no_specified_media_found",
  NotReadableError = "media_in_use",
  OverconstrainedError = "invalid_media_constraints",
  TypeError = "no_constraints",
  NONE = "",
  NO_RECORDER = "recorder_error",
}