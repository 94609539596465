/* eslint-disable react/prop-types */
import React from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";

//Store Middleware
import thunk from "redux-thunk";
import { getFirestore } from "redux-firestore";
import { ReactReduxFirebaseProvider, getFirebase } from "react-redux-firebase";
import firebase, {
  reactReduxFirebaseConfig,
  createFirestoreInstanceExt,
} from "./config/firebase";
import rootReducer from "./store/reducers/rootReducer";

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore }))
  )
);

const rrfProps = {
  firebase,
  config: reactReduxFirebaseConfig,
  dispatch: store.dispatch,
  createFirestoreInstance: createFirestoreInstanceExt,
};

const ReduxProvider: React.FC = ({ children }) => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        {children}
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

export default ReduxProvider;
