import { isLoaded, isEmpty } from 'react-redux-firebase';

const checkAuth = ({auth, profile}) => {
    return {
                auth,
                profile,
                loaded: isLoaded(auth),
                signedIn: !isEmpty(auth),
                isAnon: isLoaded(auth) && !isEmpty(auth) && auth.isAnonymous,
                hasProfile: isLoaded(profile) && !isEmpty(profile)
    };
}

export default checkAuth;