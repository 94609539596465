import React from "react";
import {
  Box,
  Button,
  Heading,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { useAnalytics } from "use-analytics";
import DropCalendar from "../../layout/DropCalendar";
import FinalMovieInfoModal from "./FinalMovieInfoModal";

const defaultDueDate = (deliveryDate: Date) => {
  return moment(deliveryDate)
    .startOf("hour")
    .subtract(72, "hours")
    .add(1, "minute")
    .toDate();
};
interface SetDueDateCardViewProps {
  colorScheme: string;
  deliveryDate: Date;
  onClick: (dueDate: Date) => void;
}
export const SetDueDateView: React.FC<SetDueDateCardViewProps> = ({
  colorScheme,
  deliveryDate,
  onClick,
}: SetDueDateCardViewProps) => {
  const { track } = useAnalytics();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dueDate, setDueDate] = React.useState<Date>(
    defaultDueDate(deliveryDate)
  );
  const isDateValid = (date: Date) => {
    const _valid = !!(
      date &&
      moment(date).isBetween(
        moment().startOf("day"),
        moment(deliveryDate).endOf("day")
      )
    );
    return _valid;
  };
  const handleDueDateChange = (value: Date) => {
    setDueDate(value);
  };

  return (
    <Box>
      <Heading as="h4" size="md" mb={3}>
        Invite storytellers
      </Heading>
      <Text fontWeight="bold" mb={3}>
        When should people have all their videos submitted by?
      </Text>
      <Box mb={3}>
        <DropCalendar
          useChakraInput
          startDate={dueDate}
          onChange={handleDueDateChange}
          showTimeSelect={false}
          limitTo={{
            minDate: moment().startOf("day").toDate(),
            maxDate: moment(deliveryDate).endOf("day").toDate(),
          }}
        />
      </Box>
      <Text mb={3} fontSize="small" maxW="370px">
        <Text as="i">
          We recommend a date at least 3 business days before you plan on giving
          your gift, but you can specify less time if you use our DIY video
          builder.{" "}
          <Link
            color="puce.500"
            onClick={() => {
              track("dashbaord:user_openedDueDateExplainer");
              onOpen();
            }}
          >
            Learn more
          </Link>
        </Text>
      </Text>
      <Button
        disabled={!isDateValid(dueDate)}
        colorScheme={colorScheme}
        onClick={() => {
          onClick(dueDate);
        }}
      >
        Continue
      </Button>
      <FinalMovieInfoModal
        isOpen={isOpen}
        onClose={onClose}
        colorScheme="tumbleweed"
      />
    </Box>
  );
};

export default SetDueDateView;
