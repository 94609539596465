import { useToast } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { Navigate, useParams } from "react-router-dom";
import { useAuth, useLocalStorage } from "../../../hooks";
import LoadingPage from "../../../pages/LoadingPage";
import assignPartners from "../utils/assignPartners";

const Partners = () => {
  const { id } = useParams();
  const auth = useAuth();
  const [partners, setPartners] = useLocalStorage("partner_ids", []) as [
    string[],
    (ids: string[]) => void
  ];

  const [done, setDone] = React.useState(false);
  useFirestoreConnect([
    {
      collection: "partners",
      doc: id,
    },
  ]);
  const partner: any = useSelector(
    ({ firestore: { data } }: any) => data.partners && data.partners[id]
  ) as any;

  const toast = useToast();

  const store = React.useCallback(() => {
    console.log("store");
    if (id && partners && !partners.includes(id)) {
      setPartners([...partners, id]);
    }
  }, [id, partners]);

  const addPartner = React.useCallback(async () => {
    if (!id || !partner) throw Error("Partner could not be found.");
    if (!!auth.currentUser) {
      assignPartners(auth.currentUser, [id]);
    } else {
      store();
    }
  }, [id, partner]);

  const notFoundToast = () => {
    toast({
      title: "Partner not found or no longer active.",
      description:
        "We could not locate this partner. They may no longer be active or the link was not correct. If you feel this is a mistake. Please contact support.",
      status: "error",
      duration: null,
      isClosable: true,
      position: "bottom",
    });
  };

  React.useEffect(() => {
    console.log("Partner Changed.", {
      loaded: isLoaded(partner),
      empty: isEmpty(partner),
    });
    if (isLoaded(partner)) {
      if (!isEmpty(partner)) {
        addPartner()
          .then(() => {
            toast({
              title: "Your partner coupon has been added",
              description:
                "Your coupon will automatically be applied during checkout.",
              status: "success",
              duration: null,
              isClosable: true,
              position: "bottom",
            });
            setDone(true);
          })
          .catch(notFoundToast);
      } else {
        notFoundToast();
      }
      setDone(true);
    }
  }, [id, partner]);

  return (
    <>
      {!done ? (
        <LoadingPage />
      ) : partner?.packageId ? (
        <Navigate to={`/start/new#${partner?.packageId}`} replace />
      ) : (
        <Navigate to={"/"} replace />
      )}
    </>
  );
};

export default Partners;
