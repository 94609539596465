import React from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';

interface InitialCardViewProps { colorScheme:string, onClick: () => void }
export const InitialCardView:React.FC<InitialCardViewProps> = ({colorScheme, onClick }:InitialCardViewProps) => {
    return (<Box>
        <Heading as='h4' size='md' mb={3}>Tell Your Story</Heading>
        <Text mb={3}>While you&apos;re waiting for storytellers, go ahead and add your own answers to your prompts!</Text>
        <Button colorScheme={colorScheme} onClick={onClick}>Start Recording</Button>
    </Box>)
}

export default InitialCardView;