import React, { useEffect, useRef } from "react";
import { motion, useMotionValue, useSpring } from "framer-motion";
import styled from "@emotion/styled";
import { Box, Flex, FlexProps } from "@chakra-ui/react";
import { chakra, useTheme } from "@chakra-ui/system";
import seeker from "../../assets/seeker.svg";

const Wrapper = motion<FlexProps>(Flex);

// const durationToTimestring = (duration: number, fraction: number) => {
//   const secs = duration * fraction;
//   console.log("secs", secs);

//   const minutes = Math.floor(secs / 60);
//   const seconds = secs * 3600 - minutes * 60;
//   const secString = seconds.toString();

//   return `${minutes || "0"}:${
//     secString.length < 2 ? `0${secString.charAt(0)}` : secString.slice(0, 2)
//   }`;
// };

const MotionBox = motion(Box);
const scrubberSize = 15;
const indicatorSize = 2;

const VideoCropper = (props: Props) => {
  const {
    sliderMin,
    sliderMax,
    setSliderMin,
    setSliderMax,
    duration,
    progress,
    progressInterval,
    playing,
    onSeek,
  } = props;

  const chakraTheme = useTheme();

  const handleSize = 2;
  const handleSizeInPx = handleSize * 16;
  const handleSeparatorLength = handleSizeInPx * 2.25;

  const trackWidth = props.trackWidth;
  const trackWidthInPx = trackWidth * 16;
  const availTrackWidth = trackWidth - handleSize;
  const availTrackWidthInPx = availTrackWidth * 16;

  const Handle = styled(Box)`
    width: calc(${handleSize}rem - 2px);
    height: calc(${handleSize}rem - 2px);
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    z-index: 10;
    background-color: ${chakraTheme.colors.seaGreen[600]};
    &:nth-of-type(2) {
      left: -${handleSize}rem;
    }
  `;

  const SeekerContainer = styled(Box)`
    /* position: absolute; */
    z-index: 8;
    height: calc(${scrubberSize}px + ${handleSizeInPx}px + 4px);
    width: ${scrubberSize}px;
    padding-left: ${scrubberSize / 2}px;
  `;

  const AnimatedHandle = Handle.withComponent(motion.div);
  const MotionSeekerContainer = SeekerContainer.withComponent(motion.div);

  const TrackHolder = styled(motion.div)`
    width: ${availTrackWidth + handleSize}rem;
    height: 2rem;
    background-color: ${chakraTheme.colors.seaGreen[100]};
    border-radius: 999px;
    z-index: 0;
    border: 1px ${chakraTheme.colors.seaGreen[300]} solid;
    position: relative;
  `;

  const ProgressTrackHolder = styled(motion.div)`
    width: ${availTrackWidth}rem;
    /* margin-left: ${handleSizeInPx / 2}px; */
    height: ${handleSize}rem;
    z-index: 6;
    position: absolute;
    /* pointer-events: none; */
  `;

  const constraintsRef = useRef(null);
  const progressContstraintRef = useRef(null);

  const handleA = useMotionValue(sliderMin);
  const handleB = useMotionValue(sliderMax);
  const seekerHandle = useSpring(progress || 0, {
    stiffness: 800,
    damping: 80,
  });
  const cropWidth = useMotionValue(sliderMax - sliderMin);

  const Track = styled(motion.div)(() => ({
    left: `${sliderMin}px`,
    width: `${sliderMax - sliderMin}px`,
    zIndex: 5,
    height: "2rem",
    backgroundColor: `${chakraTheme.colors.seaGreen[250]}`,
    borderRadius: "999px",
    border: `1px ${chakraTheme.colors.seaGreen[250]} solid`,
    position: "absolute",
    top: 0,
  }));

  useEffect(() => {
    handleA.set(sliderMin);
    handleB.set(sliderMax);
    cropWidth.set(sliderMax - sliderMin);
  }, [sliderMin, sliderMax]);

  useEffect(() => {
    if (progress && duration) {
      if (!playing && progress === 1) {
        seekerHandle.set(handleA.get());
      } else {
        seekerHandle.set(
          progress * availTrackWidthInPx <= handleA.get()
            ? handleA.get()
            : progress * availTrackWidthInPx + 32 >= handleB.get()
            ? handleB.get() - 32
            : progress * availTrackWidthInPx
        );
      }
    }
  }, [progress]);

  if (duration)
    return (
      <Wrapper flexFlow="column wrap" justify="center" alignItems="center">
        <TrackHolder
          ref={constraintsRef}
          // @ts-ignore
          trackWidth={availTrackWidth}
          inValueX={sliderMin}
          outValueX={sliderMax}
          trackWidthInPx={trackWidthInPx}
        >
          <AnimatedHandle
            drag="x"
            dragMomentum={false}
            dragElastic={0}
            style={{ x: handleA }}
            // @ts-ignore
            onDrag={() => {
              cropWidth.set(handleB.get() - handleA.get());
            }}
            onDragEnd={() => {
              setSliderMin(handleA.get());
            }}
            dragConstraints={{
              left: 0,
              right: sliderMax - handleSeparatorLength,
            }}
          />
          <AnimatedHandle
            drag="x"
            style={{ x: handleB }}
            dragMomentum={false}
            dragElastic={0}
            onDrag={() => {
              cropWidth.set(handleB.get() - handleA.get());
            }}
            onDragEnd={() => {
              setSliderMax(handleB.get());
            }}
            dragConstraints={{
              left: sliderMin + handleSeparatorLength,
              right: trackWidthInPx,
            }}
          />

          <Track
            style={{
              left: handleA,
              width: cropWidth,
            }}
          />
          <ProgressTrackHolder ref={progressContstraintRef}>
            <MotionSeekerContainer
              style={{ x: seekerHandle }}
              cursor="pointer"
              drag="x"
              dragMomentum={false}
              dragElastic={0}
              dragConstraints={{
                left: handleA.get() - handleSizeInPx / 4,
                right: handleB.get() - handleSizeInPx,
              }}
              onDragEnd={() =>
                onSeek(
                  seekerHandle.get() / availTrackWidthInPx > 0
                    ? seekerHandle.get() / availTrackWidthInPx
                    : 0
                )
              }
              mt={`calc(0px - ${scrubberSize}px - ${indicatorSize / 2}px)`}
              // Divide by four since 1/2 of 1 of the two handles
              ml={`${handleSizeInPx / 4}px`}
            >
              <MotionBox
                position="absolute"
                zIndex="8"
                ml={`-${scrubberSize / 2}px`}
              >
                <MotionBox
                  w={`${scrubberSize}px`}
                  h={`${scrubberSize}px`}
                  ml="-1px"
                >
                  <motion.img
                    style={{ pointerEvents: "none" }}
                    src={seeker}
                    width={scrubberSize}
                    height={scrubberSize}
                  ></motion.img>
                </MotionBox>
                <MotionBox
                  height={`calc(${handleSize}rem - 4px)`}
                  mt="2px"
                  ml={`calc(${scrubberSize}px - ${indicatorSize}px - ${
                    scrubberSize / 2
                  }px)`}
                  width={`${indicatorSize}px`}
                  bgColor="airBlue.700"
                  rounded="full"
                  zIndex="8"
                />
              </MotionBox>
            </MotionSeekerContainer>
          </ProgressTrackHolder>
        </TrackHolder>
      </Wrapper>
    );

  return <></>;
};

export default VideoCropper;

interface Props {
  sliderMin: number;
  sliderMax: number;
  trackWidth: number;
  setSliderMin: (value: number) => void;
  setSliderMax: (value: number) => void;
  videoIn: number;
  videoOut: number;
  duration?: number;
  progress?: number;
  progressInterval: number;
  onSeek: (value: number) => void;
  playing: boolean;
}
