import React from "react";
import {
  Box,
  Stack,
  Flex,
  Text,
  Divider,
  Tooltip,
  Checkbox,
} from "@chakra-ui/react";
import DropCalendar from "../../layout/DropCalendar";

import { isMobile } from "react-device-detect";
import moment from "moment";
import { timestampToDate } from "../../../utils/convert";

interface Reminders {
    "weekly": boolean,
    "midway": boolean,
    "dayBefore": boolean,
    "weekBefore": boolean,
    "dueDate": boolean,
    "dayAfter": boolean
}

const StorySettings = ({dueDate, reminders, isLegacy, onChange}:{dueDate:any|Date, reminders: Reminders, isLegacy: boolean, onChange: ({dueDate, reminders}:{dueDate:any|Date, reminders: Reminders}) => void }) => {
  let _dueDate = dueDate && timestampToDate(dueDate)

  const handleDueDateChange = (value:string|Date) => {
    const isDateValid = (date:string|Date) => {
      const _valid = !!(date && moment(date).isAfter());
      return _valid;
    };
    _dueDate = { value, valid: { isValid: isDateValid(value) } };
    onChange && onChange({dueDate:_dueDate.value, reminders: reminders})
  };

  const onChangeReminder = ({ target: { name, checked } }: any) => {
    onChange && onChange({dueDate:_dueDate, reminders:  { ...reminders, [name]: checked }})
  };

  const ReminderCheckbox = ({ tooltip, label, name }:{ tooltip:string|React.ReactNode, label:string|React.ReactNode, name:"weekly"|"midway"|"dayBefore"|"weekBefore"|"dueDate"|"dayAfter" }) => {
    return (
      <Checkbox
        id={name}
        name={name}
        onChange={onChangeReminder}
        isChecked={reminders[name]}
      >
        <Tooltip label={tooltip} fontSize="md">
          <Text fontWeight="medium" fontSize="sm">
            {label}
          </Text>
        </Tooltip>
      </Checkbox>
    );
  };

  return (
      <Flex
        pad="none"
        align="flex-start"
        justify="center"
        direction={["column", "column", "row"]}
        w="full"
      >
        <Stack flex={1} flexBasis="1/2">
          <Text fontWeight="bold">All videos should be completed by:</Text>
          <DropCalendar
            useChakraInput
            startDate={timestampToDate(_dueDate)}
            onChange={handleDueDateChange}
            showTimeSelect={true}
            limitTo={{
              minDate: moment().add(48, "hours").toDate(),
              maxDate: moment().add(48, "hours").add(1, "year").toDate(),
            }}
          />
          <Text>
            <em>
              To allow enough time to put together your finished movie, your due
              date should be at least 5 days before you want the finished movie.
            </em>
          </Text>
        </Stack>
        {isMobile ? (
          <Divider my="lg" borderColor="gray.300" />
        ) : (
          <Box
            w="1px"
            borderLeft="1px"
            borderStyle="dotted"
            alignSelf="stretch"
            mx="lg"
            borderColor="gray.400"
          />
        )}
        <Stack flex={1} flexBasis="1/2">
          <Text fontWeight="bold">Reminders should be sent:</Text>
          <Text size="md">
            We will send automated reminders to people who have not responded.
            Select which you’d like us to send.
          </Text>
          <Stack spacing={0.5}>
            {isLegacy && (
              <ReminderCheckbox
                name="weekly"
                label="Weekly"
                tooltip="Send a reminder once a week during your Quilted Story"
              />
            )}
            <ReminderCheckbox
              name="midway"
              label="Midway"
              tooltip="Send a reminder midway through your Quilted Story"
            />
            {isLegacy && (
              <ReminderCheckbox
                name="weekBefore"
                label="1 week before"
                tooltip="Send a reminder the week before your Quilted Story deadline"
              />
            )}
            {!isLegacy && (
              <ReminderCheckbox
                name="dayBefore"
                label="Day before"
                tooltip="Send a reminder the day before your Quilted Story deadline"
              />
            )}
            {!isLegacy && (
              <ReminderCheckbox
                name="dueDate"
                label="Due date"
                tooltip="Send a reminder the day of your Quilted Story deadline"
              />
            )}
            <ReminderCheckbox
              name="dayAfter"
              label="Day after"
              tooltip="Send a reminder the day after your Quilted Story’s
                deadline"
            />
          </Stack>
        </Stack>
      </Flex>
  );
};

export default StorySettings;
