import config from "../config/config";

const {
  diy: diyPricing,
  concierge: conciergePricing,
  overrides,
} = config.app.pricing;

export type Prices = { price: number; discount?: number };
type Pricing = {
  diy: Prices;
  concierge: Prices;
  hide?: true;
};
interface IDictionary<TValue> {
  [id: string]: TValue;
}

const getPricing = (templateId: string) => {
  const priceOverride = (overrides &&
    (overrides as IDictionary<Partial<Pricing>>)[templateId || ""]) as
    | Partial<Pricing>
    | undefined;

  const getDiyPrice = () => {
    return (priceOverride && priceOverride.diy) || (diyPricing as Prices);
  };
  const getConciergePrice = () => {
    return (
      (priceOverride && priceOverride.concierge) || (conciergePricing as Prices)
    );
  };

  return {
    diy: getDiyPrice(),
    concierge: getConciergePrice(),
    hide: priceOverride?.hide,
  } as Pricing;
};

export default getPricing;
