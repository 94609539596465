import firebase from "firebase";
import { VerifiedPartner } from "../types";

export const VerifiedPartnerConverter: firebase.firestore.FirestoreDataConverter<VerifiedPartner> =
{
    toFirestore(data: VerifiedPartner) {
        return {
            ...data,
        };
    },
    fromFirestore(snapshot) {
        const data = snapshot.data();
        try {
            const partner: VerifiedPartner = {
                id: data.id,
                name: data.name,
                coupon: data.coupon,
                diy: data.diy,
                packageId: data.packageId,
                redeemed: data.redeemed,
                ...data,
            };
            return partner;
        } catch (error) {
            console.error(error);
            return {} as VerifiedPartner;
        }
    },
};