
  const getStoryStates = (story) => {
    /*
    STORY STATES: */
    //SETUP:
    const inSetup = story.state === 'setup';
    //RUNNING:
    const isRunning = story.state === 'running';
    //PAID:
    const isPaid = !!(story.purchase && story.purchase.status && story.purchase.status === 'paid');
    //FINAL VIDEO AVAILABLE FOR APPROVAL:
    const finalVideoStarted = !!story.finalVideo || isPaid;
    const isFulfilled = (story.state === 'complete' || story.state === 'fulfilled') && !!story.finalVideo;
    const needsApproval = (isFulfilled && !story.finalVideo.isApproved);
    const revisionsRequested = isFulfilled && !!story.finalVideo.revisionsRequested;
    // COMPLETE
    const isComplete = isFulfilled && story.state === 'complete';


    return { inSetup, isRunning, isPaid, finalVideoStarted, isFulfilled, needsApproval, revisionsRequested, isComplete, isConcierge: !!story.isConcierge }
  }

  export default getStoryStates;