import React from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase';

import {
  Text,
} from "@chakra-ui/react";
import StoriesList from './StoriesList';


function LoadStories({ Stories, StoriesToSetup, Templates }) {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if(!loaded) {
      setLoaded(!!isLoaded(Stories) && !!isLoaded(StoriesToSetup) && !!isLoaded(Templates))
    }
  }, [Stories, StoriesToSetup, Templates, loaded])

  if(!loaded) return (<Text>Loading Stories...</Text>);

  return (<StoriesList stories={Stories} storiesToSetup={StoriesToSetup} templates={Templates} />);
}

LoadStories.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  auth: PropTypes.shape({
    uid: PropTypes.string.isRequired
  })
};

const mapStateToProps = (state) => {
    const data = {
        StoriesToSetup: undefined,
        Stories: undefined,
        Teplates: undefined,
        unfiltered : state.firestore.data['StoriesToSetup']
    };

    if(state.firestore) {
        data.Stories = state.firestore.ordered.stories;
        const _inSetup = state.firestore.data && state.firestore.data['StoriesToSetup'];
        if(isLoaded(_inSetup))
        data.StoriesToSetup = isEmpty(_inSetup) ? null : Object.keys(_inSetup).map(key => { return { id: key, ..._inSetup[key] }; });
        if(isLoaded(data.Stories))
        data.Stories = [...data.Stories].sort((a,b) => (a.dueDate ? a.dueDate : 0) - (b.dueDate ? b.dueDate : 0))
        data.Templates = state.firestore.ordered.templates;
    }
    return data;
}

const enhance = compose(
  firestoreConnect(props => {
    const queries = [
      {
        collection: 'templates'
      },{
        collection: 'stories',
        where: [
            ['uid', '==', props.auth.uid],
            ['state', '==', 'setup']
          ],
          storeAs: 'StoriesToSetup',
          orderBy: [["createdAt", "asc"]]
      },
      {
        collection: 'stories',
        where: [
            ['uid', '==', props.auth.uid],
            ['state', '!=', 'setup'],
          ],
          orderBy: [["state","asc"]]

      }
    ];
    return queries
  }),
  connect(mapStateToProps)
)

export default enhance(LoadStories)