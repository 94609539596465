/* eslint-disable react/prop-types */
import React, { memo } from "react";
import { Box, Flex, Image, Show, useColorModeValue } from "@chakra-ui/react";
import { themeColors } from "../../chakraTheme";

export interface ActionCardProps {
  colorScheme: themeColors | string;
  image?: string;
}

const ActionCard: React.FC<ActionCardProps> = ({
  colorScheme: color,
  image,
  children,
}) => {
  const colorScale = useColorModeValue("50", "500");
  return (
    <Flex
      gap={8}
      bg={`${color}.${colorScale}`}
      py={7}
      pl={8}
      pr={10}
      rounded="lg"
      boxShadow="md"
      maxW="container.md"
    >
      <Show above="sm">
        {!!image && (
          <Box>
            <Image src={image} />
          </Box>
        )}
      </Show>
      <Box flex="1">{children}</Box>
    </Flex>
  );
};

export default ActionCard;
