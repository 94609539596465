import { Template, StoryTemplateMetadata } from "../types/Templates";

const getTemplateMetadata = (template: Template) => {
  const t: StoryTemplateMetadata = {
    id: template.id,
    title: template.title,
    illustration: template.illustration || null,
    accentColor: template.accentColor,
    type: template.type,
    variantId: template.variantId,
    maxStorytellers: template.maxStorytellers,
    welcomeMessageTemplate: template.welcomeMessageTemplate,
    conciergeOnly: template.conciergeOnly,
    allowPhoto: template.allowPhoto,
  };
  return { ...t };
};

export default getTemplateMetadata;
