import React from "react";
import * as Sentry from "@sentry/react";
import PageContainer from "../../layout/components/PageContainer";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Code,
  Stack,
  Text,
} from "@chakra-ui/react";

export function FallbackComponent({ error, componentStack, resetError }) {
  return (
    <PageContainer>
      <Stack gap={6}>
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Error!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            <Stack gap={6}>
              <Text>Something did not do what it was supposed to do.</Text>
              <Button colorScheme="blackAlpha" onClick={() => resetError()}>
                Go back
              </Button>
            </Stack>
          </AlertDescription>
        </Alert>
        <Accordion>
          <AccordionItem>
            <Text>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Show error detail
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Text>
            <AccordionPanel pb={4}>
            <Stack gap={6}>
              <Code colorScheme='red'>{error.toString()}</Code>
              <Code>{componentStack}</Code>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Stack>
    </PageContainer>
  );
}

const ErrorBoundary = ({ children, fallback = FallbackComponent }) => {
  return (
    <Sentry.ErrorBoundary fallback={fallback} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
