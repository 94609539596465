import React from "react";
import {
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Text,
  Tbody,
  Td,
  HStack,
  Flex,
  Stack,
  ButtonProps,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from "react-device-detect";
import formatPhoneNumber from "../../../../utils/formatPhoneNumber";

export const ContactEmailPhone = ({
  contact,
}: {
  contact: iPhoneContact | iEmailContact;
}) => {
  return (
    <HStack>
      <Flex
        align="center"
        justify="center"
        color="tumbleweed.500"
        bgColor="tumbleweed.100"
        w="6"
        h="6"
        rounded="sm"
      >
        <FontAwesomeIcon
          icon={contact.contactPreference === "SMS" ? "mobile-alt" : "at"}
        />
      </Flex>
      <Text wordBreak="break-word">
        {contact.contactPreference === "SMS"
          ? formatPhoneNumber.forDisplay(contact.phone)
          : contact.email}
      </Text>
    </HStack>
  );
};

const ContactTable = (props: iContactTableProps) => {
  const { contacts, onDelete } = props;

  const DeleteContactButton = (props: iDeleteContactButtonProps) => {
    return (
      <Button
        leftIcon={<FontAwesomeIcon icon={["far", "trash-alt"]} />}
        variant="ghost"
        size="sm"
        colorScheme="puce"
        onClick={() => onDelete(props.contactIndex)}
        {...props}
      >
        Remove
      </Button>
    );
  };

  const DesktopView = () => {
    return (
      <Table variant="simple">
        <Thead bgColor="springWood.500">
          <Tr>
            <Th>
              <Text fontWeight="bold" fontFamily="sans-serif">
                Name
              </Text>
            </Th>
            <Th>
              <Text fontWeight="bold" fontFamily="sans-serif">
                Contact
              </Text>
            </Th>
            <Th isNumeric>
              <Text fontWeight="bold" fontFamily="sans-serif">
                Delete?
              </Text>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {contacts.map((contact, i) => {
            return (
              <Tr key={i}>
                <Td>{contact.name}</Td>
                <Td>
                  <ContactEmailPhone contact={contact} />
                </Td>
                <Td isNumeric>
                  <DeleteContactButton contactIndex={i} />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    );
  };

  const MobileView = () => {
    return (
      <Stack spacing="md">
        {contacts.map((contact, i) => {
          return (
            <Flex
              key={i}
              p="sm"
              rounded="md"
              bgColor="springWood.500"
              justify="space-between"
              align="center"
            >
              <Stack align="start">
                <Text fontWeight="bold">{contact.name}</Text>
                <Text>
                  <ContactEmailPhone contact={contact} />
                </Text>
              </Stack>
              <Flex>
                <DeleteContactButton
                  contactIndex={i}
                  variant="outline"
                  size="sm"
                  ml="sm"
                />
              </Flex>
            </Flex>
          );
        })}
      </Stack>
    );
  };

  return isMobile ? <MobileView /> : <DesktopView />;
};

interface iDeleteContactButtonProps extends ButtonProps {
  contactIndex: number;
}

interface iContactTableProps {
  contacts: (iPhoneContact | iEmailContact)[];
  onDelete: (...args: any[]) => any;
}

interface iContact {
  name: string;
  contactPreference: "SMS" | "EMAIL";
}

interface iPhoneContact extends iContact {
  phone: string;
  contactPreference: "SMS";
}

interface iEmailContact extends iContact {
  email: string;
  contactPreference: "EMAIL";
}

export default ContactTable;
