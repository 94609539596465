import { downloadUrl } from '../../utils/download-url';
import { getDownloadUrl } from '../../utils/firebase-storage';

export const DownloadFromStorage = (storagePath, downloadFileName, onProgress, onComplete, onError, onAbort) => {

  const download = (url) => {
    const promise = new Promise((resolve, reject) => {

      const resolveHandler = () => {
        resolve()
        onComplete()
      }
      const rejectHandler = (type) => (error) => {
        reject({type, error})
      }

      downloadUrl(downloadFileName, url, onProgress, resolveHandler, rejectHandler('onError'), rejectHandler('onAbort'));
    })

    return promise;
  }

  return getDownloadUrl(storagePath)    
    .then((url) => {
      return download(url)
      .catch(({type, error, ...rest}) => {
        if(type === 'onError' && onError) onError({...error, ...rest})
        if(type === 'onAbort' && onAbort) onAbort({...rest})
        return null;
      })      
    })
    .catch(error => {
      onError({ message: 'There was an error getting a download url.', error })
    });
}