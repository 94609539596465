import React from "react";

import { useAuth } from "../../../hooks";
import { useFirestore } from "react-redux-firebase";
import { VerifiedPartnerConverter } from "../io/VerifiedPartnerConverter";
import { VerifiedPartner } from "../types";

const useVerifiedPartners = (): VerifiedPartner[] | undefined => {
  const auth = useAuth();
  const firestore = useFirestore();
  const [myPartners, setMyPartners] = React.useState<VerifiedPartner[]>();
  React.useEffect(() => {
    if (auth.currentUser) {
      const query = firestore
        .collection("users")
        .doc(auth.currentUser.uid)
        .collection("verifiedPartners")
        .where("redeemed", "==", false)
        .withConverter(VerifiedPartnerConverter);
      query.get().then((snapshot) => {
        setMyPartners(
          snapshot.docs
            .map((doc) => doc.data())
            .filter((partner) => !!partner.coupon)
        );
      });
    }
  }, [auth.currentUser?.uid]);

  return myPartners;
};

export default useVerifiedPartners;
