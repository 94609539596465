import React from 'react';
import {
    Flex,
    Box,
    Container,
    Spacer,
  } from "@chakra-ui/react";
import { ReactComponent as Logo } from "../../assets/quilted-color.svg";
import ColoredSvg from "../../components/layout/ColoredSVG";

interface NavbarLayoutProps {
    left: React.ReactNode,
    right: React.ReactNode,
    center: React.ReactNode,
    children: React.ReactNode
}

export const LogoBox = ({ asLink = false }:{asLink:boolean}) => {
    return asLink ? (
          <a href="/my-stories" title="My Quilted Dashboard">
            <ColoredSvg width={{ min: "100px", max: "200px" }}>
              <Logo />
            </ColoredSvg>
          </a>
        ) : (
          <ColoredSvg width={{ min: "100px", max: "200px" }}>
            <Logo />
          </ColoredSvg>
        )
  };

const NavbarLayout = ({left, center, right, children, ...props}: NavbarLayoutProps) => {
    return (
        <Flex
        direction="row"
        align="center"
        justify="between"
        py="md"
        {...props}
        minW={["sm", "md", "full"]}
        borderBottom="1px"
        borderBottomColor="gray.100"
      >
        <Flex
          as="header"
          direction="row"
          align="center"
          justify="space-between"
          w={["full", "md", "4xl"]}
          m="0 auto"
        >
          <Flex flexBasis="25%" direction="row" justify="flex-start" pl="4">
            {left}
          </Flex>
          <Spacer />
          <Flex>
            {center || <LogoBox asLink={false} />}
          </Flex>
          <Spacer />
          <Flex flexBasis="25%" display="flex" justify="flex-end" pr="4">
            {right}
          </Flex>
        </Flex>
        {children}
      </Flex>
    )
}

export default NavbarLayout;