import React from "react";
import {
  Stack,
  Text,
  Flex,
  Heading,
  Divider,
  Image,
  Container,
  HStack,
} from "@chakra-ui/react";
import svgSelectFace from "../../assets/diy-select-face.png";
import svgCreateMovie from "../../assets/diy-create-movie.png";
import svgGetGift from "../../assets/diy-get-gift.png";

const EmptyEditorState = (props: EmptyEditorStateProps) => {
  return (
    <Stack textAlign="center" p="md">
      {!props.hideTitle && (
        <Heading as="h2" size="h2" fontSize="2xl" variant="center">
          Ready to build your movie?
        </Heading>
      )}
      <Flex
        direction={["column", "column", "row", "row"]}
        justify="space-around"
        minH="400px"
        h={["auto", "500px"]}
        flex="1"
      >
        <Stack flexBasis="1/2" flex="1" p="md" pl="0" alignItems="center">
          <HStack align="start" justify="center">
            <Flex
              w="2rem"
              h="2rem"
              fontFamily="serif"
              bgColor="majesty.50"
              border="1px solid"
              borderColor="majesty.200"
              justify="center"
              align="center"
              rounded="full"
              fontSize="lg"
              fontWeight="bold"
            >
              1.
            </Flex>
            <Heading as="h4" size="h4">
              Customize your clips
            </Heading>
          </HStack>
          <Flex w="200px" h="200px" justify="center" align="center">
            <Image src={svgSelectFace} />
          </Flex>
          <Container>
            <Text fontSize="xs">
              All the clips you captured are shown to the left. Watch them, use
              the trim bar to select your favorite parts, and even hide clips
              you'd rather not include.
            </Text>
          </Container>
        </Stack>
        <Divider orientation="vertical" h={["0px", "0px", "0px", "100%"]} />
        <Stack flexBasis="1/2" flex="1" p="md" alignItems="center">
          <HStack align="start" justify="center">
            <Flex
              w="2rem"
              h="2rem"
              fontFamily="serif"
              bgColor="seaGreen.50"
              border="1px solid"
              borderColor="seaGreen.200"
              justify="center"
              align="center"
              rounded="full"
              fontSize="lg"
              fontWeight="bold"
            >
              2.
            </Flex>
            <Heading as="h3" size="h4" variant="center">
              Preview your movie
            </Heading>
          </HStack>
          <Flex w="200px" h="200px" justify="center" align="center">
            <Image src={svgCreateMovie} />
          </Flex>
          <Container>
            <Text fontSize="xs">
              When you're all done, hit the "Create Movie" button to start
              processing your movie. We'll email you in an hour or so when your
              video is ready to be viewed.
            </Text>
          </Container>
        </Stack>
        <Divider orientation="vertical" h="100%" />
        <Stack flexBasis="1/2" flex="1" p="md" pr="0" alignItems="center">
          <HStack align="start" justify="center">
            <Flex
              w="2rem"
              h="2rem"
              fontFamily="serif"
              bgColor="airBlue.50"
              border="1px solid"
              borderColor="airBlue.200"
              justify="center"
              align="center"
              rounded="full"
              fontSize="lg"
              fontWeight="bold"
            >
              3.
            </Flex>
            <Heading as="h3" size="h4" variant="center">
              Share your gift
            </Heading>
          </HStack>
          <Flex w="200px" h="200px" justify="center" align="center">
            <Image src={svgGetGift} />
          </Flex>
          <Container>
            <Text fontSize="xs">
              We'll create your movie and you can edit it until it's perfect.
              Once you pay, you can download and share the full-quality movie.
              You'll also have the chance to purchase a USB stick or video card.
            </Text>
          </Container>
        </Stack>
      </Flex>
    </Stack>
  );
};

export default EmptyEditorState;

interface EmptyEditorStateProps {
  hideTitle?: boolean;
}
