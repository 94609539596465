import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Heading,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Reminders } from "../../../machines/Dashboard/invitationsMachine";

interface SetRemindersViewProps {
  colorScheme: string;
  isLegacy: boolean;
  inviteMethod: "link"|"invites";
  onClick: (reminders: Reminders) => void;
}
export const SetRemindersView: React.FC<SetRemindersViewProps> = ({
  colorScheme,
  isLegacy,
  inviteMethod = "invites",
  onClick,
}: SetRemindersViewProps) => {
  const [reminders, setReminders] = React.useState<Reminders>({
    weekly: isLegacy,
    midway: true,
    dayBefore: !isLegacy,
    weekBefore: isLegacy,
    dueDate: !isLegacy,
    dayAfter: true,
  });

  const handleOnClick = () => {
    onClick && reminders && onClick(reminders);
  };

  const onChangeReminder = ({ target: { name, checked } }: any) => {
    setReminders({ ...reminders, [name]: checked });
  };

  const ReminderCheckbox = ({
    tooltip,
    label,
    name,
  }: {
    tooltip: string | React.ReactNode;
    label: string | React.ReactNode;
    name:
      | "weekly"
      | "midway"
      | "dayBefore"
      | "weekBefore"
      | "dueDate"
      | "dayAfter";
  }) => {
    return (
      <Checkbox
        id={name}
        name={name}
        onChange={onChangeReminder}
        isChecked={reminders[name]}
      >
        <Tooltip label={tooltip} fontSize="md">
          <Text fontWeight="medium" fontSize="sm">
            {label}
          </Text>
        </Tooltip>
      </Checkbox>
    );
  };

  const copy: {"invites":{ heading:string,body:string}}&{"link":{ heading:string,body:string}} = {
    "invites": {
      heading: "When would you like us to send reminders?",
      body: "In addition to the initial invite, we can schedule reminders to be sent to your storytellers who haven’t responded yet."
    },
    "link": {
      heading: "Would you like us to send reminders?",
      body: "If you’d like, we can email reminders to your storytellers as the due date approaches. We’ll only send reminders to people who open the link you send them and enter their email."
    }
  }

  return (
    <Box>
      <Heading as="h4" size="md" mb={3}>
        Invite storytellers
      </Heading>
      <Text fontWeight="bold" mb={3}>
        {copy[inviteMethod].heading}
      </Text>
      <Text mb={3}>
        {copy[inviteMethod].body}
      </Text>
      <Stack spacing={0.5} mb={3}>
        {isLegacy && (
          <ReminderCheckbox
            name="weekly"
            label="Weekly"
            tooltip="Send a reminder once a week during your Quilted Story"
          />
        )}
        <ReminderCheckbox
          name="midway"
          label="Midway"
          tooltip="Send a reminder midway through your Quilted Story"
        />
        {isLegacy && (
          <ReminderCheckbox
            name="weekBefore"
            label="1 week before"
            tooltip="Send a reminder the week before your Quilted Story deadline"
          />
        )}
        {!isLegacy && (
          <ReminderCheckbox
            name="dayBefore"
            label="Day before"
            tooltip="Send a reminder the day before your Quilted Story deadline"
          />
        )}
        {!isLegacy && (
          <ReminderCheckbox
            name="dueDate"
            label="Due date"
            tooltip="Send a reminder the day of your Quilted Story deadline"
          />
        )}
        <ReminderCheckbox
          name="dayAfter"
          label="Day after"
          tooltip="Send a reminder the day after your Quilted Story’s
                deadline"
        />
      </Stack>
      <Button colorScheme={colorScheme} onClick={handleOnClick}>
        Continue
      </Button>
    </Box>
  );
};

export default SetRemindersView;
