import React from "react";

import StoryManager from "../components/start/StoryManager";
import PageContainer from "../layout/components/PageContainer";
import useHotjar from "react-use-hotjar";
import config from "../config/config";
import CreateStoryWizard from "../components/start/CreateStoryWizard";

const StartPage = ({isNew}) => {
  const { initHotjar } = useHotjar();

  React.useEffect(() => {
    const { hjid, hjsv } = config.hotjar;
    initHotjar(hjid, hjsv, console.log);
  }, [initHotjar]);

  return (
    <PageContainer minW={undefined} maxW="90vw" w="3xl">
      <StoryManager isNew={isNew}>
        <CreateStoryWizard />
      </StoryManager>
    </PageContainer>
  );
};

export default StartPage;
