import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "react-redux-firebase";
import {
  Center,
  Link,
  Text,
  SimpleGrid,
  Stack,
  Heading,
} from "@chakra-ui/react";

import StoryCard from "./StoryCard";
import EmptyStories from "./EmptyStories";
import PageContainer from "../../layout/components/PageContainer";

const EmptyStoriesNotice = ({ text }) => (
  <Stack>
    <Heading as="h3" size="h3" variant="center">
      <span>{text}</span>
    </Heading>
    <Center>
      <Text fontWeight="bold">
        <Link
          href="/start/new"
          color="airBlue.500"
          _hover={{ color: "airBlue.700" }}
        >
          Create one now!
        </Link>
      </Text>
    </Center>
  </Stack>
);

function StoriesList({ stories, storiesToSetup, templates }) {
  const getTemplate = (id) => {
    return (
      [...templates].find((template) => template.id === id) || {
        accentColor: "airBlue.50",
        illustration: null,
      }
    );
  };

  return (
    <>
      {isEmpty(stories) && isEmpty(storiesToSetup) ? (
        <EmptyStories />
      ) : (
          <Stack spacing="8" w="full">
            <Heading as="h2" size="h2" variant="center">
              My Stories
            </Heading>
            <Heading as="h3" size="h3">
              Launched
            </Heading>
            {isEmpty(stories) ? (
              <EmptyStoriesNotice text="You do not have any running or completed stories." />
            ) : (
              <SimpleGrid minChildWidth="225px" spacing="20px" w="full">
                {stories.map((story) => {
                  var { accentColor, illustration } = getTemplate(
                    story.templateId
                  );
                  story.template = {
                    ...story.template,
                    accentColor,
                    illustration,
                  };
                  return (
                    <StoryCard
                      story={story}
                      key={`${story.id}`}
                      active={true}
                    />
                  );
                })}
              </SimpleGrid>
            )}
            <Heading as="h3" size="h3">
              In Setup
            </Heading>
            {isEmpty(storiesToSetup) ? (
              <EmptyStoriesNotice text="You do not have any stories in setup." />
            ) : (
              <SimpleGrid minChildWidth="225px" spacing="20px" w="full">
                {storiesToSetup.map((story) => {
                  var { accentColor, illustration } = getTemplate(
                    story.templateId
                  );
                  story.template = {
                    ...story.template,
                    accentColor,
                    illustration,
                  };
                  return <StoryCard story={story} key={story.id} />;
                })}
              </SimpleGrid>
            )}
          </Stack>
      )}
    </>
  );
}

StoriesList.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  stories: PropTypes.array,
  storiesToSetup: PropTypes.array,
};

export default StoriesList;
