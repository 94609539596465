/* eslint-disable react/prop-types */
import React from "react";

import {
  Flex,
  Container,
  Heading,
  Text,
  VStack,
  IconButton,
  Progress,
  Box,
  Center,
  SimpleGrid,
  Spacer,
} from "@chakra-ui/react";
import ChakraCarousel from "../../layout/Carousel";
import { getColorScheme } from "../../../chakraTheme";
import {
  ResponsiveImage,
  StackedResponsiveImageCard,
} from "../../layout/ImageResponsive";
import MuxImage from "../../layout/MuxImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useActor } from "@xstate/react";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { FC } from "react";
import { Player } from "../../video/Player";
import RespondButton from "./RespondButton";
import { promptMachine } from "../../../machines/Response/promptMachine";
import { InterpreterFrom } from "xstate";

type PromptData = { idx: string; text: string; response: any; ref: any };

const PromptTile = ({
  prompt,
  index,
  onPlay,
}: {
  prompt: PromptData;
  index: number;
  onPlay: () => void;
}) => {
  const [state, send] = useActor(
    prompt.ref as InterpreterFrom<typeof promptMachine>
  );
  const { response, text } = state.context;
  const [hash, setHash] = React.useState(new Date().getTime());
  React.useEffect(() => {
    setHash(new Date().getTime());
  }, [response]);

  const getCacheBusterUrl = (url: string) => {
    const separator = url.indexOf("?") === -1 ? "?" : "&";
    return `${url}${separator}hash=${hash}`;
  };

  return (
    <>
      {!state.matches("error") ? (
        <Flex
          key={index}
          shadow="md"
          justifyContent="space-between"
          flexDirection="column"
          overflow="hidden"
          rounded={5}
          flex={1}
          p={5}
          bgColor={`${getColorScheme(index)}.${
            state.matches("recording") ? "400" : "100"
          }`}
          minH={isMobile ? "390px" : "225px"}
          data-state={state.value}
        >
          <VStack mb={6}>
            <Heading
              textAlign="left"
              w="full"
              as="h4"
              variant="sans"
              size="xs"
              fontWeight="bold"
            >
              Question {index + 1}
            </Heading>
            <Text fontSize="md" lineHeight="1.25rem" w="full" variant="serif">
              {text}
            </Text>
          </VStack>
          <Spacer />
          <Flex align="center" justify="center" my="1" mx="0">
            {state.matches("processing") ? (
              <Box w="full" h="full">
                <Progress
                  size="xs"
                  isIndeterminate
                  colorScheme={getColorScheme(index)}
                />
                <Center>
                  <Text fontSize="xs">Processing...</Text>
                </Center>
              </Box>
            ) : (
              response &&
              (response.type === "image" ? (
                <StackedResponsiveImageCard
                  maxW="290"
                  maxH="208"
                  anchor="center"
                  margin={{ horizontal: "auto", bottom: "medium" }}
                  flex={false}
                >
                  <ResponsiveImage
                    src={getCacheBusterUrl(response.content.downloadUrl)}
                    key={hash}
                  />
                  <></>
                </StackedResponsiveImageCard>
              ) : response.content.muxPlaybackId ? (
                <StackedResponsiveImageCard
                  maxW="290"
                  maxH="208"
                  anchor="center"
                  margin={{ horizontal: "auto", bottom: "medium" }}
                  flex={false}
                >
                  <MuxImage
                    playbackId={response.content.muxPlaybackId}
                    dimensions={null}
                    time={null}
                    alt={`Prompt Video`}
                    crop="smartcrop"
                  />
                  <IconButton
                    color="white"
                    bgColor="seaGreen.600"
                    _hover={{ bgColor: "seaGreen.400" }}
                    icon={<FontAwesomeIcon icon="play" />}
                    onClick={onPlay}
                    border="white"
                    aria-label="play"
                  />
                </StackedResponsiveImageCard>
              ) : null)
            )}
          </Flex>
          <Flex justifyContent="center">
            <RespondButton
              onClick={(useInput) => {
                console.log("RESPOND CLICK", {
                  type: !!useInput ? "CAPTURE" : "RECORD",
                  useInput: !!useInput,
                });
                send({
                  type: !!useInput ? "CAPTURE" : "RECORD",
                  useInput: !!useInput,
                });
              }}
              colorScheme={
                state.matches("processing") ? "gray" : getColorScheme(index)
              }
              color="gray.900"
              size="sm"
              variant={(response && "outline") || undefined}
              disabled={state.matches("processing")}
            >
              {!!response || state.matches("processing") ? (
                <span>Redo Response</span>
              ) : (
                <span>Add Response</span>
              )}
            </RespondButton>
          </Flex>
        </Flex>
      ) : state.matches("error") ? (
        <>Error</>
      ) : null}
    </>
  );
};

const LayoutView: FC = ({ children }) => {
  return (
    <Container
      py={8}
      px={0}
      maxW={{
        sm: "full",
        md: "full",
        lg: "lg",
        xl: "full",
      }}
      height="full"
    >
      <BrowserView>
        <SimpleGrid columns={[1, 2, 3]} spacing={5}>
          {children}
        </SimpleGrid>
      </BrowserView>
      <MobileView>
        <ChakraCarousel gap={32}>{children}</ChakraCarousel>
      </MobileView>
    </Container>
  );
};

const PromptsList = ({ prompts }: { prompts: Array<PromptData> }) => {
  const [playlist, setPlaylist] = React.useState<Array<any> | null>(null);

  return (
    (prompts && (
      <>
        {playlist && (
          <Player onClose={() => setPlaylist(null)} playlist={playlist} />
        )}
        <LayoutView>
          {prompts.map((prompt, index) => (
            <PromptTile
              prompt={prompt}
              index={index}
              key={index}
              onPlay={() => {
                setPlaylist([
                  { text: prompt.text, url: prompt.response.content.streamUrl },
                ]);
              }}
            />
          ))}
        </LayoutView>
      </>
    )) ||
    null
  );
};

export default PromptsList;
