import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { iStoryManagerStateContext } from "../../../../types/StoryManagerState";
import getPricing, { Prices } from "../../../../utils/pricing";
import { useStoryManagerState } from "../../StoryManager";

const PricingBoxContainer = () => {
  // @ts-ignore
  const {
    state: { story },
  }: { state: iStoryManagerStateContext } = useStoryManagerState();
  const forceConcierge = !!story?.template?.conciergeOnly;
  const pricing = getPricing(story?.templateId as string);

  return !!pricing.hide ? null : (
    <>
      {!forceConcierge ? (
        <PricingBox>
          Final movies start at{" "}
          <Text as="span" color="majesty.700">
            {"$"}
            {(pricing.diy.price - (pricing.diy.discount || 0)).toFixed(2)}
          </Text>
        </PricingBox>
      ) : (
        <PricingBox>
          Concierge movies are{" "}
          <Text as="span" color="majesty.700">
            {"$"}
            {(
              pricing.concierge.price - (pricing.concierge.discount || 0)
            ).toFixed(2)}
          </Text>
        </PricingBox>
      )}
    </>
  );
};

export const PricingBox: React.FC<any> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Box bgColor="majesty.50" p="xl" textAlign="center" rounded="lg">
      <Text variant="serif" fontSize="md" my="auto">
        <strong>
          You don&apos;t pay until you&apos;ve collected your videos
        </strong>{" "}
        and requested your
        <br />
        final compiled movie.&nbsp; {children}
      </Text>
    </Box>
  );
};

export default PricingBoxContainer;
