import React from "react";
import { Link, Button, HStack, Text, ButtonProps } from "@chakra-ui/react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { useTheme } from "@emotion/react";
import { useClickToCopy } from "../../hooks/useClickToCopy";


const ClickToCopy = ({
  onCopy,
  label,
  url,
  asButton = false,
  iconColor,
  textColor,
  ...props
}: iClickToCopy) => {
  const theme = useTheme();
  //@ts-ignore
  const tumbleweed = theme.colors.tumbleweed[500];

  const handler = useClickToCopy(url, onCopy);

  if (asButton) {
    return (
      <Button
        color={textColor || undefined}
        variant="unstyled"
        fontWeight="normal"
        {...handler}
      >
        <HStack>
          <FontAwesomeIcon
            icon={["far", "copy"]}
            color={iconColor || tumbleweed}
          />
          <Text>Copy link</Text>
        </HStack>
      </Button>
    );
  }

  return (
    <Link href={url || "#"} {...handler} {...props}>
      {label || url}
    </Link>
  );
};

export default ClickToCopy;

interface iClickToCopy {
  onCopy: (...args: any[]) => any;
  label?: string;
  url: string;
  asButton?: boolean;
  iconColor?: FontAwesomeIconProps["color"];
  textColor?: ButtonProps["color"];
}
