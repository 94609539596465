import React from "react";

import { Heading, Stack } from "@chakra-ui/layout";
import TemplateSelector from "./components/TemplateSelector";
import { useEffect } from "react";
import { useStoryManagerDispatch, useStoryManagerState } from "../StoryManager";
import { iStoryManagerStateContext } from "../../../types/StoryManagerState";

const TemplateStep = ({ id }: { id: string }) => {
  const dispatch = useStoryManagerDispatch();
  // @ts-ignore
  const {
    state: { story },
  }: { state: iStoryManagerStateContext } = useStoryManagerState();

  useEffect(() => {
    dispatch({
      type: "update",
      payload: {
        valid: {
          [id]: {
            hasTemplate: !!story?.templateId,
          },
        },
      },
    });
  }, [story]);

  return (
    <Stack spacing="8">
      <Heading as="h2" size="h2" variant="center">
        What&apos;s your occasion?
      </Heading>
      <TemplateSelector id={id} />
    </Stack>
  );
};

export default TemplateStep;
