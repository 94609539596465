import React from "react";
import InitialCardView from "./InitialCardView";
import StepCard from "../StepCard";
import { useAnalytics } from "use-analytics";
import CardImage from "../../../assets/tell-your-story.svg";
import IncompleteCardView from "./IncompleteCardView";
import CompleteCardView from "./CompleteCardView";

type cardProps = {
  colorScheme: string;
  promptCount: number;
  responseCount: number;
  storyUrl: string;
};

const TellYourStoryCard = ({
  colorScheme,
  promptCount,
  responseCount,
  storyUrl,
}: cardProps) => {
  const { track } = useAnalytics();

  const openResponseView = () => {
    const params = [
      "resizable=no",
      "toolbar=no",
      "scrollbars=no",
      "menubar=no",
      "status=no",
      "directories=no",
      //@ts-ignore
      "height=" + screen.height,
      //@ts-ignore
      "width=" + screen.width,
      "fullscreen=yes", // only works in IE, but here for completeness
    ].join(",");
    track("dashboard:user_startedResponding", {
      storyUrl,
      promptCount,
      responseCount,
    });
    //@ts-ignore
    window.open(storyUrl, "MyResponses", params);
  };

  return (
    <StepCard image={CardImage} colorScheme={colorScheme}>
      {!responseCount ? (
        <InitialCardView colorScheme={colorScheme} onClick={openResponseView} />
      ) : responseCount < promptCount ? (
        <IncompleteCardView
          colorScheme={colorScheme}
          responses={responseCount}
          prompts={promptCount}
          onClick={openResponseView}
        />
      ) : (
        <CompleteCardView
          colorScheme={colorScheme}
          onClick={openResponseView}
        />
      )}
    </StepCard>
  );
};

export default TellYourStoryCard;
