import  React from 'react';
import {
    Box,
    Button,
    Flex,
    Heading,
    Image,
    Stack,
    Text,
    Container
  } from "@chakra-ui/react";
import { useActor } from '@xstate/react';
import { useEffect } from 'react';

const RespondedView = ({ onClick, progress }: { onClick: () => void, progress: number}) => {

  useEffect(() => {
    console.log("progress", progress);
  },[progress])

    return (
      <Flex
        justify="space-between"
        my="lg"
        h="full"
        direction="column"
        p="lg"
        shadow="lg"
        bgColor="springWood.100"
        rounded="lg"
        align="center"
      >
        <Box>
          <Heading as="h2" size="h2" variant="center">
            Success
          </Heading>
          {!!progress && progress < 100 && (
            <Text align="center" my="4">
              (but don't go anywhere yet)
            </Text>
          )}
        </Box>
        <Stack spacing="6">
          <Image
            src="/img/package-icons/mountain.svg"
            maxW="300px"
            alignSelf="center"
          />
          <Container>
            <Text align="center" lineHeight="short" fontSize="lg">
              Your video has been submitted. You can continue to upload additional responses while your video is uploading.
            </Text>
          </Container>
        </Stack>
        <Button
          colorScheme="seaGreen"
          onClick={onClick}
          role="button"
          data-testid="continue"
        >
          Continue responding
        </Button>
      </Flex>
    );
  }

  export default RespondedView;