import React from 'react';
import { Route, Routes, Navigate } from "react-router-dom"
import AuthPage from '../components/auth/AuthPage';
import { Layout } from '../layout';
import PrivateOutlet from "./PrivateOutlet";
import Dashboard from "../pages/MyStories";
import PageNotFound from './content/PageNotFound';
import StartPage from '../pages/Start';
import GoodByePage from '../pages/GoodBye';
import DeleteAccount from '../components/auth/DeleteAccount';
import FacebookDeauthorizationPage from '../pages/FacebookDeauthorization';
import ResetPassword from '../components/auth/ResetPassword';
import RequestPasswordReset from '../components/auth/RequestPasswordReset';
import Partners from '../components/partners/pages/Partners';
import PrivateRoute from './PrivateRoute';
import AnonymousRoute from './AnonymousRoute';
import MyStoriesRoutes from "./content/my-stories/MyStoriesRoutes";
import StoryRoutes from './content/story/StoryRoutes';
import QrCodeHandler from './content/story/QrCodeHandler';


const AppRoutes = () => {

    return <Routes>
    <Route path="*" element={<Layout />}>
      <Route index element={<AnonymousRoute><Navigate to={"/signup"} replace /></AnonymousRoute>} />
      <Route path="signup" element={<AnonymousRoute><AuthPage authMode="signup" /></AnonymousRoute>} />
      <Route path="login" element={<AnonymousRoute><AuthPage authMode="login" /></AnonymousRoute>} />
      <Route path="signin" element={<AnonymousRoute><Navigate to={"/login"} replace /></AnonymousRoute>} />

      <Route path="reset-password" exact element={<RequestPasswordReset />} />
      <Route path="reset-password/verify" exact element={<ResetPassword />} />
      <Route path="facebook/:status" exact element={<FacebookDeauthorizationPage />}/>
      <Route path="delete-account" exact element={<PrivateRoute to="/good-bye"><DeleteAccount /></PrivateRoute>} />
      <Route path="good-bye" exact element={<AnonymousRoute><GoodByePage /></AnonymousRoute>} />

      <Route path="partners/:id" element={<Partners />} />

      <Route path="start" exact element={<Navigate to="/start/new" replace />} />
      <Route path="start/new">
          <Route index element={<StartPage isNew={true} />} />
          <Route path=":step" element={<StartPage isNew={true} />} />
      </Route>
      <Route path="edit/:id">
          <Route index element={<StartPage />} />
          <Route path=":step" element={<StartPage />} />
      </Route>

      <Route path="my-stories" element={<PrivateOutlet />}>
        <Route index element={<Dashboard />} />
        <Route path="story/:id/*" element={<MyStoriesRoutes />} />
      </Route>
      <Route path="404" element={<PageNotFound />} />
      <Route path="qr/:slug/*" element={<QrCodeHandler />} />
      <Route path=":slug/*" element={<StoryRoutes />} />
      <Route path="*" element={<PageNotFound />} />
    </Route>
  </Routes>
}

export default AppRoutes;