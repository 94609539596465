/* eslint-disable react/prop-types */
import React, { memo } from "react";
import { Box, Flex, Image, Show, useColorModeValue } from "@chakra-ui/react";
import { MotionBox } from "../common/Motions";
import { themeColors } from "../../chakraTheme";
import { usePresence } from "framer-motion";
import ActionCard, { ActionCardProps } from "../common/ActionCard";

export interface StepCardProps extends ActionCardProps {
  image: string;
}

const StepCard: React.FC<StepCardProps> = (props: StepCardProps) => {
  return <ActionCard {...props} />;
};

export const MotionStepCard: React.FC<{ key: any }> = ({ children }) => {
  const [isPresent, safeToRemove] = usePresence();
  const transition = { type: "spring", stiffness: 500, damping: 50, mass: 1 };
  const animations = {
    layout: true,
    initial: "out",
    style: {
      position: isPresent ? "static" : "absolute",
    },
    animate: isPresent ? "in" : "out",
    whileTap: "tapped",
    whileHover: "hover",
    whileFocus: "hover",
    variants: {
      in: { scaleY: 1, opacity: 1, zIndex: 0, transition: { duration: 0.5 } },
      out: { scaleY: 0, opacity: 0, zIndex: -1, transition: { duration: 0.5 } },
      tapped: { scale: 0.95, transition: { duration: 0.25 } },
      hover: { zIndex: 1 },
    },
    onAnimationComplete: () => !isPresent && safeToRemove && safeToRemove(),
    transition,
  };

  //@ts-ignore
  return <MotionBox {...animations}>{children}</MotionBox>;
};

export default StepCard;
