import React from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import PageContainer from "../../layout/components/PageContainer";
import InvitationsCard from "./invitations/InvitationsCard";
import TellYourStoryCard from "./tellYourStory/TellYourStoryCard";
import FinalMovieCard, { finalMovieState } from "./finalMovie/FinalMovieCard";
import ResponsesCard, { Prompt } from "./responses/ResponsesCard";
import {
  InvitationsContext,
  Reminders,
  Storyteller,
} from "../../machines/Dashboard/invitationsMachine";
import { useLocation, useNavigate } from "react-router-dom";
import { useAnalytics } from "use-analytics";
import { MotionStepCard } from "./StepCard";
import AnnouncementBox from "./AnnouncementBox";
import { useFeatureFlags } from "../featureFlags";
import ServicesDetailModal from "../serviceComparison/ServicesDetailModal";

interface DashboardProps {
  dueDate?: Date;
  storytellers?: Storyteller[];
  reminders?: Reminders;
  storyUrl: string;
  subject: string;
  title: string;
  prompts: Prompt[];
  isLegacy: boolean;
  hasResponses: boolean;
  myResponseCount: number;
  inviteMethod?: "invites" | "link";
  finalVideoState: finalMovieState;
  finalVideoIsConcierge: boolean;
  finalVideoIsFinal: boolean;
  forceConcierge?: true;
  templateId: string;
  onFinalVideoClick: () => void;
  onSaveDueDate: (context: InvitationsContext) => Promise<unknown>;
  onSaveStorytellers: (context: InvitationsContext) => Promise<unknown>;
  onSaveReminders: (context: InvitationsContext) => Promise<unknown>;
  onSaveInviteMethod: (context: InvitationsContext) => Promise<unknown>;
}
const Dashboard = ({
  dueDate,
  storytellers,
  reminders,
  storyUrl,
  subject,
  title,
  prompts,
  isLegacy,
  hasResponses,
  myResponseCount,
  inviteMethod,
  finalVideoState,
  finalVideoIsConcierge,
  finalVideoIsFinal,
  forceConcierge,
  templateId,
  onSaveDueDate,
  onSaveStorytellers,
  onSaveReminders,
  onSaveInviteMethod,
  onFinalVideoClick,
}: DashboardProps) => {
  const { isOpen, onClose, onToggle } = useDisclosure();

  const navigate = useNavigate();
  const { hash } = useLocation();
  const { track } = useAnalytics();
  const flags = useFeatureFlags();
  const announcementLanguage = JSON.parse(
    flags.conciergeAnnouncementLanguage.getValue()
  ) as { copy: string; cta: string };

  const complete = finalVideoState === "complete";
  const cards: Array<{
    position: number;
    component: any;
    key: string;
    show: boolean;
  }> = [
    {
      position:
        !dueDate || (!storytellers && !inviteMethod) || !reminders ? 1 : 2,
      key: "invitations",
      show: !complete,
      component: (
        <InvitationsCard
          colorScheme="airBlue"
          dueDate={dueDate}
          storytellers={storytellers}
          reminders={reminders}
          subject={subject}
          isLegacy={isLegacy}
          promptCount={(prompts && prompts.length) || 0}
          inviteMethod={inviteMethod}
          onSaveDueDate={onSaveDueDate}
          onSaveStorytellers={onSaveStorytellers}
          onSaveReminders={onSaveReminders}
          onSaveInviteMethod={onSaveInviteMethod}
        />
      ),
    },
    {
      position:
        !!dueDate && (!!storytellers || !!inviteMethod) && !!reminders ? 1 : 2,
      key: "tellYourStory",
      show: !complete,
      component: (
        <TellYourStoryCard
          colorScheme="seaGreen"
          promptCount={(prompts && prompts.length) || 0}
          responseCount={myResponseCount}
          storyUrl={storyUrl}
        />
      ),
    },
    {
      position: finalVideoState !== "notStarted" ? 0 : 3,
      key: "finalMovie",
      show: true,
      component: (
        <FinalMovieCard
          colorScheme="majesty"
          subject={subject}
          state={finalVideoState}
          isConcierge={finalVideoIsConcierge}
          isFinal={finalVideoIsFinal}
          forceConcierge={forceConcierge}
          disabled={!hasResponses}
          onClick={onFinalVideoClick}
        />
      ),
    },
    {
      position: 4,
      key: "responses",
      show: hasResponses && !complete,
      component: <ResponsesCard colorScheme="tumbleweed" prompts={prompts} />,
    },
  ];

  return (
    <PageContainer w="90vw">
      <Helmet>
        <title>Quilted | {title}</title>
      </Helmet>
      <Flex
        direction="column"
        alignSelf="stretch"
        flex={1}
        align="center"
        justify="start"
        width="100%"
        pb="sm"
        my="lg"
      >
        {!complete && (
          <Heading
            as="h2"
            size="h2"
            fontSize="2xl"
            variant="center"
            alignSelf="center"
          >
            {hash === "#launched" ? "Ready to start your story?" : title}
          </Heading>
        )}
        {!complete && (
          <Container>
            <Text textAlign="center" mb={3}>
              {hash === "#launched" ? (
                <>
                  Your story is all set up, but you need some responses to send
                  to {subject}. Kick things off by sending out your story to
                  friends and family or adding a few responses of your own.
                </>
              ) : (
                <>
                  Manage your story, view responses, and start your final video.
                </>
              )}
            </Text>
          </Container>
        )}
        {!complete && !!dueDate && !!storytellers && !!reminders && (
          <Button
            variant="outline"
            color="black"
            px={10}
            onClick={() => {
              track("dashboard:user_openedStorySettings");
              navigate("settings");
            }}
          >
            Manage story settings
          </Button>
        )}
        <Stack my={3} gap={4}>
          {!forceConcierge && !complete && finalVideoState === "notStarted" && (
            <>
              <AnnouncementBox colorScheme="majesty" icon="photo-film">
                <Text mb={2}>{announcementLanguage.copy}</Text>
                <Button
                  variant="link"
                  colorScheme="majesty"
                  onClick={() => {
                    track("dashboard:user_openedDIYvsConciergeModal");
                    onToggle();
                  }}
                >
                  {announcementLanguage.cta}
                </Button>
              </AnnouncementBox>
              <ServicesDetailModal
                isOpen={isOpen}
                onClose={onClose}
                templateId={templateId}
              />
            </>
          )}
          {cards
            .sort((a, b) => a.position - b.position)
            .filter((card) => card.show)
            .map((card) => (
              <MotionStepCard key={card.key}>{card.component}</MotionStepCard>
            ))}
        </Stack>
      </Flex>
    </PageContainer>
  );
};

export default Dashboard;
