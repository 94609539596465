import React, { useEffect } from "react";
import {
  Stack,
  Text,
  Flex,
  Heading,
  Button,
  Box,
  IconButton,
  Divider,
} from "@chakra-ui/react";
import ContinueEditingLatest from "./ContinueEditingLatest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import ReactPlayer from "react-player";
// @ts-ignore
import { useAnalytics } from "use-analytics";

const LoadVideoProjectState = (props: LoadVideoProjectStateProps) => {
  const { existingProjects, completedProjects, setProject } = props;

  const { track } = useAnalytics();

  useEffect(() => {
    track("finalVideo | Shown LoadVideoProject Screen");
  }, []);

  const ProjectPlaybackBox = (props: { idx: number; project: any }) => {
    const { idx, project } = props;
    return (
      <Stack
        key={idx}
        _hover={{ shadow: "lg" }}
        transition="200ms linear"
        p="sm"
        rounded="md"
        border=".5px solid"
        borderColor="gray.300"
        align="flex-start"
        bgColor="springWood.100"
        maxH="400px"
      >
        <Flex direction="column" maxW="400px">
          <Flex
            direction={["column", "row"]}
            justify="space-between"
            align="center"
            w="100%"
            rounded="sm"
            p="sm"
            bgColor="airBlue.50"
          >
            <Text
              fontSize="md"
              fontWeight="medium"
              textAlign={["center", "left"]}
            >
              Open the movie you started on{" "}
              {moment
                .unix(project.createdAt.seconds)
                .format("MMMM Do [at] h:mm a")
                .toString()}
            </Text>
            <IconButton
              border="1px solid"
              ml={["initial", "4rem"]}
              colorScheme="airBlue"
              rounded="full"
              icon={<FontAwesomeIcon icon="chevron-right" />}
              aria-label="edit project"
              onClick={() => {
                track("finalVideo | Started editing previous version", {
                  previousRevision1Index: idx + 1,
                });
                setProject({
                  ...completedProjects[0],
                  id: null,
                });
              }}
            />
          </Flex>
          <ReactPlayer
            controls
            style={{ marginTop: "1.5rem" }}
            width="100%"
            height="100%"
            url={project.streamUrl}
          />
        </Flex>
      </Stack>
    );
  };

  return (
    <Flex
      direction="row"
      align="center"
      textAlign="center"
      p="md"
      h={["initial", "100%"]}
      justify="center"
    >
      <Flex
        direction={["column", "row"]}
        justify="space-around"
        minH="200px"
        maxH={["initial", "100%"]}
        mt="lg"
      >
        <Stack
          flexBasis="2/3"
          flex="1"
          p={["0", "lg"]}
          pl="0"
          spacing="xl"
          overflow={["visible", "auto"]}
        >
          <ContinueEditingLatest
            existingProjects={existingProjects}
            setProject={setProject}
          />
          {!!completedProjects?.length && <Divider borderStyle="dotted" />}
          {!!completedProjects?.length && (
            <Flex mt="lg" direction="column" align="center">
              <Heading as="h5" size="h5" mb="1rem">
                Or continue with a previous version
              </Heading>
              <Flex flexWrap="wrap" gridGap="2rem">
                {completedProjects.map((project: any, idx: number) => {
                  return (
                    <ProjectPlaybackBox key={idx} idx={idx} project={project} />
                  );
                })}
              </Flex>
            </Flex>
          )}
        </Stack>
        <Box w="1" borderLeft=".5px solid" h="100%" />
        <Stack
          pt={["lg", "0"]}
          px="lg"
          alignItems="flex-start"
          justifyContent="center"
          flexBasis="1/3"
          flex="1"
          maxW={{ md: "300px" }}
          spacing="md"
        >
          <Stack align="flex-start">
            <Heading as="h4" size="h4">
              Prefer a clean slate?
            </Heading>
            <Text textAlign="left">
              Not happy with any of your previous edits thus far? Start your
              editing process over with your storytellers’ original clips.
            </Text>
          </Stack>
          <Button
            variant="outline"
            colorScheme="majesty"
            rounded="md"
            onClick={() => {
              track("finalVideo | DIY Video - Start over");
              setProject(null);
            }}
          >
            Start a new version
          </Button>
        </Stack>
      </Flex>
    </Flex>
  );
};

export default LoadVideoProjectState;

interface LoadVideoProjectStateProps {
  existingProjects: any;
  setProject: (x: any) => any;
  completedProjects: any;
}
