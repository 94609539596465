import React from "react";
import { Route, Routes, Outlet, useParams } from "react-router-dom";

import ManageStorySettings from "../../../components/story/StorySettings";
import FinalVideoPage from "../../../pages/FinalVideo";
import StoryProvider from "../../../components/story/StoryProvider";
import NoSubmissionsPage from "../../../pages/NoSubmissions";
import DiyVideoWrapper from "../../../pages/DIYVideoWrapper";
import StoryDashboard from "../../../pages/StoryDashboard";

const StoryContentLayout = () => {
  let params = useParams();
  return (
    <StoryProvider params={params}>
      <Outlet />
    </StoryProvider>
  );
};

const MyStoriesRoutes = () => {
  return (
    <Routes>
      <Route element={<StoryContentLayout />}>
        <Route index element={<StoryDashboard />} />
        <Route path="final-video/*" element={<FinalVideoPage />} />
        <Route path="no-submissions" element={<NoSubmissionsPage />} />
        <Route path="settings" element={<ManageStorySettings />} />
        <Route path="diy/*" element={<DiyVideoWrapper />} />
      </Route>
    </Routes>
  );
};

export default MyStoriesRoutes;
