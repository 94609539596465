import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { normalizeColor } from "../../utils/colors";
import { Box } from "grommet";
import { Box as ChakraBox } from "@chakra-ui/react";

const StyledChatBox = styled.div`
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  background-color: ${(props) =>
    normalizeColor(
      (props.background && props.background) || "light-5",
      props.theme
    )};
  position: relative;
  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: -10px;
    left: -7px;
    height: 30px;
    width: 20px;
    background: ${(props) =>
      normalizeColor(
        (props.background && props.background) || "light-5",
        props.theme
      )};
    border-bottom-right-radius: 15px;
  }
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: -10px;
    left: -10px;
    width: 10px;
    height: 30px;
    background: ${(props) =>
      normalizeColor(props.tailBackground || "background", props.theme)};
    border-bottom-right-radius: 10px;
  }
`;

const ChatBox = ({ background, pad = "md", children, ...rest }) => {
  return (
    <StyledChatBox {...rest} background={background}>
      <ChakraBox p={pad}>{children}</ChakraBox>
    </StyledChatBox>
  );
};

export const ChakraChatBox = ({ background, children, ...props }) => {
  return (
    <ChakraBox
      sx={{
        borderRadius: "20px",
        marginTop: "5px",
        marginBottom: "5px",
        display: "inline-block",
      }}
      _before={{
        content: `"\\21"`,
        position: "absolute",
        zIndex: "0",
        bottom: "-10px",
        left: "-7px",
        height: "30px",
        width: "20px",
        borderBottomRightRadius: "15px",
        background: background || "tumbleweed.100",
      }}
      _after={{
        content: `"\\21"`,
        position: "absolute",
        zIndex: "1",
        bottom: "-10px",
        left: "-10px",
        width: "10px",
        height: "30px",
        borderBottomRightRadius: "10px",
        background: props.tailBackground || "tumbleweed.100",
      }}
    >
      {children}
    </ChakraBox>
  );
};

ChatBox.propTypes = {
  /* eslint-enable react/no-unused-prop-types */
  background: PropTypes.string,
};

export default ChatBox;
