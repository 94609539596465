import firebase from 'firebase';
import { createFirestoreInstance } from 'redux-firestore';
import * as Sentry from "@sentry/react";
import config from './config';


const getFB = () => {
  try {
    return firebase.app && firebase.app();
  } catch (error) {
    return firebase.initializeApp(config.firebase);;
  }
};

const fb = getFB();

if(config.isLocal) {
  firebase.functions().useEmulator("localhost", 5003);
  //firebase.auth().useEmulator("http://localhost:9099");
  firebase.storage().useEmulator("localhost", 9199);
}

firebase.auth().onAuthStateChanged(function (user) {
  console.log("sentry auth state changed. User: ", !!user)
  if (user) {
    // User is signed in.
    Sentry.setUser({ email:user.email, uid:user.uid });
  }
  else {
    Sentry.configureScope(scope => scope.setUser(null));
  }
});

// Initialize Performance Monitoring and get a reference to the service
export const performance = firebase.performance();

export default firebase;

// To refresh user's claims, in user profile firestore doc, add/update field 'refreshToken'.
export const reactReduxFirebaseConfig = { userProfile: 'users', enableClaims: true, useFirestoreForProfile: true }

export const createFirestoreInstanceExt = (
  firebaseInstance,
  configs,
  dispatch,
) => {
  const firestore = createFirestoreInstance(firebaseInstance, configs, dispatch);

  if(config.isLocal) {
    console.log('Running locally, switch firestore to use emulator');
    firestore.settings({
      host: "localhost:8080",
      ssl: false,
    });
  }
  return firestore;
};