import React, { useEffect } from "react";
import ModalDialog from "../common/ModalDialog";
import { Button, useBreakpointValue, Center } from "@chakra-ui/react";
import ServicesDetail from "./ServicesDetail";

const ServicesDetailModal = ({
  isOpen,
  onClose,
  templateId,
}: ModalProps & { templateId: string }) => {
  const responsiveValues: any | undefined = useBreakpointValue({
    base: {},
    lg: {
      width: "full",
      maxW: "950px",
    },
  });

  useEffect(() => {
    if (isOpen) {
    }
  }, [isOpen]);

  return (
    <ModalDialog
      hideCloseButton
      isOpen={isOpen}
      onClose={onClose}
      modalContent={responsiveValues}
    >
      <ServicesDetail templateId={templateId} />
      <Center>
        <Button onClick={onClose}>Done</Button>
      </Center>
    </ModalDialog>
  );
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default ServicesDetailModal;
