import { useFirestore } from "react-redux-firebase";
import {
  Flex,
  Box,
  Progress,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import PageContainer from "../layout/components/PageContainer";

import { useStoryContext } from "../components/story/StoryProvider";
import { Story } from "../types/StoryManagerState";
import { VideoSubmission } from "../types/DIYVideoBuilder";
import LoadVideoProjectState from "../components/diyVideo/LoadVideoProjectState";
import ProjectEditor from "../components/diyVideo/ProjectEditor";
import ReviewMoviePreview from "../components/diyVideo/ReviewMoviePreview";
import { Helmet } from "react-helmet";
// @ts-ignore
import { useAnalytics } from "use-analytics";
import { buildIllustrationUrl } from "../utils/buildIllustrationUrl";
import useVerifiedPartners from "../components/partners/hooks/useVerifiedPartners";

const DiyVideoWrapper = () => {
  const firestore = useFirestore();
  const { track } = useAnalytics();

  const partners = useVerifiedPartners();

  const storyContext = useStoryContext();
  // @ts-ignore
  const { story }: { story: Story; submissions: VideoSubmission[] } =
    storyContext;

  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [existingProjects, setExistingProjects] =
    useState<any[] | undefined | null>(undefined);
  const [selectedProject, setSelectedProject] =
    useState<string | undefined | null>(undefined);
  const [projectsInProgress, setProjectsInProgress] = useState<any[]>();
  const [projectsInReview, setProjectsInReview] = useState<any[]>();

  // Get projects from firebase
  // Sort projects by date(updated or created)
  // Check for any projects that are processing, show processing screen
  // Check for any projects that are completed, show review screen
  // Finish loading
  const loadStories = async () => {
    const projs: any[] = [];
    let sortedProjs: any[] = [];
    if (story.id) {
      return await firestore
        .collection("stories")
        .doc(story.id)
        .collection("diyVideos")
        .get()
        .then((projects) => {
          projects.forEach((proj) =>
            projs.push({ ...proj.data(), id: proj.id })
          );
          sortedProjs = projs
            .map((p) => {
              return {
                ...p,
                latestAction: p.updatedAt?.toDate() || p.createdAt?.toDate(),
              };
            })
            .sort((a, b) => {
              return b.latestAction - a.latestAction;
            });
        })
        .then(() => {
          if (!sortedProjs.length || sortedProjs.length === 0) {
            setExistingProjects(null);
            setSelectedProject(null);
          } else setExistingProjects(sortedProjs);
        })
        .then(() => {
          const editingVideos = sortedProjs.filter(
            (proj) =>
              proj.state === "editing" ||
              proj.state === "process" ||
              proj.state === "processing"
          );
          if (editingVideos.length) {
            setProjectsInProgress(editingVideos);
          }

          const completedVideos = sortedProjs.filter(
            (proj) => proj.state === "reviewing" || proj.state === "complete"
          );
          if (completedVideos.length) {
            setProjectsInReview(completedVideos);
            return;
          }

          const processingVideos = sortedProjs.filter(
            (proj) => proj.state === "process" || proj.state === "processing"
          );
          if (processingVideos?.length) {
            setSelectedProject(processingVideos[0]);
            return;
          }
        })
        .then(() => {
          setHasLoaded(true);
        });
    }
  };

  useEffect(() => {
    loadStories();
    track("finalVideo | Opened DIY Video Editor", {
      storyId: story?.id,
      storyTitle: story?.title,
      storySubject: story?.subject,
      storySlug: story?.slug,
      templateId: story?.template?.id,
      templateName: story?.template?.title,
      templateIllustration: buildIllustrationUrl(story?.template?.illustration),
    });
  }, [story.id]);

  return (
    <PageContainer w="90vw">
      <Helmet>
        <title>Quilted | DIY Video Editor</title>
      </Helmet>
      <Flex
        direction="column"
        alignSelf="stretch"
        flex={1}
        align="stretch"
        justify="start"
        width="100%"
        pb="sm"
      >
        {/* A project can automatically be created and selected if there are no other existing projects. When better URLs are implemented, this can also automatically go to an existing project based on the url params */}
        {hasLoaded && (selectedProject || selectedProject === null) ? (
          <ProjectEditor
            selectedProject={selectedProject}
            partners={partners}
          />
        ) : (
          <Flex
            direction="column"
            bg="springWood.200"
            shadow="lg"
            flex={2}
            minW="350px"
            alignSelf="stretch"
            overflow="scroll"
            maxH="calc(100vh - 80px)"
          >
            {/* If there are projects in progress and no projects being reviewed, or if there are projects in progress and projects being reviewed, but the project in progress has been updated more recently than the project in review, advance to the ProjectEditor */}
            {(hasLoaded &&
              projectsInProgress?.length &&
              !projectsInReview?.length) ||
            (hasLoaded &&
              projectsInProgress?.length &&
              projectsInReview?.length &&
              projectsInProgress[0].latestAction >
                projectsInReview[0].latestAction) ? (
              <>
                {/* If the latest project in progress is being processed, show the processing screen. Otherwise show the project selector screen */}
                {projectsInProgress[0].state === "process" ||
                projectsInProgress[0].state === "processing" ? (
                  <ProjectEditor
                    selectedProject={projectsInProgress[0]}
                    partners={partners}
                  />
                ) : (
                  <LoadVideoProjectState
                    existingProjects={projectsInProgress}
                    completedProjects={projectsInReview || null}
                    setProject={setSelectedProject}
                  />
                )}
              </>
            ) : hasLoaded && projectsInReview?.length ? (
              // If projects are being reviewed and there aren't any more recent projects being edited (filtered out previously), show the movie review screen
              <ReviewMoviePreview
                completedProjects={projectsInReview}
                existingProjects={projectsInProgress}
                setProject={setSelectedProject}
              />
            ) : (
              // Otherwise, the projects are still loading and the loading screen should be shown
              <Flex h="100%" justify="center" align="center" direction="column">
                <Box w="300px">
                  <Progress size="xs" isIndeterminate />
                </Box>
                <Text fontWeight="light">Loading your projects</Text>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </PageContainer>
  );
};

export default DiyVideoWrapper;
