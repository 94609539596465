import React from 'react'
import { Routes, Route, useNavigate, useParams } from 'react-router-dom'
import {
    Button,
    Flex,
    Heading,
    Stack,
    Text,
    Center,
    Container,
  } from "@chakra-ui/react";
import PageContainer from '../layout/components/PageContainer'
import ShopifyProvider from '../components/shopify/ShopifyProvider'
import { useStoryContext } from '../components/story/StoryProvider';
import RequestFinalVideoForm from '../components/story/RequestFinalVideoForm'
import StoryCountdown from '../components/common/StoryCountdown'
import { timestampToDate, timestampToMoment } from '../utils/convert'
import FinalVideoView from '../components/story/FinalVideoView';
import PurchaseConcierge from '../components/story/finalVideo/PurchaseConcierge';

import ApplyPartnerCoupons from '../components/partners/components/ApplyPartnerCoupons';

const FinalVideoPage = ({children}) => {
    return (
    <PageContainer minW={undefined} maxW="90vw" w="3xl">
        {children}
    </PageContainer>
    );
}

const PurchaseConciergePage = () => {
    const { id } = useParams();
    return (<ShopifyProvider storyId={id}>
       <PurchaseConcierge storyId={id} />
       <ApplyPartnerCoupons />
    </ShopifyProvider>);
}

const PurchaseDIYPage = () => {
    const { id, diyVideoId } = useParams();
    return (<ShopifyProvider storyId={id} diyVideoId={diyVideoId}>
        <RequestFinalVideoForm />
        <ApplyPartnerCoupons />
    </ShopifyProvider>)
}

const RequestFinalVideoEarly = (props) => {
    const navigate = useNavigate();
    const {story} = useStoryContext();

    const onContinue = () => {
        navigate('../', { replace:true })
    }
    const onNo = () => {
        navigate(-1)
    }

    return (<PageContainer>
      <Stack justify="center" py="sm" spacing={8}>
        <Container textAlign="center">
        <Heading as="h2" size="h2" variant="center">This Quilted project<br />is still in progress</Heading>
        <Stack spacing={6}>
                <Center>
                    <Text>Quilted Movie Due Date: {timestampToMoment(story.dueDate).format("MM/DD/YYYY")}</Text>
                </Center>
                <div>
                        <StoryCountdown dueDate={timestampToDate(story.dueDate)} />
                </div>
            <Stack
            bgColor="springWood.500"
            rounded="md"
            px="xl"
            py="lg"
            align="center"
            justify="center"
            direction="column"
            minH="225px"
            spacing="5">
                <Text textAlign="center">The due date for people to submit videos has not yet passed. <u>The people you invited will no longer be able to submit additional videos after you start your final movie.</u></Text>
                <Text textAlign="center" fontWeight="bold">Do you still want to start your final movie anyways?</Text>
                <Button onClick={onContinue} colorScheme="tumbleweed" variant="outline">Yes, Start Final Movie</Button>
            </Stack>
            <Flex
            minW="full"
            direction="row-reverse"
            align="center"
            justify="end"
            p="sm"
            >
                <Button onClick={onNo} colorScheme="seaGreen">No, I'll Give Them More Time</Button>
            </Flex>
        </Stack>
    </Container>
    </Stack>
    </PageContainer>)
}


const RequestFinalVideoPage = () => {
    return (<Routes>
        <Route index element={<FinalVideoPage><PurchaseConciergePage /></FinalVideoPage>}/>
        <Route path="concierge" exact element={<FinalVideoView />} />
        <Route path="early" exact element={<RequestFinalVideoEarly />} />
        <Route path="diy/:diyVideoId" exact element={<FinalVideoPage><PurchaseDIYPage /></FinalVideoPage>} />
    </Routes>)
}


export default RequestFinalVideoPage;